import { useTheme } from '@emotion/react'
import { ColDef } from 'ag-grid-community'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import { STATUS } from '../Notification/Constants'
import { statuses } from '../Radiology/Constants/constants'
import { radiologyColor } from '../Radiology/Utils/RadiologyColors'
import { allocation } from '../bedManagement/modals'
import EPrescription from '../patients/dashboard/EPrescription'
import OpCaseSheetPrint from '../patients/dashboard/OpCaseSheetPrint'
import { capitalizeFirstLetter } from '../patients/utils'
import { getFilteredEncounters, upComingAppointmentType } from './Utils'
import { ProvenanceData } from '../patients/patientsSlice'
import medicalPrescription from '../../assets/icons/medical-prescription.png'
import OpCaseLogo from '../../assets/icons/opcasesheet.png'
import OpdProvenanceView from './OpdProvenanceView'
import { visitDashboard } from './constant'
import MedAgTable from '../../components/MedAgTable'

const styles = () => ({
  RadiologyDasboardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    gap: 20,
  },
  radiologyDasboardTableContainer: {
    width: '100%',
    height: '100%',
  },
  radiologyDasboardheading: {
    color: radiologyColor?.deepBlue,
    fontSize: '16px',
    fontWeight: 600,
  },
  radiologyDasboardCreateBtn: {
    textTransform: 'capitalize',
    backgroundColor: radiologyColor?.steelBlue,
  },
  deviceText: {
    color: radiologyColor?.deepBlue,
    fontSize: '16px',
    fontWeight: 600,
  },
  centerHeader: {
    color: radiologyColor?.white,
    fontSize: '12px',
    textAlign: 'center',
    background: '#4682b4',
    '& .ag-header-cell-text': {
      flex: 1,
      fontSize: '12px',
      background: '#4682b4',
    },
  },
  fs12: {
    fontSize: '12px',
  },
  pointer: {
    cursor: 'pointer',
  },
  statusText: {
    color: radiologyColor?.blue,
    cursor: 'pointer',
  },
  opdvisitActions: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'center',
  },

  actionIconImg: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
})

type props = {
  encounterDetails: any
  upcomingAppointments: upComingAppointmentType[]
  drInfo: any
  medications: any
  patientData: any
  diagnosisList: any
  labOrderList: any
  onClickVisit: (appointmentId: string) => void
  onShowVisit: (patientId: string, encounterId: string) => void
  onStatusChange: (
    e: React.ChangeEvent<HTMLSelectElement>,
    encounterId: string
  ) => void
  onViewClick: (id: string) => void
  provenances: Record<string, ProvenanceData>
  historyList: any
  examinationList: any
  vitalsList: any
  onFetchPatientData: (e: string, d: string) => void
  handleOpCaseSheet: () => void
  handleEPrescriptionPrint: (e: string) => void
  OpCaseSheetRef: React.MutableRefObject<null>
  eprescriptionRef: React.MutableRefObject<null>
}
const OpdVisitDashboard = ({
  encounterDetails,
  upcomingAppointments,
  drInfo,
  medications,
  patientData,
  diagnosisList,
  labOrderList,
  onClickVisit,
  onShowVisit,
  onStatusChange,
  onViewClick,
  provenances,
  historyList,
  examinationList,
  vitalsList,
  onFetchPatientData,
  handleOpCaseSheet,
  handleEPrescriptionPrint,
  OpCaseSheetRef,
  eprescriptionRef,
}: props) => {
  const themes = useTheme()
  const classes = useCustomStyles(styles, themes)
  const handleCreateVisitClick = (appointmentId: string) => {
    onClickVisit(appointmentId)
  }

  const renderVisitType = (params: any) => {
    const filteredEncounters = getFilteredEncounters(
      encounterDetails,
      params?.data?.appointmentId
    )

    if (filteredEncounters?.length === 0) {
      return <span>{visitDashboard?.general}</span>
    }

    return filteredEncounters?.map((encounter: any) => (
      <span key={encounter?.encounterId}>
        {encounter?.visitType
          ? encounter?.visitType
              ?.toLowerCase()
              ?.split(' ')
              ?.map(
                (word: string) =>
                  word?.charAt(0)?.toUpperCase() + word?.slice(1)
              )
              ?.join(' ')
          : visitDashboard?.general}
      </span>
    ))
  }
  const renderViewVisit = (params: any) => {
    const { appointmentId, status } = params.data
    const filteredEncounters = getFilteredEncounters(
      encounterDetails,
      appointmentId
    )

    if (filteredEncounters?.length > 0) {
      return (
        <>
          {filteredEncounters?.map((encounter: any) => (
            <a
              key={encounter?.encounterId}
              onClick={() => onShowVisit(appointmentId, encounter?.encounterId)}
              className='view-visit-button'
            >
              {visitDashboard?.view}
            </a>
          ))}
        </>
      )
    }

    return status !== visitDashboard?.cancelled ? (
      <a
        onClick={() => handleCreateVisitClick(appointmentId)}
        className='createvisitbutton'
      >
        {visitDashboard?.createVisit}
      </a>
    ) : (
      <span>{visitDashboard?.cancelledCapital}</span>
    )
  }
  const renderTreatmentPlan = (params: any) => {
    const filteredEncounters = getFilteredEncounters(
      encounterDetails,
      params?.data?.appointmentId
    )

    if (filteredEncounters?.length === 0) {
      return <span>{visitDashboard?.hyphen}</span>
    }

    return (
      <>
        {filteredEncounters?.map((encounter: any) =>
          encounter?.visitStatus === STATUS?.FINISHED ? (
            <span key={encounter?.encounterId}>
              {encounter?.visitStatus === visitDashboard?.triaged
                ? visitDashboard?.InProgress
                : statuses?.completed}
            </span>
          ) : (
            <select
              className='medication-input total-width-content medication-container'
              key={encounter?.encounterId}
              onChange={(e) => onStatusChange(e, encounter?.encounterId)}
            >
              <option value={encounter?.encounterId}>
                {encounter?.visitStatus === visitDashboard?.triaged
                  ? visitDashboard?.InProgress
                  : capitalizeFirstLetter(encounter?.visitStatus)}
              </option>
              <option value={visitDashboard?.finished}>
                {visitDashboard?.Completed}
              </option>
            </select>
          )
        )}
      </>
    )
  }
  const renderCreatedProvenanceView = (params: any) => {
    const filteredEncounters = getFilteredEncounters(
      encounterDetails,
      params?.data?.appointmentId
    )

    if (filteredEncounters?.length === 0) {
      return visitDashboard?.hyphen
    }

    return (
      <>
        {filteredEncounters?.map((encounter: any) => (
          <td key={encounter?.encounterId}>
            <OpdProvenanceView
              date={provenances?.[encounter?.encounterId]?.createdOn}
              name={provenances?.[encounter?.encounterId]?.createdBy}
              mode='CREATE'
            />
          </td>
        ))}
      </>
    )
  }
  const renderUpdateProvenanceView = (params: any) => {
    const filteredEncounters = getFilteredEncounters(
      encounterDetails,
      params?.data?.appointmentId
    )

    if (filteredEncounters.length === 0) {
      return visitDashboard?.hyphen
    }

    return (
      <>
        {filteredEncounters?.map((encounter: any) => (
          <div key={`update-${encounter?.encounterId}`}>
            <OpdProvenanceView
              date={provenances?.[encounter?.encounterId]?.updatedOn}
              name={provenances?.[encounter?.encounterId]?.updatedBy}
              mode='UPDATE'
            />
          </div>
        ))}
      </>
    )
  }
  const treatmentplan = (params: any) => {
    const filteredEncounters = getFilteredEncounters(
      encounterDetails,
      params?.data?.appointmentId
    )
    const visitTypeElements = filteredEncounters?.map((encounter: any) => (
      <a
        key={encounter?.encounterId}
        onClick={() => onViewClick(encounter?.encounterId)}
        className='view-visit-button'
      >
        {visitDashboard?.view}
      </a>
    ))

    return visitTypeElements?.length > 0 ? (
      visitTypeElements
    ) : (
      <span>{visitDashboard?.hyphen}</span>
    )
  }

  const renderPrescrition = (params: any) => {
    const { data } = params
    const { appointmentId } = data
    const hasEncounters =
      encounterDetails &&
      encounterDetails?.length > 0 &&
      encounterDetails?.some(
        (encounter: any) => encounter?.appointmentId === appointmentId
      )
    const handlePatientDataClick = async () => {
      await onFetchPatientData(patientData?.id, appointmentId)
      handleOpCaseSheet()
    }
    const handleEPrescriptionClick = async () => {
      await onFetchPatientData(patientData?.id, appointmentId)
      handleEPrescriptionPrint(appointmentId)
    }
    return (
      <div className='box-btn-panel'>
        <div className='e-prescriptionBtn'>
          <div className='opdvisit-opsheet'>
            <EPrescription
              ref={eprescriptionRef}
              patient={patientData}
              visitsList={drInfo}
              diagnosisList={diagnosisList}
              labOrderList={labOrderList} // Empty initially
              medications={medications}
            />
          </div>
        </div>
        <div className='caseSheetButton'>
          <div className='opdvisit-opsheet'>
            <OpCaseSheetPrint
              ref={OpCaseSheetRef}
              visitsList={drInfo}
              patient={patientData}
              historyList={historyList}
              examinationList={examinationList}
              vitalsList={vitalsList}
              diagnosisList={diagnosisList}
              labOrderList={[]}
              radiologyList={[]}
            />
          </div>
        </div>
        {hasEncounters ? (
          <>
            <div className='opcasesheet-print '>
              <div
                onClick={handlePatientDataClick}
                className='opcaseSheet-image-content'
              >
                <img
                  title='OP Case Sheet'
                  src={OpCaseLogo}
                  alt='Print OP Case Sheet'
                  width={'23px'}
                />
              </div>
              <div
                onClick={handleEPrescriptionClick}
                className='opcaseSheet-image-content'
              >
                <img
                  title='E-Prescription'
                  src={medicalPrescription}
                  alt='Print E-Prescription'
                  width={'23px'}
                />
              </div>
            </div>
          </>
        ) : (
          <span>{visitDashboard?.hyphen}</span>
        )}
      </div>
    )
  }

  const columnDefs: ColDef[] = [
    {
      headerName: 'Service',
      field: 'service',
      flex: 1,
      sortable: false,
      filter: false,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      minWidth: 148,
      resizable: false,
    },
    {
      headerName: 'Visit Type',
      field: 'visitType',
      flex: 1,
      sortable: false,
      filter: false,
      headerClass: classes?.centerHeader,
      cellRenderer: renderVisitType,
      cellClass: 'custom-header-cell',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      minWidth: 130,
    },
    {
      headerName: 'Doc Name',
      field: 'docName',
      flex: 1,
      sortable: false,
      filter: false,
      suppressMenu: false,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      minWidth: 130,
    },
    {
      headerName: 'Date',
      field: 'date',
      flex: 1,
      sortable: false,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: classes?.centerHeader,
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      minWidth: 110,
    },
    {
      headerName: 'Time Slot',
      field: 'time',
      flex: 1,
      minWidth: 120,
      sortable: false,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: classes?.centerHeader,
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    {
      headerName: 'View Visit',
      field: 'visit',
      flex: 1,
      sortable: false,
      filter: false,
      cellRenderer: renderViewVisit,
      cellClass: 'custom-header-cell',
      headerClass: classes?.centerHeader,
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      minWidth: 120,
    },
    {
      headerName: 'Treatment Plan',
      field: 'treatmentPlan',
      flex: 1,
      sortable: true,
      minWidth: 140,
      filter: false,
      cellRenderer: treatmentplan,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },

    {
      headerName: 'Status',
      field: 'ordered_on',
      sortable: true,
      cellRenderer: renderTreatmentPlan,
      minWidth: 150,
      cellClass: 'custom-header-cell',
      headerClass: classes?.centerHeader,
      suppressMenu: false,
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    {
      headerName: 'Created By',
      field: 'tests',
      minWidth: 110,
      sortable: false,
      filter: false,
      headerClass: classes?.centerHeader,
      cellRenderer: renderCreatedProvenanceView,
      cellClass: 'custom-header-cell',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    {
      headerName: 'Updated By',
      field: 'tests',
      width: 110,
      sortable: false,
      filter: false,
      cellRenderer: renderUpdateProvenanceView,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    {
      headerName: 'View',
      field: 'actions',
      minWidth: 120,
      sortable: false,
      filter: false,
      cellRenderer: renderPrescrition,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center', display: 'flex' },
    },
  ]

  return (
    <>
      <div className='opd-table'>{allocation?.visitHistory}</div>

      <MedAgTable
        rowData={upcomingAppointments?.sort(
          (a: any, b: any) =>
            new Date(b?.date).getTime() - new Date(a?.date).getTime()
        )}
        columnDefs={columnDefs}
        headerHeight={30}
        pagination={false}
        enableCellTextSelection={true}
        domLayout='autoHeight'
      />
    </>
  )
}
export default OpdVisitDashboard
