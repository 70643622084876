import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material'
import React from 'react'
import parse, { domToReact, DOMNode, Element } from 'html-react-parser'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import { tagElements } from '../../constants'

type dialogProps = {
  interpretationDialogOpen: boolean
  onClose: () => void
  selectedInterpretation: string
}
const styles = (theme: any) => ({
  dialogPaper: {
    maxWidth: '50% !important',
  },
  contentWrapper: {
    margin: '20px auto',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  paragraph: {
    fontSize: '14px',
  },
  strong: {
    color: theme.palette.error.main,
  },
  emphasized: {
    color: theme.palette.primary.main,
  },
  list: {
    paddingLeft: '12px',
  },
  listItem: {
    fontSize: '14px',
    marginBottom: '5px',
    '&::before': {
      content: '"•"',
      marginRight: '5px',
    },
  },
  button: {
    textTransform: 'initial',
  },
})

// Function to format and render HTML content
const formatHtmlContent = (html: string, classes: any) => {
  return parse(html, {
    replace: (domNode: DOMNode) => {
      if (domNode?.type === tagElements?.tag) {
        const element = domNode as Element

        // Handle different HTML tags
        switch (element?.name) {
          case tagElements?.headingOne:
            return (
              <h1 className={classes?.title}>
                {domToReact(element?.children as DOMNode[])}
              </h1>
            )
          case tagElements?.headingTwo:
            return (
              <h2 className={classes?.title}>
                {domToReact(element?.children as DOMNode[])}
              </h2>
            )
          case tagElements?.paragraph:
            return (
              <p className={classes?.paragraph}>
                {domToReact(element?.children as DOMNode[])}
              </p>
            )
          case tagElements?.strong:
            return (
              <strong className={classes?.strong}>
                {domToReact(element?.children as DOMNode[])}
              </strong>
            )
          case tagElements?.unlistItems:
            return (
              <ul className={classes?.list}>
                {domToReact(element?.children as DOMNode[])}
              </ul>
            )
          case tagElements?.listItems:
            return (
              <li className={classes?.listItem}>
                .{domToReact(element?.children as DOMNode[])}
              </li>
            )
          case tagElements?.em:
            return (
              <em className={classes?.emphasized}>
                {domToReact(element?.children as DOMNode[])}
              </em>
            )
          default:
            return domToReact(element?.children as DOMNode[])
        }
      }
    },
  })
}

const InterpretationDialog = ({
  interpretationDialogOpen,
  onClose,
  selectedInterpretation,
}: dialogProps) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  return (
    <Dialog
      open={interpretationDialogOpen}
      onClose={onClose}
      classes={{ paper: classes?.dialogPaper }}
    >
      <DialogTitle className={classes?.title}>Interpretation</DialogTitle>
      <DialogContent>
        <div className={classes?.contentWrapper}>
          {formatHtmlContent(selectedInterpretation, classes)}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary' className={classes?.button}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InterpretationDialog
