import { useRef, useEffect, useState, useCallback, ChangeEvent } from 'react'
import { startSxpProxy } from '../../utils/api'
import '../membership/membership.scss'
import {
  AB_MEMBERHSIP_PROJECT_ID,
  ADMIN_PROJECT_ID,
  ODOO_COGNITIVECARE_INVOICE,
} from '../../utils/constants'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectSelectedLocationId } from '../location/locationSlice'
import { NavLink, useNavigate } from 'react-router-dom'
import { MEMBERSHIP_UPDATE } from '../../utils/roles'
import KeycloakService from '../../utils/keycloakService'
import Edit from '../../assets/icons/edit_icon.svg'
import MaskedNumber from '../../components/MaskedNumber'
import { odooPharmacyLink } from '../appointments/models'
import ProvenanceView from '../../components/ProvenanceView'
import { create } from '../lms/constants'
import MembershipSearch from '../membership/MembershipSearch'
import PrintLogo from '../../assets/images/print.png'
import {
  selectQuery,
  selectParam,
  setQueryParam,
} from '../membership/membershipSlice'
import {
  PaginateModel,
  MembershipModel,
  membershipTableHeadings,
} from '../membership/models'
import {
  ABmembership,
  ABMembershipIntent,
  AyuvuBharosa,
  CognitiveCareInvoice,
  Expired,
  loadingmemebrship,
  membershipCreate,
  MemeberStatus,
  noinvoice,
  noMember,
  of,
  pagesize,
  resize,
  Showing,
  Status,
  to,
} from './constants'
import {
  ABMemberDashboard,
  editABMembership,
} from '../bedManagement/endpoints/EndPoints'
import { forms } from '../administration/constants'
import { unpaid } from '../OPDScreen/constant'
import { next, previous } from '../Radiology/Constants/constants'
import { openUrlFromApi } from '../patients/utils'
import { toast } from 'react-toastify'

const ABMembershipDashboard = () => {
  const query = useAppSelector(selectQuery)
  const param = useAppSelector(selectParam)
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [paginate, setPaginate] = useState<PaginateModel>({
    totalCount: 0,
    totalPages: 0,
    data: [],
  })
  const [memberships, setMemberships] = useState<MembershipModel[]>([])
  const filteredMemberships = memberships?.filter(
    (e) => e?.partner?.ref !== false
  )
  const location = useAppSelector(selectSelectedLocationId)
  const handlePageChange = (newPage: any) => {
    setCurrentPage(newPage)
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [limit, paginate.totalCount])
  const getMemberships = useCallback(
    (q: any, p: any) => {
      const state = {
        location,
        [q]: p,
        limit: limit,
        offset: (currentPage - 1) * limit,
        membershipType: AyuvuBharosa,
      }
      startSxpProxy(
        AB_MEMBERHSIP_PROJECT_ID,
        ABMembershipIntent?.abMembershipLines,
        state
      )
        ?.then((data) => {
          setPaginate({
            totalCount: data?.data?.total_count,
            totalPages: data?.data?.total_pages,
            data: data?.data?.data,
          })
          const members: MembershipModel[] = data?.data?.data ?? []
          setMemberships(members)
        })
        ?.catch((err) => {
          console?.log(err)
          setMemberships([])
          setPaginate({
            totalCount: 0,
            totalPages: 0,
            data: [],
          })
        })
        ?.finally(() => setLoading(false))
    },
    [location, currentPage, limit]
  )
  const index = (currentPage - 1) * limit
  const startIndex = index + 1
  const endIndex = Math?.min(index + limit, paginate?.totalCount)
  useEffect(() => {
    setLoading(true)
    getMemberships(query, param)
  }, [getMemberships, query, param])

  const membershipTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = membershipTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container?.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }
    window.addEventListener(`${resize}`, resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener(`${resize}`, resizeHandler)
      dispatch(setQueryParam({ q: '', param: '' }))
    }
  }, [dispatch])

  const handleCreateMembership = () => {
    navigate(`${membershipCreate}`)
  }
  const openOdooInvoice = (id: any) => {
    openUrlFromApi(ADMIN_PROJECT_ID, odooPharmacyLink, {
      invoiceId: id,
      location: location,
    })
  }
  const onEdit = (id: any) => {
    navigate(`${editABMembership}/${id}`)
  }
  const statusFunction = (id: string) => {
    switch (id) {
      case `${Status?.paid}`:
        return `${MemeberStatus?.paid}`
      case `${Status?.none}`:
        return `${MemeberStatus?.none}`
      case `${Status?.waiting}`:
        return `${MemeberStatus?.waiting}`
      case `${Status?.invoiced}`:
        return `${MemeberStatus?.invoiced}`
      case `${Status?.canceled}`:
        return `${MemeberStatus?.canceled}`
      case `${Status?.old}`:
        return `${MemeberStatus?.old}`
      default:
        return `${MemeberStatus?.Others}`
    }
  }
  const dateCheck = (date: string) => {
    const d1 = new Date(date)
    const d2 = new Date()
    if (d1 >= d2) {
      return `${forms?.active}`
    } else {
      return `${Expired}`
    }
  }
  const handleSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setLimit(Number(e?.target?.value))
  }
  const handleInvoiceClick = (id: any) => {
    if (id) {
      window.open(`${ODOO_COGNITIVECARE_INVOICE}/${id}`, '_blank')
    } else {
      toast.error(noinvoice, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      })
    }
  }
  return (
    <div className='layoutcontent'>
      <div className='membershipText'>{ABmembership}</div>
      <MembershipSearch onCreate={handleCreateMembership} />
      <div
        className='appointments-table-container'
        ref={membershipTableContainerRef}
      >
        <table className='data-table admin-table table-fixed'>
          <thead className='admin-table-head sticky'>
            <tr className='text-center'>
              <th className='table-w-3'>{membershipTableHeadings?.slno}</th>
              <th className='table-w-10'>
                {membershipTableHeadings?.MembershipId}
              </th>
              <th className='table-w-10'>{membershipTableHeadings?.uhid}</th>
              <th className='text-left'>{membershipTableHeadings?.primary}</th>
              <th className='table-w-8'>{membershipTableHeadings?.mobileNo}</th>
              <th className='table-w-8'>
                {membershipTableHeadings?.operatingUnit}
              </th>
              <th>{membershipTableHeadings?.membership}</th>
              <th className='table-w-7'>{membershipTableHeadings?.memType}</th>
              <th className='table-w-7'>{membershipTableHeadings?.validity}</th>
              <th className='table-w-7'>
                {membershipTableHeadings?.memStatus}
              </th>
              <th className='table-w-7'>
                {membershipTableHeadings?.createdBy}
              </th>
              <th className='table-w-6'>
                {membershipTableHeadings?.payStatus}
              </th>
              <th className='table-w-7'>{CognitiveCareInvoice}</th>
              <th className='width-minor'>{membershipTableHeadings?.edit}</th>
            </tr>
          </thead>
          <tbody>
            {filteredMemberships?.length > 0 ? (
              filteredMemberships?.map((d, i) => (
                <tr key={d?.id}>
                  <td className='text-center'>{index + i + 1}</td>
                  <td className='text-center'>
                    <NavLink to={`${ABMemberDashboard}${d?.id}`}>
                      {d?.name}
                    </NavLink>
                  </td>
                  <td>{d?.partner?.ref}</td>
                  <td>{d?.partner?.name}</td>
                  <td className='text-center'>
                    {d?.partner?.phone ? (
                      <MaskedNumber mobile={d?.partner?.phone} />
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                    {
                      d?.account_invoice_id?.operating_unit_id?.[1]?.split(
                        ' '
                      )?.[1]
                    }
                  </td>
                  <td className='text-center'>{d?.membership_id?.[1]}</td>
                  <td className='text-center'>{statusFunction(d?.state)}</td>
                  <td className='text-center'>
                    {d?.state === `${Status?.paid}`
                      ? d?.date_to
                        ? d?.date_to
                        : '-'
                      : '-'}
                  </td>
                  <td className='text-center'>
                    {d?.date_to ? dateCheck(d?.date_to) : '-'}
                  </td>
                  <td>
                    <ProvenanceView
                      mode={create}
                      date={d?.account_invoice_id?.invoice_date_time ?? ''}
                      name={d?.account_invoice_id?.thop_user_name ?? ''}
                    />
                  </td>
                  <td className='text-center'>
                    {d?.state === `${Status?.invoiced}` ||
                    d?.state === `${Status?.waiting}` ? (
                      <span
                        className={
                          d?.cognitivecare_invoice_id === 0 ? 'invoiceLink' : ''
                        }
                        onClick={() =>
                          d?.cognitivecare_invoice_id === 0 &&
                          openOdooInvoice(d?.account_invoice_id?.id)
                        }
                      >
                        {unpaid}
                      </span>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td
                    onClick={() =>
                      handleInvoiceClick(d?.cognitivecare_invoice_id)
                    }
                    className='text-center'
                  >
                    <img src={PrintLogo} alt='Print Report' />
                  </td>
                  <td className='text-center appointmentBtn'>
                    <div
                      onClick={() =>
                        KeycloakService.hasRole([MEMBERSHIP_UPDATE])
                          ? onEdit(d?.id)
                          : undefined
                      }
                      title='Edit Patient'
                      className={`custom-btn custom-btn-primary ml10 ${
                        KeycloakService.hasRole([MEMBERSHIP_UPDATE])
                          ? ''
                          : 'auth-disabled'
                      }`}
                      style={{
                        cursor: KeycloakService.hasRole([MEMBERSHIP_UPDATE])
                          ? 'pointer'
                          : 'default',
                      }}
                    >
                      <img alt='Edit Patient' src={Edit} />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className='text-center' colSpan={8}>
                  {loading ? `${loadingmemebrship}` : `${noMember}`}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='text-right'>
        <div className='inline-block pagination text-right'>
          <span>
            <span className='mr10'>
              {Showing} <b>{startIndex}</b> {to} <b>{endIndex}</b> {of}{' '}
              <b>{paginate?.totalCount}</b>
            </span>
            <select
              className='rowsperpage'
              defaultValue={limit}
              onChange={handleSizeChange}
            >
              {pagesize?.map((size) => (
                <option key={size}>{size}</option>
              ))}
            </select>
          </span>
        </div>

        <div className='inline-block previousButton'>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            className={`mr10 btn btn-primaryBtn ${
              currentPage !== 1 ? '' : 'disableBtn'
            }`}
            disabled={currentPage === 1}
          >
            {previous}
          </button>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            className={`btn btn-primaryBtn ${
              currentPage !== paginate?.totalPages ? '' : 'disableBtn'
            }`}
            disabled={currentPage === paginate?.totalPages}
          >
            {next}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ABMembershipDashboard
