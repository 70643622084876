import React, { useMemo, useCallback, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef, GridReadyEvent } from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { Box } from '@mui/material'

interface DataTableProps<T> {
  rowData: T[]
  columnDefs: ColDef[]
  [key: string]: any
}

const MedAgTable = <T extends object>({
  rowData,
  columnDefs,
  ...rest
}: DataTableProps<T>) => {
  const gridRef = useRef<AgGridReact<T>>(null)
  const defaultColDef = useMemo<ColDef>(
    () => ({
      //   floatingFilter: true,
      resizable: true,
      editable: false,
      //   autoHeaderHeight: true,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const onGridReady = useCallback((params: GridReadyEvent) => {
    params.api.sizeColumnsToFit()
  }, [])

  return (
    <Box className='ag-theme-alpine'>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        {...rest}
      />
    </Box>
  )
}

export default MedAgTable
