import React from 'react'
import {
  readableDateFormat,
  readableDateTimeFormat,
} from '../../../../utils/dateUtils'
import { CREATE, created, lastUpdated, NA } from './constants'
import { emptyString } from '../../../Radiology/Constants/constants'

type Props = {
  date?: string
  name?: string
  mode: 'CREATE' | 'UPDATE'
}

const LmsProvenanceView = ({ date, name, mode }: Props) => {
  return (
    <div
      className='provenance-view'
      title={
        date
          ? `${
              mode === CREATE ? created : lastUpdated
            } on ${readableDateTimeFormat(new Date(date))}`
          : emptyString
      }
    >
      {name ? (
        <>
          <div className='lms-name-content'>
            <span>{name || emptyString}</span>
            <span>
              {date ? readableDateFormat(new Date(date)) : emptyString}
            </span>
          </div>
        </>
      ) : (
        <span className='time'>
          {date ? readableDateTimeFormat(new Date(date)) : NA}
        </span>
      )}
    </div>
  )
}

export default LmsProvenanceView
