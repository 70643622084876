export const GET_ALL_BEDS_PATIENTS_ENDPOINT =
  'api/ipd/bed-patient-assignments/getAllBedAndPatientsByStatusAndLocation'
export const GET_ASSIGNED_PATIENTS_FOR_A_BED_ENDPOINT =
  'api/ipd/bed-patient-assignments/getAssignedPatientForABed?bedStatus='
export const ALL_NOTIFICATIONS_STATUS =
  'api/ipd/notificationstatus/getAllNotifications'
export const ODOOCOGNITIVECARE_URL = '/api/v1/download_invoice_template'
export const ODOOCOGNITIVECARE_INVOICE_URL = '/odoo/invoicing'
export const SEND_WHATSAPP_MESSAGE = '/ipd/notification/sendNotification'
export const DMS_UPLOAD_AND_SEND_NOTIFICATION = '/ipd/notification/upload-file'
export const radiologySearchEndpoint = 'api/radiology/radiologyOrdersSearch'
