import React, { forwardRef } from 'react'
import logo from '../../../assets/icons/logo.svg'
import locationIcon from '../../../assets/icons/location.svg'
import emailIcon from '../../../assets/icons/Email.svg'
import mobileIcon from '../../../assets/icons/mobile.svg'
import { ReactComponent as NoHurts } from '../../../assets/icons/noHurts.svg'
import { ReactComponent as HurtLittle } from '../../../assets/icons/hurtsLittle.svg'
import { ReactComponent as HurtLittleMore } from '../../../assets/icons/hurtsLittleMore.svg'
import { ReactComponent as HurtMore } from '../../../assets/icons/hurtsMore.svg'
import { ReactComponent as HurtLot } from '../../../assets/icons/hurtsLot.svg'
import { ReactComponent as HurtWorst } from '../../../assets/icons/hurtsWorst.svg'
import { Patient } from 'fhir/r4'
import {
  invoiceDateTimeFormat,
  readableDateTime,
  getTotalAge,
} from '../../../utils/dateUtils'
import Barcode from 'react-barcode'
import { upComingAppointmentType } from './patientsDashboard'
import { useAppSelector } from '../../../app/hooks'
import { selectLocations } from '../../location/locationSlice'
import { getLocationAddress } from '../utils'
import './card.scss'
import { followUpdate, profilePicture, signatureText } from '../constants'
import { emptyString } from '../../Radiology/Constants/constants'

type PatientProps = {
  patient: Patient
  historyList: any
  examinationList: any
  vitalsList: any
  diagnosisList: any
  labOrderList: any
  radiologyList: any
  visitsList: any
  upcomingAppointments?: upComingAppointmentType
}

interface historyDataProps {
  [key: string]: string
}

function toCamelCase(str: string) {
  return str
    .replace(/(^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
    .replaceAll('/', '')
}

export const getFilterdData = (dataObj: any) => {
  const Data: historyDataProps = {}
  if (dataObj) {
    dataObj.forEach((item: any) => {
      const key = toCamelCase(item.code.coding[0].display)
      Data[key] = item.valueString ? item.valueString : ''
    })
  }
  return Data
}

const OpCaseSheet = ({
  patient,
  visitsList,
  historyList,
  vitalsList,
  examinationList,
  diagnosisList,
}: PatientProps) => {
  const { name, gender, birthDate } = patient
  const { drName, department, date, signature } = visitsList

  const getRegistrationNo = (patient: Patient | undefined) => {
    const identifier = patient?.identifier
    if (identifier) {
      const regNo = identifier.find((data: any) =>
        data?.system?.includes('patient-identifier') ? data : ''
      )

      const thopId = identifier.find((data: any) =>
        data?.system?.includes('thop-identifier') ? data : ''
      )
      return { regNo, thopId }
    }
    return ''
  }

  const getVitalData = (vitalsList: any) => {
    const Data: historyDataProps = {}
    if (vitalsList) {
      vitalsList.forEach((item: any) => {
        const key = toCamelCase(item.code.coding[0].code)
        Data[key] = item.valueString ? item.valueString : ''
      })
    }
    return Data
  }

  const getBmi = () => {
    let BMI
    if (Height && Weight) {
      BMI = (parseInt(Weight) / parseInt(Height) / parseInt(Height)) * 10000
      BMI = parseInt(BMI.toFixed(2))
      if (BMI) {
        if (BMI < 18.5) {
          BMI = `Underweight- ${BMI}`
        } else if (BMI >= 18.5 && BMI < 25) {
          BMI = `Normal- ${BMI}`
        } else if (BMI >= 25 && BMI < 30) {
          BMI = `Overweight- ${BMI}`
        } else {
          BMI = `Obese- ${BMI}`
        }
      }
    }
    return BMI
  }

  const getSliced = ({ data, length }: any) => data.slice(0, length - 3) + '...'

  const patientName = `${name?.[0]?.given?.[0]} ${
    name?.[0]?.given?.[1] ? name?.[0]?.given?.[1] : ''
  } ${name?.[0]?.family}`

  const age = birthDate ? getTotalAge(new Date(birthDate)) : ''

  const DrName = `${drName?.[0]?.given?.[0] ?? ''} ${
    drName?.[0]?.given?.[1] ? drName?.[0]?.given?.[1] : ''
  } ${drName?.[0]?.family ?? ''}`.trim()

  const registrationNoData: any = getRegistrationNo(patient)

  const registrationNo = registrationNoData?.regNo?.value
    ? registrationNoData?.regNo?.value
    : registrationNoData?.thopId?.value

  const barCodeData = registrationNo ?? ''

  const currentDate = invoiceDateTimeFormat(new Date())

  const appointmentDate = readableDateTime(date)

  const locations = useAppSelector(selectLocations)
  const locationAddress = getLocationAddress(registrationNo, locations)

  const historyData = getFilterdData(historyList)
  const {
    AlcoholicHistory,
    BowelAndBladder,
    CheifComplaints,
    Clubbing,
    Cyanosis,
    Diet,
    Edema,
    FamilyHistory,
    Height,
    HistoryOfDrugAllergies,
    HistoryOfPresentIllness,
    HistoryOfPreviousSurgeries,
    Icterus,
    Lymphadenopathy,
    ObstetricHistory,
    OtherRelevantHistory,
    Pallor,
    PastHistory,
    Sleep,
    SmokingHistory,
    Weight,
    WongBakerPainScore,
  } = historyData

  const vitalData = getVitalData(vitalsList)
  const { Diastolic, Posture, Pulse, Resprate, Spo2, Systolic, Temparature } =
    vitalData

  const examinationListData = getFilterdData(examinationList)
  const { CardiovascularSystem, RespiratorySystem, NervousSystem, PerAbdomen } =
    examinationListData

  const diagnosisData = getFilterdData(diagnosisList)
  const {
    ProvisionalDiagnosis,
    DifferentialDiagnosis,
    InvestigationsDiagnostics,
    FinalDiagnosis,
    TreatmentPlan,
  } = diagnosisData

  return (
    <div>
      <link
        href='https://fonts.googleapis.com/css?family=Roboto'
        rel='stylesheet'
      />
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n            body{margin: 0; padding: 0;}\n            *{box-sizing: border-box;}\n            @page :left {\n                margin-left: 0cm;\n                margin-right: 0cm;\n                margin-top: 0cm;\n                margin-bottom: 0cm;\n            }\n            @page :right {\n                margin-left: 0cm;\n                margin-right: 0cm;\n                margin-top: 0cm;\n                margin-bottom: 0cm;\n            }\n      \n        ',
        }}
      />
      <div className='opCaseSheetMain'>
        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className='tableBody'>
                  <div className='tableBody-Head'>
                    <div className='medUniLogo'>
                      <img src={logo ?? ''} alt='Medunited Logo' />
                    </div>
                    <div className='medUniLogo' />
                    <div className='addressArea'>
                      <img src={locationIcon} alt='location icon' />
                      <p />
                      {locationAddress?.address?.line?.[0]}
                      <p />
                      <div className='mobileNoAddress'>
                        <div>
                          <img src={mobileIcon} alt='mobile icon' />
                          <p />
                          {locationAddress?.telecom?.[0]?.value}
                          <p />
                        </div>
                        &nbsp; &nbsp;
                        <div className='emailAddress'>
                          <img alt='email icon' src={emailIcon} />
                          <p style={{ fontSize: '17px' }}>
                            {locationAddress?.telecom?.[1]?.value}
                          </p>
                        </div>
                      </div>
                      <div className='barCodeOpcaseSheet'>
                        {barCodeData && (
                          <Barcode
                            value={'' + barCodeData}
                            height={30}
                            width={1.3}
                            margin={0}
                            fontSize={12}
                          />
                        )}
                      </div>
                      &nbsp;
                    </div>
                    <div className='titleOpcaseSheet'>
                      <h4> OUT PATIENT CASE SHEET</h4>
                    </div>
                  </div>
                  <hr className='horizontalLineOP' />
                  <div className='headerRibbon'>
                    <div className='flexOne'>
                      <div className='headerRibbon'>
                        <div className='flexPointSeven patientUHID'>
                          <p>Patient UHID :</p>
                        </div>
                        <div className='patientUHIDValue'>
                          {' '}
                          <span>{registrationNo ?? ''}</span>
                        </div>
                      </div>
                      <div className='headerRibbon'>
                        <div className='flexPointSeven patientUHID'>
                          <p>Name :</p>
                        </div>
                        <div className='patientUHIDValue'>
                          {' '}
                          <span>{patientName ?? ''}</span>
                        </div>
                      </div>
                      <div className='headerRibbon'>
                        <div className='flexPointSeven patientUHID'>
                          <p>Gender :</p>
                        </div>
                        <div className='patientUHIDValue'>
                          {' '}
                          <span>{gender ?? ''}</span>
                        </div>
                      </div>
                      <div className='headerRibbon'>
                        <div className='flexPointSeven patientUHID'>
                          <p>Age :</p>
                        </div>
                        <div className='patientUHIDValue'>
                          {' '}
                          <span>{age ?? ''}</span>
                        </div>
                      </div>
                    </div>
                    <div className='flexOne'>
                      <div className='headerRibbon'>
                        <div className='flexPointSeven patientUHID'>
                          <p>Date :</p>
                        </div>
                        <div className='patientUHIDValue'>
                          {' '}
                          <span>{appointmentDate ?? ''}</span>
                        </div>
                      </div>
                      <div className='headerRibbon'>
                        <div className='flexPointSeven patientUHID'>
                          <p>Department :</p>
                        </div>
                        <div className='patientUHIDValue'>
                          {' '}
                          <span>{department ?? ''}</span>
                        </div>
                      </div>
                      <div className='headerRibbon'>
                        <div className='flexPointSeven patientUHID'>
                          <p>Doctor Name :</p>
                        </div>
                        <div className='patientUHIDValue'>
                          {' '}
                          <span>Dr. {DrName ?? 'NA'}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className='wongPainScoreLine'>
                    <span>Wong-Baker Pain Score</span>
                  </p>
                  <hr className='horizontalLine' />
                  <div className='wongPainScoreParam'>
                    <div className='flexOne'>
                      <NoHurts
                        style={{ fill: '#2372C0', stroke: '#3155c5a8' }}
                      />
                      <br />0<p className='pText'>No Hurt</p>
                      <br />
                      <input
                        className='pTextInput'
                        type='checkbox'
                        checked={WongBakerPainScore === 'nh'}
                        readOnly
                      />
                    </div>
                    <div className='flexOne'>
                      <HurtLittle
                        style={{ fill: '#4A6940', stroke: '#4c58a5a8' }}
                      />
                      <br />2
                      <p className='pText'>
                        Hurts
                        <br />
                        little bit
                      </p>
                      <input
                        className='pTextInput'
                        type='checkbox'
                        checked={WongBakerPainScore === 'hlb'}
                        readOnly
                      />
                    </div>
                    <div className='flexOne'>
                      <HurtLittleMore
                        style={{ fill: '#72617E', stroke: '#7a567ea8' }}
                      />
                      <br />4
                      <p className='pText'>
                        Hurts
                        <br />
                        little more
                      </p>
                      <input
                        className='pTextInput'
                        type='checkbox'
                        checked={WongBakerPainScore === 'hlm'}
                        readOnly
                      />
                    </div>
                    <div className='flexOne'>
                      <HurtMore
                        style={{ fill: '#8C5B69', stroke: '#a84859a8' }}
                      />
                      <br />6
                      <p className='pText'>
                        Hurts
                        <br />
                        Even More
                      </p>
                      <input
                        className='pTextInput'
                        type='checkbox'
                        checked={WongBakerPainScore === 'hem'}
                        readOnly
                      />
                    </div>
                    <div className='flexOne'>
                      <HurtLot
                        style={{ fill: '#B55346', stroke: '#B55346a8' }}
                      />
                      <br />8
                      <p className='pText'>
                        Hurts
                        <br />
                        Whole Lot
                      </p>
                      <input
                        className='pTextInput'
                        type='checkbox'
                        checked={WongBakerPainScore === 'hwl'}
                        readOnly
                      />
                    </div>
                    <div className='flexOne'>
                      <HurtWorst
                        style={{ fill: '#DF4A23', stroke: '#ff0000a8' }}
                      />
                      <br />
                      10
                      <p className='pText'>
                        {' '}
                        Hurts
                        <br />
                        Worst
                      </p>
                      <input
                        className='pTextInput'
                        type='checkbox'
                        checked={WongBakerPainScore === 'hw'}
                        readOnly
                      />
                    </div>
                  </div>
                  <p className='wongPainScoreLine'>
                    <span>History &amp; Examination</span>
                  </p>
                  <hr className='horizontalLine' />
                  <div>
                    <p className='subTitleOpSheet'>
                      <span>Chief Complaints &amp; Duration :</span>
                    </p>
                    <span className='caseSheetSummery'>
                      {CheifComplaints && CheifComplaints.length > 1200
                        ? getSliced({ data: CheifComplaints, length: 1200 })
                        : CheifComplaints}
                    </span>
                  </div>
                  <div>
                    <p className='subTitleOpSheet'>
                      <span>History of Presenting Illness :</span>
                    </p>
                    <span className='caseSheetSummery'>
                      {HistoryOfPresentIllness &&
                      HistoryOfPresentIllness.length > 1200
                        ? getSliced({
                            data: HistoryOfPresentIllness,
                            length: 1200,
                          })
                        : HistoryOfPresentIllness}
                    </span>
                  </div>
                  <div>
                    <p className='subTitleOpSheet'>
                      <span>Past History :</span>
                    </p>
                    <span
                      className='caseSheetSummery'
                      style={{
                        minHeight: PastHistory?.length > 1200 ? '200px' : '',
                      }}
                    >
                      {PastHistory && PastHistory.length > 1200
                        ? getSliced({
                            data: PastHistory,
                            length: 1200,
                          })
                        : PastHistory ?? ''}
                    </span>
                  </div>
                  <p className='pHistory top55'>
                    <span>Personal History</span>
                  </p>
                  <hr className='horizontalLine' />
                  <div className='pHistorySec'>
                    <div className='felxOne'>
                      <div className='headerRibbon'>
                        <div className='flexOne'>
                          <p className='pHistoryMenu'> Diet :</p>
                          <p className='pHistoryMenu marginTop30'> Sleep :</p>
                          <p className='pHistoryMenu marginTop30'>
                            {' '}
                            Bowel &amp; Bladder :
                          </p>
                        </div>
                        <div className='flexOne'>
                          <div className='headerRibbon'>
                            <div className='pDataCheckBox'>
                              <input
                                checked={Diet === 'veg'}
                                readOnly
                                type='checkbox'
                              />
                            </div>
                            <div
                              className='flexOne'
                              style={{ marginRight: '100px' }}
                            >
                              <p className='pHistoryMenu'>Veg</p>
                            </div>
                          </div>
                          <div className='headerRibbon'>
                            <div className='pDataCheckBox'>
                              <input
                                checked={Sleep === 'adequate'}
                                readOnly
                                type='checkbox'
                              />
                            </div>
                            <div
                              className='flexOne'
                              style={{ marginRight: '87px' }}
                            >
                              <p className='pHistoryMenu'>Adequate</p>
                            </div>
                          </div>
                          <div className='headerRibbon'>
                            <div className='pDataCheckBox'>
                              <input
                                checked={BowelAndBladder === 'regular'}
                                readOnly
                                type='checkbox'
                              />
                            </div>
                            <div
                              className='felxOne'
                              style={{ marginRight: '96px' }}
                            >
                              <p className='pHistoryMenu'>Regular</p>
                            </div>
                          </div>
                        </div>
                        <div className='flexOne'>
                          <div className='headerRibbon'>
                            <div className='pDataCheckBox'>
                              <input
                                checked={Diet === 'mixed'}
                                readOnly
                                type='checkbox'
                              />
                            </div>
                            <div
                              className='flexOne'
                              style={{ marginRight: '106px' }}
                            >
                              <p className='pHistoryMenu'>Mixed</p>
                            </div>
                          </div>
                          <div className='headerRibbon'>
                            <div className='pDataCheckBox'>
                              <input
                                checked={Sleep === 'inadequate'}
                                readOnly
                                type='checkbox'
                              />
                            </div>
                            <div
                              className='flexOne'
                              style={{ marginRight: '78px' }}
                            >
                              <p className='pHistoryMenu'>Inadequate</p>
                            </div>
                          </div>
                          <div className='headerRibbon'>
                            <div className='pDataCheckBox'>
                              <input
                                checked={BowelAndBladder === 'irregular'}
                                readOnly
                                type='checkbox'
                              />
                            </div>
                            <div
                              className='felxOne'
                              style={{ marginRight: '92px' }}
                            >
                              <p className='pHistoryMenu'>Irregular</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className='flexOne'
                      style={{ border: '1px solid #ccc' }}
                    >
                      <div style={{ borderBottom: '1px solid #ccc' }}>
                        <p
                          className='pHistoryMenu pSummary'
                          style={{
                            marginTop: '5px',
                            alignContent: 'flex-start',
                            textAlign: 'left',
                          }}
                        >
                          Smoking History :{' '}
                          <span className='font13px height50'>
                            {SmokingHistory ?? '-'}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p
                          className='pHistoryMenu pSummary'
                          style={{
                            marginTop: '5px',
                          }}
                        >
                          Alcohol History :{' '}
                          <span className='font13px '>
                            {AlcoholicHistory ?? '-'}
                          </span>{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='otherHistoryBox'>
                    <p className='pSummary pText'>
                      <span> History of Drug Allergies :</span>
                      <span className='font13px'>{HistoryOfDrugAllergies}</span>
                    </p>
                  </div>
                  <div className='otherHistoryBox'>
                    <p className='pSummary pText'>
                      <span> Other Relevant Habitual History :</span>
                      <span className='font13px'>
                        {OtherRelevantHistory &&
                        OtherRelevantHistory.length > 450
                          ? getSliced({
                              data: OtherRelevantHistory,
                              length: 450,
                            })
                          : OtherRelevantHistory}
                      </span>
                    </p>
                  </div>
                  <p className='familyHistoryOp'>
                    <span>Family History </span>
                  </p>{' '}
                  <hr className='horizontalLine' />
                  <span className='familyHistorySummary caseSheetSummery'>
                    {FamilyHistory && FamilyHistory.length > 1200
                      ? getSliced({
                          data: FamilyHistory,
                          length: 1200,
                        })
                      : FamilyHistory}
                  </span>
                  <p className='ObstetricHistory'>
                    <span>Obstetric History</span>
                  </p>
                  <hr className='horizontalLine' />
                  <span className='familyHistorySummary caseSheetSummery'>
                    {ObstetricHistory && ObstetricHistory.length > 1200
                      ? getSliced({
                          data: ObstetricHistory,
                          length: 1200,
                        })
                      : ObstetricHistory}
                  </span>
                  <p className='ObstetricHistory'>
                    <span>History of Previous Surgeries</span>
                  </p>
                  <hr className='horizontalLine' />
                  <span className='familyHistorySummary caseSheetSummery'>
                    {HistoryOfPreviousSurgeries}
                  </span>
                  <p className='ObstetricHistory'>
                    <span>General Physical Examination</span>
                  </p>
                  <hr className='horizontalLine' />
                  <div style={{ display: 'flex', marginTop: '20px' }}>
                    <div className='flexOne'>
                      <label className='pText'>Height (CM&apos;s)</label>
                      <input className='phyExamValue' readOnly value={Height} />
                    </div>
                    <div className='flexOne'>
                      <label className='pText'>Weight (KG&apos;s)</label>
                      <input className='phyExamValue' readOnly value={Weight} />
                    </div>
                    <div className='flexOne'>
                      <label className='pText'>BMI</label>
                      <input
                        className='phyExamValue'
                        readOnly
                        value={getBmi()}
                      />
                    </div>
                  </div>
                  <div className='generalExam'>
                    <div className='headerRibbon'>
                      <div className='flexOne'>
                        <input
                          className='inputGeneralExam'
                          readOnly
                          checked={Pallor === 'yes'}
                          type='checkbox'
                        />
                      </div>
                      <div className='generalExamTitle'>
                        <label className='pText'>Pallor</label>
                      </div>
                    </div>

                    <div className='headerRibbon marginLeft80'>
                      <div className='flexOne'>
                        <input
                          className='inputGeneralExam'
                          readOnly
                          checked={Icterus === 'yes'}
                          type='checkbox'
                        />
                      </div>
                      <div className='generalExamTitle'>
                        <label className='pText'>Icterus</label>
                      </div>
                    </div>
                    <div className='headerRibbon marginLeft80'>
                      <div className='flexOne'>
                        <input
                          className='inputGeneralExam'
                          readOnly
                          checked={Cyanosis === 'yes'}
                          type='checkbox'
                        />
                      </div>
                      <div className='generalExamTitle'>
                        <label className='pText'>Cyanosis</label>
                      </div>
                    </div>
                    <div className='headerRibbon marginLeft80'>
                      <div className='flexOne'>
                        <input
                          className='inputGeneralExam'
                          readOnly
                          checked={Clubbing === 'yes'}
                          type='checkbox'
                        />
                      </div>
                      <div className='generalExamTitle'>
                        <label className='pText'>Clubbing</label>
                      </div>
                    </div>
                    <div className='headerRibbon marginLeft80'>
                      <div className='flexOne'>
                        <input
                          className='inputGeneralExam'
                          readOnly
                          checked={Lymphadenopathy === 'yes'}
                          type='checkbox'
                        />
                      </div>
                      <div className='generalExamTitle'>
                        <label className='pText'>Lymphadenopathy</label>
                      </div>
                    </div>
                    <div className='headerRibbon marginLeft80'>
                      <div className='flexOne'>
                        <input
                          className='inputGeneralExam'
                          readOnly
                          checked={Edema === 'yes'}
                          type='checkbox'
                        />
                      </div>
                      <div className='inputGeneralExam'>
                        <label className='pText'>Edema</label>
                      </div>
                    </div>
                  </div>
                  <p className='ObstetricHistory top55'>
                    <span>Vitals</span>
                  </p>
                  <hr className='horizontalLine' />
                  <div className='headerRibbon'>
                    <div style={{ width: '29%' }}>
                      <div className='vitalOp'>
                        <div
                          className='vitalOpInner'
                          style={{
                            width: '35px',
                          }}
                        >
                          <p
                            className='pText'
                            style={{
                              marginLeft: '60px',
                            }}
                          >
                            Pulse
                          </p>
                          &nbsp;
                          <p className='pText'>
                            Blood Pressure
                            <br />
                            (mm Hg)
                          </p>
                        </div>
                        <div style={{ flex: 1, width: '58%' }}>
                          <input
                            className='vitalInput'
                            readOnly
                            value={Pulse ?? ''}
                          />
                          &nbsp;
                          <input
                            className='vitalInput'
                            style={{
                              marginTop: '20px',
                            }}
                            readOnly
                            value={Systolic ? `${Systolic} / ${Diastolic}` : ''}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '30%', marginLeft: '20px' }}>
                      <div className='vitalOp'>
                        <div
                          className='vitalOpInner'
                          style={{
                            width: '41%',
                          }}
                        >
                          <p className='pText'>Temperature(F)</p>
                          &nbsp;
                          <p
                            className='pText'
                            style={{
                              marginLeft: '50px',
                            }}
                          >
                            Posture
                          </p>
                        </div>
                        <div style={{ flex: 1, width: '54%' }}>
                          <input
                            className='vitalInput'
                            readOnly
                            value={Temparature ?? ''}
                          />
                          &nbsp;
                          <input
                            className='vitalInput'
                            readOnly
                            style={{
                              marginTop: '20px',
                            }}
                            value={Posture ?? ''}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ width: '33%' }}>
                      <div className='vitalOp'>
                        <div
                          className='vitalOpInner'
                          style={{
                            width: '40%',
                          }}
                        >
                          <p className='pText'>Respiratory Rate</p>
                          &nbsp;
                          <p
                            className='pText'
                            style={{
                              marginLeft: '58px',
                            }}
                          >
                            Spo2 (%)
                          </p>
                        </div>
                        <div style={{ flex: 1, width: '50%' }}>
                          <input
                            className='vitalInput'
                            readOnly
                            value={Resprate ?? ''}
                          />
                          &nbsp;
                          <input
                            className='vitalInput'
                            readOnly
                            value={Spo2}
                            style={{
                              marginTop: '20px',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className='ObstetricHistory'>
                    <span>Systemic Examination</span>
                  </p>
                  <hr className='horizontalLine' />
                  <p
                    className='pText'
                    style={{
                      marginBottom: '4px',
                    }}
                  >
                    <span>Cardiovascular System : </span>
                  </p>
                  <span className='caseSheetSummery'>
                    {CardiovascularSystem && CardiovascularSystem.length > 1200
                      ? getSliced({
                          data: CardiovascularSystem,
                          length: 1200,
                        })
                      : CardiovascularSystem ?? ''}
                  </span>
                  <p
                    className='pText'
                    style={{
                      marginBottom: '4px',
                      // marginTop: '25px',
                    }}
                  >
                    <span>Respiratory System :</span>
                  </p>
                  <span className='caseSheetSummery'>
                    {RespiratorySystem && RespiratorySystem.length > 1200
                      ? getSliced({
                          data: RespiratorySystem,
                          length: 1200,
                        })
                      : RespiratorySystem ?? ''}
                  </span>
                  <p
                    className='pText'
                    style={{
                      marginBottom: '4px',
                      // marginTop: '25px',
                    }}
                  >
                    <span>Nervous System : </span>
                  </p>
                  <span className='caseSheetSummery'>
                    {NervousSystem && NervousSystem.length > 1200
                      ? getSliced({
                          data: NervousSystem,
                          length: 1200,
                        })
                      : NervousSystem ?? ''}
                  </span>
                  <p
                    className='pText'
                    style={{
                      marginBottom: '4px',
                      // marginTop: '25px',
                    }}
                  >
                    <span>P/A (Per Abdomen) : </span>
                  </p>
                  <span className='caseSheetSummery'>
                    {PerAbdomen && PerAbdomen.length > 1200
                      ? getSliced({
                          data: PerAbdomen,
                          length: 1200,
                        })
                      : PerAbdomen ?? ''}
                  </span>
                  <p className='diagnosis'>
                    <span>Diagnosis</span>
                  </p>
                  <hr className='horizontalLine' />
                  {/* &nbsp; */}
                  <div className='diagnosisSummaryBox'>
                    <div className='diagnosisSummaryBoxInner'>
                      <p>
                        <span>Provisional Diagnosis : </span>
                        <span className='font13px'>
                          {ProvisionalDiagnosis &&
                          ProvisionalDiagnosis.length > 600
                            ? getSliced({
                                data: ProvisionalDiagnosis,
                                length: 600,
                              })
                            : ProvisionalDiagnosis ?? ''}
                        </span>
                      </p>
                    </div>
                    <div className='flexOne pSummary'>
                      <p className='pText'>
                        <span>Final Diagnosis : </span>
                        <span className='font13px'>
                          {FinalDiagnosis && FinalDiagnosis.length > 600
                            ? getSliced({
                                data: FinalDiagnosis,
                                length: 600,
                              })
                            : FinalDiagnosis ?? ''}
                        </span>
                      </p>
                    </div>
                  </div>
                  <p className='ObstetricHistory top50'>
                    <span>Differential Diagnosis </span>
                  </p>
                  <hr className='horizontalLine' />
                  <span className='caseSheetSummery'>
                    {DifferentialDiagnosis &&
                    DifferentialDiagnosis.length > 1600
                      ? getSliced({
                          data: DifferentialDiagnosis,
                          length: 1600,
                        })
                      : DifferentialDiagnosis ?? ''}
                  </span>
                  <p className='ObstetricHistory'>
                    <span>Investigations / Diagnostics </span>
                  </p>
                  <hr className='horizontalLine' />
                  <span
                    // className='investTreatPlan'
                    style={{ display: 'inline-block', height: '400px' }}
                  >
                    {InvestigationsDiagnostics &&
                    InvestigationsDiagnostics.length > 1700
                      ? getSliced({
                          data: InvestigationsDiagnostics,
                          length: 1700,
                        })
                      : InvestigationsDiagnostics ?? ''}
                  </span>
                  <p className='ObstetricHistory'>
                    <span>Treatment Plan</span>
                  </p>
                  <hr className='horizontalLine' />
                  <span
                    // className='investTreatPlan'
                    style={{ display: 'inline-block', height: '500px' }}
                  >
                    {TreatmentPlan && TreatmentPlan.length > 1700
                      ? getSliced({
                          data: TreatmentPlan,
                          length: 1700,
                        })
                      : TreatmentPlan ?? ''}
                  </span>
                  <div className='signAreaOp'>
                    <div className='followup-container'>
                      <p className='pText'>
                        {followUpdate} : {'{'}
                        {currentDate}
                        {'}'}
                      </p>
                    </div>

                    <div
                      className={`signature-container ${
                        signature === '' && 'inactive'
                      }`}
                    >
                      <div className='signature-details'>
                        {signature !== emptyString && (
                          <img
                            className='admin-signature'
                            src={signature}
                            alt={profilePicture}
                          />
                        )}
                      </div>
                      <hr className='horizontalLine' />
                      <p className='pText'>{signatureText}</p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            {/* <tr>
              <td style={{ height: '100px' }}>&nbsp;</td>
            </tr> */}
          </tfoot>
        </table>
        <div className='ribbon'>
          <div className='headerRibbon'>
            <div className='orangeline' />
            <div className='blueline' />
          </div>
        </div>
        <div className='footerOpcaseSheet'>
          <div className='headerRibbon'>
            <div className='orangeline' />
            <div className='blueline' />
          </div>
        </div>
      </div>
    </div>
  )
}
const OpCaseSheetPrint = forwardRef<HTMLDivElement, PatientProps>(
  function Label(props, ref) {
    const {
      patient,
      historyList,
      examinationList,
      vitalsList,
      diagnosisList,
      labOrderList,
      radiologyList,
      visitsList,
      upcomingAppointments,
    } = props
    return (
      <div ref={ref}>
        <OpCaseSheet
          patient={patient}
          visitsList={visitsList}
          historyList={historyList}
          examinationList={examinationList}
          vitalsList={vitalsList}
          diagnosisList={diagnosisList}
          labOrderList={labOrderList}
          radiologyList={radiologyList}
          upcomingAppointments={upcomingAppointments}
        />
      </div>
    )
  }
)

export default OpCaseSheetPrint
