import { useTheme } from '@emotion/react'
import { useCallback, useEffect, useState } from 'react'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { CellClickedEvent, ColDef } from 'ag-grid-community'
import { startSxpProxy } from '../../utils/api'
import {
  CRITICAL_BED_ID,
  IPD_PROJECT_ID,
  NON_CRITICAL_BED_ID,
} from '../../utils/constants'
import {
  AmbulanceDialogDetails,
  Beds,
  INTENT_GET_WARD_ROOM_BED_DETAILS,
  ProrithmDevices,
  Rooms,
  Wards,
  constants,
  mappingStatus,
  messages,
  paginationOptions,
} from './Constants'
import { toast } from 'react-toastify'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectLocations } from '../location/locationSlice'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  checkDeviceMappingStatus,
  deleteProrithmDevice,
  editProrithmMapping,
  getAllProrithmDevices,
  getAmbulanceDetails,
} from './utils'
import { selectAddDeviceCount, setAddDeviceCount } from './ProrithmSlice'
import ProRithmAgDataGrid from './ProRithmAgDataGrid'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import MapToAmbulanceForm from './MappingForms/MapToAmbulance'

export const styles = () => ({
  proRithmDasboardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '2%',
    gap: 20,
  },
  proRithmDasboardTableContainer: {
    width: '100%',
    height: '100%',
  },
  proRithmDasboardheading: {
    color: '#090968',
    fontSize: '16px',
    fontWeight: 600,
  },
  proRithmDasboardCreateBtn: {
    textTransform: 'capitalize',
    backgroundColor: '#4682b4',
  },
  deviceText: {
    color: '#090968',
    fontSize: '16px',
    fontWeight: 600,
  },
  centerHeader: {
    color: '#ffffff',
    textAlign: 'center',
    '& .ag-header-cell-text': {
      flex: 1,
    },
  },
  lightBlue: {
    background: '#4682b4',
  },
})

const ProRithmDasboard = () => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const dispatch = useAppDispatch()
  const locations = useAppSelector(selectLocations)
  const addDeviceCount = useAppSelector(selectAddDeviceCount)
  const [prorithmDeviceList, setProrithmDeviceList] = useState<
    ProrithmDevices[]
  >([])
  const [deviceMappingStatus, setDeviceMappingStatus] = useState<string>('')
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(20)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [openAddToDialog, setOpenAddToDialog] = useState<boolean>(false)
  const [mapTo, setMapTo] = useState<string>('')
  const [selectedLocation, setSelectedLocation] = useState<string>('')
  const [bedType, setBedType] = useState<string | undefined>('')
  const [wards, setWards] = useState<Wards[]>([])
  const [rooms, setRooms] = useState<Rooms[]>([])
  const [beds, setBeds] = useState<Beds[]>([])
  const [selectedWard, setSelectedWard] = useState<string>('')
  const [selectedRoom, setSelectedRoom] = useState<string>('')
  const [selectedBed, setSelectedBed] = useState<string>('')
  const [openDeleteDeviceDialog, setOpenDeleteDeviceDialog] =
    useState<boolean>(false)
  const [openEditDeviceDialog, setOpenEditDeviceDialog] =
    useState<boolean>(false)
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>('')
  const [openAmbulanceDetails, setOpenAmbulanceDetails] =
    useState<boolean>(false)
  const [ambulanceDetails, setAmbulanceDetails] =
    useState<AmbulanceDialogDetails | null>(null)

  const formik = useFormik({
    initialValues: {
      ambulanceNumber: '',
      location: '',
      driverPhone: '',
      emtPhone: '',
      status: '',
    },
    validationSchema: Yup.object({
      ambulanceNumber: Yup.string()
        .matches(
          /^[a-zA-Z0-9]+$/,
          'Ambulance number must be alphanumeric and cannot contain special characters'
        )
        .required('Ambulance number is required'),
      location: Yup.string().required('Location is required'),
      driverPhone: Yup.string()
        .matches(/^\d{10}$/, 'Driver phone number must be exactly 10 digits')
        .required('Driver phone number is required'),
      emtPhone: Yup.string()
        .matches(/^\d{10}$/, 'EMT phone number must be exactly 10 digits')
        .required('EMT phone number is required'),
      status: Yup.string().required('Status is required'),
    }),

    onSubmit: () => {
      handleDeviceMapping()
    },
  })

  const DeviceMappingActions = (params: any) => {
    return (
      <>
        <IconButton
          size='small'
          id='edit-prorithm-device'
          onClick={() => handleEditDevice(params?.data)}
        >
          <ModeEditOutlinedIcon fontSize='small' />
        </IconButton>
        <IconButton
          size='small'
          id='delete-prorithm-device'
          onClick={() => handleDeleteDevice(params?.data)}
        >
          <DeleteOutlineOutlinedIcon fontSize='small' />
        </IconButton>
      </>
    )
  }

  const columnDefs: ColDef[] = [
    {
      headerName: 'S No',
      field: 'sNo',
      width: 80,
      sortable: false,
      filter: false,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
    },
    {
      headerName: 'Prorithm ID',
      field: 'prorithmDeviceId',
      flex: 1,
      sortable: false,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: {
        fontSize: '12px',
        textDecoration: 'underline',
        cursor: 'pointer',
      },
      onCellClicked: (event: CellClickedEvent) => {
        handleDeviceClick(event)
      },
    },
    {
      headerName: 'Location',
      field: 'locationName',
      flex: 1,
      sortable: false,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
    },
    {
      headerName: 'Ward',
      field: 'wardName',
      flex: 1,
      sortable: false,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
    },
    {
      headerName: 'Room',
      field: 'roomName',
      flex: 1,
      sortable: false,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
    },
    {
      headerName: 'Bed',
      field: 'bedName',
      flex: 1,
      sortable: false,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
    },
    {
      headerName: 'Ambulance Detail',
      field: 'ambDetail',
      flex: 1,
      sortable: false,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellRenderer: (params: any) => {
        const prorithmId = params?.data?.prorithmDeviceId
        return (
          <IconButton
            size='small'
            onClick={() => handleAmbulanceDialog(prorithmId)}
          >
            <InfoOutlinedIcon fontSize='small' />
          </IconButton>
        )
      },
      cellStyle: {
        fontSize: '12px',
        alignItems: 'center',
        textAlign: 'center',
      },
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 100,
      sortable: false,
      filter: false,
      cellRenderer: DeviceMappingActions,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
    },
  ]

  const handleAmbulanceDialog = (prorithmId: string) => {
    setOpenAmbulanceDetails(true)
    const result = getAmbulanceDetails(prorithmId)
    result
      .then((res) => {
        const data = {
          prorithmDeviceId: res?.prorithmDeviceId,
          locationName: res?.locationName,
          ambulanceNumber: res?.ambulanceNumber,
          driverPhoneNumber: res?.driverPhoneNumber,
          emtNumber: res?.emtNumber,
          ambulanceStatus: res?.ambulanceStatus,
        }
        setAmbulanceDetails(data)
      })
      .catch((err) => console.error(err))
  }

  const handleEditDevice = (data: any) => {
    setOpenEditDeviceDialog(true)
    setSelectedDeviceId(data?.prorithmDeviceId ?? '')
  }

  const handleDeviceMapping = async () => {
    const mappingStatus = mapTo
    const wardName = wards?.find(
      (item) => item?.id === Number(selectedWard)
    )?.ward_name
    const roomName = rooms?.find(
      (item) => item?.id === Number(selectedRoom)
    )?.room_name
    const bedNumber = beds?.find(
      (item) => item?.id === Number(selectedBed)
    )?.bed_number
    const bedDetails = {
      wardId: selectedWard ? parseInt(selectedWard) : undefined,
      wardName: wardName,
      roomId: selectedRoom ? parseInt(selectedRoom) : undefined,
      roomName: roomName,
      bedId: selectedBed ? parseInt(selectedBed) : undefined,
      bedName: bedNumber,
    }

    const ambulanceDetails = {
      ambulanceNumber: formik?.values?.ambulanceNumber,
      location: formik?.values?.location,
      driverPhone: formik?.values?.driverPhone,
      emtPhone: formik?.values?.emtPhone,
      status: formik?.values?.status,
    }

    const success = await editProrithmMapping(
      mappingStatus,
      selectedDeviceId,
      selectedLocation,
      bedDetails,
      ambulanceDetails
    )
    if (success) {
      dispatch(setAddDeviceCount())
      toast(messages?.deviceMappedSuccessfully, {
        position: 'bottom-center',
        hideProgressBar: true,
        closeButton: false,
        theme: 'dark',
        autoClose: 4000,
      })
    }
    handleMappingDialogClose()
  }

  const handleDeleteDevice = (data: any) => {
    setOpenDeleteDeviceDialog(true)
    const deviceId = data?.prorithmDeviceId ?? ''
    setSelectedDeviceId(deviceId)
  }

  const removeProrithmDevice = async () => {
    setOpenDeleteDeviceDialog(false)
    const result = await deleteProrithmDevice(selectedDeviceId)
    if (result) {
      dispatch(setAddDeviceCount())
      toast(messages?.deviceDeletedSuccssfully, {
        position: 'bottom-center',
        hideProgressBar: true,
        closeButton: false,
        theme: 'dark',
        autoClose: 4000,
      })
    }
  }

  const handleDeviceClick = async (event: CellClickedEvent) => {
    const deviceId = event?.data?.prorithmDeviceId ?? ''
    setSelectedDeviceId(deviceId)
    setOpenAddToDialog(true)
    const status = await checkDeviceMappingStatus(deviceId)
    setDeviceMappingStatus(status)
  }

  const handleMapToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMapTo((event.target as HTMLInputElement).value)
  }

  const handleMappingDialogClose = () => {
    if (openAddToDialog) {
      setOpenAddToDialog(false)
    }
    if (openEditDeviceDialog) {
      setOpenEditDeviceDialog(false)
    }
    setMapTo('')
  }

  const getBedDetails = () => {
    const intent = INTENT_GET_WARD_ROOM_BED_DETAILS
    const state = {
      status: constants?.available,
      locationName: selectedLocation,
      bedTypeId: bedType ? bedType : '',
    }
    startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        setWards(data?.data?.data?.wards)
        setRooms(data?.data?.data?.rooms)
        setBeds(data?.data?.data?.beds)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const fetchProrithmDevices = useCallback(async () => {
    try {
      const devices = await getAllProrithmDevices(page, pageSize)

      if (devices && devices?.data) {
        const result = devices?.data?.map((item: any, index: number) => ({
          sNo: page * pageSize + index + 1,
          ...item,
        }))

        setProrithmDeviceList(result)
        setTotalCount(devices?.totalElements)
        setPage(devices?.page)
        setPageSize(devices?.size)
      } else {
        console.error('Failed to fetch devices.')
      }
    } catch (error) {
      console.error('Error fetching devices:', error)
    }
  }, [page, pageSize])

  const MapToBedForm = () => {
    return (
      <Box display='flex' flexDirection='row'>
        <MapToLocationForm />
        <Box minWidth={220} marginY={3} marginX={1}>
          <FormControl fullWidth>
            <InputLabel>{constants?.bedType}</InputLabel>
            <Select
              value={bedType}
              id='select-bed-type'
              label={constants?.bedType}
              className={classes?.SelectLayout}
              disabled={selectedLocation?.length === 0}
              onChange={(e: SelectChangeEvent) => setBedType(e?.target?.value)}
            >
              <MenuItem id='critical-bed-value' value={CRITICAL_BED_ID}>
                {constants?.critical}
              </MenuItem>
              <MenuItem id='non-critical-bed-value' value={NON_CRITICAL_BED_ID}>
                {constants?.nonCritical}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box minWidth={220} marginY={3} marginX={1}>
          <FormControl fullWidth>
            <InputLabel>{constants?.selectWard}</InputLabel>
            <Select
              value={selectedWard}
              id='select-ward-type'
              label={constants?.selectWard}
              disabled={bedType?.length === 0}
              className={classes?.SelectLayout}
              onChange={(e: SelectChangeEvent) => {
                setSelectedWard(e?.target?.value)
                setSelectedRoom('')
              }}
            >
              {wards?.map((item) => {
                return (
                  <MenuItem key={item?.id} value={item?.id?.toString()}>
                    {item?.ward_name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
        <Box minWidth={220} marginY={3} marginX={1}>
          <FormControl fullWidth>
            <InputLabel>{constants?.selectRoom}</InputLabel>
            <Select
              value={selectedRoom}
              id='select-room-type'
              label={constants?.selectRoom}
              className={classes?.SelectLayout}
              disabled={selectedWard?.length === 0}
              onChange={(e: SelectChangeEvent) =>
                setSelectedRoom(e?.target?.value)
              }
            >
              {rooms
                ?.filter(
                  (item) =>
                    selectedWard?.toString() === item?.ward_id?.toString()
                )
                ?.map((item) => {
                  return (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.room_name}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </Box>
        <Box minWidth={220} marginY={3} marginX={1}>
          <FormControl fullWidth>
            <InputLabel>{constants?.selectBed}</InputLabel>
            <Select
              value={selectedBed}
              id='select-bed-type'
              label={constants?.selectBed}
              className={classes?.SelectLayout}
              disabled={selectedRoom?.length === 0}
              onChange={(e: SelectChangeEvent) =>
                setSelectedBed(e?.target?.value)
              }
            >
              {beds
                ?.filter(
                  (item) =>
                    selectedRoom?.toString() === item?.room_id?.toString()
                )
                ?.map((item) => {
                  return (
                    <MenuItem key={item?.id} value={item?.id?.toString()}>
                      {item?.bed_number}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        </Box>
      </Box>
    )
  }

  const MapToLocationForm = () => {
    return (
      <Box minWidth={220} marginY={3}>
        <FormControl fullWidth>
          <InputLabel>{constants?.selectLocation}</InputLabel>
          <Select
            value={selectedLocation}
            id='select-location-value'
            label={constants?.selectLocation}
            className={classes?.SelectLayout}
            onChange={(e: SelectChangeEvent) =>
              setSelectedLocation(e?.target?.value)
            }
          >
            {locations
              ?.filter(
                (l: any) => l?.resource?.status === constants?.activeStatus
              )
              ?.map((d: any) => (
                <MenuItem key={d?.resource?.id} value={d?.resource?.name}>
                  {d?.resource?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
  }

  const MapToOptions = () => {
    return (
      <FormControl>
        <RadioGroup
          id='map-to-options'
          row
          value={mapTo}
          onChange={handleMapToChange}
        >
          <FormControlLabel
            id='location-value'
            value='location'
            label='Location'
            control={<Radio />}
          />
          <FormControlLabel
            id='bed-value'
            value='bed'
            label='Bed'
            control={<Radio />}
          />
          <FormControlLabel
            id='ambulance-value'
            value='ambulance'
            label='Ambulance'
            control={<Radio />}
          />
        </RadioGroup>
      </FormControl>
    )
  }

  const isLocationSelected =
    mapTo === constants?.location && selectedLocation?.length === 0
  const isBedSelected = mapTo === constants?.bed && selectedBed?.length === 0
  const isSubmitDisabled = isLocationSelected || isBedSelected

  useEffect(() => {
    fetchProrithmDevices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize])

  useEffect(() => {
    // Get bed details on selecting location and bed type
    if (selectedLocation?.length > 0 && bedType) {
      getBedDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, bedType])

  useEffect(() => {
    // Reset form on changing Mapto value
    setSelectedLocation('')
    setBedType('')
    setSelectedWard('')
    setSelectedRoom('')
    setSelectedBed('')
  }, [mapTo])

  useEffect(() => {
    // get all prorithm devices list
    const fetchAllDevicesTimeout = setTimeout(() => {
      fetchProrithmDevices()
    }, 1000)
    return () => {
      clearTimeout(fetchAllDevicesTimeout)
    }
  }, [addDeviceCount, fetchProrithmDevices])

  return (
    <Box height={'93%'} width={'100%'}>
      <Dialog
        maxWidth={false}
        open={openAddToDialog}
        onClose={handleMappingDialogClose}
      >
        <DialogTitle>{constants?.mapTo}</DialogTitle>
        <DialogContent>
          {deviceMappingStatus?.toLocaleLowerCase() ===
            mappingStatus?.notMapped?.toLocaleLowerCase() && (
            <>
              <MapToOptions />
              {mapTo === constants?.location && <MapToLocationForm />}
              {mapTo === constants?.bed && <MapToBedForm />}
              {mapTo === constants?.ambulanceText && (
                <MapToAmbulanceForm
                  selectedDeviceId={selectedDeviceId}
                  handleMappingDialogClose={handleMappingDialogClose}
                />
              )}
            </>
          )}
          {deviceMappingStatus?.toLocaleLowerCase() ===
            mappingStatus?.bed?.toLocaleLowerCase() && (
            <Typography id='device-mapped-to-bed'>
              {mappingStatus?.bedStatus}
            </Typography>
          )}
          {deviceMappingStatus?.toLocaleLowerCase() ===
            mappingStatus?.location?.toLocaleLowerCase() && (
            <Typography id='device-mapped-to-location'>
              {mappingStatus?.locationStatus}
            </Typography>
          )}
          {deviceMappingStatus?.toLocaleLowerCase() ===
            mappingStatus?.ambulance?.toLocaleLowerCase() && (
            <Typography id='device-mapped-to-ambulance'>
              {mappingStatus?.ambulanceStatus}
            </Typography>
          )}
        </DialogContent>
        {mapTo !== constants.ambulanceText ? (
          <DialogActions>
            <Button
              id='close-map-to-dialog'
              onClick={() => setOpenAddToDialog(false)}
            >
              {constants?.close}
            </Button>
            <Button
              type='submit'
              id='submit-btn-map-to-dialog'
              onClick={handleDeviceMapping}
              disabled={isSubmitDisabled}
            >
              {constants?.submit}
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <Dialog
        open={openDeleteDeviceDialog}
        onClose={() => setOpenDeleteDeviceDialog(false)}
      >
        <DialogTitle>{constants?.askDeleteDevice}</DialogTitle>
        <DialogActions>
          <Button
            size='small'
            variant='contained'
            className={classes?.lightBlue}
            id='close-delete-device-dialog-btn'
            onClick={() => setOpenDeleteDeviceDialog(false)}
          >
            {constants?.no}
          </Button>
          <Button
            autoFocus
            size='small'
            variant='contained'
            id='delete-device-btn'
            className={classes?.lightBlue}
            onClick={() => removeProrithmDevice()}
          >
            {constants?.yes}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth={false}
        open={openEditDeviceDialog}
        onClose={() => {
          setOpenEditDeviceDialog(false)
          setMapTo('')
        }}
      >
        <DialogTitle>{constants?.editMappedDevice}</DialogTitle>
        <DialogContent>
          <Box display='flex'>
            <Typography marginTop={1} marginRight={2}>
              {constants?.reMap}
            </Typography>
            <MapToOptions />
          </Box>
          {mapTo === constants?.location && <MapToLocationForm />}
          {mapTo === constants?.bed && <MapToBedForm />}
          {mapTo === constants?.ambulanceText && (
            <MapToAmbulanceForm
              selectedDeviceId={selectedDeviceId}
              handleMappingDialogClose={handleMappingDialogClose}
            />
          )}
        </DialogContent>
        <DialogActions>
          {mapTo !== constants.ambulanceText ? (
            <DialogActions>
              <Button
                id='close-edit-device-dialog-btn'
                onClick={() => setOpenEditDeviceDialog(false)}
              >
                {constants?.close}
              </Button>
              <Button
                type='submit'
                id='submit-btn-map-to-dialog'
                onClick={handleDeviceMapping}
                disabled={isSubmitDisabled}
              >
                {constants?.submit}
              </Button>
            </DialogActions>
          ) : null}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAmbulanceDetails}
        onClose={() => setOpenAmbulanceDetails(false)}
      >
        <DialogTitle>{constants?.ambulanceDetails}</DialogTitle>
        <DialogContent>
          <InfoRow
            label={constants?.deviceId}
            value={ambulanceDetails?.prorithmDeviceId}
          />
          <InfoRow
            label={constants?.locationText}
            value={ambulanceDetails?.locationName}
          />
          <InfoRow
            label={constants?.ambulanceNumber}
            value={ambulanceDetails?.ambulanceNumber}
          />
          <InfoRow
            label={constants?.driverPhone}
            value={ambulanceDetails?.driverPhoneNumber}
          />
          <InfoRow
            label={constants?.emtPhone}
            value={ambulanceDetails?.emtNumber}
          />
          <InfoRow
            label={constants?.status}
            value={ambulanceDetails?.ambulanceStatus}
          />
        </DialogContent>
        <DialogActions>
          <Button
            size='small'
            variant='contained'
            className={classes?.lightBlue}
            id='amb-details-btn'
            onClick={() => setOpenAmbulanceDetails(false)}
          >
            {constants?.close}
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        className={classes?.proRithmDasboardContainer}
        height={'100%'}
        width={'100%'}
      >
        <Box
          className={classes?.proRithmDasboardTableContainer}
          height={'100%'}
          width={'100%'}
        >
          <ProRithmAgDataGrid
            tableHeading='ProRithm'
            tableHeight={'100%'}
            rowData={prorithmDeviceList}
            columnDefs={columnDefs}
            pagination={true}
            pageSizeOptions={paginationOptions}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalRows={totalCount}
            headerHeight={30}
            searchFieldWidth={200}
          />
        </Box>
      </Box>
    </Box>
  )
}

const InfoRow: React.FC<{
  label: string
  value: string | null | undefined
}> = ({ label, value }) => {
  return (
    <Box display='flex'>
      <Typography fontStyle='italic'>{label}:&nbsp;</Typography>
      <Typography>{value}</Typography>
    </Box>
  )
}

export default ProRithmDasboard
