/* eslint-disable camelcase */
import React, { useEffect, useState, KeyboardEvent, ChangeEvent } from 'react'
import { startSxpProxy } from '../../utils/api'
import { LABS_PROJECT_ID } from '../../utils/constants'
import './labs.scss'
import { LabOrder, LabTest, OrderEvent, Panel } from './models'
import LabSamples from './components/LabSamples'
import SelectedLabTests from './components/SelectedLabTests'
import LabPanels from './components/LabPanels'
import Tests from './components/Tests'
import { groupTests, prepareOrderEventTests } from '../lms/utils'
import { getDoctorForVisit, saveOrder } from './utils'
import { useSamples } from '../lms/components/useSamples'
import KeycloakService from '../../utils/keycloakService'
import { LAB_ORDERS_WRITE } from '../../utils/roles'
import { useAppSelector } from '../../app/hooks'
import {
  selectLocationById,
  selectLocations,
  selectSelectedLocationId,
} from '../location/locationSlice'
import FilterTests from './components/FilterTests'
import { useLocation } from 'react-router-dom'
import { sendSocketData } from '../Notification/Socket'
import { NEW_IPD_DATA_UPDATE } from '../Notification/Constants'
import { getBedPatientDetails } from '../ipRegistration/IpdPatientRegisterSlice'

type Props = {
  id?: string
  patientId: string
  mode?: 'visit' | 'patient' | 'package'
  order: LabOrder
  onSave?: (panels?: Panel[], labTests?: LabTest[]) => void
  requestedBy?: string
  origin?: string
}

const LabTests = ({
  id,
  patientId,
  mode,
  order,
  onSave,
  requestedBy,
  origin,
}: Props) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const samples = useSamples()
  const [error, setError] = useState<string>('')
  const [panels, setPanels] = useState<Panel[]>([])
  const [saved, setSaved] = useState<LabOrder>({})
  const [selectedPanels, setSelectedPanels] = useState<Panel[]>([])
  const [selectedLabTests, setSelectedLabTests] = useState<LabTest[]>([])
  const [searchText, setSearchText] = useState('')
  const locationId = useAppSelector(selectSelectedLocationId)
  const location = useAppSelector((state) =>
    selectLocationById(locationId, state)
  )
  const bedPatientDetails = useAppSelector(getBedPatientDetails)
  const locationNames = useAppSelector(selectLocations)
  const locationName =
    locationNames?.find(
      (location: any) => location?.resource?.id === locationId
    )?.resource?.name || ''

  const [apiCall, setApiCall] = useState<boolean>(false)
  const [filterPanels, setFilterPanels] = useState<Panel[]>([])
  const locationpath = useLocation()

  useEffect(() => {
    setSaved(order)
  }, [order])

  useEffect(() => {
    const sample = samples[activeIndex]
    if (sample) {
      const intent = 'getAllTestsBySampleIdApi'
      startSxpProxy(LABS_PROJECT_ID, intent, { sampleId: sample.id })
        .then((data: any) => {
          const panelData: Panel[] = data.data?.sample?.[0]?.panels ?? []
          const morphed = panelData
            .map((p) => {
              const tests = p.lab_tests.map((plt) => ({
                ...plt,
                sampleId: sample.id,
                sampleName: sample.name,
                panelId: p.id,
                panelName: p.name,
              }))
              return {
                ...p,
                lab_tests: tests,
                sampleId: sample.id,
                sampleName: sample.name,
              }
            })
            .filter((p) => p.lab_tests.length > 0)
          setPanels(morphed)
        })
        .catch(() => {
          setPanels([])
        })
    }
  }, [activeIndex, samples])

  const handleSampleSelect = (id: number) => {
    setActiveIndex(id)
    setApiCall(false)
    setSearchText('')
  }

  const handlePanelSelect = (panel: Panel) => {
    if (!canUpdate) {
      return
    }
    const foundIndex = selectedPanels.findIndex((s) => s.id === panel.id)
    if (foundIndex >= 0) {
      const first = selectedPanels.slice(0, foundIndex)
      const second = selectedPanels.slice(foundIndex + 1)
      return setSelectedPanels([...first, ...second])
    }
    setSelectedPanels((sp) => [...sp, panel])
    setSelectedLabTests((slt) =>
      slt.filter(
        (lt) => panel.lab_tests.findIndex((plt) => plt.id === lt.id) === -1
      )
    )
  }

  const handleTestSelect = (test: LabTest) => {
    if (!canUpdate) {
      return
    }
    const foundIndex = selectedLabTests.findIndex((s) => s.id === test.id)
    if (foundIndex >= 0) {
      const first = selectedLabTests.slice(0, foundIndex)
      const second = selectedLabTests.slice(foundIndex + 1)
      return setSelectedLabTests([...first, ...second])
    }
    setSelectedLabTests((sel) => [...sel, test])
  }
  const handleUpdate = (sampleId?: number) => {
    if (!canUpdate) {
      return
    }

    const filteredPanels = saved.panels?.filter(
      (sp) => sp.sampleId !== sampleId
    )
    const filteredLabTests = saved.lab_tests?.filter(
      (slt) => slt.sampleId !== sampleId
    )
    setSaved((svd) => ({
      ...svd,
      panels: filteredPanels,
      // eslint-disable-next-line camelcase
      lab_tests: filteredLabTests,
    }))
    setSelectedPanels((sp) => sp.filter((p) => p.sampleId !== sampleId))
    setSelectedLabTests((slt) => slt.filter((lt) => lt.sampleId !== sampleId))
  }

  const handleSave = async () => {
    if (!KeycloakService.hasRole([LAB_ORDERS_WRITE])) {
      return
    }
    if (!canUpdate) {
      return
    }
    const { filteredPanels, filteredLabTests } = groupTests(
      saved,
      selectedPanels,
      selectedLabTests
    )
    if (mode === 'package') {
      onSave?.(filteredPanels, filteredLabTests)
      return
    }
    if (mode === 'patient' && !requestedBy) {
      alert('Requested By is mandatory for external tests...')
      return
    }
    if (mode === 'patient' && !origin) {
      alert('Origin is mandatory for external tests...')
      return
    }
    let requester = requestedBy
    if (mode === 'visit') {
      requester = await getDoctorForVisit(id ?? '')
    }
    const originType = origin
    if (mode === 'visit') {
      origin = 'OPD'
    }
    saveOrder(
      mode,
      saved,
      id ?? '',
      patientId,
      filteredPanels,
      filteredLabTests,
      location?.resource.name ?? '',
      0,
      0,
      requester,
      originType
    )
      .then((data: any) => {
        sendSocketData({
          messageType: NEW_IPD_DATA_UPDATE,
          patientID: bedPatientDetails?.[0]?.patientId,
          patientLocation: locationName,
          message: '',
        })
        let order: LabOrder
        const stateObj: Partial<OrderEvent> = {
          updated_by: KeycloakService.getUsername(),
        }
        if (saved.id) {
          order = data.data?.update_lab_orders?.returning?.[0]
          stateObj.order_id = saved.id
          stateObj.type = 'ORDER_MODIFIED'
          stateObj.tests = prepareOrderEventTests(
            filteredPanels,
            filteredLabTests,
            'ORDER_MODIFIED'
          )
        } else {
          order = data.data?.insert_lab_orders_one
          stateObj.order_id = order.id
          stateObj.type = 'ORDERED'
          stateObj.tests = prepareOrderEventTests(
            filteredPanels,
            filteredLabTests,
            'ORDERED'
          )
        }
        startSxpProxy(LABS_PROJECT_ID, 'createOrderEvent', {
          event: stateObj,
        }).then(() => {
          setSaved(order ?? {})
          setSelectedPanels([])
          setSelectedLabTests([])
          onSave?.(filteredPanels, filteredLabTests)
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const { filteredPanels, filteredLabTests } = groupTests(
    saved,
    selectedPanels,
    selectedLabTests
  )
  const selectedPanelObj: Record<number, Panel> = filteredPanels.reduce(
    (acc, cur) => ({ ...acc, [cur.id]: cur }),
    {}
  )
  const selectedLabTestObj: Record<number, LabTest> = filteredLabTests.reduce(
    (acc, cur) => ({ ...acc, [cur.id]: cur }),
    {}
  )

  const transformed: Panel[] = panels.map((p) => {
    const foundPanel = selectedPanelObj[p.id]
    if (foundPanel) {
      const tests = p.lab_tests.map((lt) => ({
        ...lt,
        active: true,
        controlled: true,
      }))
      // eslint-disable-next-line camelcase
      return { ...p, lab_tests: tests, active: true }
    }
    const pTests = p.lab_tests.map((lt) => {
      const foundLabTest = selectedLabTestObj[lt.id]
      if (foundLabTest) {
        return { ...lt, active: true, controlled: false }
      }
      return lt
    })
    // eslint-disable-next-line camelcase
    return { ...p, lab_tests: pTests }
  })
  const transformedPanels: Panel[] = filterPanels.map((p) => {
    const foundPanel = selectedPanelObj[p.id]
    if (foundPanel) {
      const tests = p.lab_tests.map((lt) => ({
        ...lt,
        active: true,
        controlled: true,
      }))
      // eslint-disable-next-line camelcase
      return { ...p, lab_tests: tests, active: true }
    }
    const pTests = p.lab_tests.map((lt) => {
      const foundLabTest = selectedLabTestObj[lt.id]
      if (foundLabTest) {
        return { ...lt, active: true, controlled: false }
      }
      return lt
    })
    // eslint-disable-next-line camelcase
    return { ...p, lab_tests: pTests }
  })

  const canUpdate =
    !saved.order_events ||
    saved?.order_events?.[0]?.type === 'ORDERED' ||
    saved?.order_events?.[0]?.type === 'ORDER_MODIFIED'

  const handleKeyDown = (ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      handleSearch(searchText)
    }
  }

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setError('')
    const value = ev.target.value
    setSearchText(value)
    handleSearch(value)
  }

  const handleSearch = (param: string) => {
    if (param.length < 3) {
      setError('Search text should be at least 3 characters long')
      return
    } else {
      executeFunction()
      setActiveIndex(-1)
      setApiCall(true)
    }
  }

  const executeFunction = () => {
    const state = {
      name: searchText,
    }
    startSxpProxy(LABS_PROJECT_ID, 'getAllPanelsSamplesByName', state).then(
      (data) => {
        const panelData: any = data.data?.panel ?? []
        const morphed = panelData
          .map((p: any) => {
            const sampleId = p?.sample?.id
            const sampleName = p?.sample?.name
            const tests = p.lab_tests.map((plt: any) => ({
              ...plt,
              sampleId: sampleId,
              sampleName: sampleName,
              panelId: p.id,
              panelName: p.name,
            }))
            // eslint-disable-next-line camelcase
            return {
              ...p,
              lab_tests: tests,
              sampleId: sampleId,
              sampleName: sampleName,
            }
          })
          .filter((p: any) => p.lab_tests.length > 0)
        setFilterPanels(morphed)
      }
    )
  }

  return (
    <div className='lab-container'>
      <div className='lab-sidebar mtminus16'>
        <LabSamples
          samples={samples}
          active={activeIndex}
          onSampleSelect={handleSampleSelect}
        />
      </div>
      <div className='lab-content'>
        <div
          className='lab-search'
          // needed
          style={{
            justifyContent: 'end',
            marginTop: '-90px',
            marginRight: '-12rem',
          }}
        >
          <input
            className='search-lab-input'
            placeholder='Search by panel name or test name'
            type='text'
            value={searchText}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <button
            disabled={searchText?.length < 3}
            onClick={() => handleSearch(searchText)}
            className={`btn btn-primaryBtn ${
              searchText?.length >= 3 ? '' : 'disableBtn'
            }`}
          >
            Search
          </button>
        </div>
        {error.length > 0 && (
          <span className='search-error-laborder'>{error}</span>
        )}
        <div className='search-error-filtertest'>
          {apiCall && (
            <FilterTests
              searchText={searchText}
              filterPanels={transformedPanels}
              onPanelSelect={handlePanelSelect}
            />
          )}
        </div>
        <div style={{ marginTop: '23px' }}>
          {!apiCall && (
            <LabPanels
              searchText={searchText}
              panels={transformed}
              onPanelSelect={handlePanelSelect}
            />
          )}
        </div>

        {mode != 'patient' && requestedBy ? (
          <Tests
            searchText={searchText}
            panels={transformed?.length === 0 ? transformedPanels : transformed}
            onTestSelect={handleTestSelect}
          />
        ) : null}
      </div>
      {/* needed */}
      <div className='lab-sidebar' style={{ flex: ' 0 0 200px' }}>
        {locationpath.pathname !== '/labReference' && (
          <SelectedLabTests
            samples={samples}
            selectedPanels={selectedPanels}
            selectedLabTests={selectedLabTests}
            saved={saved}
            onUpdate={handleUpdate}
            onSave={handleSave}
          />
        )}
      </div>
    </div>
  )
}

export default LabTests
