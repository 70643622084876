import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import OpdMedicationDashboard from './OpdMedicationDashboard'
import OpdLabOrderSummary from './OpdLabOrderSummary'
import OpdRadiologyOrderSummary from './OpdRadiologyOrderSummary'
import { useTheme } from '@emotion/react'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
const styles = () => ({
  tabItem: {
    textTransform: 'capitalize',
    color: '#ffffff',
    fontSize: '12px',
    fontWeight: 500,
  },
  selectedTab: {
    color: '#F7AC25 !important',
    fontWeight: 600,
    fontSize: '12px',
  },
  indicator: {
    backgroundColor: '#F7AC25',
  },
  tabPanel: {
    height: '100%',
    padding: 0,
  },
  tabsContainer: {
    width: '100%',
    height: '100%',
  },
  tabBorder: {
    borderBottom: '1px solid',
    borderColor: 'divider',
  },
  tabsRoot: {
    backgroundColor: '#4682b4',
    '& .MuiButtonBase-root': {
      textTransform: 'capitalize',
      color: '#ffffff',
    },
    '& .MuiTab-root': {
      color: '#ffffff',
      fontSize: '12px',
      fontWeight: 500,
    },
    '& .Mui-selected': {
      color: '#F7AC25',
      fontWeight: 600,
      fontSize: '12px',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#F7AC25',
    },
  },
})

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={classes?.tabPanel}
    >
      {value === index && <Box className={classes?.tabPanel}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function OpdSummaryTabs(props: any) {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const { allMedications, patientData, patientRegisteredId, setRefresh } = props
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box className={classes?.tabsContainer}>
      <Box className={classes?.tabBorder}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          className={classes?.tabsRoot}
          classes={{ indicator: classes?.indicator }}
        >
          <Tab label='Medications' {...a11yProps(0)} />
          <Tab label='Lab Orders' {...a11yProps(1)} />
          <Tab label='Radiology Orders' {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <OpdMedicationDashboard
          allMedications={allMedications}
          setRefresh={setRefresh}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <OpdLabOrderSummary
          patientRegisteredId={patientRegisteredId}
          patientData={patientData}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <OpdRadiologyOrderSummary
          patientRegisteredId={patientRegisteredId}
          patientData={patientData}
        />
      </CustomTabPanel>
    </Box>
  )
}
