import { useEffect, useState } from 'react'
import { startSxpProxy } from '../../../../utils/api'
import { ADMIN_PROJECT_ID } from '../../../../utils/constants'
import { radiologyIntents } from '../../Constants/constants'
import { radiologyDepartments } from '../../models'

export const useRadiologyDepartments = () => {
  const [radiologyDepartments, setRadiologyDepartments] = useState<
    radiologyDepartments[]
  >([])
  const getRadiologists = async () => {
    await startSxpProxy(ADMIN_PROJECT_ID, radiologyIntents?.getRadiologists, {})
      .then((data: any) => {
        const departments = data?.data?.map((e: any) => {
          const id = e?.id
          const name = e?.firstName.concat(' ', e?.lastName)
          const siganture = e?.signature
          const codingArray =
            e?.specialities?.find((speciality: any) => speciality?.coding)
              ?.coding || []
          const specialty = codingArray?.map((coding: any) => coding?.display)
          const qualification = e?.qualification
          return {
            id,
            name,
            siganture,
            specialty,
            qualification,
            code: 'Radiologist',
          }
        })
        if (departments?.length > 0) {
          setRadiologyDepartments(departments)
        } else {
          setRadiologyDepartments([])
        }
      })
      .catch((err) => {
        console.error(err, 'err')
        setRadiologyDepartments([])
      })
  }
  useEffect(() => {
    getRadiologists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return radiologyDepartments
}
