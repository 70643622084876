import { unknown } from '../Radiology/Constants/constants'
import {
  MUH777,
  MUH888,
  dengueApp,
  home,
  mentalHealthApp,
  mobile,
} from './constant'
import { makeName } from '../lms/utils'
import { getTotalAge } from '../../utils/dateUtils'
import { capitalizeFirstLetter } from '../patients/utils'
export type FormattedPatient = {
  photo?: any
  id: string
  name: string
  gender: string
  age: string
  fhirId: string
  thopId: string
  invoiceId: string
  registeredOn: string
  registrationType: string
  mobile: string
}
export type upComingAppointmentType = {
  data: string
  docName: string
  service: string
  timets: string
}
export const GetPatientOpd = (
  details: any,
  location: any,
  locationAddress: any
) => {
  const { photo, gender } = details
  const registrationNo = details?.id
  const barCodeData = registrationNo ?? ''

  return {
    photo: photo,
    registrationNo: registrationNo,
    barcode: barCodeData,
    patientAddress: locationAddress,
    gender: gender,
    patientName: details?.name,
    age: details?.age,
    mobile: details?.mobile,
    registeredOn: details?.registeredOn,
    registeredAt: location,
  }
}
export const LocationPrefix = (id: any, locations: any) => {
  const prefix = id?.slice(0, 6)
  switch (prefix) {
    case MUH777:
      return mentalHealthApp
    case MUH888:
      return dengueApp
    default: {
      const location = locations?.find((loc: any) =>
        loc?.resource?.identifier?.some(
          (identifier: any) => identifier?.value === prefix
        )
      )
      return location ? location?.resource?.name : unknown
    }
  }
}
export const formattedPatient = (p: any) => {
  const thop = p?.identifier?.[0]
  const bahmni = p?.identifier?.[2]
  const created = p?.identifier?.[3]
  const regType = p?.identifier?.[3]
  const invoice = p?.identifier?.[5]
  const name = makeName(p?.name)
  const age = getTotalAge(p?.birthDate)
  const gender = capitalizeFirstLetter(p?.gender)
  const tel1 = p?.telecom?.find((prt: any) => prt?.use === mobile)
  const tel2 = p?.telecom?.find((prt: any) => prt?.use === home)
  return {
    id: bahmni?.value ?? thop?.value ?? p?.id ?? '',
    name: name.trim() ?? '',
    gender: gender,
    age: age,
    fhirId: p?.id ?? '',
    thopId: thop?.value ?? '',
    invoiceId: invoice?.value ?? '',
    registeredOn: created?.period?.start ?? '',
    registrationType: regType?.value ?? '',
    mobile: tel1?.value ?? tel2?.value ?? '',
  }
}
export const getFilteredEncounters = (
  encounterDetails: any,
  appointmentId: any
) => {
  const encountersArray = Array.isArray(encounterDetails)
    ? encounterDetails
    : encounterDetails
    ? [encounterDetails]
    : []
  return encountersArray.filter(
    (encounter: any) => encounter?.appointmentId === appointmentId
  )
}
