/* eslint-disable camelcase */
import { Fragment, useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import {
  selectSelectedLocationId,
  selectLocationById,
} from '../../location/locationSlice'
import { RefRadiologyResult } from '../models'
import { useOrganizations } from '../../lms/components/useOrganizations'
import { startSxpProxy } from '../../../utils/api'
import { OPD_PROJECT_ID, RADIOLOGY_PROJECT_ID } from '../../../utils/constants'
import RadiologyReferredGroup from './search/RadiologyReferredGroup'
import { TableContainer, TablePagination, useTheme } from '@mui/material'
import {
  dialogMessages,
  emptyString,
  enterKey,
  loadingText,
  noResultsfound,
  radiologyIntents,
  radiologyTestStatus,
  referredPageOptions,
  search,
} from '../Constants/constants'
import '../radiology.scss'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'
import { radiologyColor } from '../Utils/RadiologyColors'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { toast } from 'react-toastify'

const styles = () => ({
  referredOutContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '10px',
  },
  referredSearch: {
    marginBottom: '6px',
    width: '300px',
    padding: '5px 10px',
    border: `1px solid ${radiologyColor?.metallicGray}`,
    borderRadius: '4px',
    backgroundColor: radiologyColor?.transparent,
    background: radiologyColor?.white,

    '&::placeholder': {
      fontSize: '13px',
      fontStyle: 'italic',
      fontWeight: 500,
    },
  },
  adminHeader: {
    fontSize: '14px',
    fontWeight: 500,
    margin: '2px 0 8px',
  },
  referredTable: {
    overflow: 'auto',
    maxHeight: '92%',
    width: '100%',
  },
  radiologyTestGroupTable: {
    width: '100%',
    borderRadius: '8px',
    border: `1px solid ${radiologyColor?.corporateBlue}`,
    borderSpacing: '0',
    tableLayout: 'fixed',
    fontSize: '13px',
    borderCollapse: 'collapse',
    '& tr, & td, & th': {
      border: `1px solid ${radiologyColor?.softGrey}`,
    },
    '& thead': {
      backgroundColor: radiologyColor?.steelBlue,
      color: radiologyColor?.white,
      border: `1px solid ${radiologyColor?.softGrey}`,
      position: 'sticky',
      top: '0px',
      zIndex: 9,
      '& tr': {
        textAlign: 'left',
        '& th': {
          fontWeight: 500,
          padding: '6px 12px',
        },
        '& th:first-child': {
          borderTopLeftRadius: '4px',
        },
        '& th:last-child': {
          borderTopRightRadius: '4px',
        },
      },
    },
    '& tbody': {
      '& tr:nth-child(even)': {
        backgroundColor: radiologyColor?.pearlGray,
      },
    },
  },
  tableHeadCell: {
    color: radiologyColor?.pureWhite,
    borderRight: `1px solid ${radiologyColor?.pureWhite}`,
    padding: '7px',
  },
  testNameSection: {
    width: '30%',
  },
  regType: {
    width: '10%',
  },
  orderTypeSection: {
    width: '8%',
  },
  patientUhid: {
    width: '13%',
  },
  referredToSection: {
    width: '15%',
  },
  actions: {
    width: '25%',
  },
  alignText: {
    textAlign: 'center',
  },
  referredPagination: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
})

const RadiologyReferred = () => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const locationId = useAppSelector(selectSelectedLocationId)
  const location = useAppSelector((state) =>
    selectLocationById(locationId, state)
  )
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState<RefRadiologyResult[]>([])
  const patientTableContainerRef = useRef<HTMLDivElement | null>(null)
  const organizations = useOrganizations()
  const [searchParam, setSearchParam] = useState('')
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState(0)

  const getResultsByLocation = async () => {
    setLoading(true)
    try {
      const state = {
        source: location?.resource?.name || '',
        uhid: searchParam,
        patient: Boolean(searchParam),
        limit: pageSize,
        offset: page,
      }

      const data = await startSxpProxy(
        RADIOLOGY_PROJECT_ID,
        radiologyIntents?.getReferredRadiologyResults,
        state
      )

      const results: RefRadiologyResult[] =
        data?.data?.radiology_test_result || []
      const count =
        data?.data?.radiology_test_result_aggregate?.aggregate?.count || 0
      setTotalCount(count)

      const patIds = results
        ?.map((r) => r?.patient_id)
        ?.filter(Boolean)
        ?.join(',')
      if (!patIds) {
        setResults(results)
        setLoading(false)
        return
      }

      const patientData = await fetchPatientsByIds(patIds)
      const mappedResults = mapResultsWithPatients(results, patientData)
      setResults(mappedResults)
      setLoading(false)
    } catch {
      setLoading(false)
      setTotalCount(0)
    }
  }

  const fetchPatientsByIds = async (ids: string) => {
    const data = await startSxpProxy(
      OPD_PROJECT_ID,
      radiologyIntents?.getPatientsByIdsApi,
      { ids }
    )
    return (
      data?.data?.entry?.reduce((acc: any, cur: any) => {
        acc[cur?.resource?.id] = cur.resource
        return acc
      }, {}) || {}
    )
  }

  const mapResultsWithPatients = (
    results: RefRadiologyResult[],
    patientData: Record<string, any>
  ) => {
    return results?.map((node) => ({
      ...node,
      patient: node?.patient_id ? patientData[node?.patient_id] : null,
    }))
  }
  const handleSearchClick = () => {
    getResultsByLocation()
  }

  const handleClearSearch = () => {
    if (searchParam === emptyString) {
      getResultsByLocation()
    }
  }
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === enterKey && searchParam.length > 7) {
      handleSearchClick()
    } else if (event.key === enterKey && searchParam.length <= 7) {
      toast.error(dialogMessages?.searchInputError)
    }
  }
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (setPage) setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (setPage) setPage(0)
    if (setPageSize) setPageSize(parseInt(event.target.value, 10))
  }
  const TableMessageRow = ({
    message,
    colSpan,
  }: {
    message: string
    colSpan: number
  }) => (
    <TableRow>
      <TableCell className={classes?.alignText} colSpan={colSpan}>
        {message}
      </TableCell>
    </TableRow>
  )
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  useEffect(() => {
    getResultsByLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, page, pageSize])
  useEffect(() => {
    handleClearSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam])

  return (
    <>
      <div className={classes?.referredOutContainer}>
        <input
          type='search'
          value={searchParam}
          onChange={(e) => setSearchParam(e.target.value)}
          onKeyDown={handleKeyDown}
          className={classes?.referredSearch}
          placeholder='Search by Patient UHID'
        />
        <div>
          <button className='btn btn-primaryBtn' onClick={handleSearchClick}>
            {search}
          </button>
        </div>
      </div>
      <>
        <TableContainer component={Paper} className={classes?.referredTable}>
          <Table
            aria-label='collapsible table'
            size='small'
            className={classes?.radiologyTestGroupTable}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className={`${classes?.tableHeadCell} ${classes?.testNameSection}`}
                >
                  Test Name
                </TableCell>
                <TableCell
                  className={`${classes?.tableHeadCell} ${classes?.regType}`}
                >
                  Reg Type.
                </TableCell>
                <TableCell
                  className={`${classes?.tableHeadCell} ${classes?.orderTypeSection}`}
                >
                  Order Id
                </TableCell>
                <TableCell
                  className={`${classes?.tableHeadCell} ${classes?.patientUhid}`}
                >
                  Patient UHID
                </TableCell>
                <TableCell
                  className={`${classes?.tableHeadCell} ${classes?.patientUhid}`}
                >
                  Patient Name
                </TableCell>
                <TableCell
                  className={`${classes?.tableHeadCell} ${classes?.referredToSection}`}
                >
                  Referred To
                </TableCell>
                <TableCell
                  className={`${classes?.tableHeadCell} ${classes?.actions}`}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableMessageRow message={loadingText} colSpan={7} />
              ) : results?.length === 0 ? (
                <TableMessageRow message={noResultsfound} colSpan={7} />
              ) : (
                results?.map((k: RefRadiologyResult) => {
                  const patientId = k?.patient
                  const key = `${k?.test_id}${k?.radiology_order_id}${patientId}`
                  return (
                    <Fragment key={key}>
                      <RadiologyReferredGroup
                        result={k}
                        organizations={organizations}
                        disabled={
                          k?.radiology_order?.status ===
                          radiologyTestStatus?.completed
                        }
                      />
                    </Fragment>
                  )
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes?.referredPagination}>
          <TablePagination
            variant='footer'
            size='small'
            component='div'
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={referredPageOptions}
          />
        </div>
      </>
    </>
  )
}
export default RadiologyReferred
