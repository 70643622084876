import React, { useState, useEffect } from 'react'
import { AdminTableProps } from '../lms/models'
import { useAppSelector } from '../../app/hooks'
import { selectedLmsAdminSearch, selectedLmsStatus } from '../lms/lmsSlice'
import { Box, IconButton } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import 'react-quill/dist/quill.snow.css'
import { format } from 'date-fns'
import KeycloakService from '../../utils/keycloakService'
import { SUPER_ADMIN_DELETE } from '../../utils/roles'
import { DeleteConfirmationDialog } from '../lms/components/admin/DeleteConfirmationDialog'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import { useTheme } from '@emotion/react'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import './radiology.scss'
import { activeText } from '../Radiology/Constants/constants'
import { Archive } from '../lms/components/admin/Archive'
import { menuValues } from '../lms/constants'

interface Template {
  id: string
  template_name: string
  content: string
  created_by: string
  created_on: string
  updated_by: string
  updated_on: string
  test_name: string
  test_id: string
  test_department: string
  status: string
}

const styles = () => ({
  dataGrid: {
    '& .MuiDataGrid-columnHeaders, .MuiDataGrid-columnHeader': {
      backgroundColor: '#4682b4 !important',
      color: 'white',
      height: '30px !important',
      fontSize: '13px',
    },
    '& .MuiDataGrid-cell': {
      padding: '0px 16px',
      '&:focus, &:focus-within': {
        outline: 'none !important',
      },
    },
  },
  dataGridContainer: {
    width: '100%',
    height: '100%',
  },
  dataGridHeader: {
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#4682b4',
      color: 'white',
      fontSize: '16px',
      fontWeight: 'bold',
    },
  },
})

const RadiologyReportTemplatesTable = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const status = useAppSelector(selectedLmsStatus)
  const [tableData, setTableData] = useState<Template[]>([])
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null
  )
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  })

  useEffect(() => {
    if (Array.isArray(data)) {
      const mappedData: Template[] = data?.map((item) => ({
        id: item?.id?.toString() || '',
        template_name: item?.template_name || '',
        content: item?.description || '<p></p>',
        created_by: item?.created_by || '',
        created_on: item?.created_on || new Date().toISOString(),
        updated_by: item?.updated_by || '',
        updated_on: item?.updated_on || new Date().toISOString(),
        test_department:
          item?.radiology_lab_test_and_test_template?.radiology_sample
            ?.department || '',
        test_name: item?.radiology_lab_test_and_test_template?.name,
        test_id: item?.radiology_lab_test_and_test_template?.id,
        status: item?.status,
      }))
      setTableData(mappedData)
    }
  }, [data])

  const handleOpenDeleteDialog = (template: Template) => {
    setSelectedTemplate(template)
    setDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
    setSelectedTemplate(null)
  }

  const handlePaginationModelChange = (newModel: any) => {
    setPaginationModel(newModel)
  }

  const getFilteredData = () => {
    if (!Array.isArray(tableData)) return []

    let filtered = [...tableData]

    if (searchParam) {
      filtered = filtered?.filter((item) =>
        item?.template_name?.toLowerCase()?.includes(searchParam?.toLowerCase())
      )
    }

    if (status) {
      filtered = filtered?.filter((item) => item?.status === status)
    }

    return filtered
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 50,
    },
    {
      field: 'template_name',
      headerName: 'Template Name',
      flex: 1,
      minWidth: 250,
      renderCell: (params: any) => {
        const data = params?.row
        return (
          <a
            href='#'
            onClick={() => {
              onEdit(data?.id)
            }}
            className='lms-btn'
          >
            {data?.template_name}
          </a>
        )
      },
    },
    {
      field: 'test_id',
      headerName: 'Test ID',
      flex: 1,
      minWidth: 50,
    },
    {
      field: 'test_name',
      headerName: 'Test Name',
      flex: 1,
      minWidth: 230,
    },
    {
      field: 'test_department',
      headerName: 'Test Department',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'created_by',
      headerName: 'Created By',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'created_on',
      headerName: 'Created On',
      flex: 1,
      minWidth: 100,
      valueFormatter: (params: any) =>
        params ? format(new Date(params), 'dd MMM yyyy HH:mm') : '-',
    },
    {
      field: 'updated_by',
      headerName: 'Updated By',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'updated_on',
      headerName: 'Updated On',
      flex: 1,
      minWidth: 100,
      valueFormatter: (params: any) =>
        params ? format(new Date(params), 'dd MMM yyyy HH:mm') : '-',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 110,
      sortable: false,
      flex: 1,
      filterable: false,
      renderCell: (params) => ({
        ...(params?.row?.status === activeText ? (
          <>
            <IconButton
              title='Archive'
              size='small'
              onClick={() =>
                Archive(
                  params?.row,
                  setTableData,
                  menuValues?.radiologyReportTemplates
                )
              }
              disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
            >
              <ArchiveOutlinedIcon fontSize='small' />
            </IconButton>
            <IconButton
              title='Delete'
              size='small'
              onClick={(e) => {
                e.stopPropagation()
                handleOpenDeleteDialog(params?.row)
              }}
              disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
            >
              <DeleteOutlined fontSize='small' />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
              title='Un-Archive'
              size='small'
              onClick={() =>
                Archive(
                  params?.row,
                  setTableData,
                  menuValues?.radiologyReportTemplates
                )
              }
              disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
            >
              <UnarchiveOutlinedIcon fontSize='small' />
            </IconButton>
            <IconButton
              title='Delete'
              size='small'
              onClick={(e) => {
                e.stopPropagation()
                handleOpenDeleteDialog(params?.row)
              }}
              disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
            >
              <DeleteOutlined fontSize='small' />
            </IconButton>
          </>
        )),
      }),
    },
  ]

  const filteredData = getFilteredData()

  return (
    <Box className={classes?.dataGridContainer} sx={{ width: '100%' }}>
      <DataGrid
        rows={filteredData}
        columns={columns}
        pageSizeOptions={[25, 50, 100]}
        disableRowSelectionOnClick
        getRowId={(row) => row?.id}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        pagination
        className={classes?.dataGrid}
        scrollbarSize={10}
        rowHeight={30}
      />
      {deleteDialogOpen && (
        <DeleteConfirmationDialog
          openDialog={deleteDialogOpen}
          closeDialog={handleCloseDeleteDialog}
          data={selectedTemplate}
          name={selectedTemplate?.template_name}
          setData={setTableData}
          item={menuValues?.radiologyReportTemplates}
        />
      )}
    </Box>
  )
}

export default RadiologyReportTemplatesTable
