import React, { useEffect, useState } from 'react'
import './user-management-form.css'
import { startSxpProxy } from '../../../utils/api'
import { ADMIN_PROJECT_ID, CHAT_PROJECT_ID } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { usercreation } from '../../patients/constants'
import ToastMessage from '../../lms/components/ToastMessage'
import { toastOptions } from '../../lms/models'
import {
  checkbox,
  forms,
  formValues,
  genderValues,
  phonenumber,
  prefix,
  selectedClientRoles,
  SelectedKeyclockRoles,
  SelectPractitionerRole,
  SelectPractitionerRoleName,
  UserManagementMessages,
} from '../constants'
import { phoneNumberValidation } from '../../ipRegistration/Constants'
export interface PractitionerOption {
  display: string
  code: string
  name: string
  id: string
}

export interface KeycloakRole {
  id: string
  name: string
}

const Form = () => {
  const [locations, setLocations] = useState([])
  const [practitionerOptions, setPractitionerOptions] = useState<
    PractitionerOption[]
  >([])
  const [groupRoles, setGroupRoles] = useState<PractitionerOption[]>([])
  const navigate = useNavigate()
  const [isloading, setisLoading] = useState(false)
  const [formData, setFormData] = useState({
    prefix: 'mr',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    phonenumber: '',
    SelectedLocation: '',
    SelectedKeyclockRoles: [] as { id: string; name: string }[],
    selectedClientRoles: [] as { id: string; name: string }[],
    SelectPractitionerRole: '',
    SelectPractitionerName: '',
    SelectPractitionerRoleName: '',
    gender: 'male',
    isDoctor: false,
    isOdooUser: false,
  })
  const handleChange = (e: any) => {
    const { name, options, type, checked, value } = e.target
    setFormData((prevFormData) => {
      if (type === checkbox) {
        return { ...prevFormData, [name]: checked }
      }
      if (name === SelectedKeyclockRoles || name === selectedClientRoles) {
        const selectedValues =
          options
            ?.filter((option: any) => option?.selected)
            ?.map((option: any) => ({
              id: option?.value,
              name: option?.textContent || '',
            })) || []
        return { ...prevFormData, [name]: selectedValues }
      }
      if (name === SelectPractitionerRole) {
        const selectedOption = practitionerOptions?.find(
          (option) => option?.code === value
        )
        return {
          ...prevFormData,
          SelectPractitionerRole: value,
          SelectPractitionerRoleName: selectedOption?.display || '',
        }
      }
      if (name === phonenumber) {
        const regex = phoneNumberValidation
        if (!regex?.test(value) || value?.length > 10) return prevFormData
      }
      if (name === prefix) {
        const gender = genderValues?.includes(value)
          ? formValues?.female
          : formValues?.male
        return { ...prevFormData, [name]: value, gender }
      }
      return { ...prevFormData, [name]: value }
    })
  }
  const handleSubmit = (e: any) => {
    e.preventDefault()
    setisLoading(true)
    startSxpProxy(ADMIN_PROJECT_ID, 'UserCreation', formData)
      ?.then((data) => {
        if (data?.status === 204 || data?.status === 201) {
          toast?.success('User Created')
          navigate('/userdata')
        }
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.data?.errorMessage ??
          UserManagementMessages?.KeyclockErrorMsg

        toast(<ToastMessage message={errorMessage} />, {
          ...toastOptions,
          type: 'error',
        })
      })
      .finally(() => {
        setisLoading(false)
      })
  }

  useEffect(() => {
    startSxpProxy(CHAT_PROJECT_ID, 'getLocationsAPI', {})
      ?.then((data) => {
        if (data?.data?.entry) {
          const fetchedLocations = data?.data?.entry.map(
            (entry: { resource: { id: any; name: any; status: any } }) => ({
              id: entry?.resource.id,
              name: entry?.resource.name,
              status: entry?.resource.status,
            })
          )
          setLocations(fetchedLocations)
        }
      })
      .catch((err) => console.error('Error fetching locations:', err))
  }, [])
  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, 'getValueSet', {})
      ?.then((data) => {
        if (data?.data) {
          setPractitionerOptions(data.data)
        }
      })
      .catch((err) => console.error('Error fetching locations:', err))
  }, [])

  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, 'allRoles', {})
      ?.then((data) => {
        if (data?.data) {
          // needed
          // setKeycloakRoles(data?.data?.medunitedRoles)
          setGroupRoles(data?.data?.grouproles)
        }
      })
      .catch((err) => console.error('Error fetching Keycloak roles:', err))
  }, [])
  const activeLocations = locations?.filter(
    (location: any) => location?.status === 'active'
  )
  const navigateBack = () => {
    navigate(-1)
  }
  const handleRoleClick = (selectedRole: { id: string; name: string }) => {
    const isSelected = formData?.selectedClientRoles?.some(
      (role: any) => role?.id === selectedRole?.id
    )
    const updatedRoles = isSelected
      ? formData?.selectedClientRoles.filter(
          (role: any) => role?.id !== selectedRole?.id
        )
      : [...formData.selectedClientRoles, selectedRole]
    setFormData({ ...formData, selectedClientRoles: updatedRoles })
  }
  return (
    <>
      <div className='package-container'>
        <KeyboardBackspaceIcon
          className='backspace-icon-search-view mb25'
          onClick={navigateBack}
        />
        <span className='user-form-content'>{usercreation}</span>
      </div>
      <form className='full-usercreation-form' onSubmit={handleSubmit}>
        <div className='form-group inline'>
          <div style={{ display: 'flex' }}>
            <label htmlFor='username'>
              Username
              <span className='required_red'>*</span>:
            </label>
            <select
              id='prefix'
              name='prefix'
              value={formData?.prefix}
              onChange={handleChange}
              required
            >
              <option value='mr'>Mr</option>
              <option value='miss'>Miss</option>
              <option value='ms'>Ms</option>
              <option value='mrs'>Mrs</option>
              <option value='dr'>Dr</option>
            </select>

            <input
              type='text'
              id='username'
              name='username'
              value={formData?.username}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className='form-group'>
          <label htmlFor='firstName'>
            First Name
            <span className='required_red'>*</span>:
          </label>
          <input
            type='text'
            id='firstName'
            name='firstName'
            value={formData?.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className='form-group'>
          <label htmlFor='lastName'>
            Last Name
            <span className='required_red'>*</span>:
          </label>
          <input
            type='text'
            id='lastName'
            name='lastName'
            value={formData?.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className='form-group'>
          <label htmlFor='gender'>
            Gender
            <span className='required_red'>*</span>:
          </label>
          <select
            id='gender'
            name='gender'
            value={formData?.gender}
            onChange={handleChange}
            required
          >
            <option value='male'>Male</option>
            <option value='female'>Female</option>
            <option value='other'>Other</option>
          </select>
        </div>
        <div className='form-group'>
          <label htmlFor='email'>
            Email
            <span className='required_red'>*</span>:
          </label>
          <input
            type='email'
            id='email'
            name='email'
            value={formData?.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className='form-group'>
          <label htmlFor='phoneNumber'>
            Phone Number
            <span className='required_red'>*</span>:
          </label>
          <input
            type='tel'
            id='phonenumber'
            name='phonenumber'
            value={formData?.phonenumber}
            onChange={handleChange}
            required
            inputMode='numeric'
            maxLength={10}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='location'>
            Location
            <span className='required_red'>*</span>:
          </label>
          <select
            id='SelectedLocation'
            name='SelectedLocation'
            value={formData?.SelectedLocation}
            onChange={handleChange}
            required
          >
            <option value=''>Select</option>
            {activeLocations?.map((location: any) => (
              <option key={location?.name} value={location?.id}>
                {location?.name}
              </option>
            ))}
          </select>
        </div>
        {/* needed */}
        {/* <div className='form-group'>
          <label htmlFor='keycloakRole'>
            Keycloak Role
            <span className='required_red'>*</span>:
          </label>
          <select
            id='SelectedKeyclockRoles'
            name='SelectedKeyclockRoles'
            // value={formData?.SelectedKeyclockRoles?.map((role) => role?.id)}
            onChange={handleChange}
            multiple
            className='userform-selectedRoles'
            style={{ height: '80px' }}
          >
            {keycloakRoles?.length > 0 &&
              keycloakRoles?.map((role: KeycloakRole) => (
                <option key={role.id} value={role.id}>
                  {role?.name}
                </option>
              ))}
          </select>
        </div> */}
        <div className='form-group'>
          <label htmlFor='keycloakRole'>
            Group Roles <span className='required_red'>*</span>:
          </label>
          <div className='custom-select'>
            {groupRoles?.length > 0 &&
              groupRoles?.map((role: KeycloakRole) => (
                <div
                  key={role?.id}
                  onClick={() =>
                    handleRoleClick({ id: role?.id, name: role?.name })
                  }
                  className={`role-item ${
                    formData.selectedClientRoles.some(
                      (r: any) => r?.id === role?.id
                    )
                      ? 'selected'
                      : ''
                  }`}
                >
                  {role?.name}
                </div>
              ))}
          </div>
        </div>
        <div className='form-group'>
          <label htmlFor='PractitionerRole'>
            Practitioner Role
            <span className='required_red'>*</span>:
          </label>
          <select
            id='SelectPractitionerRole'
            name='SelectPractitionerRole'
            value={formData?.SelectPractitionerRole}
            onChange={(e) => {
              const selectedOption = practitionerOptions?.find(
                (option) => option?.code === e.target.value
              )
              handleChange({
                target: {
                  name: SelectPractitionerRole,
                  value: selectedOption?.code || '',
                },
              })
              handleChange({
                target: {
                  name: SelectPractitionerRoleName,
                  value: selectedOption?.display || '',
                },
              })
            }}
            required
          >
            <option value=''>Select</option>
            {practitionerOptions?.map((option: any) => (
              <option key={option?.display} value={option?.code}>
                {option?.display}
              </option>
            ))}
          </select>
        </div>
        <div className='form-group'>
          <label>Create Odoo:</label>
          <input
            type='checkbox'
            name='isOdooUser'
            checked={formData?.isOdooUser}
            onChange={handleChange}
            className='userform-checkbox'
            style={{ height: '20px' }}
          />
        </div>
        <div className='button-container-form'>
          <button
            className='userform-submit-btn'
            type='submit'
            disabled={isloading}
          >
            {isloading ? forms?.Submitting : forms?.submit}
          </button>
        </div>
      </form>
    </>
  )
}

export default Form
