import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { startSxpProxy } from '../../../utils/api'
import { CHAT_PROJECT_ID, PACKAGE_PROJECT_ID } from '../../../utils/constants'
import { Patient } from 'fhir/r4'
import { makeName } from '../../lms/utils'
import {
  removePatient,
  toastOptions,
} from '../../Radiology/Constants/constants'
import { toast } from 'react-toastify'
import {
  MemberPatient,
  PackageModel,
  SearchPatient,
} from './../../membership/models'
import ListOfPckages from './ListOfPackages'
import SelectableSearch from '../../../components/SelectableSearch'
import SearchPatientList from './searchPatientList'
import ToastMessage from '../../lms/components/ToastMessage'
import IconButton from '@mui/material/IconButton'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

const CreatePackage = () => {
  const navigate = useNavigate()
  const [show, setShow] = useState<boolean>(false)
  const [patients, setPatients] = useState<SearchPatient[]>([])
  const [members, setMembers] = useState<MemberPatient[]>([])

  const formatPatients = (entry: { resource: Patient }[]): SearchPatient[] => {
    return (
      entry.map((en) => {
        const gender = en.resource.gender
        const pat: SearchPatient = {
          id: en.resource.id ?? '',
          name: makeName(en.resource.name),
          gender:
            gender === 'male'
              ? 'Male'
              : gender === 'female'
              ? 'Female'
              : 'Others',
          dob: en.resource.birthDate ?? '',
          uhid: en.resource?.identifier?.[2]?.value ?? '',
          thopId: en.resource?.identifier?.[0]?.value ?? '',
          isSelected: false,
          isNominee: false,
          registration: en.resource?.identifier?.[3]?.value ?? '',
        }
        return pat
      }) ?? []
    )
  }

  const handleSearch = (query: string, param: string) => {
    if (!query) {
      setPatients([])
      setShow(false)
      return
    }
    setShow(true)
    const intent = 'getPatientsAPI'
    let q = ''
    switch (query) {
      case 'ID':
        q = 'identifier'
        break
      case 'Phone':
        q = 'phone'
        break
      case 'Name':
        q = 'name'
        break
    }
    const state = {
      pageSize: 20,
      query: q,
      value: param ? param : undefined,
    }
    startSxpProxy(CHAT_PROJECT_ID, intent, state)
      .then((data) => {
        if (data.data.entry?.length) {
          setPatients(formatPatients(data.data.entry))
        } else {
          setPatients([])
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleMemberSelect = (id: string) => {
    const selectedMember = patients.find((member) => member.id === id)
    if (selectedMember) {
      startSxpProxy(PACKAGE_PROJECT_ID, 'updateRegType', {
        identifier: selectedMember?.uhid,
      })
        .then(async (data) => {
          const members: PackageModel[] = data?.data?.data ?? []
          const member = !members.every(
            (item) => item.state !== 'waiting' && item.state !== 'invoiced'
          )
          if (members.length === 0 || !member) {
            setMembers((prev) => [...prev, selectedMember])
          } else {
            if (member) {
              toast(
                <ToastMessage
                  message={
                    'already available in Package.But is in pending state'
                  }
                />,
                {
                  ...toastOptions,
                  type: 'error',
                }
              )
            } else {
              toast(
                <ToastMessage message={'already available in Packages'} />,
                {
                  ...toastOptions,
                  type: 'error',
                }
              )
            }
          }
        })
        .catch((err) => {
          console.log(err)
          toast(
            <ToastMessage
              message={err?.response?.data?.message ?? 'eror occured'}
            />,
            {
              ...toastOptions,
              type: 'error',
            }
          )
        })
    }
  }

  const handleMemberUnSelect = (id: string) => {
    const member = members.filter((d) => d.id !== id)
    setMembers(member)
  }

  const handlePackages = () => {
    navigate('/package')
  }

  return (
    <div className='membership-layout-content'>
      <div className='overallContainer'>
        <div>
          <IconButton onClick={handlePackages}>
            <KeyboardBackspaceIcon
              sx={{ fontSize: 20, color: 'blue', marginTop: '-8px' }}
            />
          </IconButton>
        </div>
        <div className='flexDiv'>
          <SelectableSearch
            items={['ID', 'Phone', 'Name']}
            onSearch={handleSearch}
          />
          <button
            className={
              show && patients.length === 0
                ? 'btn btn-primaryBtn'
                : 'disabled-button'
            }
            // onClick={handlePatient}
            disabled={!(show && patients.length === 0)}
          >
            {removePatient}
          </button>
        </div>
      </div>
      <div className='mb10'></div>
      {(members?.length > 0 || show) && (
        <ListOfPckages
          packages={members}
          onMemberUnSelect={handleMemberUnSelect}
        />
      )}
      {show && (
        <SearchPatientList
          patients={patients}
          onMemberSelect={handleMemberSelect}
          members={members}
        />
      )}
    </div>
  )
}

export default CreatePackage
