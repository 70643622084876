import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { Button, TextField, Autocomplete, Box } from '@mui/material'
import Asterisk from '../../../../components/Asterisk'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { startSxpProxy } from '../../../../utils/api'
import {
  FILE_SERVER_URL,
  LABS_PROJECT_ID,
  TEMPLATE_CONSTANTS,
} from '../../../../utils/constants'
import InterpretationImage from '../../../../form/InterpretationImage'
import {
  selectedLmsItemId,
  setLmsFormMode,
  setLmsItem,
  setLmsItemId,
} from '../../lmsSlice'
import { useAppSelector } from '../../../../app/hooks'
import { AdminIntent } from '../../constants'
import KeycloakService from '../../../../utils/keycloakService'
import { toast } from 'react-toastify'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import { useTheme } from '@emotion/react'
import Loading from '../../../../form/fields/Loading'
import { useDispatch } from 'react-redux'
import AdminTable from './AdminTable'
import LmsAdmin from './LmsAdmin'

const styles = () => ({
  mainContainer: {
    justifyContent: 'center',
    fontSize: '12px !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '0 auto',
    width: '100%',
    '& .ql-container.ql-snow': {
      height: '200px !important',
      color: '#404040',
    },
    '& .ql-toolbar.ql-snow': {
      width: 'unset !important',
    },

    '& .MuiInputBase-root-MuiOutlinedInput-root': {
      width: 'unset !important',
      fontSize: '12px',
    },
  },
  formContainer: {
    width: '60%',
    margin: 'auto',
    right: '10%',
  },
  docContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    justifyContent: 'center',
  },
  inputLabel: {
    fontSize: '13px',
    fontWeight: 500,
    margin: '4px 0',
    minWidth: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyCOntent: 'right',
  },
  inputContent: {
    fontSize: '10px !improtant',
    flex: 1,
    '& .MuiOutlinedInput-input ': {
      height: '1rem',
      fontSize: '12px',
      fontWeoght: '500',
    },
    '& .MuiInputLabel-root ': {
      fontSize: '12px !important',
      right: '8% !important',
      display: 'flex !important',
    },
    '& .MuiAutocomplete-endAdornment ': {
      fontSize: '12px !important',
      right: '8% !important',
      display: 'flex !important',
    },
  },
  buttonContainer: {
    marginTop: '16px',
    margin: 'auto',
  },
  submitButton: {
    textTransform: 'none',
    width: '12%',
  },
  marginTop: {
    marginTop: '16px',
  },
  testDepartment: {
    fontSize: '0.8rem',
    color: '#666',
  },
})

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'color',
  'background',
  'script',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
]

const modules = {
  toolbar: {
    container: [
      [{ font: [9] }],
      [{ size: [9, 10] }],
      ['bold', 'italic'],
      [{ color: ['grey'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  },
}

const CreateUpdatePanelTemplates = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [testOptions, setTestOptions] = useState<any[]>([])
  const [isLoadingTests, setIsLoadingTests] = useState(false)
  const [isTemplateCreated, setIsTemplateCreated] = useState(false)

  const id = useAppSelector(selectedLmsItemId)
  const quillRef = useRef(null)
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)

  const initialFormState = {
    id: id || '',
    templateName: '',
    testName: '',
    testId: null,
    testDepartment: '',
    templateImage: '',
    description: '',
  }

  const [templateData, setTemplateData] = useState<any>(null)
  const [imageURL, setImageURL] = useState('')
  const [showAdminConfig, setShowAdminConfig] = useState(false)
  const [showAdminTable, setShowAdminTable] = useState(false)
  const [templateDescriptionValue, setTemplateDescriptionValue] = useState('')
  const dispatch = useDispatch()

  const {
    values,
    setValues,
    handleBlur,
    handleChange,
    submitForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialFormState,
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      handleRegistrationSubmit(values)
    },
    enableReinitialize: true,
  })

  const handleRegistrationSubmit = async (values: {
    id?: string
    templateName?: string
    testDepartment?: string
    testName?: string
    testId?: null
    templateImage?: string
    description?: string
  }) => {
    const { id, templateName, description, testId } = values

    setIsLoading(true)

    const formatted = {
      id: id ? parseInt(id) : undefined,
      test_id: testId || templateData?.panel?.id || undefined,
      template_name: templateName,
      description: description,
      updated_by: KeycloakService?.getUsername(),
      created_by: templateData?.created_by ?? KeycloakService?.getUsername(),
      interpretation_image: values.templateImage || '',
      status: templateData?.status,
    }

    const intent = id
      ? AdminIntent?.updateTestTemplate
      : AdminIntent?.createTestTemplate

    startSxpProxy(LABS_PROJECT_ID, intent, formatted)
      .then((data: any) => {
        setIsTemplateCreated(true)
        if (
          intent === AdminIntent?.createTestTemplate &&
          data?.insert_test_templates_one?.id
        )
          dispatch(setLmsItemId(data?.insert_test_templates_one?.id))
        if (data?.status === 200) {
          const successMessage = id
            ? `${templateName} updated successfully`
            : `${templateName} created successfully`
          toast.success(successMessage)

          if (id) {
            setShowAdminTable(true)
          } else {
            setShowAdminConfig(true)
            dispatch(setLmsItem('reportTemplates'))
            dispatch(setLmsFormMode('none'))
          }
        }
      })
      .catch((error) => {
        toast.error(error?.message || 'Operation failed')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getTemplateById = (id: string) => {
    startSxpProxy(LABS_PROJECT_ID, AdminIntent?.getTestTemplateById, {
      id: parseInt(id),
    })?.then((data) => {
      setTemplateData(data?.data?.test_templates_by_pk)
    })
  }

  const fetchTestOptions = useCallback(() => {
    setIsLoadingTests(true)

    startSxpProxy(LABS_PROJECT_ID, 'getAllActiveInactivePanels', {})
      .then((data) => {
        if (data?.data) {
          const tableData = data?.data?.panel?.flat()?.flat()

          tableData?.sort(
            (
              a: {
                department: { department_name: number }
                name: { toLowerCase: () => number }
              },
              b: {
                department: { department_name: number }
                name: { toLowerCase: () => number }
              }
            ) => {
              if (
                a?.department?.department_name < b?.department?.department_name
              ) {
                return -1
              } else if (
                a?.department?.department_name > b?.department?.department_name
              ) {
                return 1
              } else {
                return a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : 1
              }
            }
          )

          const formattedOptions = tableData?.map(
            (test: {
              id: any
              name: any
              department: { department_name: any }
              sample: { name: any }
            }) => ({
              id: test?.id,
              name: test?.name,
              department: test?.department?.department_name || 'Unknown',
              sample: test?.sample?.name || 'Unknown',
            })
          )
          setTestOptions(formattedOptions)
        }
      })
      .catch((error) => {
        console.error('Error fetching test options:', error)
        toast.error('Failed to load test options')
        setTestOptions([])
      })
      .finally(() => {
        setIsLoadingTests(false)
      })
  }, [])

  useEffect(() => {
    fetchTestOptions()
    if (id) {
      getTemplateById(id)
    }
  }, [fetchTestOptions, id, isTemplateCreated])

  useEffect(() => {
    if (templateData) {
      setValues({
        id: templateData?.id,
        templateName: templateData?.template_name ?? '',
        testName: templateData?.panel?.name || '',
        testDepartment: templateData?.panel?.department?.department_name || '',
        testId: templateData?.panel?.id || '',
        templateImage: templateData?.interpretation_image || '',
        description: templateData?.description || '',
      })

      setTemplateDescriptionValue(
        templateData?.description?.replace(/\\n/g, '\n') || ''
      )

      if (templateData?.image) {
        setImageURL(`${FILE_SERVER_URL}/${templateData?.image}`)
      }
    }
  }, [templateData, setValues, testOptions, isTemplateCreated, id])

  const handleQuillChange = (content: SetStateAction<string>) => {
    setTemplateDescriptionValue(content)
    setFieldValue('description', content)
  }

  const handleTestChange = (
    event: any,
    newValue: { name: any; id: any; department: any }
  ) => {
    if (newValue) {
      setFieldValue('testName', newValue?.name)
      setFieldValue('testDepartment', newValue?.department)
      setFieldValue('testId', newValue?.id)
    } else {
      setFieldValue('testName', '')
      setFieldValue('testDepartment', '')
      setFieldValue('testId', null)
    }
  }

  if (showAdminTable) {
    dispatch(setLmsItem('reportTemplates'))
    dispatch(setLmsFormMode('none'))
    return <AdminTable projectId={LABS_PROJECT_ID} />
  }
  if (showAdminConfig) {
    dispatch(setLmsItem('reportTemplates'))
    dispatch(setLmsFormMode('none'))
    return <LmsAdmin />
  }

  return (
    <div className={classes?.mainContainer}>
      {isLoading && <Loading />}
      {showAdminConfig ? (
        <LmsAdmin />
      ) : (
        <form className={classes?.formContainer}>
          <div className={classes?.formSection}>
            <div className={classes?.docContainer}>
              <label htmlFor='template-name' className={classes?.inputLabel}>
                {TEMPLATE_CONSTANTS?.templateName}
                <Asterisk />
                &nbsp;:
              </label>
              <TextField
                type='text'
                className={classes?.inputContent}
                required
                fullWidth
                name='templateName'
                value={values?.templateName}
                onChange={handleChange}
                onBlur={handleBlur}
                size='small'
              />
            </div>
            <div className={classes?.docContainer}>
              <label htmlFor='test-name' className={classes?.inputLabel}>
                {TEMPLATE_CONSTANTS?.associatedTestName} &nbsp;:
              </label>
              <Autocomplete
                className={classes?.inputContent}
                options={testOptions}
                getOptionLabel={(option) => option?.name || ''}
                loading={isLoadingTests}
                onChange={handleTestChange}
                size='small'
                value={
                  testOptions?.find((test) => test?.id === values?.testId) ||
                  null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size='small'
                    fullWidth
                    className={classes?.inputContent}
                    placeholder='Select a test'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingTests && TEMPLATE_CONSTANTS?.loading}
                          {params?.InputProps?.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box display='flex' flexDirection='column'>
                      <span>{option?.name}</span>
                      <span className={classes?.testDepartment}>
                        {option.department} - {option.sample}
                      </span>
                    </Box>
                  </li>
                )}
              />
            </div>
            <div className={`${classes?.docContainer} ${classes?.marginTop}`}>
              <label htmlFor='templateContent' className={classes?.inputLabel}>
                {TEMPLATE_CONSTANTS?.templateContent} &nbsp;:
              </label>
              <div className={classes?.inputContent}>
                <ReactQuill
                  theme='snow'
                  value={templateDescriptionValue}
                  onChange={handleQuillChange}
                  formats={formats}
                  modules={modules}
                  className={classes?.quillEditor}
                  ref={quillRef}
                />
              </div>
            </div>
            <div className={classes?.docContainer}>
              <label htmlFor='templateImage' className={classes?.inputLabel}>
                {TEMPLATE_CONSTANTS?.templateImage}:
              </label>
              <TextField
                type='text'
                name='templateImage'
                value={values?.templateImage}
                className={classes?.inputContent}
                placeholder='Template Image'
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                size='small'
              />
            </div>
            <div className={classes?.docContainer}>
              <div className={classes?.inputLabel}></div>
              <div className={classes?.inputContent}>
                <InterpretationImage
                  imageURL={imageURL}
                  setImageURL={setImageURL}
                />
              </div>
            </div>
            <div className={classes?.docContainer}>
              <div className={classes?.inputLabel}></div>
              <div
                className={`${classes?.inputContent} ${classes?.buttonContainer}`}
              >
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  className={classes?.submitButton}
                  disabled={isLoading}
                  onClick={submitForm}
                >
                  {TEMPLATE_CONSTANTS?.submit}
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

export default CreateUpdatePanelTemplates
