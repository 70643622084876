export const followUpdate = 'Follow-up Date'
export const profilePicture = 'Profile Picture'
export const signatureText = 'Signature of Doctor with Reg No'
export const usercreation = 'UserCreation'
export const locationcreation = 'Create Location '
export const editlocation = 'Edit Location'
export const OPRegistration = 'OP Registration'
export const getallRegType = 'getallRegType'
export const RegistrationType = 'Registration Type'
export const imageValidationRegex = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i
