export type labTests = {
  description: string
  id: number
  name: string
  panelId: number
  panelName: string
  sampleName: string
  sampleId: number
}
export type Panels = {
  description: string
  id: number
  lab_tests: labTests[]
  name: string
  sampleId: number
  sampleName: string
}

export type labTestsType = {
  origin: string
  lab_tests: any
  patientUhid: any
  patientThopId: any
  patient_id: any
  requested_by: any
  ordered_by_name: any
  summary: string
  ordered_on: string
  lr_number: string
  status: string
  validation_completed_on: string
  collection_completed_on: string
  id: number
  source: string
  panels: Panels[]
  patientId: string
}
export type RadiologyTestsType = {
  origin: string
  patientUhid: string
  patientThopId: string
  patient_id: any
  requested_by: string
  ordered_by_name: string
  summary: string
  ordered_on: string
  lr_number: string
  status: string
  validation_completed_on: string
  id: number
  source: string
  radiology_order_tests: any[]
  radiology_test_results: any[]
  patientId: string
}

export type appointmentType = {
  id: number
  date: string
  desc: string
  resource: {
    statusHistory: any
    subject: {
      reference: string
    }
  }
}

export type upComingAppointmentType = {
  date: any
  docName: string
  service: string
  time: string
  status: any
  appointmentId: any
  origin: string
}

export type allAppointments = {
  date: any
  docName: string
}

export const visitSuccess = 'Visit Created Successfully'
export const mobile = 'mobile'
export const home = 'home'
export const NewOPDObservations = 'NewOPDObservations'
export const triaged = 'triaged'
export const InProgress = 'InProgress'
export const FetchDataError = 'Error fetching data:'
export const unpaid = 'unpaid'
export const registrationCard = 'Registration Card'
export const MUH777 = 'MUH777'
export const MUH888 = 'MUH888'
export const mentalHealthApp = 'Mental Health App'
export const dengueApp = 'Dengue App'
export const onAlternateDays = 'On alternate days'
export const opdRoute = '/opd'
export const radiologyText = 'radiology'
export const visitDashboard = {
  general: 'General',
  cancelled: 'cancelled',
  createVisit: 'Create Visit',
  view: 'View',
  cancelledCapital: 'Cancelled',
  hyphen: '-',
  triaged: 'triaged',
  InProgress: 'InProgress',
  finished: 'finished',
  Completed: 'Completed',
  encounter: 'Encounter',
}
export const opProvenance = {
  create: 'CREATE',
  Created: 'Created',
  lastUpdated: 'Last Updated',
  na: 'NA',
  completed: 'COMPLETED',
}
export const opdIntents = {
  getLabTestsByIds: 'getLabTestsByIds',
  getLabOrdersByPatientIds: 'getLabOrdersByPatientIds',
  getApprovedRadiologyResultInOpd: 'getApprovedRadiologyResultInOpd',
}
export const opdMedications = [
  'Immediately',
  'Once a day',
  'Twice a day',
  'Five times a day',
  'Thrice a day',
  'Four times a day',
]
export const MEALS = {
  BREAKFAST: 'breakfast',
  LUNCH: 'lunch',
  DINNER: 'dinner',
}

export const MEAL_INTERVALS = {
  BREAKFAST_BEFORE: 'bb',
  BREAKFAST_AFTER: 'ab',
  LUNCH_BEFORE: 'bl',
  LUNCH_AFTER: 'al',
  DINNER_BEFORE: 'bd',
  DINNER_AFTER: 'ad',
}

export const MEAL_TIMINGS = {
  BEFORE: 'before',
  AFTER: 'after',
}
export const REGISTRATION_TEXT: Record<string, string> = {
  fr: 'Full',
  qr: 'Quick',
  er: 'ER',
  pmc: 'Pharmacy',
  lab: 'Lab',
  dgs: 'Diagnostics',
  camp: 'Campaign',
  membership: 'MemberShip',
  membershipPrimary: 'MemberShip',
  package: 'Package',
  ABMembershipQuick: 'AB MemberShip',
  ABMembershipFull: 'AB MemberShip',
}

export const REGISTRATION_TITLE: Record<string, string> = {
  fr: 'Full Registration',
  qr: 'Quick Registration',
  er: 'Emergency',
  pmc: 'Pharmacy',
  lab: 'Laboratory',
  dgs: 'Diagnostics',
  camp: 'Campaign',
  membership: 'MemberShip',
  membershipPrimary: 'MemberShip',
  package: 'Package',
  ABMembershipQuick: 'AB MemberShip Full',
  ABMembershipFull: 'AB MemberShip Full',
}

export const DEFAULT_TEXT = 'Other'
export const DEFAULT_TITLE = 'Invalid Registration Type'
export const dental = 'dental'
export const physio = 'physio'
export const active = 'active'
