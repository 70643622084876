import React, { useEffect, useCallback, useState } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  useTheme,
} from '@mui/material'
import { startSxpProxy } from '../../../utils/api'
import {
  ADMIN_PROJECT_ID,
  REALM,
  MEDUNITED_KEYCLOCK_ID,
} from '../../../utils/constants'
import {
  AddRoles,
  AssignedRoles,
  AvailableRoles,
  UserManagementIntent,
} from '../constants'
import { Cancel } from '../../AB Membership/constants'
import './user-management-form.css'
import { toast } from 'react-toastify'
import { toastOptions } from '../../lms/models'
import ToastMessage from '../../lms/components/ToastMessage'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'
import { styles } from '../../ProRithm/ProRithmDasboard'
export interface RoleOption {
  id: string
  name: string
}

interface KeycloakRolesDialogProps {
  isOpen: boolean
  onClose: () => void
  roles: RoleOption[]
  userId: any
  grouproles: any
  isGroup?: boolean
}

const KeycloakRolesDialog: React.FC<KeycloakRolesDialogProps> = ({
  isOpen,
  onClose,
  roles,
  userId,
  isGroup = false,
  grouproles,
}) => {
  const [availableKeycloakRoles, setAvailableKeycloakRoles] = useState<
    RoleOption[]
  >([])
  const [selectedAvailableRoles, setSelectedAvailableRoles] = useState<
    RoleOption[]
  >([])
  // needed
  // const [selectedAssignedRoles, setSelectedAssignedRoles] = useState<
  //   RoleOption[]
  // >([])
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const keyclockId = userId
  const fetchAvailableRoles = useCallback(() => {
    startSxpProxy(
      ADMIN_PROJECT_ID,
      UserManagementIntent?.keyclockAvaiableRoles,
      { userId }
    )
      ?.then((response) => {
        if (response?.data?.data) {
          setAvailableKeycloakRoles(response?.data?.data)
        }
      })
      .catch((err) => console.error(err))
  }, [userId])

  useEffect(() => {
    if (isOpen) {
      if (!isGroup) {
        fetchAvailableRoles()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isGroup])
  const handleAddRoles = () => {
    const selectedRolesData = selectedAvailableRoles?.map((role) => ({
      id: role?.id,
      name: role?.name,
      clientRole: true,
      composite: false,
      containerId: isGroup ? REALM : MEDUNITED_KEYCLOCK_ID,
    }))
    const intent = isGroup
      ? UserManagementIntent?.addGroupRole
      : UserManagementIntent?.addKeyclockRoles

    startSxpProxy(ADMIN_PROJECT_ID, intent, {
      keyclockId,
      SelectedKeyclockRoles: selectedRolesData,
    })
      ?.then((response) => {
        toast(<ToastMessage message={response?.data?.message} />, {
          ...toastOptions,
          type: 'success',
        })

        onClose()
        setSelectedAvailableRoles([])
      })
      .catch((err) => {
        toast(<ToastMessage message={err?.data?.message} />, {
          ...toastOptions,
          type: 'error',
        })
      })
  }
  // needed
  // const handleRemoveRoles = () => {
  //   const selectedRolesData = selectedAssignedRoles.map((role) => ({
  //     id: role.id,
  //     name: role.name,
  //     clientRole: true,
  //     composite: false,
  //     containerId: isGroup ? REALM : MEDUNITED_KEYCLOCK_ID,
  //   }))
  //   const intent = isGroup
  //     ? UserManagementIntent?.RemoveRole
  //     : UserManagementIntent?.RemoveClientRole

  //   startSxpProxy(ADMIN_PROJECT_ID, intent, {
  //     keyclockId,
  //     SelectedKeyclockRoles: selectedRolesData,
  //   })
  //     ?.then((response) => {
  //       toast(<ToastMessage message={response?.data?.message} />, {
  //         ...toastOptions,
  //         type: 'success',
  //       })
  //       onClose()
  //     })
  //     .catch((err) => {
  //       toast(<ToastMessage message={err?.data?.message} />, {
  //         ...toastOptions,
  //         type: 'error',
  //       })
  //     })
  // }

  const handleCancel = () => {
    setSelectedAvailableRoles([])
    onClose()
  }

  const handleCheckboxChange = (
    roleId: string,
    roleList: RoleOption[],
    isAvailable: boolean
  ) => {
    const role = roleList?.find((role) => role?.id === roleId)
    if (!role) return
    if (isAvailable) {
      setSelectedAvailableRoles((prev) =>
        prev?.some((selectedRole) => selectedRole?.id === roleId)
          ? prev?.filter((selectedRole) => selectedRole?.id !== roleId)
          : [...prev, role]
      )
    }
    // neede
    // else {
    //   setSelectedAssignedRoles((prev) =>
    //     prev?.some((selectedRole) => selectedRole?.id === roleId)
    //       ? prev?.filter((selectedRole) => selectedRole?.id !== roleId)
    //       : [...prev, role]
    //   )
    // }
  }

  const renderRoleList = (
    rolesList: RoleOption[],
    isCheckbox = false,
    isAvailable = false
  ) => (
    <ul className='rolesfontsize'>
      {rolesList?.map((role) => (
        <li key={role?.id} className={isCheckbox ? 'rolelist' : ''}>
          {isCheckbox && isAvailable && (
            <Checkbox
              className='checkboxsize'
              checked={selectedAvailableRoles?.some((r) => r?.id === role?.id)}
              onChange={() =>
                handleCheckboxChange(role?.id, rolesList, isAvailable)
              }
            />
          )}
          {role?.name}
        </li>
      ))}
    </ul>
  )

  return (
    <Dialog open={isOpen} onClose={handleCancel}>
      <DialogContent>
        <h3 className='rolestitlefontsize'>
          {isGroup ? 'Group Roles' : 'Client Roles'}
        </h3>
        <div className='role-list-container'>
          <h3 className='rolestitlefontsize'>{AssignedRoles}</h3>
          {renderRoleList(
            isGroup ? grouproles?.assignedroles || [] : roles,
            true,
            false
          )}
          {(isGroup ? grouproles?.availableRoles : availableKeycloakRoles)
            ?.length > 0 && (
            <div>
              <h3 className='rolestitlefontsize'>{AvailableRoles}</h3>
              {renderRoleList(
                isGroup ? grouproles?.availableRoles : availableKeycloakRoles,
                true,
                true
              )}
            </div>
          )}
        </div>
        <div className='dialogbutton fixed-buttons'>
          <Button
            className={classes?.proRithmDasboardCreateBtn}
            onClick={handleAddRoles}
            disabled={selectedAvailableRoles?.length === 0}
            variant='contained'
            size='small'
          >
            {AddRoles}
          </Button>
          {/* needed */}
          {/* <Button
            className={classes?.proRithmDasboardCreateBtn}
            onClick={handleRemoveRoles}
            disabled={selectedAssignedRoles?.length === 0}
            variant='contained'
            size='small'
          >
            Remove
          </Button> */}
          <Button
            className={classes?.proRithmDasboardCreateBtn}
            onClick={handleCancel}
            variant='contained'
            size='small'
          >
            {Cancel}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default KeycloakRolesDialog
