import React, { useState, useRef, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  TextField,
  Box,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import KeycloakService from '../../../utils/keycloakService'
import { AdminIntent } from '../constants'
import { startSxpProxy } from '../../../utils/api'
import { LABS_PROJECT_ID, TEMPLATE_CONSTANTS } from '../../../utils/constants'
import { toast } from 'react-toastify'
import InterpretationImage from '../../../form/InterpretationImage'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'
import { useTheme } from '@emotion/react'
import Loading from '../../../form/fields/Loading'

const styles = () => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogTitleText: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  nameFieldContainer: {
    '& .MuiOutlinedInput-input': {
      height: '1rem',
      fontSize: '14px',
    },
  },
  contentLabel: {
    marginBottom: '5px',
  },
  editorContainer: {
    height: '300px',
    marginBottom: '16px',
  },
  editor: {
    height: '250px',
  },
  dialogActions: {
    padding: '16px 24px',
  },
  cancelButton: {
    marginRight: '8px',
  },
})

interface PanelTemplatesEditDialogProps {
  openDialog: boolean
  closeDialog: () => void
  selectedTemplate: Template | null
  templates: Template[]
  newTemplate: boolean
  testId: number | undefined
  testName: string | undefined
}

interface Template {
  id: string
  template_name: string
  interpretation_image: string
  description: string
  created_by: string
  created_on: string
  status: string
  test_id: string
  test_name: string
  updated_by: string
  updated_on: string
}

export const PanelTemplatesEditDialog: React.FC<
  PanelTemplatesEditDialogProps
> = ({
  openDialog,
  closeDialog,
  selectedTemplate,
  newTemplate,
  testId,
  testName,
}) => {
  const [templateName, setTemplateName] = useState<string>(
    selectedTemplate?.template_name ?? ''
  )
  const [templateImage, setTemplateImage] = useState<string>(
    selectedTemplate?.interpretation_image ?? ''
  )
  const [status, setStatus] = useState<string>(selectedTemplate?.status ?? '')
  const [loading, setLoading] = useState<boolean>(false)
  const [templateContent, setTemplateContent] = useState<string>(
    selectedTemplate?.description ?? ''
  )
  const [imageURL, setImageURL] = useState<string | undefined>(
    selectedTemplate?.interpretation_image
  )
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)

  const quillRef = useRef<any>(null)
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      [{ font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      ['clean'],
    ],
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'color',
    'background',
    'script',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]

  useEffect(() => {
    if (selectedTemplate && !newTemplate) {
      setTemplateName(selectedTemplate?.template_name)
      setTemplateContent(selectedTemplate?.description)
      setTemplateImage(selectedTemplate?.interpretation_image)
      setStatus(selectedTemplate?.status)
    } else {
      setTemplateName('')
      setTemplateContent('')
      setTemplateImage('')
      setStatus('')
    }
  }, [selectedTemplate, newTemplate, openDialog])

  const handleRegistrationSubmit = async (values: {
    templateName: string
    templateContent: string
    templateImage: string
    status: string
  }) => {
    const { templateName, templateContent, templateImage, status } = values

    const formattedForUpdate = {
      id: selectedTemplate?.id ? parseInt(selectedTemplate?.id) : undefined,
      template_name: templateName,
      test_id: testId || undefined,
      test_name: testName ?? '',
      description: templateContent ?? '',
      updated_by: KeycloakService.getUsername(),
      interpretation_image: templateImage,
      status: status ?? '',
    }

    const formattedForCreate = {
      test_id: testId || undefined,
      description: templateContent ?? '',
      updated_by: KeycloakService.getUsername(),
      created_by: KeycloakService.getUsername(),
      template_name: templateName,
      test_name: testName,
      interpretation_image: templateImage,
      status: status,
    }

    if (selectedTemplate?.id) {
      formattedForUpdate.id = parseInt(selectedTemplate?.id)
    }

    const intent = selectedTemplate?.id
      ? AdminIntent?.updateTestTemplate
      : AdminIntent?.createTestTemplate
    setLoading(true)

    startSxpProxy(
      LABS_PROJECT_ID,
      intent,
      newTemplate ? formattedForCreate : formattedForUpdate
    )
      .then((data) => {
        if (data?.status === 200) {
          const successMessage = selectedTemplate?.id
            ? `${templateName} ${TEMPLATE_CONSTANTS?.updatedSuccess}`
            : `${templateName} ${TEMPLATE_CONSTANTS?.createSuccess}`
          toast.success(successMessage)
          closeDialog()
        }
      })
      .catch((error) => {
        toast.error(error?.message || TEMPLATE_CONSTANTS?.operationFailed)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      {loading && <Loading />}
      <Dialog
        open={openDialog}
        onClose={closeDialog}
        maxWidth='md'
        fullWidth
        id='template-edit-dialog'
      >
        <DialogTitle>
          <Box className={classes?.dialogTitle}>
            <Typography variant='h6' className={classes?.dialogTitleText}>
              {newTemplate
                ? TEMPLATE_CONSTANTS?.addNewTemplate
                : TEMPLATE_CONSTANTS?.editTemplate}
            </Typography>
            <IconButton
              aria-label='close'
              onClick={closeDialog}
              size='small'
              id='close-edit-dialog-button'
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box className={classes?.nameFieldContainer}>
            <TextField
              fullWidth
              label='Template Name'
              variant='outlined'
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              size='small'
              margin='normal'
              id='template-name-field'
            />
          </Box>

          <Typography variant='subtitle1' className={classes?.contentLabel}>
            {TEMPLATE_CONSTANTS?.templateContent}
          </Typography>

          <Box className={classes?.editorContainer}>
            <ReactQuill
              theme='snow'
              value={templateContent}
              onChange={setTemplateContent}
              formats={formats}
              modules={modules}
              className={classes?.editor}
              ref={quillRef}
            />
          </Box>
          <Box className={classes?.nameFieldContainer}>
            <TextField
              fullWidth
              label='Template Image'
              variant='outlined'
              value={templateImage}
              onChange={(e) => setTemplateImage(e.target.value)}
              size='small'
              margin='normal'
              id='template-name-field'
            />
          </Box>
          <InterpretationImage imageURL={imageURL} setImageURL={setImageURL} />
        </DialogContent>
        <DialogActions className={classes?.dialogActions}>
          <Button
            onClick={closeDialog}
            color='secondary'
            className={classes?.cancelButton}
            id='cancel-template-button'
          >
            {TEMPLATE_CONSTANTS?.cancel}
          </Button>
          <Button
            onClick={() =>
              handleRegistrationSubmit({
                templateName,
                templateContent,
                templateImage,
                status,
              })
            }
            color='primary'
            variant='contained'
            disabled={!templateName?.trim()}
            size='small'
            id='save-template-button'
          >
            {newTemplate
              ? TEMPLATE_CONSTANTS?.addTemplate
              : TEMPLATE_CONSTANTS?.save}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
