import React, { useEffect, useState } from 'react'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import { Event } from 'react-big-calendar'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  Doctor,
  fetchCalendarSlotsAsync,
  fetchDoctorsAsync,
  resetCalendarStatus,
  selectSchedulerDoctors,
  selectSchedulerEvents,
  selectSelectedDoctor,
  setSelectedDoctor,
} from './schedulerSlice'
import DoctorList from './components/DoctorList'
import SlotCalendar from './components/SlotCalendar'

const Scheduler = () => {
  const date = new Date()
  const [startDate, setStartDate] = useState<Date>(startOfWeek(date))
  const [endDate, setEndDate] = useState<Date>(endOfWeek(date))

  const doctors = useAppSelector(selectSchedulerDoctors)
  const selectedDoc = useAppSelector(selectSelectedDoctor)
  const events = useAppSelector(selectSchedulerEvents)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchDoctorsAsync({ start: startDate, end: endDate }))
    return () => {
      dispatch(resetCalendarStatus())
    }
  }, [dispatch, startDate, endDate])

  const handleDocChange = (doc: Doctor, i: number) => {
    dispatch(setSelectedDoctor(i))
    dispatch(
      fetchCalendarSlotsAsync({ docId: doc.id, start: startDate, end: endDate })
    )
  }

  const onRangeChange = (range: Date[] | { start: Date; end: Date }) => {
    let start
    let end
    if (Array.isArray(range)) {
      start = range[0]
      end = range[range.length - 1]
    } else {
      start = range.start
      end = range.end
    }
    setStartDate(start)
    setEndDate(end)
    dispatch(
      fetchCalendarSlotsAsync({ docId: doctors[selectedDoc].id, start, end })
    )
  }

  const customEvents: Event[] = events.map((ev) => {
    return { ...ev, start: new Date(ev.start), end: new Date(ev.end) }
  })

  return (
    <div className='layoutcontent total-width-content'>
      <div className='calnderLayout'>
        <DoctorList
          doctors={doctors}
          selectedDoc={selectedDoc}
          handleChange={handleDocChange}
        />
        <SlotCalendar events={customEvents} onRangeChange={onRangeChange} />
      </div>
    </div>
  )
}

export default Scheduler
