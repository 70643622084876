import { useEffect, useRef, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import RenderOnRole from '../../../../components/RenderOnRole'
import {
  DIAGNOSIS_READ,
  HISTORY_READ,
  LAB_ORDERS_READ,
  MEDICATIONS_READ,
  SYSTEMIC_READ,
  VITALS_READ,
} from '../../../../utils/roles'
import OpdPhysioMenu from './OpdPhysioMenu'
import OPDPatientTable from '../../../OPDScreen/OpdScreen'
import OPDVisitsDetails from '../../../OPDScreen/visitDetails'
import { id } from 'date-fns/locale'
import { startSxpProxy } from '../../../../utils/api'
import { OPD_PROJECT_ID } from '../../../../utils/constants'
import { appointmentType } from '../../../OPDScreen/constant'
import { getTotalAge } from '../../../../utils/dateUtils'
import { makeName } from '../../../lms/utils'
import { capitalizeFirstLetter } from '../../../patients/utils'
import DateofAppointments from '../../DateofAppointment'
import { FormattedPatient } from '../../../patients/Patients'
import { Box } from '@mui/material'

const OpdPhysio = () => {
  const [show, setShow] = useState<boolean>(false)
  const [patientData, setPatientData] = useState<FormattedPatient[]>([])
  // const [visitsList, setVisitList] = useState<appointmentType[]>([])
  const [visitsDateList, setVisitsDateList] = useState<appointmentType[]>([])
  const [selectedDate, setSelectedDate] = useState(-1)
  const location = useLocation()
  const storepatentid = location?.state?.id

  const visitDateHandler = (data: any, i: any) => {
    setSelectedDate(i)
  }

  const transformPatientData = (p: any): FormattedPatient => {
    const thop = p.identifier?.[0]
    const bahmni = p.identifier?.[2]
    const created = p.identifier?.[3]
    const regType = p.identifier?.[3]
    const invoice = p.identifier?.[5]
    const name = makeName(p.name)
    const age = getTotalAge(p.birthDate)
    const gender = capitalizeFirstLetter(p.gender)
    const tel1 = p.telecom?.find((prt: { use: string }) => prt.use === 'mobile')
    const tel2 = p.telecom?.find((prt: { use: string }) => prt.use === 'home')

    return {
      id: bahmni?.value ?? thop?.value ?? p.id ?? '',
      name: name.trim() ?? '',
      gender: gender ?? '',
      age: age ?? '',
      fhirId: p.id ?? '',
      thopId: thop?.value ?? '',
      invoiceId: invoice?.value ?? '',
      registeredOn: created?.period?.start ?? '',
      registrationType: regType?.value ?? '',
      mobile: tel1?.value ?? tel2?.value ?? '',
    }
  }

  useEffect(() => {
    const intent = 'getPatientVisits'
    const patientIntent = 'getPatientApi'
    const state = {
      id: storepatentid,
    }

    startSxpProxy(OPD_PROJECT_ID, intent, state)
      .then((res) => {
        if (res?.data?.entry) {
          // setVisitList(res?.data?.entry)
          setVisitsDateList(res?.data?.entry)
          setSelectedDate(0)
        } else {
          // setVisitList([])
        }
      })
      .catch((e) => console.log(e))

    startSxpProxy(OPD_PROJECT_ID, patientIntent, state)
      .then((res1) => {
        const data = Array.isArray(res1?.data) ? res1?.data : [res1?.data]
        const formattedData = transformPatientData(data[0])
        setPatientData([formattedData])
        setShow(true)
      })
      .catch((f) => console.log(f))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const patientTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 200}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  const element = (
    <div className='opd-container opd-height dental-container'>
      {show && (
        <div>
          <div>{<OPDVisitsDetails />}</div>
          <div className='opd-visittable'>
            <OPDPatientTable patients={patientData} isOpenDocsPage={true} />
          </div>
        </div>
      )}
      <div
        className='opd-menudates opd-dates-container'
        ref={show ? patientTableContainerRef : null}
      >
        <div className='opdmenu flex-shrink'>{<OpdPhysioMenu />}</div>
        <Box flex={1} width={'100%'} marginTop={'1%'} overflow={'auto'}>
          {show && (
            <div>
              <DateofAppointments
                dateHandler={visitDateHandler}
                activeDate={selectedDate}
                visitDates={visitsDateList}
              />
            </div>
          )}
          <div
            className={`flex-height-content ${
              show ? 'opd-content-visit' : 'opd-content'
            }`}
          >
            <Outlet />
          </div>
        </Box>
      </div>
    </div>
  )
  return (
    <RenderOnRole
      roles={[
        HISTORY_READ,
        VITALS_READ,
        SYSTEMIC_READ,
        DIAGNOSIS_READ,
        MEDICATIONS_READ,
        LAB_ORDERS_READ,
      ]}
      element={element}
    />
  )
}

export default OpdPhysio
