import axios from 'axios'
import KeycloakService from '../utils/keycloakService'
import { ALL_NOTIFICATIONS_STATUS } from '../utils/Endpoints'

export const getUnreadNotifications = async (status: string) => {
  const token = KeycloakService.getToken()

  try {
    const response: any = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}${ALL_NOTIFICATIONS_STATUS}?status=${status}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const { data, success, message } = response?.data || {}
    if (success) {
      return {
        success: true,
        data: data,
        message: message,
      }
    } else {
      return {
        success: false,
        data: null,
        message: message,
      }
    }
  } catch (error: any) {
    console.error('Error fetching notifications: ', error)
    return {
      success: false,
      data: null,
      message: error?.message,
    }
  }
}
