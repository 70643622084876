import { IconButton } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { useNavigate } from 'react-router-dom'
import PartnerForm from './PartnerForm'
import { radiologyEndPoint } from '../Radiology/RadiologyEndPoints'
import './organization.scss'

const CreateOrganizationForm = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className='organization-container'>
        <IconButton
          onClick={() => navigate(radiologyEndPoint?.referredOut)}
          className='organization-icon-content'
        >
          <KeyboardBackspaceIcon className='back-btn-admin' />
        </IconButton>
        <PartnerForm />
      </div>
    </>
  )
}
export default CreateOrganizationForm
