import React, { useEffect, useState } from 'react'
import './radiologyModel.scss'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { useTheme } from '@mui/material'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import { radiologyColor } from '../../Utils/RadiologyColors'
import { highToLow, sortOrderKey } from '../../Constants/constants'

const styles = () => ({
  filterIcon: {
    fontSize: '19px',
    color: 'black',
    background: radiologyColor?.transparent,
  },
  filterIconContainer: {
    top: '3px',
    cursor: 'pointer',
  },
  withRedDot: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: '6px',
      width: '7px',
      height: '7px',
      backgroundColor: radiologyColor?.red,
      borderRadius: '50%',
      border: `2px solid ${radiologyColor?.white}`,
      transform: 'translate(50%, -50%)',
    },
  },
  sortingIcon: {
    fontSize: '15px',
    cursor: 'pointer',
    color: 'red !important',
  },
  customHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  hiddenText: {
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    fontSize: '10px',
    color: '#183f7c !important',
  },
  customHeaderHover: {
    '&:hover > *:nth-child(2), &:hover > *:nth-child(3)': {
      opacity: 1,
      fontSize: '10px',
    },
  },
  notHiddenText: {
    fontSize: '10px',
    color: '#183f7c !important',
    display: 'flex',
  },
  arrowUpwardContent: {
    Size: '17px',
    color: '#183f7c !important',
  },
  ArrowDownwardIconContent: {
    fontSize: '15px',
    color: '#183f7c !important',
  },
})

const CustomHeader = (props: any) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [isFiltered, setIsFiltered] = useState(false)
  const [sortOrder, setSortOrder] = useState<string | null>(null)
  const [checkSorting, setCheckSorting] = useState(false)
  const [checkFiltering, setCheckFiltering] = useState(false)

  useEffect(() => {
    const onFilterChanged = () => {
      const filterModel = props?.api?.getFilterModel()
      const hasFilter = !!filterModel[props?.column?.colId]
      setIsFiltered(hasFilter)
      setCheckFiltering(hasFilter) // Control filter icon visibility
    }

    const onSortChanged = () => {
      const currentSort = props?.column?.getSort()
      setSortOrder(currentSort)
    }

    props?.api?.addEventListener('filterChanged', onFilterChanged)
    props?.column?.addEventListener('sortChanged', onSortChanged)

    return () => {
      props?.api?.removeEventListener('filterChanged', onFilterChanged)
      props?.column?.removeEventListener('sortChanged', onSortChanged)
    }
  }, [props?.api, props?.column])

  // Handle sorting request
  const onSortRequested = () => {
    let newSortOrder = null
    if (sortOrder === null) newSortOrder = sortOrderKey?.asc
    else if (sortOrder === sortOrderKey?.asc) newSortOrder = sortOrderKey?.desc
    else newSortOrder = null

    props?.setSort(newSortOrder)
  }

  useEffect(() => {
    if (sortOrder === sortOrderKey?.asc || sortOrder === sortOrderKey?.desc) {
      setCheckSorting(true)
    } else {
      setCheckSorting(false)
    }
  }, [sortOrder])

  return (
    <div className={`${classes?.customHeader} ${classes?.customHeaderHover}`}>
      <span>{props?.displayName}</span>

      {/* Sorting Icon */}
      <span
        onClick={onSortRequested}
        className={`${
          checkSorting ? classes?.notHiddenText : classes?.hiddenText
        }`}
      >
        {sortOrder === sortOrderKey?.asc ? (
          <ArrowUpwardIcon className={classes?.arrowUpward} />
        ) : (
          <ArrowDownwardIcon
            titleAccess={highToLow}
            className={classes?.ArrowDownwardIconContent}
          />
        )}
      </span>

      {/* Filter Icon */}
      <span
        onClick={(e) => props?.showColumnMenu(e?.target)}
        className={`${classes?.filterIconContainer} ${
          isFiltered && classes?.withRedDot
        } ${checkFiltering ? classes?.notHiddenText : classes?.hiddenText}`}
      >
        <MoreVertIcon className={classes?.filterIcon} />
      </span>
    </div>
  )
}

export default CustomHeader
