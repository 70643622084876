import React from 'react'
import { readableDateTimeFormat } from '../../utils/dateUtils'
import { opProvenance, visitDashboard } from './constant'
import { emptyString } from '../Radiology/Constants/constants'

type Props = {
  date?: string
  name?: string
  mode: 'CREATE' | 'UPDATE'
}

const OpdProvenanceView = ({ date, name, mode }: Props) => {
  return (
    <div
      className='provenance-view'
      title={
        date
          ? `${
              mode === opProvenance?.create
                ? opProvenance?.Created
                : opProvenance?.lastUpdated
            } on ${readableDateTimeFormat(new Date(date))}`
          : emptyString
      }
    >
      {name ? (
        <>
          <span className='title'>{name || visitDashboard?.hyphen}</span>
        </>
      ) : (
        <span className='time'>
          {date ? readableDateTimeFormat(new Date(date)) : opProvenance?.na}
        </span>
      )}
    </div>
  )
}

export default OpdProvenanceView
