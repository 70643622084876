import { useRef, useEffect, useState } from 'react'
import VisitsNewTable from '../appointments/VisitsNewTable'
import { opdTableHeadings } from '../lms/constants'
import { FormattedPatient } from '../patients/Patients'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import KeycloakService from '../../utils/keycloakService'
import { PATIENTS_WRITE } from '../../utils/roles'
import {
  selectLocations,
  selectSelectedLocationId,
} from '../location/locationSlice'
import { ProvenanceData, setRedirectTo } from '../patients/patientsSlice'
import { useNavigate } from 'react-router-dom'
import { Dialog, DialogContent, IconButton, Snackbar } from '@mui/material'
import { pdf } from '@react-pdf/renderer'
import { Provenance, Practitioner } from 'fhir/r4'
import MaskedNumber from '../../components/MaskedNumber'
import ProvenanceView from '../../components/ProvenanceView'
import { startSxpProxy } from '../../utils/api'
import {
  ADMIN_PROJECT_ID,
  REGISTRATION_PROJECT_ID,
} from '../../utils/constants'
import { odooPharmacyLink } from '../appointments/models'
import LocationByPrefix from '../patients/dashboard/LocationByPrefix'
import { getLocationAddress, getProvenanceData } from '../patients/utils'
import { LocationPrefix, GetPatientOpd } from './Utils'
import {
  DEFAULT_TEXT,
  DEFAULT_TITLE,
  REGISTRATION_TEXT,
  REGISTRATION_TITLE,
  active,
  dental,
  physio,
  unpaid,
} from './constant'
import PrintLogo from '../../assets/images/print.png'
import OPDComponentPrint from './opdCard'
import UploadDocs from '../patients/dashboard/UploadDocs'
import uploadIcon from './../../assets/images/download_uplaod.png'
import visiticon from './../../assets/images/visit.png'
import patientDashboardIcon from './../../assets/images/patientDashboardIcon.png'
import { intent } from '../administration/constants'
import RegistrationCardPrint from './RegistrationCardPrint'
type Props = {
  patients: FormattedPatient[]
}
const RegistrationType = ({ regType }: { regType: string }) => {
  const text = REGISTRATION_TEXT[regType] || DEFAULT_TEXT
  const title = REGISTRATION_TITLE[regType] || DEFAULT_TITLE

  return <span title={title}>{text}</span>
}
const PatientRow = ({
  d,
  provenances,
  isOpenDocsPage,
  handleDocClick,
  locations,
}: {
  d: FormattedPatient
  provenances: Record<string, ProvenanceData>
  index: number
  onCreate: () => void
  onEdit: () => void
  isOpenDocsPage: boolean
  handleDocClick: (patientId: string) => void
  locations: any
}) => {
  const registrationRef = useRef(null)
  const handleRegistrationPrint = async (details: any, locations: any) => {
    const registrationNo = details?.id
    const locationAddress = getLocationAddress(registrationNo, locations)
    const location = registrationNo
      ? LocationPrefix(registrationNo, locations)
      : '----'

    try {
      const data = await GetPatientOpd(details, location, locationAddress)
      if (!data?.registrationNo) {
        console.error('Invalid data:', data)
        return
      }
      const blob = await pdf(<RegistrationCardPrint data={data} />).toBlob()
      const url = URL.createObjectURL(blob)
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      iframe.src = url
      document.body.appendChild(iframe)
      iframe.onload = () => {
        iframe?.contentWindow?.print()
      }
    } catch (error) {
      console.error('Error in handleRegistrationPrint:', error)
    }
  }
  const [showAlert, setShowAlert] = useState(false)
  const locationId = useAppSelector(selectSelectedLocationId)
  const [encounterDetails] = useState<any>(null)
  const openOdooInvoice = (id: string) => {
    startSxpProxy(ADMIN_PROJECT_ID, odooPharmacyLink, {
      invoiceId: id,
      location: locationId,
    })
      .then((data) => {
        const url = data?.data?.auth_link
        window.open(url, '_blank', 'noopener,noreferrer')
      })
      .catch((err) => console.error(err, 'err'))
  }
  const details: any = {
    name: d?.name,
    id: d?.id,
    Age: d?.age,
    gender: d?.gender,
    Mobile: d?.mobile,
    RegisteredOn: d?.registeredOn,
    photo: d?.photo,
  }
  const navigate = useNavigate()
  const showvisit = (
    patientId: string,
    encounterId: string,
    visitType: string
  ) => {
    let basePath = `/visits/${encounterId}/history`
    if (visitType === dental) {
      basePath = `/visits/dental/${encounterId}/historyDental`
    } else if (visitType === physio) {
      basePath = `/visits/physio/${encounterId}/historyPhysio`
    }

    navigate(basePath, {
      state: {
        id: patientId,
        show: true,
      },
    })
  }

  const handleVisitClick = () => {
    if (encounterDetails?.[0]?.encounterId) {
      showvisit(
        d?.fhirId,
        encounterDetails?.[0]?.encounterId,
        encounterDetails?.visitType
      )
    } else {
      setShowAlert(true)
    }
  }

  const handleNameClick = () => {
    navigate('/opd', { state: { fhirId: d?.fhirId, MUHId: d?.id } })
  }

  return (
    <tr>
      <td>{d?.id || d?.fhirId}</td>
      <td>
        <RegistrationType regType={d?.registrationType} />
      </td>
      <td>
        {d?.name}
        &nbsp;
        {d?.invoiceId && d?.invoiceId !== unpaid && (
          <span
            className='OPDScreenInvoice'
            onClick={() => openOdooInvoice(d?.invoiceId)}
          >
            Unpaid
          </span>
        )}
      </td>
      <td>
        <MaskedNumber mobile={d?.mobile} />
      </td>
      <td className='text-center'>{d?.gender}</td>
      <td className='text-center' title={d?.age}>
        {d?.age}
      </td>
      <td className='text-center'>
        <LocationByPrefix id={d?.id} />
      </td>
      <td className='text-center'>
        <ProvenanceView
          date={d?.registeredOn}
          name={provenances?.[d?.fhirId]?.createdBy}
          mode={'CREATE'}
        />
      </td>
      <td className='text-center'>
        <ProvenanceView
          date={provenances?.[d?.fhirId]?.updatedOn ?? d?.registeredOn}
          name={
            provenances?.[d?.fhirId]?.updatedBy ??
            provenances?.[d?.fhirId]?.createdBy
          }
          mode={'UPDATE'}
        />
      </td>
      <td>
        <div className={`uploadDocBtns ${isOpenDocsPage ? active : ''}`}>
          <div
            onClick={() => handleDocClick(d?.id)}
            className='uploadbtn upload-document-icon'
          >
            <img
              title='Upload Document`s'
              src={uploadIcon}
              alt='upload button'
              className='text-center'
            />
          </div>
        </div>
      </td>
      <td className='text-center appointmentBtn'>
        <div className='printButtons'>
          <div className='print-document-icon'>
            <OPDComponentPrint ref={registrationRef} patient={details} />
          </div>
          <div className='registration-card-print'>
            <div
              onClick={() => handleRegistrationPrint(d, locations)}
              className='printbtn'
            >
              <img
                title='Registration Card'
                src={PrintLogo}
                alt='Print Registration Card'
              />
            </div>
            <div className='printbtn'>
              <div className='printbtn'>
                <img
                  title='Visits'
                  src={visiticon}
                  alt='Visits'
                  onClick={handleVisitClick}
                />
                <Snackbar
                  open={showAlert}
                  autoHideDuration={3000}
                  onClose={() => setShowAlert(false)}
                  message='No visits'
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                />
              </div>
            </div>
            <div className='printbtn'>
              <img
                title='DashBoard'
                src={patientDashboardIcon}
                alt='DashBoard'
                className='pat-print-icon'
                onClick={() => handleNameClick()}
              />
            </div>
          </div>
        </div>
      </td>
    </tr>
  )
}
const OPDPatientList = ({ patients }: Props & { isOpenDocsPage: boolean }) => {
  const patientTableContainerRef = useRef<HTMLDivElement | null>(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isOpenDocsPage, setIsOpenDocsPage] = useState(false)
  const locations = useAppSelector(selectLocations)
  const ids = patients?.map((p) => p?.fhirId)?.join(',')
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})

  const handleCloseDialog = () => {
    setIsOpenDocsPage(false)
  }

  const editPatient = (d: FormattedPatient) => {
    if (KeycloakService.hasRole([PATIENTS_WRITE])) {
      dispatch(setRedirectTo('patients'))
      navigate(`/patient-registration/${d?.fhirId}`)
    }
  }
  const handleDocClick = () => {
    setIsOpenDocsPage((prevState) => !prevState)
  }
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  const patientId = patients?.[0]?.fhirId
  useEffect(() => {
    if (ids) {
      startSxpProxy(REGISTRATION_PROJECT_ID, intent?.fetchProvenance, {
        patientIds: ids,
      })
        .then((data) => {
          const entries =
            data?.data?.entry?.map(
              (dde: { resource: Provenance | Practitioner }) => dde?.resource
            ) ?? []
          const obj = getProvenanceData(entries)
          setProvenances(obj)
        })
        .catch((err) => console.error(err))
    }
  }, [ids])
  return (
    <>
      <Dialog open={isOpenDocsPage} onClose={handleCloseDialog} fullWidth>
        <IconButton onClick={handleCloseDialog} className='dialog-close-btn'>
          X
        </IconButton>
        <DialogContent className='opd-dialog-content'>
          <UploadDocs patientId={patientId} />
        </DialogContent>
      </Dialog>
      {patients?.length === 1 ? (
        <div className='fixTableHead' ref={patientTableContainerRef}>
          <table className='data-table table-fixed admin-table'>
            <thead className='TableHeadFixed'>
              <tr>
                <th className='text-left table-w-15'>
                  {opdTableHeadings?.UHID}
                </th>
                <th className='table-w-8'>{opdTableHeadings?.RegType}</th>
                <th className='text-left table-w-15'>
                  {opdTableHeadings?.Name}
                </th>
                <th className='table-w-8'>{opdTableHeadings?.MobileNo}</th>
                <th className='text-center table-w-6'>
                  {opdTableHeadings?.Gender}
                </th>
                <th className='text-center table-w-8'>
                  {opdTableHeadings?.Age}
                </th>
                <th className='text-center table-w-10'>
                  {opdTableHeadings?.OperatingUnit}
                </th>
                <th className='text-center table-w-10'>
                  {opdTableHeadings?.CreatedOn}
                </th>
                <th className='text-center table-w-10'>
                  {opdTableHeadings?.UpdatedOn}
                </th>
                <th className='text-center table-w-10'>
                  {opdTableHeadings?.PatientDoc}
                </th>
                <th className='text-center table-w-10'>
                  {opdTableHeadings?.View}
                </th>
              </tr>
            </thead>
            <tbody>
              {patients?.map((d, i) => (
                <PatientRow
                  key={d?.fhirId}
                  d={d}
                  provenances={provenances}
                  index={i}
                  onCreate={() => ''}
                  onEdit={() => editPatient(d)}
                  isOpenDocsPage={isOpenDocsPage}
                  handleDocClick={handleDocClick}
                  locations={locations}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        patients?.length !== 1 && (
          <div className='VisitNewTable'>
            <VisitsNewTable />
          </div>
        )
      )}
    </>
  )
}
export default OPDPatientList
