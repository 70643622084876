import React from 'react'

const socketInstance: {
  socket: WebSocket | null
  sendData: (payload: Record<string, any>) => void
} = {
  socket: null,
  sendData: (payload: Record<string, any>) => {
    if (
      socketInstance?.socket &&
      socketInstance?.socket?.readyState === WebSocket.OPEN
    ) {
      try {
        socketInstance?.socket?.send(JSON.stringify(payload))
      } catch (error) {
        console.error('Failed to send WebSocket message:', error)
      }
    } else {
      console.warn('WebSocket is not open. Cannot send message.')
    }
  },
}

export function useWebSocket(
  url: string | undefined,
  appType: string | undefined,
  onMessage: (message: any) => void
) {
  const onMessageRef = React.useRef(onMessage)

  React.useEffect(() => {
    onMessageRef.current = onMessage
  }, [onMessage])

  function createSocketConnection() {
    if (
      socketInstance?.socket &&
      socketInstance?.socket?.readyState === WebSocket.OPEN
    ) {
      console.warn('WebSocket is already open.')
      return
    }

    const socket = new WebSocket(`${url}?appType=${appType}`)
    socketInstance.socket = socket

    socket.onopen = (event) => {
      console.log('WebSocket connection opened:', event)
    }

    socket.onmessage = (event: MessageEvent) => {
      try {
        const message = JSON.parse(event.data)
        onMessageRef?.current(message)
      } catch (error) {
        console.error('Error parsing WebSocket message:', error)
      }
    }

    socket.onerror = (event: Event) => {
      console.error('WebSocket error observed:', event)
    }

    socket.onclose = (event: CloseEvent) => {
      console.log('WebSocket closed with code:', event.code)
      socketInstance.socket = null
      if (event.code !== 1000 && event.code !== 1001) {
        console.log('Abnormal closure, attempting to reconnect...')
        setTimeout(createSocketConnection, 3000) // Retry after 3 seconds
      }
    }
  }

  function closeSocketConnection() {
    if (socketInstance?.socket) {
      socketInstance?.socket?.close()
      socketInstance.socket = null
    }
  }

  return {
    createSocketConnection,
    sendData: socketInstance?.sendData,
    closeSocketConnection,
  }
}

export const sendSocketData = socketInstance?.sendData
