import * as React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { startSxpProxy } from '../../utils/api'
import { LABS_PROJECT_ID } from '../../utils/constants'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import KeycloakService from '../../utils/keycloakService'
import { VALIDATION_WRITE } from '../../utils/roles'
import { formatInvoiceKeyData } from '../lms/utils'
import { InvoiceData } from '../lms/models'
import {
  LabOrderProps,
  Order,
  Patient,
} from '../bedManagement/IpdVisit/IpdSummary/Interfaces'
import {
  formatReadableDate,
  dateDifference,
} from '../bedManagement/IpdVisit/IpdSummary/Constants'
import { formattedPatient } from './Utils'
import { opProvenance, opdIntents } from './constant'
import { GetLabDepartments } from '../lms/components/search/GetLabDepartments'
import { useInvoicePDFGenerator } from '../lms/components/dashboard/InvoicePdfLib'

function Row({ row, patient, index, labDepartments }: LabOrderProps) {
  const [open, setOpen] = React.useState(false)
  const { generatePDF } = useInvoicePDFGenerator()
  const handleInvoicePrint = React.useCallback(
    async (item: Order, patient: any) => {
      if (!KeycloakService.hasRole([VALIDATION_WRITE])) return

      const intent = opdIntents?.getLabTestsByIds
      const panelIds =
        item?.panels?.flatMap((p: any) =>
          p?.lab_tests?.map((t: any) => t?.id)
        ) ?? []
      const testIds = item?.lab_tests?.map((t: any) => t?.id) ?? []
      const state = { orderId: item?.id, ids: [...panelIds, ...testIds] }

      try {
        const { data } = await startSxpProxy(LABS_PROJECT_ID, intent, state)
        const panels = formatInvoiceKeyData(
          data?.lab_test ?? [],
          item?.status ?? '',
          labDepartments
        )

        const invoiceData: InvoiceData = {
          id: patient?.id ?? '',
          name: patient?.name || '',
          age: patient?.age || '',
          gender: patient?.gender || '',
          mobile: patient?.mobile ?? '',
          lrNumber: item.lr_number || '',
          orderedOn: item.ordered_on ?? '',
          completedOn: item.collection_completed_on ?? '',
          requestedBy: item.requested_by ?? item.ordered_by_name ?? '',
          validatedOn: item.validation_completed_on ?? '',
          panels,
          summary: item?.summary ?? '',
          orderType: item?.origin,
          fileId: item?.document_id,
        }

        const pdfBlob = await generatePDF(invoiceData)
        const fileUrl = URL.createObjectURL(pdfBlob)

        const newWindow = window.open('', '_blank')
        if (newWindow) {
          const iframe = document.createElement('iframe')
          Object.assign(iframe.style, {
            width: '100%',
            height: '100%',
            border: 'none',
          })
          iframe.src = fileUrl
          newWindow.document.title = 'Test Results'
          newWindow.document.body.style.margin = '0'
          newWindow.document.body.appendChild(iframe)
        } else {
          console.error('Popup blocked. Enable popups to view the invoice.')
        }
      } catch (err) {
        console.error('Error generating invoice:', err)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen((prev) => !prev)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align='center'>{index + 1}</TableCell>
        <TableCell component='th' scope='row'>
          {row?.lr_number}
        </TableCell>
        <TableCell align='left'>{row?.requested_by}</TableCell>
        <TableCell align='left'>{row?.status}</TableCell>
        <TableCell align='center'>
          {dateDifference(row?.ordered_on, row?.validation_completed_on)}
        </TableCell>
        <TableCell align='left'>
          <IconButton
            onClick={() => handleInvoicePrint(row, patient)}
            disabled={row?.status !== opProvenance?.completed}
          >
            <ArticleOutlinedIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell className='table-lab-visit-ipd-order' colSpan={7}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box m={1}>
                <Typography
                  variant='h6'
                  gutterBottom
                  component='div'
                  fontSize='14px'
                  fontWeight={600}
                >
                  Tests
                </Typography>
                <Table
                  size='small'
                  aria-label='purchases'
                  className='opd-lab-table'
                >
                  <TableHead className='table-head-lab-opd-visit'>
                    <TableRow>
                      <TableCell className='table-head-th-lab-opd-visit'>
                        Test
                      </TableCell>
                      <TableCell
                        className='table-head-th-lab-opd-visit'
                        align='left'
                      >
                        Doctor
                      </TableCell>
                      <TableCell
                        className='table-head-th-lab-opd-visit'
                        align='left'
                      >
                        Date & Time
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.panels?.map((panel: any) => (
                      <TableRow key={panel?.name}>
                        <TableCell component='th' scope='row'>
                          {panel?.name}
                        </TableCell>
                        <TableCell align='left'>{row?.requested_by}</TableCell>
                        <TableCell align='left'>
                          {formatReadableDate(row?.ordered_on)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

interface LabProps {
  patientRegisteredId: string
  patientData: Patient
}

export default function OpdLabOrderSummary(props: LabProps) {
  const labDepartments = GetLabDepartments()

  const { patientRegisteredId, patientData } = props
  const [labOrders, setLabOrders] = React.useState<Order[]>([])

  const fetchAllLabOrders = async (patientRegisteredId: string) => {
    try {
      const intent = opdIntents?.getLabOrdersByPatientIds
      const state = {
        patientId: patientRegisteredId,
      }
      const resp = await startSxpProxy(LABS_PROJECT_ID, intent, state)
      if (resp?.data?.lab_orders) {
        const orders = resp?.data?.lab_orders
        setLabOrders(orders)
      } else {
        setLabOrders([])
      }
    } catch (e) {
      console.error('Error fetching lab orders:', e)
    }
  }

  React.useEffect(() => {
    fetchAllLabOrders(patientRegisteredId)
  }, [patientRegisteredId])

  return (
    <Box mt={2} mb={2}>
      <TableContainer component={Paper}>
        <Table aria-label='collapsible table' size='small' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className='table-head-th-lab-opd-visit' />
              <TableCell className='table-head-th-lab-opd-visit' align='center'>
                Sl No
              </TableCell>
              <TableCell className='table-head-th-lab-opd-visit'>
                Lab Id
              </TableCell>
              <TableCell className='table-head-th-lab-opd-visit' align='left'>
                Requested By
              </TableCell>
              <TableCell className='table-head-th-lab-opd-visit' align='left'>
                Status
              </TableCell>
              <TableCell className='table-head-th-lab-opd-visit' align='center'>
                TAT
              </TableCell>
              <TableCell className='table-head-th-lab-opd-visit' align='left'>
                View
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody className='table-opd-lab-body'>
            {labOrders?.map((row: any, index: number) => (
              <Row
                key={row?.lr_number}
                row={row}
                patient={formattedPatient(patientData)}
                index={index}
                labDepartments={labDepartments}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
