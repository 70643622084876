import { useNavigate } from 'react-router-dom'
import { startSxpProxy } from '../../../utils/api'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import { useEffect, useState } from 'react'
import { Practitioner, Provenance } from 'fhir/r4'
import { intent } from '../constants'
import { ProvenanceData } from '../../patients/patientsSlice'
import { getProvenanceData } from '../../patients/utils'
import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'
import { ColDef, ITextFilterParams } from 'ag-grid-community'
import ProvenanceView from '../../../components/ProvenanceView'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { setPageSize } from '../../../app/appSlice'
import MedAgDataGrid from '../../../components/MedAgDataGrid'
import {
  pageSizeOptions,
  provenanceCreate,
  provenanceUpdate,
  radiologyIntents,
  radiologyTableButtons,
} from '../../Radiology/Constants/constants'
import { radiologyEndPoint } from '../../Radiology/RadiologyEndPoints'
import { radiologyColor } from '../../Radiology/Utils/RadiologyColors'
import CustomHeader from '../../Radiology/components/RadiologyModel/CustomHeader'
const styles = () => ({
  RadiologyDasboardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '95%',
    padding: 10,
    gap: 20,
  },
  radiologyDasboardTableContainer: {
    width: '100%',
    height: '95%',
  },
  radiologyDasboardheading: {
    color: radiologyColor?.deepBlue,
    fontSize: '16px',
    fontWeight: 600,
  },
  radiologyDasboardCreateBtn: {
    textTransform: 'capitalize',
    backgroundColor: radiologyColor?.steelBlue,
  },
  deviceText: {
    color: radiologyColor?.deepBlue,
    fontSize: '16px',
    fontWeight: 600,
  },
  centerHeader: {
    color: radiologyColor?.white,
    textAlign: 'center',
    '& .ag-header-cell-text': {
      flex: 1,
    },
  },
  specialtyFont: {
    fontSize: '12px',
  },
  specialtyPointer: {
    cursor: 'pointer',
  },
  specialtyContent: {
    textAlign: 'center',
  },
})

const Radiologist = () => {
  const navigate = useNavigate()
  const [radiologists, setRadiologists] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const ids = radiologists?.map((p: any) => p?.id)?.join(',')
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})

  const getProvenance = () => {
    startSxpProxy(ADMIN_PROJECT_ID, intent.fetchProvByDoctors, {
      pracIds: ids,
    }).then((data) => {
      const entries =
        data?.data?.entry?.map(
          (dde: { resource: Provenance | Practitioner }) => dde?.resource
        ) ?? []
      const obj = getProvenanceData(entries)
      setProvenances(obj)
    })
  }
  const getRadiologists = () => {
    setLoading(true)
    startSxpProxy(ADMIN_PROJECT_ID, radiologyIntents?.getRadiologists, {})
      .then((data: any) => {
        setRadiologists(data?.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  const renderName = (params: any) => {
    const firstName = params?.data?.firstName ?? ''
    const lastName = params?.data?.lastName ?? ''
    return firstName.concat(' ', lastName)
  }
  const RenderUpdatedActions = (params: any) => {
    return (
      <ProvenanceView
        date={params?.data?.registeredOn}
        name={
          provenances[params?.data?.id]?.updatedBy ??
          provenances[params?.data.id]?.createdBy
        }
        mode={provenanceUpdate}
      />
    )
  }
  const RenderCreatedActions = (params: any) => {
    return (
      <ProvenanceView
        date={params?.data?.registeredOn}
        name={provenances[params?.data?.id]?.createdBy}
        mode={provenanceCreate}
      />
    )
  }
  const renderSpecialty = (params: any) => {
    const speciality = params?.data?.specialities?.[0]?.coding?.map(
      (e: any) => e?.display
    )
    const length = params?.data?.specialities?.[0]?.coding?.length

    return (
      <div>
        {length === 1 ? (
          <span>{speciality?.[0]}</span>
        ) : (
          <>
            <Typography className={classes?.specialtyContent}>
              <span className={classes?.specialtyFont}> {speciality?.[0]}</span>
              {length > 1 && (
                <>
                  ,&nbsp;
                  <Tooltip title={speciality?.slice(1)?.join(', ')} arrow>
                    <span className={classes?.specialtyPointer}>...</span>
                  </Tooltip>
                </>
              )}
            </Typography>
          </>
        )}
      </div>
    )
  }
  const RenderEditAction = (params: any) => {
    const handleEdit = () => {
      navigate(`${radiologyEndPoint?.editRadiologist}/${params?.data?.id}`)
    }
    return <EditOutlinedIcon titleAccess='Edit' onClick={handleEdit} />
  }
  const FilterActions = () => {
    const navigateCreate = () => {
      navigate(radiologyEndPoint?.createRadiologist)
    }
    return (
      <Button
        variant='contained'
        size='small'
        className={classes?.radiologyDasboardCreateBtn}
        onClick={navigateCreate}
      >
        Create
      </Button>
    )
  }
  const columnDefs: ColDef[] = [
    {
      headerName: 'Sl No',
      field: 'id',
      width: 90,
      sortable: true,
      filter: false,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
    },
    {
      headerName: 'Name',
      field: 'firstName',
      flex: 1,
      sortable: true,
      filter: true,
      cellRenderer: renderName,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      minWidth: 165,
    },
    {
      headerName: 'Speciality',
      field: 'specialities',
      flex: 1,
      sortable: true,
      filter: true,
      cellRenderer: renderSpecialty,
      filterParams: {
        buttons: [radiologyTableButtons?.clear],
      } as ITextFilterParams,
      valueGetter: (params: any) =>
        params?.data?.specialities
          ?.find((speciality: any) => speciality?.coding)
          ?.coding?.map((name: any) => name?.display),
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', display: 'flex', alignItems: 'center' },
      minWidth: 110,
    },
    {
      headerName: 'Qualification',
      field: 'qualification',
      flex: 1,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 140,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Reg No',
      field: 'regNumber',
      flex: 1,
      sortable: true,
      minWidth: 130,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
      },
    },
    {
      headerName: 'Email',
      field: 'email',
      flex: 1,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 110,
    },
    {
      headerName: 'Mobile Number',
      field: 'phone',
      flex: 1,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 145,
    },
    {
      headerName: 'Date of Birth',
      field: 'dob',
      flex: 1,
      minWidth: 120,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
    },
    {
      headerName: 'Gender',
      field: 'gender',
      flex: 1,
      sortable: true,
      filter: true,
      filterParams: { buttons: ['clear'] } as ITextFilterParams,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 120,
    },
    {
      headerName: 'Status',
      field: 'status',
      flex: 1,
      minWidth: 120,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
    },
    {
      headerName: 'Created On',
      field: 'created on',
      flex: 1,
      sortable: true,
      filter: true,
      minWidth: 140,
      cellRenderer: RenderCreatedActions,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
    },
    {
      headerName: 'Updated On',
      field: 'updated_on',
      width: 100,
      sortable: false,
      filter: false,
      headerClass: classes?.centerHeader,
      cellRenderer: RenderUpdatedActions,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 100,
      sortable: false,
      filter: false,
      cellRenderer: RenderEditAction,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center', display: 'flex' },
    },
  ]
  useEffect(() => {
    if (ids) {
      getProvenance()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids])
  useEffect(() => {
    getRadiologists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Box className={classes?.RadiologyDasboardContainer}>
      <Box className={classes?.radiologyDasboardTableContainer}>
        <MedAgDataGrid
          tableHeading='Radiologist'
          tableHeight={'100%'}
          rowData={radiologists}
          columnDefs={columnDefs}
          pagination={false}
          pageSizeOptions={pageSizeOptions}
          setPageSize={setPageSize}
          headerHeight={30}
          searchFieldWidth={200}
          loading={loading}
          filterActions={<FilterActions />}
        />
      </Box>
    </Box>
  )
}
export default Radiologist
