import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'
import medunitedLogo from '../../../src/assets/images/medunited.png'
import locationIcon from '../../../src/assets/icons/location.png'
import emailIcon from '../../../src/assets/icons/email.png'
import mobileIcon from '../../../src/assets/icons/mobile.png'
import { generateBarcodeImage } from '../lms/utils'
import { crhealthcare } from '../patientRegstration/constants'
import { registrationCard } from './constant'
import { invoiceDateTimeFormat } from '../../utils/dateUtils'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #000',
    paddingBottom: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginVertical: 10,
    textDecoration: 'underline',
  },
  section: {
    marginVertical: 10,
    paddingHorizontal: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
  },
  label: {
    fontWeight: 'bold',
    width: '40%',
  },
  value: {
    width: '60%',
  },
  barcode: {
    marginTop: 10,
    textAlign: 'center',
  },
  profileImage: {
    width: 100,
    height: 100,
    borderRadius: 50,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  container: {
    top: 0,
    width: '100%',
    height: 8,
    position: 'relative',
  },
  headerTopLine: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 6,
    width: '30vw',
    backgroundColor: '#F47421',
  },
  headerTopLineAfter: {
    content: '',
    position: 'absolute',
    top: 0,
    right: 0,
    height: 6,
    width: '70vw',
    backgroundColor: '#004D91',
  },
  medLogo: {
    position: 'absolute',
    marginLeft: '16',
    marginTop: '5px',
    height: '20%',
    width: '20%',
    top: '4%',
  },
  logo: {
    width: '10rem',
    height: '10rem',
  },
  pageCount: {
    position: 'absolute',
    bottom: 20,
    right: 12,
    fontSize: 8,
  },
  headerData: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    alignItems: 'flex-end',
    marginRight: 12,
    marginTop: '7px',
    fontSize: 8,
  },
  headerLine: {
    height: 1,
    backgroundColor: '#cbcbcb',
    marginTop: 18,
    marginHorizontal: 10,
  },
  boxContainer: {
    borderWidth: 1,
    borderColor: '#cbcbcb',
    marginHorizontal: 10,
    marginTop: 10,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  leftSection: {
    width: '60%',
    borderRightWidth: 1,
    borderColor: '#cbcbcb',
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    paddingLeft: 20,
  },
  rightSection: {
    width: '40%',
    padding: 5,
    border: '1px solid #cbcbcb',
    justifyContent: 'space-around',
    display: 'flex',
  },
  headerItem: {
    marginLeft: 20,
    marginRight: 30,
    marginTop: 25,
    borderBottom: '1px solid #cbcbcb',
    padding: 4,
    paddingBottom: 8,
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 4,
    marginRight: 20,
    gap: 20,
    alignItems: 'flex-start',
  },
  headerValue: {
    fontSize: 9,
    marginTop: 1,
    flexShrink: 1,
    flexWrap: 'wrap',
    width: '65%',
    wordBreak: 'break-word',
  },
  headerKeyView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '30%',
  },
  headerTextSize: {
    fontSize: 12,
  },
  boldText: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 10,
  },
  bottomText: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    fontSize: 13,
    backgroundColor: '#e8e8e8',
    textAlign: 'right',
  },
  footer: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    color: '#343434',
    borderTop: '1px solid #CDCDCD',
    // fontSize: 8,
    marginTop: 10,
    backgroundColor: '#e8e8e8',
    padding: 6,
  },
  headerContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  imageView: {
    marginRight: 3,
  },
  emailView: {
    marginRight: 2,
    marginLeft: 3,
  },
  footerInvoice: {
    textAlign: 'right',
    fontSize: 10,
  },
  registrationContainer: {
    marginTop: 5,
    display: 'flex',
    flexDirection: 'row',
    gap: '70px',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  textCard: {
    textAlign: 'center',
    fontSize: '13px',
    fontWeight: 700,
    textDecoration: 'underline',
  },
  barcodeContent: {
    width: 180,
    height: 30,
  },
  photoContent: {
    width: '100%',
    alignSelf: 'center',
  },
  imageContent: {
    height: '10rem',
    width: '10rem',
  },
})
const Logo = () => (
  <View style={styles.medLogo} fixed>
    <Image src={medunitedLogo} />
  </View>
)

const HeaderData = ({ data }: any) => (
  <View style={styles.headerData} fixed>
    <View style={styles?.headerContent}>
      <View style={styles?.imageView}>
        <Image source={locationIcon} style={styles?.imageContent} />
      </View>
      <Text>Reg. Address:{data?.patientAddress?.address?.line?.[0]}</Text>
    </View>
    <View style={styles?.headerContainer}>
      <View style={styles?.headerContent}>
        <View style={styles?.imageView}>
          <Image source={mobileIcon} style={styles.logo} />
        </View>
        <Text>{data?.patientAddress?.telecom?.[0]?.value}</Text>
      </View>
      <View style={styles?.headerContent}>
        <View style={styles?.emailView}>
          <Image source={emailIcon} style={styles.logo} />
        </View>
        <Text>{data?.patientAddress?.telecom?.[1]?.value}</Text>
      </View>
    </View>
  </View>
)
const HeaderRow = ({
  label,
  value,
}: {
  label: string
  value: string | number
}) => {
  return (
    <View style={styles?.headerRow}>
      <View style={styles?.headerKeyView}>
        <Text style={styles?.boldText}>{label}</Text>
        <Text style={styles?.headerTextSize}>{': '}</Text>
      </View>
      <Text style={styles?.headerValue}>{value}</Text>
    </View>
  )
}
const InvoiceFooter = () => (
  <View fixed style={styles?.footer}>
    <View>
      <Text style={styles?.footerInvoice}>{crhealthcare}</Text>
    </View>
  </View>
)
const RegistrationCardPrint = ({ data }: { data: any }) => (
  <Document>
    <Page size='A5' orientation='landscape' style={styles.page}>
      <View fixed style={styles.container}>
        <View style={styles.headerTopLine} />
        <View style={styles.headerTopLineAfter} />
      </View>

      <Logo />
      <HeaderData data={data} />
      <View style={styles.headerLine} />
      <View style={styles?.registrationContainer}>
        <View>
          <Text style={styles?.textCard}>{registrationCard}</Text>
        </View>
        <View style={styles?.barcodeContent}>
          {data?.barCode ?? (
            <Image src={generateBarcodeImage(data?.barcode ?? '')} />
          )}
        </View>
      </View>
      <View style={styles.boxContainer}>
        <View style={styles.rowContainer}>
          <View style={styles.leftSection}>
            <HeaderRow label='Registration ID' value={data?.registrationNo} />
            <HeaderRow label='Patient Name' value={data?.patientName} />
            <HeaderRow label='Age' value={data?.age} />
            <HeaderRow label='Gender' value={data?.gender} />
            <HeaderRow label='Phone Number' value={data?.mobile} />
            <HeaderRow
              label='Registered On'
              value={
                data?.registeredOn
                  ? invoiceDateTimeFormat(new Date(data?.registeredOn))
                  : ''
              }
            />
            <HeaderRow label='Registered At' value={data?.registeredAt} />
          </View>
          {/* Right Section */}
          <View style={styles.rightSection}>
            {data?.photo && (
              <Image source={data?.photo} style={styles?.photoContent} />
            )}
          </View>
        </View>
      </View>
      <InvoiceFooter />
    </Page>
  </Document>
)

export default RegistrationCardPrint
