export const MainMenuConst = {
  radiologist: 'radiologist',
  proRithm: 'proRithm',
  labDevices: 'labDevices',
  radiologyDevices: 'radiologyDevices',
  pathologist: 'pathologist',
  billing: 'billing',
}
export const devicesOptions = [
  { label: 'ProRithm', value: 'proRithm' },
  { label: 'Lab Devices', value: 'labDevices' },
  { label: 'Radiology Devices', value: 'radiologyDevices' },
]
export const partnersOptions = [
  { label: 'Doctor', value: 'doctor' },
  { label: 'B2B Partners', value: 'referredOut' },
  { value: 'pathologist', label: 'Pathologist' },
  { value: 'radiologist', label: 'Radiologist' },
]
export const subdropdownitems = {
  radiology: 'radiology',
  bedManagementAdmin: 'bedManagementAdmin',
  devices: 'devices',
  partners: 'partners',
  doctor: 'doctor',
  referredOut: 'referredOut',
}
export const intent = {
  odooLogin: 'odooLogin',
  pharmacyLocation: 'pharmacyLocation',
}
export const keyConstants = {
  tab: 'Tab',
  enter: 'Enter',
  arrowUp: 'ArrowUp',
  arrowDown: 'ArrowDown',
}
