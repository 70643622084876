/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react'
import LabTests from './LabTests'
import { useLocation, useParams } from 'react-router-dom'
import { LabOrder, LabTest, OrderEvent, Panel } from './models'
import { startSxpProxy } from '../../utils/api'
import { LABS_PROJECT_ID, OPD_PROJECT_ID } from '../../utils/constants'
import PackageTests from './PackageTests'
import { toast } from 'react-toastify'
import ToastMessage from '../lms/components/ToastMessage'
import { toastOptions } from '../lms/models'
import { saveOrder } from './utils'
import KeycloakService from '../../utils/keycloakService'
import { LAB_ORDERS_WRITE } from '../../utils/roles'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  selectLocationById,
  selectLocations,
  selectSelectedLocationId,
} from '../location/locationSlice'
import { prepareOrderEventTests } from '../lms/utils'
import { readableDateTimeFormat } from '../../utils/dateUtils'
import Select from 'react-select'
import { Doctor } from '../scheduler/schedulerSlice'
import { useSamples } from '../lms/components/useSamples'
import { useOrganizations } from '../lms/components/useOrganizations'
import { fetchSlotsDoctorsAsync, selectSlotsDoctors } from '../slots/slotsSlice'
import { setQueryParam } from '../membership/membershipSlice'
import { Button, IconButton } from '@mui/material'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { getBedPatientDetails } from '../ipRegistration/IpdPatientRegisterSlice'
import { sendSocketData } from '../Notification/Socket'
import { NEW_IPD_DATA_UPDATE } from '../Notification/Constants'

interface LabTests {
  name: string
  description: string
  id: number
}

interface Test {
  name: string
  description: string
  id: number
  lab_tests: LabTests[]
  sampleId: number
  sampleName: string
}

interface Row {
  mode: { label: string; value: string } | null
  requestedBy: { label: string; value: string } | null
  sampleName: { label: string; value: string } | null
  testName: { label: string; value: string } | null
  panel: Test | null
  origin: { label: string; value: string } | null
}

const Orders = () => {
  const { id }: any = useParams()
  const [patientId, setPatientId] = useState('')
  const [order, setOrder] = useState<LabOrder>({})
  const [orders, setOrders] = useState<LabOrder[]>([])
  const [activeIndex, setActiveIndex] = useState(0)
  // const [mode, setMode] = useState<LabMode>('')
  const [origin, setOrigin] = useState('IPD')
  const locationId = useAppSelector(selectSelectedLocationId)
  const location = useAppSelector((state) =>
    selectLocationById(locationId, state)
  )
  const bedPatientDetails = useAppSelector(getBedPatientDetails)
  const locationNames = useAppSelector(selectLocations)
  const locationName =
    locationNames?.find(
      (location: any) => location?.resource?.id === locationId
    )?.resource?.name || ''

  const [requestedBy, setRequestedBy] = useState('')
  const [rows, setRows] = useState<Row[]>([])
  const [isRowAdded] = useState(false)
  const [testNameOptions, setTestNameOptions] = useState<
    { label: string; value: string; panels: any }[]
  >([])
  const [testNameOptionsAll, setTestNameOptionsAll] = useState<
    { label: string; value: string; panels: any }[]
  >([])

  const [visitStatus, SetVisitStatus] = useState('')

  const [newRow, setNewRow] = useState<Row>({
    mode: null,
    requestedBy: null,
    sampleName: null,
    testName: null,
    panel: null,
    origin: null,
  })
  const [ordersEvent, setOrdersEvent] = useState<OrderEvent[]>([])

  const dispatch = useAppDispatch()

  const handleModeChange = (selectedOption: any) => {
    // const selectedValue = selectedOption?.value || ''
    // setMode(selectedValue)
    setNewRow((prev) => ({ ...prev, mode: selectedOption }))
  }

  const handlePackageSave = (
    packageId: number,
    price: number,
    panels: Panel[],
    labTests: LabTest[]
  ) => {
    if (!requestedBy) {
      alert('Requested By is mandatory for external tests...')
      return
    }
    saveOrder(
      'visit',
      order,
      id ?? '',
      id ?? '',
      panels,
      labTests,
      location?.resource.name ?? '',
      packageId,
      price,
      requestedBy,
      origin
    ).then(() => {
      sendSocketData({
        messageType: NEW_IPD_DATA_UPDATE,
        patientID: bedPatientDetails?.[0]?.patientId,
        patientLocation: locationName,
        message: '',
      })
      const stateObj: Partial<OrderEvent> = {
        updated_by: KeycloakService.getUsername(),
        order_id: order.id,
        type: 'ORDER_MODIFIED',
        tests: prepareOrderEventTests(panels, labTests, 'ORDER_MODIFIED'),
      }
      startSxpProxy(LABS_PROJECT_ID, 'createOrderEvent', {
        event: stateObj,
      }).then(() => {
        toast(<ToastMessage message={'Lab Tests Saved'} />, {
          ...toastOptions,
          type: 'success',
        })
      })
    })
  }

  const handleSampleNameChange = (selectedOption: any) => {
    setNewRow((prev) => ({
      ...prev,
      sampleName: selectedOption,
      testName: null,
    }))
    if (!selectedOption?.value) return

    startSxpProxy(LABS_PROJECT_ID, 'getAllTestsBySampleIdApi', {
      sampleId: parseInt(selectedOption?.value),
    })
      .then((response) => {
        const testNames =
          response?.data?.sample[0]?.panels?.map((test: any) => ({
            label: test?.name,
            value: String(test?.id),
            panel: {
              ...test,
              sampleId: parseInt(selectedOption?.value),
              sampleName: selectedOption?.label,
            },
          })) ?? []
        setTestNameOptions(testNames)
      })
      .catch((error) => {
        console.error('Error fetching test names:', error)
        setTestNameOptions([])
      })
  }

  const executeFunction = () => {
    const state = {}
    startSxpProxy(LABS_PROJECT_ID, 'getAllPanelsApi', state)
      .then((data) => {
        const panelData: any = data?.data?.panel ?? []
        const tests = panelData?.map((test: any) => ({
          label: test?.name,
          value: String(test?.id),
          panel: {
            name: test?.name,
            description: test?.description,
            id: test?.id,
            lab_tests: test?.lab_tests,
            sampleId: parseInt(test?.sample?.value),
            sampleName: test?.sample?.name,
          },
        }))
        setTestNameOptions(tests)
        setTestNameOptionsAll(tests)
      })
      .catch((error) => {
        console.error('Error fetching test names:', error)
        setTestNameOptions([])
        setTestNameOptionsAll([])
      })
  }

  // const handleInputChange = (value: string) => {
  //   // setSearchText(value)
  //   // executeFunction(value)
  // }
  const handleTestNameChange = (selectedOption: any) => {
    setNewRow((prev) => ({
      ...prev,
      testName: { label: selectedOption?.label, value: selectedOption?.value },
      sampleName: {
        label: selectedOption?.panel?.sampleName,
        value: selectedOption?.panel?.sampleId,
      },
      panel: selectedOption?.panel,
    }))
  }

  const handleSave = async () => {
    if (!KeycloakService.hasRole([LAB_ORDERS_WRITE])) {
      return
    }

    if (
      rows[0]?.sampleName &&
      rows[0]?.testName &&
      rows[0]?.requestedBy &&
      rows[0]?.mode
    ) {
      const filteredPanels: any = rows?.map((row: Row) => {
        return row?.panel
      })
      const filteredLabTests: any = []

      const mode = 'visit'
      const locationName = location?.resource.name ?? ''
      saveOrder(
        mode,
        order,
        id,
        patientId || id,
        filteredPanels,
        filteredLabTests,
        locationName,
        0,
        0,
        requestedBy,
        origin
      )
        .then((data) => {
          sendSocketData({
            messageType: NEW_IPD_DATA_UPDATE,
            patientID: bedPatientDetails?.[0]?.patientId,
            patientLocation: locationName,
            message: '',
          })
          setOrder(data?.data?.insert_lab_orders_one)
          toast(
            <ToastMessage
              message={
                order?.id
                  ? 'Lab Order Saved Successfully'
                  : 'Lab Order Created Successfully'
              }
            />,
            {
              ...toastOptions,
              type: 'success',
            }
          )

          getAllLabOrders()

          let orderLab: LabOrder
          const stateObj: Partial<OrderEvent> = {
            updated_by: KeycloakService.getUsername(),
          }
          if (order.id) {
            orderLab = data.data?.update_lab_orders?.returning?.[0]
            stateObj.order_id = order.id
            stateObj.type = 'ORDER_MODIFIED'
            stateObj.tests = prepareOrderEventTests(
              filteredPanels,
              filteredLabTests,
              'ORDER_MODIFIED'
            )
          } else {
            orderLab = data.data?.insert_lab_orders_one
            stateObj.order_id = orderLab.id
            stateObj.type = 'ORDERED'
            stateObj.tests = prepareOrderEventTests(
              filteredPanels,
              filteredLabTests,
              'ORDERED'
            )
          }
          startSxpProxy(LABS_PROJECT_ID, 'createOrderEvent', {
            event: stateObj,
          }).then(() => {
            setOrder(order ?? {})
          })
        })
        .catch((err) => {
          console.error('Error saving order:', err)
        })
    } else {
      toast(
        <ToastMessage message={'Please add atleast one test to submit'} />,
        {
          ...toastOptions,
          type: 'error',
        }
      )
    }
  }

  const handleSaveRow = () => {
    if (
      newRow.sampleName &&
      newRow.testName &&
      newRow.requestedBy &&
      newRow.mode
    ) {
      setRows((prevRows) => {
        return [...prevRows, newRow]
      })
      setNewRow({
        mode: null,
        requestedBy: null,
        sampleName: null,
        testName: null,
        panel: null,
        origin: null,
      })
      setTestNameOptions(testNameOptionsAll)
      // setIsEdit(false)
      // setTestAdded((prevState) => !prevState)
    } else {
      alert('Please select both Sample Name and Test Name before adding.')
    }
  }

  const handleRequestedByChange = (selectedOption: any) => {
    const selectedValue = selectedOption?.value || ''
    setRequestedBy(selectedValue)
    setNewRow((prev) => ({ ...prev, requestedBy: selectedOption }))
  }

  const handleOriginChange = (selectedOption: any) => {
    const selectedValue = selectedOption?.value || ''
    setOrigin(selectedValue)
    setNewRow((prev) => ({ ...prev, origin: selectedOption }))
  }
  const samples = useSamples()
  const sampleOptions = samples?.map((sample) => ({
    label: sample.name,
    value: sample.id.toString(),
  }))
  const organizations = useOrganizations()
  const doctors = useAppSelector(selectSlotsDoctors)

  const handleDeleteRow = (index: number) => {
    setRows((prevRows: Row[]) => {
      const updatedRows = [...prevRows]
      updatedRows.splice(index, 1)
      return updatedRows
    })
  }

  useEffect(() => {
    dispatch(fetchSlotsDoctorsAsync())
  }, [dispatch])

  const VisitTableRef = useRef<HTMLDivElement | null>(null)
  const getAllLabOrders = () => {
    const intent = 'getLabOrdersApi'
    startSxpProxy(LABS_PROJECT_ID, intent, { visitId: id })
      .then((data: any) => {
        const orders: LabOrder[] = data.data?.lab_orders ?? []
        setOrders(orders)
        handleActiveOrderChage(orders)
        const state = { encounterId: id }
        startSxpProxy(OPD_PROJECT_ID, 'getVisitApi', state)
          .then((data) => {
            setPatientId(data.data?.subject?.reference?.split('/')?.[1])
            SetVisitStatus(data.data?.statusHistory[0]?.status)
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleActiveOrderChage = (orders: LabOrder[]) => {
    const activeOrder: LabOrder = orders[activeIndex] ?? {}
    const formattedRows: any = activeOrder?.panels?.map((panel: any) => {
      return {
        mode: {
          label: activeOrder?.package_id ? 'Package' : 'Test',
          value: activeOrder?.package_id ? 'package' : 'test',
        },
        requestedBy: {
          label: activeOrder?.requested_by,
          value: activeOrder?.requested_by,
        },
        origin: {
          label: activeOrder?.origin,
          value: activeOrder?.origin,
        },
        sampleName: { label: panel?.sampleName, value: panel?.sampleId },
        testName: { label: panel?.name, value: panel?.id },
        panel: panel || null,
      }
    })
    setRows(formattedRows || [])
    setOrdersEvent(activeOrder?.order_events ?? [])
    setOrder(activeOrder)

    if (
      activeOrder?.id &&
      activeOrder.panels &&
      activeOrder.panels.length >= 1
    ) {
      setNewRow({
        mode: {
          label: activeOrder?.package_id ? 'Package' : 'Test',
          value: activeOrder?.package_id ? 'package' : 'test',
        },
        requestedBy: null,

        sampleName: null,
        testName: null,
        panel: null,
        origin: null,
      })
    } else {
      setNewRow({
        mode: null,
        requestedBy: null,
        sampleName: null,
        testName: null,
        panel: null,
        origin: null,
      })
    }
  }

  const renderParameters = (parameters: LabTests[]) => {
    return (
      <div>
        {parameters?.map((item: LabTests) => {
          return <li key={item?.id}>{item?.name}</li>
        })}
      </div>
    )
  }

  useEffect(() => {
    const resizeHandler = () => {
      const container = VisitTableRef.current
      if (container) {
        const availableHeight =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 90}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
      dispatch(setQueryParam({ q: '', param: '' }))
    }
  }, [dispatch])

  useEffect(() => {
    if (id) {
      getAllLabOrders()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  useEffect(() => {
    executeFunction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    handleActiveOrderChage(orders)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex])
  const locations = useLocation()

  useEffect(() => {
    if (locations.pathname.startsWith('/visits/ipdVisit')) {
      setNewRow((prevState) => ({
        ...prevState,
        origin: { value: 'IPD', label: 'IPD' },
      }))
    }
  }, [locations.pathname, setNewRow])

  return (
    <div className='orders-container'>
      <div className='order-buttons'>
        {orders?.map((ord, idx) => (
          <button
            className={`order-btn${
              activeIndex === idx ? ' active-order-btn' : ''
            }`}
            onClick={() => setActiveIndex(idx)}
            key={ord?.id}
            title={`Order ${ord?.id} - ${readableDateTimeFormat(
              new Date(ord?.ordered_on ?? '')
            )}`}
          >
            Order {ord?.id}
          </button>
        ))}
        <button
          className={`order-btn${
            activeIndex === -1 ? ' active-order-btn' : ''
          }`}
          onClick={() => setActiveIndex(-1)}
        >
          New Order
        </button>
      </div>
      {/* needed */}
      {/* <Details summary='Laboratory Sample' initialOpen={true}>
        {(!order.status || order.status === 'ORDERED') && (
          <div style={{ padding: 6 }}>
            <div className='radio-container'>
              <input
                type='radio'
                value='package'
                checked={mode === 'package'}
                onChange={() => handleModeChange('package')}
                name='mode'
                id='modePackage'
              />
              <label className='text-small' htmlFor='modePackage'>
                Select Package
              </label>
              <input
                type='radio'
                value='test'
                checked={mode === 'test'}
                onChange={() => handleModeChange('test')}
                name='mode'
                id='modeTest'
              />
              <label className='text-small' htmlFor='modeTest'>
                Select Tests
              </label>
            </div>
          </div>
        )}
        {mode === 'package' && (
          <PackageTests
            id={order.package_id ?? 0}
            status={order.status ?? ''}
            onSave={handlePackageSave}
          />
        )}
        {mode === 'test' && (
          <LabTests
            id={id ?? ''}
            patientId={patientId}
            order={order}
            mode='visit'
            onSave={handleSave}
          />
        )}
      </Details> */}

      <div>
        <div style={{ textAlign: 'end', marginBottom: '3px' }}>
          <Button
            onClick={handleSave}
            disabled={
              visitStatus === 'finished' ||
              visitStatus === 'cancelled' ||
              !ordersEvent?.every((event) =>
                event?.tests?.every(
                  (test) =>
                    test.status === 'ORDER_MODIFIED' ||
                    test.status === 'ORDERED'
                )
              )
            }
            size='small'
            variant='contained'
          >
            Submit
          </Button>
        </div>
        <table className='data-table admin-table'>
          <thead style={{ position: 'sticky', top: '0px' }}>
            <tr>
              <th className='th-mode'>Mode</th>

              <th className='th-requested'>Requested By</th>
              {!locations.pathname.startsWith('/visits/ipdVisit') && (
                <th className='th-requested'>Origin</th>
              )}
              <th className='th-sample'>Sample Name</th>
              <th className='th-test'>Test Name</th>
              <th className='th-parameters'>Parameters</th>
              <th className='th-action'>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Select
                  value={newRow?.mode}
                  onChange={handleModeChange}
                  options={[
                    { value: '', label: 'Select Mode' },
                    { value: 'test', label: 'Test' },
                    { value: 'package', label: 'Package' },
                  ]}
                  isDisabled={isRowAdded}
                />
              </td>
              <td>
                <Select
                  placeholder='Select Requested By'
                  // className='requested-by'
                  isClearable
                  value={newRow?.requestedBy}
                  onChange={handleRequestedByChange}
                  options={[
                    // { value: '', label: 'Requested By' },
                    { value: 'Self', label: 'Self' },
                    ...organizations.map((org) => ({
                      value: org.name,
                      label: org.name,
                    })),
                    ...(doctors?.map((d: Doctor) => ({
                      value: `Dr. ${d.name}${
                        d.specialty ? ` [${d.specialty}]` : ''
                      }`.trim(),
                      label: `Dr. ${d.name}${
                        d.specialty ? ` [${d.specialty}]` : ''
                      }`.trim(),
                    })) || []),
                  ]}
                  isDisabled={isRowAdded}
                />
              </td>
              {!locations.pathname.startsWith('/visits/ipdVisit') && (
                <td>
                  <Select
                    className='origin-select'
                    isClearable
                    value={newRow?.origin}
                    onChange={handleOriginChange}
                    options={[
                      { value: 'OPD', label: 'OPD' },
                      { value: 'IPD', label: 'IPD' },
                    ]}
                  />
                </td>
              )}
              <td>
                {newRow?.mode?.value === 'package' && (
                  <PackageTests
                    id={order.package_id ?? 0}
                    status={order.status ?? ''}
                    onSave={handlePackageSave}
                  />
                )}
                {newRow?.mode?.value === 'test' && (
                  <Select
                    isClearable
                    options={sampleOptions}
                    value={newRow?.sampleName}
                    onChange={handleSampleNameChange}
                    placeholder='Select Sample Name'
                  />
                )}
              </td>
              <td>
                {newRow?.mode?.value === 'test' && (
                  <Select
                    isClearable
                    options={testNameOptions}
                    value={newRow?.testName}
                    // onInputChange={handleInputChange}
                    onChange={handleTestNameChange}
                    placeholder='Select Test Name'
                  />
                )}
              </td>
              <td>{renderParameters(newRow?.panel?.lab_tests || [])}</td>
              <td>
                <button
                  onClick={handleSaveRow}
                  disabled={
                    !ordersEvent?.every((event) =>
                      event?.tests?.every(
                        (test) =>
                          test.status === 'ORDER_MODIFIED' ||
                          test.status === 'ORDERED'
                      )
                    )
                  }
                >
                  Add
                </button>
              </td>
            </tr>
            {rows?.map((row, index: number) => (
              <tr key={index}>
                <td>{row?.mode?.label}</td>
                <td>{row?.requestedBy?.label}</td>
                {!window.location.pathname.startsWith('/visits/ipdVisit') && (
                  <td>{row?.origin?.label}</td>
                )}
                <td>{row?.sampleName?.label}</td>
                <td>{row?.testName?.label}</td>
                <td>{renderParameters(row?.panel?.lab_tests || [])}</td>
                <td>
                  <IconButton
                    size='small'
                    onClick={() => handleDeleteRow(index)}
                    disabled={
                      !ordersEvent?.every((event) =>
                        event?.tests?.every(
                          (test) =>
                            test.status === 'ORDER_MODIFIED' ||
                            test.status === 'ORDERED'
                        )
                      )
                    }
                  >
                    <DeleteOutlinedIcon fontSize='small' />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Orders
