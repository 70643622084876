export const getRemarks = (e: any) => {
  if (e?.length === 0) {
    return ''
  } else {
    return e?.[0]?.remarks ?? ''
  }
}
export const getDoctorName = (e: any) => {
  if (e?.length === 0) {
    return ''
  } else {
    return e?.[0]?.assigned_doctor ?? ''
  }
}
export const getReasonStatus = (reason: string) => {
  switch (reason) {
    case 'leave Against Medical Advice':
      return 'LAMA'
    case 'death':
      return 'Death'
    case 'referral':
      return 'Referral'
    case 'normal':
      return 'Normal'
    default:
      return ''
  }
}
