import axios from 'axios'
import KeycloakService from '../utils/keycloakService'
import {
  DMS_UPLOAD_AND_SEND_NOTIFICATION,
  GET_ALL_BEDS_PATIENTS_ENDPOINT,
  GET_ASSIGNED_PATIENTS_FOR_A_BED_ENDPOINT,
  SEND_WHATSAPP_MESSAGE,
} from '../utils/Endpoints'
import { toast } from 'react-toastify'
import ToastMessage from '../features/lms/components/ToastMessage'
import { intent, messages } from '../features/administration/constants'
import { startSxpProxy } from '../utils/api'
import {
  LABS_PROJECT_ID,
  MEDUNITED_AWGMENT_GATEWAY,
  downloadUrl,
} from '../utils/constants'
import { searchpatient } from '../features/bedManagement/endpoints/EndPoints'

export const getAllBedAndPatientsByStatusAndLocation = async (
  bedStatus: string,
  locationId: string,
  page: number,
  size: number,
  searchTerm: string
) => {
  const token = KeycloakService.getToken()

  try {
    const response: any = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}${GET_ALL_BEDS_PATIENTS_ENDPOINT}?bedStatus=${bedStatus}&locationId=${locationId}&searchTerm=${searchTerm}&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const { data, success, message } = response?.data || {}
    if (success) {
      return {
        success: true,
        data: data,
        message: message,
      }
    } else {
      return {
        success: false,
        data: null,
        message: message,
      }
    }
  } catch (error: any) {
    console.error('Error opening file: ', error)
    return {
      success: false,
      data: null,
      message: error?.message,
    }
  }
}

export const getAssignedPatientForBed = async (
  bedStatus: string | null,
  patientId: string | null,
  bedId: string | null
) => {
  const token = KeycloakService.getToken()
  try {
    const response: any = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}${GET_ASSIGNED_PATIENTS_FOR_A_BED_ENDPOINT}${bedStatus}&patientId=${patientId}&bedId=${bedId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const { data, success, message } = response?.data || {}
    if (success) {
      return {
        success: true,
        data: data,
        message: message,
      }
    } else {
      return {
        success: false,
        data: null,
        message: message,
      }
    }
  } catch (error: any) {
    console.error('Error fetching assigned patient for bed: ', error)
    return {
      success: false,
      error: error?.message,
    }
  }
}

export const sendWhatsappMessage = async (
  patientName: string,
  dmsId: string,
  patientLocation: string,
  patientPhone: string | undefined,
  handleBackdropClose: () => void
) => {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append(
    'Authorization',
    `Bearer ${sessionStorage.getItem('react-token')}`
  )

  const raw = JSON.stringify({
    name: patientName,
    docId: dmsId,
    location: patientLocation,
    preference: 'WHATSAPP',
    sendTo: '91' + patientPhone,
  })

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  }

  try {
    const response = await fetch(
      process.env.REACT_APP_MEDUNITED_AWGMENT_GATEWAY + SEND_WHATSAPP_MESSAGE,
      requestOptions
    )
    if (!response.ok) {
      throw new Error('Failed to send notification')
    }
    const data = await response?.json()
    const result = data?.success

    if (result) {
      toast(<ToastMessage message={messages?.notificationSent} />, {
        position: 'bottom-center',
        hideProgressBar: true,
        closeButton: false,
        theme: 'dark',
        autoClose: 2000,
      })
    } else {
      toast(<ToastMessage message={messages?.reportFailed} />, {
        position: 'bottom-center',
        hideProgressBar: true,
        closeButton: false,
        theme: 'dark',
        autoClose: 2000,
      })
    }
    handleBackdropClose()
  } catch (err) {
    toast(<ToastMessage message={messages?.reportFailed} />, {
      position: 'bottom-center',
      hideProgressBar: true,
      closeButton: false,
      theme: 'dark',
      autoClose: 2000,
    })
    handleBackdropClose()
    console.error(err)
  }
}

export const uploadAndSendNotification = async (
  labOrderId: string,
  preference: string,
  mobileNo: string,
  location: string,
  patientName: string,
  sourceType: string,
  reportBlob?: Blob
) => {
  const myHeaders = new Headers()
  myHeaders.append(
    'Authorization',
    `Bearer ${sessionStorage.getItem('react-token')}`
  )

  const formdata = new FormData()
  if (reportBlob) {
    formdata.append('file', reportBlob, 'patientReport.pdf')
  }
  formdata.append('stateId', labOrderId)
  formdata.append('preference', preference)
  formdata.append('sendTo', '91' + mobileNo)
  formdata.append('location', location)
  formdata.append('name', patientName)
  formdata.append('sourceType', sourceType)

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow',
  }

  try {
    const res = await fetch(
      process.env.REACT_APP_MEDUNITED_AWGMENT_GATEWAY +
        DMS_UPLOAD_AND_SEND_NOTIFICATION,
      requestOptions
    )
    if (!res?.ok) {
      throw new Error('Failed to send notification')
    }
    const result = await res?.json()
    return result
  } catch (err) {
    console.error(err)
    toast(<ToastMessage message={messages?.reportFailed} />, {
      position: 'bottom-center',
      hideProgressBar: true,
      closeButton: false,
      theme: 'dark',
      autoClose: 2000,
    })
  }
}

export const updateRetryFlagForLabOrder = async (
  orderId: string | number,
  updateFlag: boolean
) => {
  const state = {
    id: orderId,
    dms_upload_retry: updateFlag,
  }
  try {
    const result = await startSxpProxy(
      LABS_PROJECT_ID,
      intent?.updateLabOrderRetryById,
      state
    )
    return result
  } catch (err) {
    console.error(err)
  }
}

export async function shortenUrl(fileId: string) {
  const urlShortnerRequestOptions: RequestInit = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('dms-token')}`,
      'Content-Type': 'text/plain',
    },
    body: downloadUrl + fileId,
    redirect: 'follow',
  }
  const response = await fetch(
    `${MEDUNITED_AWGMENT_GATEWAY}${process.env.REACT_APP_SHORTEN_URL_ENDPOINT}`,
    urlShortnerRequestOptions
  )
  if (response?.ok) {
    const downloadUrl = await response?.text()
    if (downloadUrl) {
      return downloadUrl
    } else {
      return ''
    }
  } else {
    throw new Error('Server error. Failed to shorten URL')
  }
}
export const checkMrNumberExists = async (
  mrNumber: string,
  values: any
): Promise<boolean> => {
  const token = KeycloakService?.getToken()
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}${searchpatient}${mrNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    )
    if (data?.total === 0) {
      return true
    }
    if (data?.total === 1) {
      const patient = data?.entry?.[0]?.resource
      const identifierMatch = patient?.id === values?.parent?.fhrId
      return !!identifierMatch
    }
    return false
  } catch (error) {
    console?.error(error)
    return false
  }
}
