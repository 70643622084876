import { useState, useRef, useEffect, useMemo } from 'react'
import { ipdPatients } from '../constants'
import { startSxpProxy } from '../../../utils/api'
import { IPD_PROJECT_ID } from '../../../utils/constants'
import { emptyString } from '../../Radiology/Constants/constants'
import { mappedPatients } from '../../lms/utils'
import { allocateApi, allocation, tableHeadText } from '../modals'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import UploadDocs from '../../patients/dashboard/UploadDocs'
import uploadIcon from '../../../assets/images/download_uplaod.png'
import patientDashboardIcon from './../../../assets/images/patientDashboardIcon.png'
import { setQueryParam } from './IpdVisitSlice'
import { useAppDispatch } from '../../../app/hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import '../bms.scss'
import { setBedPatientDetails } from '../../ipRegistration/IpdPatientRegisterSlice'

const IpdVisitPatientTable = ({ registeredId }: any) => {
  const dispatch = useAppDispatch()
  const [bedPatientList, setBedpatient] = useState<ipdPatients[]>([])
  const [status] = useState<string>('ASSIGNED')
  const VisitTableRef = useRef<HTMLDivElement | null>(null)
  const [locationName] = useState<string>('')
  const location = useLocation()
  const locationParams = new URLSearchParams(location?.search)
  const [id, setId] = useState(
    registeredId ? registeredId : locationParams?.get('regId')
  )
  const navigate = useNavigate()
  useEffect(() => {
    setId(locationParams?.get('regId'))
    startSxpProxy(IPD_PROJECT_ID, allocateApi.getBedPatientAssignment, {}).then(
      async (data) => {
        const nodes = data.data?.bed_patient_assignment_map ?? []
        if (nodes.length) {
          const mapped = await mappedPatients(nodes)
          setBedpatient(mapped)
        }
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filtered = useMemo(() => {
    if (status === emptyString && locationName === emptyString) {
      return bedPatientList
    }
    return bedPatientList?.filter((item: ipdPatients) => {
      return (
        (status === emptyString || item?.bedStatus === status) &&
        (locationName === emptyString || item?.location === locationName)
      )
    })
  }, [bedPatientList, status, locationName])
  const dateObject = (dateString: string) => {
    const dateObject = new Date(dateString)
    return dateObject.toISOString().split('T')[0]
  }

  const [isOpenDocsPage, setIsOpenDocsPage] = useState(false)
  const [selectedPatientId, setSelectedPatientId] = useState<string>('')

  const handleDocClick = (patientId: string) => {
    setIsOpenDocsPage(true)
    setSelectedPatientId(patientId)
  }
  const handleCloseDialog = () => {
    setIsOpenDocsPage(false)
  }
  const differenceInDays = (d1: string, d2: any) => {
    const presentDate = new Date()
    const admittedDate = new Date(d1)
    const dischargedDate = new Date(d2)
    if (d2) {
      const DifferenceInTime = dischargedDate.getTime() - admittedDate.getTime()
      const DifferenceInDays = Math.round(DifferenceInTime / (1000 * 3600 * 24))
      return DifferenceInDays
    } else {
      const DifferenceInTime = presentDate.getTime() - admittedDate.getTime()
      const DifferenceInDays = Math.round(DifferenceInTime / (1000 * 3600 * 24))
      return DifferenceInDays
    }
  }
  const filteredPatient = filtered.filter(
    (e: any) => e.patient_registered_id === id
  )
  useEffect(() => {
    dispatch(setBedPatientDetails(filteredPatient))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredPatient])
  const handleVisitDashboard = (id: string) => {
    dispatch(setQueryParam({ patientId: id }))
    navigate('/bedIpd/ipVisit/dashboard')
  }
  return (
    <>
      <div className='sub-container'>
        <div className='bedTableHead' ref={VisitTableRef}>
          <table className='data-table table-fixed admin-table relative w100'>
            <thead className='sticky'>
              <tr>
                <th className='text-center w12'>{tableHeadText.uhid}</th>
                <th className='text-left w15'>{tableHeadText.name}</th>
                <th className='text-center w10'>{tableHeadText.mobileNo}</th>
                <th className='text-center w8'>{tableHeadText.gender}</th>
                <th className='text-center w10'>DOB</th>
                <th className='text-center w10'>{tableHeadText.bedNo}</th>
                <th className='text-center w13'>
                  {tableHeadText.operatingUnit}
                </th>
                <th className='text-center w10'>{tableHeadText.status}</th>
                <th className='text-center w10'>{tableHeadText.admission}</th>
                <th className='text-center w10'>{tableHeadText.discharge}</th>
                <th className='text-center w11'>Days of Adm</th>
                <th className='text-center w11'>
                  {tableHeadText.patientdocuments}
                </th>
                <th className='text-center w11'>{tableHeadText.view}</th>
              </tr>
            </thead>
            <tbody>
              {filteredPatient?.length > 0 ? (
                filteredPatient?.map((d: ipdPatients, i: number) => {
                  return (
                    <tr key={i}>
                      <td className='text-center'>
                        {d?.patientId ? d?.patientId : allocation?.hyphen}
                      </td>
                      <td className='text-left'>{d?.patientName}</td>
                      <td className='text-center'>
                        {d?.patientMobile ?? allocation?.hyphen}
                      </td>
                      <td className='text-center'>
                        {d?.patientGender ?? allocation?.hyphen}
                      </td>
                      <td className='text-center'>
                        {d?.patientAge ?? allocation?.hyphen}
                      </td>
                      <td className='text-center'>
                        {d?.bedNumber ?? emptyString}
                      </td>
                      <td className='text-center'>{d?.location}</td>
                      <td className='text-center'>
                        {d?.bedStatus === allocation?.assigned
                          ? allocation?.admittedList
                          : allocation?.dischargedList}
                      </td>
                      <td className='text-center'>
                        {dateObject(d?.dateCreated)}
                      </td>
                      <td className='text-center'>
                        {d?.dateOfDischarge
                          ? dateObject(d?.dateOfDischarge)
                          : '-'}
                      </td>
                      <td className='text-center'>
                        {differenceInDays(d?.dateCreated, d?.dateOfDischarge)}
                      </td>
                      <td>
                        <div
                          onClick={() =>
                            handleDocClick(d?.patient_registered_id)
                          }
                          className='uploadbtn'
                          style={{ textAlign: 'center' }}
                        >
                          <img
                            title='Upload Document`s'
                            src={uploadIcon}
                            alt='upload button'
                          />
                        </div>
                      </td>
                      <td>
                        <div style={{ display: 'flex', gap: '1rem' }}>
                          {/* <div>
                            <div className='uploadbtn'>
                              <img
                                title='Visits'
                                src={visiticon}
                                alt='Visits'
                                style={{ width: '15px' }}
                              />
                            </div>
                          </div> */}
                          <div>
                            <div className='uploadbtn'>
                              <img
                                title='patient Dashboard'
                                src={patientDashboardIcon}
                                alt='patient Dashboard'
                                onClick={() =>
                                  handleVisitDashboard(d?.patientId)
                                }
                                style={{ width: '18px' }}
                              />
                            </div>
                          </div>
                        </div>
                        <Dialog
                          open={isOpenDocsPage}
                          onClose={handleCloseDialog}
                          maxWidth='sm'
                        >
                          <IconButton
                            onClick={handleCloseDialog}
                            className='flex-end f12 ml-auto'
                          >
                            x
                          </IconButton>
                          <DialogContent className='margin2 padding20'>
                            <UploadDocs
                              patientId={selectedPatientId}
                              muhid={filteredPatient?.[0]?.id}
                            />{' '}
                          </DialogContent>
                        </Dialog>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td className='text-center' colSpan={10}>
                    No Patient Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
export default IpdVisitPatientTable
