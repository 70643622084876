import React, { useEffect, useState, useCallback, useRef } from 'react'
import { startSxpProxy } from '../../utils/api'
import {
  CHAT_PROJECT_ID,
  IPD_PROJECT_ID,
  LABS_PROJECT_ID,
  OPD_PROJECT_ID,
  REGISTRATION_PROJECT_ID,
} from '../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../app/hooks'
import { setQueryParam } from '../membership/membershipSlice'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import OPDVisitCreateDialog from './OPDVisitCreateDialog'
import { useReactToPrint } from 'react-to-print'
import { FetchDataError, NewOPDObservations, visitDashboard } from './constant'
import { getProvenanceData } from '../patients/utils'
import { Practitioner, Provenance } from 'fhir/r4'
import { ProvenanceData } from '../patients/patientsSlice'
import { intent as intents } from '../administration/constants'
import { intent } from '../Radiology/Constants/constants'
import { allocation } from '../bedManagement/modals'
import OPDSummaryTabs from './OpdSummaryTabs'
import TreatmentPlanForm from './treatmentPlan'
import OpdVisitDashboard from './OpdVisitsDashboard'

interface Props {
  id: string
}
interface Appointment {
  appointmentId: string
}

export type upComingAppointmentType = {
  data: string
  docName: string
  service: string
  timets: string
}

const OPDVisits: React.FC<Props> = ({ id }) => {
  const [labOrderList, setLabOrderList] = useState<any[]>([])
  const [patientData, setPatientData] = useState<any>({})
  const [upcomingAppointments, setUpcomingAppointments] = useState<
    upComingAppointmentType[]
  >([])
  // const [visitsList, setVisitList] = useState<appointmentType[]>([])
  // const [visitsDateList, setVisitsDateList] = useState<appointmentType[]>([])
  // const [status, setStatus] = useState<'loading' | 'loaded' | 'error'>(
  //   'loading'
  // )
  const [appointmentId, setAppointmentId] = useState<string>('')
  const [allIPDAppointments, setAllIPDAppointments] = useState<Appointment[]>(
    []
  )
  // const [selectedDate, setSelectedDate] = useState(-1)
  const [appointmentIds, setAppointmentIds] = useState<string[]>([])
  const [show, setShow] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [encounterDetails, setEncounterDetails] = useState<any>(null)
  // const [closeVisit, setCloseVisit] = useState('')
  const [visitStatusChanged, setVisitStatusChanged] = useState(false)
  const [allMedications, setAllMedications] = useState([])
  const [allIPDMedications, setAllIPDMedications] = useState([])
  const [followUpAdvice, setFollowUpAdvice] = useState('')
  const [followUpDate, setFollowUpDate] = useState('')
  const [referralRequired, setReferralRequired] = useState<'Yes' | 'No'>('No')
  const [openTreatment, setOpenTreatment] = useState(false)
  const [treatmentPlantext, setTreatmentPlantext] = useState('')
  const [homeAdvice, setHomeAdvice] = useState('')
  const [referraldocname, setReferralDocNAme] = useState('')
  const [refdepartment, setRefDepartment] = useState('')
  const [refreasonReferral, setRefReasonReferral] = useState('')
  const [drInfo, setDrInfo] = useState({})
  const [historyList, setHistoryList] = useState<any>([])
  const [examinationList, setExaminationList] = useState<any>([])
  const [vitalsList, setVitalsList] = useState<any>([])
  const [diagnosisList, setDiagnosisList] = useState<any>([])
  const [medications, setMedications] = useState<any>([])
  const [isDataFetched, setIsDataFetched] = useState(false)
  const OpCaseSheetRef = useRef(null)
  const eprescriptionRef = useRef(null)
  const [encounterIds, setEncounterIds] = useState<string>('')
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleRefresh = () => {
    setRefresh(true)
  }
  const fetchAllAppointments = async (patientId: string) => {
    try {
      const intent = 'allAppointments'
      const state = {
        id: patientId,
      }
      const resp = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
      setUpcomingAppointments(resp.data)
      const ids = resp.data.map(
        (appointment: Appointment) => appointment.appointmentId
      )
      setAppointmentIds(ids)
    } catch (e) {
      console.error('Error fetching upcoming appointments:', e)
    }
  }
  // encounter call
  const fetchEncounterForAppointments = async (appointmentIds: string) => {
    try {
      const intent = 'getEncounterForAppointments'
      const state = { appointmentIds }
      const resp = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
      setEncounterDetails(resp?.data)
      const id = resp?.data?.map((app: any) => app.encounterId)?.join(',')
      setEncounterIds(id)
    } catch (e) {
      console.error('Error fetching encounter details:', e)
    }
  }
  useEffect(() => {
    if (appointmentIds?.length > 0) {
      const appointmentIdsString = appointmentIds?.join(',')
      fetchEncounterForAppointments(appointmentIdsString)
        .then((resp) => console.log('Encounter details response:', resp))
        .catch((error) =>
          console.error('Error fetching encounter details:', error)
        )
    }
  }, [appointmentIds, visitStatusChanged])

  const fetchProvencesOfVisits = useCallback(async () => {
    if (encounterIds) {
      startSxpProxy(REGISTRATION_PROJECT_ID, intents?.fetchProvenance, {
        patientIds: encounterIds,
      })
        ?.then((data) => {
          const entries =
            data?.data?.entry?.map(
              (dde: { resource: Provenance | Practitioner }) => dde?.resource
            ) ?? []

          const obj = getProvenanceData(entries)
          setProvenances(obj)
        })
        ?.catch((err) => console.error(err))
    }
  }, [encounterIds])

  useEffect(() => {
    fetchProvencesOfVisits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encounterIds])

  const closeEncounter = async (encounterId: string) => {
    try {
      const intent = 'closeEncounterForAppointment'
      const state = { encounterId }
      await startSxpProxy(CHAT_PROJECT_ID, intent, state)
      // setCloseVisit(resp?.data)
      setVisitStatusChanged((prev) => !prev)
    } catch (e) {
      console.error('Error closing encounter:', e)
    }
  }
  useEffect(() => {
    if (encounterDetails?.length > 0) {
      const encounterIds = encounterDetails.map((encounter: any) =>
        parseInt(encounter.encounterId, 10)
      )
      getallVisitmedication(encounterIds)
    }
  }, [encounterDetails])

  const getallVisitmedication = async (visitIds: number[]) => {
    try {
      const intent = 'getallVisitmedication'
      const state = { visitIds: visitIds }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      const medications = resp?.data?.medications
      setAllMedications(medications)
    } catch (e) {
      console.error('Error fetching medication details:', e)
      setAllMedications([])
    }
  }
  const fetchData = useCallback(async (patientId: string) => {
    try {
      const patientIntent = intent?.getPatientApi
      const state = { id: patientId }
      const resp = await startSxpProxy(OPD_PROJECT_ID, patientIntent, state)
      setPatientData(resp?.data)
      await fetchAllAppointments(patientId)
      await fetchLabOrders(patientId)
    } catch (e) {
      console.error(FetchDataError, e)
      // setStatus('error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    fetchData(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const showvisit = (patientId: string, encounterId: string) => {
    const basePath = `/visits/${encounterId}/history`
    navigate(basePath, {
      state: {
        id: patientId,
        show: true,
      },
    })
  }
  const getDrDetails = (appointmentId: string, dataList: any) => {
    const drInfoData = {
      drName: '',
      department: '',
      date: '',
      drRegistrationNo: '',
      designation: '',
      signature: '',
    }
    const findByReference = (reference: string) =>
      dataList?.find((item: any) => item?.fullUrl?.includes(reference))

    const appointment = findByReference(appointmentId)
    if (!appointment) {
      setDrInfo(drInfoData)
      return
    }
    const slotId = appointment?.resource?.slot?.[0]?.reference
    const slot = slotId && findByReference(slotId)
    if (!slot) {
      setDrInfo(drInfoData)
      return
    }
    const scheduleId = slot?.resource?.schedule?.reference
    const schedule = scheduleId && findByReference(scheduleId)
    if (!schedule) {
      setDrInfo(drInfoData)
      return
    }
    drInfoData.date = slot?.resource?.start ?? ''

    const practitionerReference = schedule?.resource?.actor?.find(
      (actor: any) => actor?.reference?.includes('Practitioner')
    )?.reference

    const practitioner =
      practitionerReference && findByReference(practitionerReference)
    if (practitioner) {
      drInfoData.drName = practitioner?.resource?.name ?? ''
      drInfoData.designation =
        practitioner?.resource?.qualification?.[0]?.code?.text ?? ''
      drInfoData.drRegistrationNo =
        practitioner?.resource?.qualification?.[0]?.identifier?.[0]?.value ?? ''
      drInfoData.signature = practitioner?.resource?.photo?.[1]?.url ?? ''
    }

    drInfoData.department =
      appointment?.resource?.specialty?.[0]?.coding?.[0]?.display ?? ''
    setDrInfo(drInfoData)
  }

  const VisitTableRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const fetchAllAppointments = async (patientRegisteredId: string) => {
      try {
        const intent = 'getAllSubEncounters'
        const state = { patientId: patientRegisteredId }
        const resp = await startSxpProxy(IPD_PROJECT_ID, intent, state)
        const data = resp?.data
        if (data && data?.entry && Array.isArray(data.entry)) {
          const appointmentIds = data.entry
            .filter(
              (entry: any) => entry?.resource?.resourceType === 'Encounter'
            )
            .map((entry: any) => entry?.resource?.id)

          setAllIPDAppointments(appointmentIds)
        } else {
          console.error('Unexpected data format:', data)
          setAllIPDAppointments([])
        }
      } catch (e) {
        console.error('Error fetching upcoming appointments:', e)
        setAllIPDAppointments([])
      }
    }
    if (id) {
      fetchAllAppointments(id)
    }
  }, [id])
  const GetAllOPDObservations = async (resourceId: any) => {
    try {
      const intent = NewOPDObservations
      const state = {
        encounterId: resourceId,
      }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      if (resp?.data) {
        setVitalsList(resp?.data?.vitals)
        setHistoryList(resp?.data?.history)
        setDiagnosisList(resp?.data?.diagnosis)
        setExaminationList(resp?.data?.examination)
        setMedications(resp?.data?.medications)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handlePrint = useReactToPrint({
    content: () => OpCaseSheetRef.current,
    onAfterPrint: () => {
      if (!isDataFetched) {
        setIsDataFetched(true)
      }
    },
  })

  const handleOpCaseSheetPrint = () => {
    setIsDataFetched(false)
    handlePrint()
  }

  const fetchPatientData = async (id: string, appointmentId: any) => {
    const intentData = intent?.getPatientVisits
    const patientIntent = intent?.getPatientApi
    const state = { id }

    try {
      const res = await startSxpProxy(OPD_PROJECT_ID, intentData, state)
      if (res?.data?.entry?.length > 0) {
        if (res?.data?.entry?.[0] && res?.data?.entry?.[0]?.resource) {
          const resourceId = res?.data?.entry?.filter(
            (e: any) => e?.resource?.resourceType === visitDashboard?.encounter
          )
          if (resourceId?.length > 0) {
            const encounter = resourceId?.find(
              (e: any) =>
                e?.resource?.appointment?.[0]?.reference?.split('/')?.[1] ===
                appointmentId
            )
            if (encounter) {
              await GetAllOPDObservations(encounter?.resource?.id)
              await getDrDetails(`Appointment/${appointmentId}`, res.data.entry)
            }
          }
        }
      }
      const res1 = await startSxpProxy(OPD_PROJECT_ID, patientIntent, state)
      setPatientData(res1.data)
    } catch (e) {
      console.log('Error:', e)
    }
  }

  const handleEPrescriptionPrint = useReactToPrint({
    content: () => eprescriptionRef.current,
    // pageStyle: opCaseSheetStyle,
  })

  const getallIPDVisitmedication = async () => {
    try {
      const intent = 'getallVisitmedication'
      const state = { visitIds: allIPDAppointments }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      const medications = resp?.data?.medications
      setAllIPDMedications(medications)
    } catch (e) {
      console.error('Error fetching medication details:', e)
      setAllIPDMedications([])
    }
  }
  useEffect(() => {
    // if (allIPDAppointments?.length > 0) {
    //   const encounterIds = allIPDAppointments?.map((visitId: any) =>
    //     parseInt(visitId?.id, 10)
    //   )
    getallIPDVisitmedication()
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allIPDAppointments])

  const allCombinedMedications = [
    ...allMedications.map((medication: any) => ({
      ...medication,
      source: 'OPD',
    })),
    ...allIPDMedications.map((medication: any) => ({
      ...medication,
      source: 'IPD',
    })),
  ]
  const fetchLabOrders = async (patientId: string) => {
    try {
      const intent = 'getLabOrdersByPatientIds'
      const state = {
        patientId,
      }
      const resp = await startSxpProxy(LABS_PROJECT_ID, intent, state)
      if (resp?.data?.lab_orders) {
        const orders = resp?.data?.lab_orders
        setLabOrderList(orders)
      } else {
        setLabOrderList([])
      }
    } catch (e) {
      console.error('Error fetching lab orders:', e)
    }
  }

  useEffect(() => {
    const resizeHandler = () => {
      const container = VisitTableRef.current
      if (container) {
        const availableHeight =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 200}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
      dispatch(setQueryParam({ q: '', param: '' }))
    }
  }, [dispatch])

  const handleCreateVisitClick = (appointmentId: any) => {
    setShow(true)
    setAppointmentId(appointmentId)
  }
  // futher use
  // const capitalizeFirstLetter = (string: any) => {
  //   if (!string) return ''
  //   return string?.charAt(0)?.toUpperCase() + string?.slice(1)
  // }

  const handleStatusChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    encounterId: string
  ) => {
    if (e.target.value === 'finished') {
      closeEncounter(encounterId)
    }
  }
  const getTreatmentPlan = async (encounterId: string) => {
    try {
      const intent = 'getTreatmentPlan'
      const state = { encounterId }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      const treatmentPlan = resp?.data?.entry?.[0]?.resource?.component ?? []
      setOpenTreatment(true)

      setTreatmentPlantext(
        treatmentPlan.find(
          (component: any) =>
            component?.code?.coding?.[0]?.code === 'treatmentPlan'
        )?.valueString ?? '-'
      )
      setFollowUpAdvice(
        treatmentPlan.find(
          (component: any) =>
            component?.code?.coding?.[0]?.code === 'followUpAdvice'
        )?.valueString ?? '-'
      )

      setFollowUpDate(
        treatmentPlan.find(
          (component: any) =>
            component?.code?.coding?.[0]?.code === 'followUpDate'
        )?.valueString ?? '-'
      )

      setHomeAdvice(
        treatmentPlan.find(
          (component: any) =>
            component?.code?.coding?.[0]?.code === 'homeAdvice'
        )?.valueString ?? '-'
      )

      setReferralRequired(
        treatmentPlan
          .find(
            (component: any) =>
              component?.code?.coding?.[0]?.code === 'referralRequired'
          )
          ?.valueString?.toLowerCase() === 'yes'
          ? 'Yes'
          : 'No'
      )
      setReferralDocNAme(
        treatmentPlan.find(
          (component: any) =>
            component?.code?.coding?.[0]?.code === 'nameOfDoctor'
        )?.valueString ?? '-'
      )
      setRefDepartment(
        treatmentPlan.find(
          (component: any) =>
            component?.code?.coding?.[0]?.code === 'department'
        )?.valueString ?? '-'
      )
      setRefReasonReferral(
        treatmentPlan.find(
          (component: any) =>
            component?.code?.coding?.[0]?.code === 'reasonReferral'
        )?.valueString ?? '-'
      )
    } catch (error) {
      console.error('Error fetching treatment plan details:', error)
    }
  }

  const handleViewClick = async (encounterId: string) => {
    try {
      await getTreatmentPlan(encounterId)
    } catch (error) {
      console.error('Error handling view click:', error)
    }
  }
  const handleCloseTreatment = () => {
    setOpenTreatment(false)
  }
  useEffect(() => {
    if (refresh === true) {
      fetchData(id)
    }
  }, [refresh, id, fetchData])

  const patientMUHId = patientData?.identifier?.[2]?.value
  return (
    <>
      {show && (
        <Dialog
          open={show}
          onClose={() => setShow(false)}
          maxWidth='sm'
          fullWidth={true}
          style={{
            alignItems: 'center',
          }}
        >
          <div
            onClick={() => setShow(false)}
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginRight: '2rem',
            }}
          >
            X
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <DialogTitle style={{ alignItems: 'center' }}>
              Visit&nbsp;Creation ({patientMUHId})
            </DialogTitle>
            <OPDVisitCreateDialog
              patientId={id}
              appointmentId={appointmentId}
              onRefresh={handleRefresh}
              onClose={() => setShow(false)}
            />
          </div>
        </Dialog>
      )}

      <div className='op-visit-dashboard'>
        <OpdVisitDashboard
          encounterDetails={encounterDetails}
          upcomingAppointments={upcomingAppointments}
          drInfo={drInfo}
          medications={allCombinedMedications}
          patientData={patientData}
          diagnosisList={diagnosisList}
          labOrderList={labOrderList} // Empty initially
          onClickVisit={handleCreateVisitClick}
          onShowVisit={showvisit}
          onStatusChange={handleStatusChange}
          onViewClick={handleViewClick}
          provenances={provenances}
          historyList={historyList}
          examinationList={examinationList}
          vitalsList={vitalsList}
          onFetchPatientData={fetchPatientData}
          handleOpCaseSheet={handleOpCaseSheetPrint}
          handleEPrescriptionPrint={handleEPrescriptionPrint}
          OpCaseSheetRef={OpCaseSheetRef}
          eprescriptionRef={eprescriptionRef}
        />
      </div>

      <div className='opd-summary-tabs-container'>
        <div className='ipd-tabs-heading-conatiner'>
          {allocation?.ipVisitSummary}
        </div>
        <div className='full-height-conatiner'>
          <OPDSummaryTabs
            medications={medications}
            allMedications={allMedications}
            patientRegisteredId={id}
            patientData={patientData}
          />
        </div>
      </div>
      <Dialog
        open={openTreatment}
        aria-labelledby='dialog-title'
        className='treatment-plan-dialog'
      >
        <IconButton
          aria-label='close'
          onClick={handleCloseTreatment}
          className='treatment-plan-icon '
        >
          X
        </IconButton>{' '}
        <DialogTitle id='dialog-title'>Treatment Plan Details</DialogTitle>
        <DialogContent>
          <TreatmentPlanForm
            treatmentPlan={treatmentPlantext}
            followUpAdvice={followUpAdvice}
            followUpDate={followUpDate}
            homeAdvice={homeAdvice}
            referralRequired={referralRequired}
            nameOfDoctor={referraldocname}
            department={refdepartment}
            reasonReferral={refreasonReferral}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default OPDVisits
