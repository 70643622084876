import { useEffect, useState } from 'react'
import { IPD_PROJECT_ID, OPD_PROJECT_ID } from '../../../../utils/constants'
import { useParams } from 'react-router-dom'
import { startSxpProxy } from '../../../../utils/api'
import './medications.scss'
import RenderOnRole from '../../../../components/RenderOnRole'
import { MEDICATIONS_READ, MEDICATIONS_WRITE } from '../../../../utils/roles'
import { Medication } from '../../models'
import MedicationForm from './MedicationForm'
import { toast } from 'react-toastify'
import { NEW_IPD_DATA_UPDATE } from '../../../Notification/Constants'
import { sendSocketData } from '../../../Notification/Socket'
import { useAppSelector } from '../../../../app/hooks'
import { getBedPatientDetails } from '../../../ipRegistration/IpdPatientRegisterSlice'
import {
  selectLocations,
  selectSelectedLocationId,
} from '../../../location/locationSlice'
const Medications = () => {
  const { id } = useParams()
  const [reload, setReload] = useState(false)
  const [medications, setMedications] = useState<Medication[]>([])
  const [journeyState] = useState({
    sos: 'no',
    visitId: id,
    untilNextVisit: 'no',
  })
  const [drugName] = useState('')
  const bedPatientDetails = useAppSelector(getBedPatientDetails)
  const locationId = useAppSelector(selectSelectedLocationId)
  const locationNames = useAppSelector(selectLocations)
  const locationName =
    locationNames?.find(
      (location: any) => location?.resource?.id === locationId
    )?.resource?.name || ''

  useEffect(() => {
    startSxpProxy(OPD_PROJECT_ID, 'getMedicationsVisitIdApi', { visitId: id })
      .then((data) => {
        setMedications(data?.data?.medications ?? [])
      })
      .catch((err) => {
        console.log(err)
        setMedications([])
      })
    // startSxpProxy(OPD_PROJECT_ID, 'getDoctorByVisit', { id: id })
    //   .then((resp) =>
    //     setLocation(
    //       resp?.data?.location ?? localStorage.getItem('locationName')
    //     )
    //   )
    //   .catch((err) => console.log(err))
  }, [id, journeyState, reload])

  const handleItemDelete = (medId: number) => {
    startSxpProxy(IPD_PROJECT_ID, 'deleteMedicationById', {
      medicationId: medId,
    })
      .then(() => {
        sendSocketData({
          messageType: NEW_IPD_DATA_UPDATE,
          patientID: bedPatientDetails?.[0]?.patientId,
          patientLocation: locationName,
          message: '',
        })
        setMedications(medications?.filter((med) => med?.id !== medId))
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
      })
  }

  return (
    <RenderOnRole
      roles={[MEDICATIONS_READ, MEDICATIONS_WRITE]}
      element={
        <div className='medication-container'>
          <div className='medication-search-form'>
            <MedicationForm
              drugName={drugName}
              medications={medications}
              id={id}
              onItemDelete={handleItemDelete}
              setReload={setReload}
            />
          </div>
        </div>
      }
    />
  )
}

export default Medications
