import * as React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { startSxpProxy } from '../../utils/api'
import { RADIOLOGY_PROJECT_ID } from '../../utils/constants'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import { formatReadableDate } from '../bedManagement/IpdVisit/IpdSummary/Constants'
import {
  RadiologyOrder,
  RadiologyOrderProps,
} from '../bedManagement/IpdVisit/IpdSummary/Interfaces'
import { radiologyTestStatus } from '../Radiology/Constants/constants'
import { formattedPatient } from './Utils'
import { useRadiologyDepartments } from '../Radiology/components/RadiologyModel/useRadiologyDepartments'
import { transformRadiologyResults } from '../Radiology/Utils/utils'
import { useInvoicePDFGenerator } from '../lms/components/dashboard/InvoicePdfLib'
import { opdIntents, radiologyText } from './constant'

function Row({
  row,
  patient,
  index,
  radiologyDepartments,
}: RadiologyOrderProps) {
  const [open, setOpen] = React.useState(false)
  const { generatePDF } = useInvoicePDFGenerator()
  const handleInvoicePrint = async (row: any, patient: any) => {
    const obj: any = {
      type: radiologyText,
      id: patient?.id ?? '',
      name: patient?.name || '',
      age: patient?.age || '',
      gender: patient?.gender || '',
      mobile: patient?.mobile ?? '',
      lrNumber: row?.lr_number ?? '',
      radiologyTests: row?.radiology_order_tests,
      radiologyResults: transformRadiologyResults(
        row?.radiology_test_results,
        radiologyDepartments
      ),
      orderType: row?.origin ?? '',
      orderedOn: row?.ordered_on ?? '',
      invoiceWithHeaderValue: false,
      requestedBy: row?.requested_by ?? row?.ordered_by_name ?? '',
      validatedOn: row?.validation_completed_on ?? '',
      fileId: row?.document_id,
    }
    const pdfBlob = await generatePDF(obj)
    const fileUrl = URL.createObjectURL(pdfBlob)
    const iframe = document.createElement('iframe')
    iframe.src = fileUrl
    iframe.width = '100%'
    iframe.height = '100%'
    iframe.style.border = 'none'
    const newWindow = window.open('', '_blank') as Window
    newWindow.document.body.style.margin = '0'
    newWindow.document.body.appendChild(iframe)
    newWindow.document.title = 'Test Results'
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align='center'>{index + 1}</TableCell>
        <TableCell component='th' scope='row'>
          {row?.lr_number}
        </TableCell>
        <TableCell align='left'>{row?.requested_by}</TableCell>
        <TableCell align='left'>{row?.status}</TableCell>
        <TableCell align='left'>
          <IconButton
            onClick={() => handleInvoicePrint(row, patient)}
            disabled={
              row?.status != radiologyTestStatus?.completed ? true : false
            }
          >
            <ArticleOutlinedIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell className='table-lab-visit-ipd-order' colSpan={7}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box m={1}>
                <Typography
                  variant='h6'
                  gutterBottom
                  component='div'
                  fontSize={'14px'}
                  fontWeight={600}
                >
                  Tests
                </Typography>
                <Table
                  size='small'
                  aria-label='purchases'
                  className='opd-lab-table'
                >
                  <TableHead className='table-head-lab-visit'>
                    <TableRow>
                      <TableCell className='table-head-th-lab-visit'>
                        Test
                      </TableCell>
                      <TableCell
                        className='table-head-th-lab-visit'
                        align='left'
                      >
                        Doctor
                      </TableCell>
                      <TableCell
                        className='table-head-th-lab-visit'
                        align='left'
                      >
                        Date & Time
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.radiology_order_tests?.map((panel: any) => (
                      <TableRow key={panel?.name}>
                        <TableCell component='th' scope='row'>
                          {panel?.test_name}
                        </TableCell>
                        <TableCell align='left'>{row?.requested_by}</TableCell>
                        <TableCell align='left'>
                          {formatReadableDate(row?.ordered_on)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

interface LabProps {
  patientRegisteredId: string
  patientData: any
}

export default function OpdRadiologyOrderSummary(props: LabProps) {
  const { patientRegisteredId, patientData } = props
  const [radiologyOrders, setRadiologyOrders] = React.useState<
    RadiologyOrder[]
  >([])
  const radiologyDepartments = useRadiologyDepartments()
  const fetchAllRadiologyOrders = async (patientRegisteredId: string) => {
    try {
      const intent = opdIntents?.getApprovedRadiologyResultInOpd
      const state = {
        patientId: patientRegisteredId,
      }
      const resp = await startSxpProxy(RADIOLOGY_PROJECT_ID, intent, state)
      if (resp?.data?.radiology_orders) {
        const orders = resp?.data?.radiology_orders
        setRadiologyOrders(orders)
      } else {
        setRadiologyOrders([])
      }
    } catch (e) {
      console.error('Error fetching Radiology orders:', e)
    }
  }

  React.useEffect(() => {
    fetchAllRadiologyOrders(patientRegisteredId)
  }, [patientRegisteredId])

  return (
    <Box mt={2} mb={2}>
      <TableContainer component={Paper}>
        <Table aria-label='collapsible table' size='small' stickyHeader>
          <TableHead className='table-head-lab-visit'>
            <TableRow>
              <TableCell className='table-head-th-lab-opd-visit' />
              <TableCell className='table-head-th-lab-opd-visit' align='center'>
                Sl No
              </TableCell>
              <TableCell className='table-head-th-lab-opd-visit'>
                Radiology Id
              </TableCell>
              <TableCell className='table-head-th-lab-opd-visit' align='left'>
                Requested By
              </TableCell>
              <TableCell className='table-head-th-lab-opd-visit' align='left'>
                Status
              </TableCell>
              <TableCell className='table-head-th-lab-opd-visit' align='left'>
                View
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {radiologyOrders?.map((row: any, index: number) => (
              <Row
                key={row?.lr_number}
                row={row}
                patient={formattedPatient(patientData)}
                index={index}
                radiologyDepartments={radiologyDepartments}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
