/**
 * Module for generating PDF invoices using the `pdf-lib` library.
 * Provides utilities to initialize, customize, and populate PDF documents
 * with patient and medical data.
 *
 * Dependencies: pdf-lib, react, custom utilities (generateBarcodeImage, getFormattedNumber, etc.)
 */

/* eslint-disable quotes */
import { useRef } from 'react'
import { PDFDocument, rgb, StandardFonts, PDFFont, PDFPage } from 'pdf-lib'
import parse from 'html-react-parser'
import {
  getFormattedNumber,
  getInfoData,
  isAbnormal,
  isOutsideRange,
} from '../../utils'
import QRCode from 'qrcode'

import { InvoiceData } from '../../models'
import medunitedLogo from '../../../../assets/images/medunited.png'
import locationIcon from '../../../../assets/icons/location.png'
import emailIcon from '../../../../assets/icons/email.png'
import mobileIcon from '../../../../assets/icons/mobile.png'
import { downloadUrl } from '../../../../utils/constants'
import { shortenUrl } from '../../../../services/IPDService'

const PDF_CONSTANTS = {
  WARNING_MESSAGES: {
    NULL_TEXT_ATTEMPT:
      'Attempted to draw undefined/null text, skipping this operation',
  },
  ERROR_MESSAGES: {
    INITIALIZING_PDF: 'Error initializing PDF:',
    FAILED_TO_INITIALIZE_PDF: 'Failed to initialize PDF',

    CHECK_AND_ADD_NEW_PAGE: 'Error in checkAndAddNewPage:',
    FAILED_TO_CHECK_AND_ADD_NEW_PAGE: 'Failed to check and add new page',

    ADDING_NEW_PAGE: 'Error adding new page:',
    FAILED_TO_ADD_NEW_PAGE: 'Failed to add new page',

    DRAWING_SINGLE_LINE: 'Error drawing single line:',
    DRAWING_TEXT: 'Error drawing text:',

    IMAGE_LOADING: 'Image loading error:',
    IMAGE_FORMAT_NOT_SUPPORTED: 'Image format not supported',

    DRAW_OBSERVATION_VIEW: 'Error in drawObservationView:',
    DRAW_PANEL: 'Error in drawPanel:',

    PROCESSING_SIGNATURE: 'Error processing signature:',
    DRAWING_HEADER: 'Error drawing header:',
    FAILED_TO_DRAW_HEADER: 'Failed to draw header',

    GENERATING_PDF: 'Error generating PDF:',
    FAILED_TO_GENERATE_PDF: 'Failed to generate PDF',

    CONTENT_TOO_LARGE: 'Content too large for single page',

    PANELDATA_ARRAY: 'panelDataArray must be an array',

    FAILED_TO_CREATE_PDF: 'Failed to create PDF document.',

    URL_SHORTNING_ERROR: 'Error shortening URL',
  },
  FOOTER_TEXTS: {
    DISCLAIMER: '• This Is An Electronically Authenticated Report.',
    ADVISORY1:
      '• Please Correlate With Clinical Findings, Consult a Doctor For Discussion & Further Medication.',
    ADVISORY2:
      '• Test results are not valid for medico legal purposes and as per the specimen received.',
    END_OF_REPORT: '~~~ End of Report ~~~',
  },

  HEADERS: {
    DEPARTMENT_PREFIX: 'Department of ',
    TEST_NAME: 'Test Name',
    SAMPLE_TYPE: 'Sample Type',
    INTERPRETATION: 'Interpretation:',
    INTERPRETATION_IMAGE: 'Interpretation Image:',
    DIGITAL_SIGNATURE: 'Digital Signature',
    PAGE: 'Page',
    REG_ADDRESS: 'Reg. Address',
  },

  TABLE_HEADERS: ['Parameter Name', 'Value', 'Unit', 'Biological-Ref-Range'],
  TABLE_METHOD: {
    METHOD: 'Method',
  },

  OBSERVATION: {
    NATURE_OF_SPECIMEN: 'Nature of Specimen',
    SUMMARY: 'Summary',
    OBSERVATION: 'Observation',
  },

  PATHOLOGY: {
    TECHNOLOGIST: 'Technologist',
    VERIFIED_BY: 'Verified By:',
    APPROVED_BY: 'Approved By',
    DOCTOR: 'Dr.',
    NOTE: 'Note: Test processed at',
  },

  PLACEHOLDERS: {
    DASH: '-',
  },
  SCANNER_LABEL: 'Scan to view report',
  RADIOLOGY: 'radiology',
  RADIOLOGY_TEXT: 'Radiology',
}

const MARGIN_LEFT = 30
const MARGIN_TOP = 50
const LINE_HEIGHT = 14
const PAGE_WIDTH = 595
const PAGE_HEIGHT = 842
let HEADER_HEIGHT = 155
let FOOTER_HEIGHT = 45

/**
 * Custom hook to manage PDF generation for invoices.
 * Initializes fonts, manages pages, and provides methods for drawing content.
 */
export const useInvoicePDFGenerator = () => {
  let invoiceWithHeaderValue: boolean | undefined
  const pdfDocRef = useRef<PDFDocument | null>(null)
  const currentPageRef = useRef<PDFPage | null>(null)
  const regularFontRef = useRef<PDFFont | null>(null)
  const boldFontRef = useRef<PDFFont | null>(null)
  const italicFontRef = useRef<PDFFont | null>(null)
  const currentYRef = useRef(PAGE_HEIGHT - HEADER_HEIGHT)
  const dataRef = useRef<InvoiceData | null>(null)

  // function to initialize the pdf and get first page
  /**
   * Initializes the PDF document, embedding standard fonts (regular, bold, italic)
   * and adds the first page with a header and footer.
   *
   * Throws:
   *  - Error if PDF creation or font embedding fails.
   */

  const initializePDF = async () => {
    try {
      const pdfDoc = await PDFDocument.create()
      const regularFont = await pdfDoc?.embedFont(StandardFonts.Helvetica) // to get regular font without bold
      const boldFont = await pdfDoc?.embedFont(StandardFonts.HelveticaBold) // to get bold
      const italicFont = await pdfDoc?.embedFont(StandardFonts.HelveticaOblique) // to get italic

      pdfDocRef.current = pdfDoc
      regularFontRef.current = regularFont
      boldFontRef.current = boldFont
      italicFontRef.current = italicFont
      await addNewPage() // on every call of addNewPage header and footer will be called
    } catch (error) {
      console.error(PDF_CONSTANTS.ERROR_MESSAGES.INITIALIZING_PDF, error)
      throw new Error(PDF_CONSTANTS.ERROR_MESSAGES.FAILED_TO_INITIALIZE_PDF)
    }
  }

  // to check the remaining height and add new page according to remaining height
  // in this we are passing requiredHeight to check required height is available or not
  /**
   * Checks if the current page has enough space for the specified content height.
   * Adds a new page if the content exceeds the available space.
   *
   * @param requiredHeight - The height needed for the next content block.
   * @returns {boolean} - Returns true if a new page was added.
   *
   * Throws:
   *  - Error if content is too large for a single page.
   */

  const checkAndAddNewPage = async (requiredHeight: number) => {
    try {
      const bufferSpace = LINE_HEIGHT
      const totalRequiredHeight = requiredHeight + bufferSpace
      const currentPosition = currentYRef?.current
      const remainingSpace = currentPosition - FOOTER_HEIGHT - 5
      if (
        remainingSpace < totalRequiredHeight ||
        currentPosition <= FOOTER_HEIGHT + 5
      ) {
        await addNewPage()
        const newPageRemainingSpace = currentYRef?.current - FOOTER_HEIGHT - 5
        if (newPageRemainingSpace < totalRequiredHeight) {
          throw new Error(PDF_CONSTANTS?.ERROR_MESSAGES?.CONTENT_TOO_LARGE)
        }
        return true
      }
      if (currentPosition - requiredHeight <= FOOTER_HEIGHT + 5) {
        await addNewPage()
        return true
      }
      return false
    } catch (error) {
      console.error(PDF_CONSTANTS.ERROR_MESSAGES.CHECK_AND_ADD_NEW_PAGE, error)
      throw new Error(
        `${
          (PDF_CONSTANTS.ERROR_MESSAGES.FAILED_TO_CHECK_AND_ADD_NEW_PAGE, error)
        }`
      )
    }
  }

  // to addNewPage with header and footer
  /**
   * Adds a new page to the PDF document.
   * Automatically includes the header and footer on each new page.
   *
   * Throws:
   *  - Error if PDF document is not initialized.
   */

  const addNewPage = async () => {
    if (!pdfDocRef?.current) {
      throw new Error(PDF_CONSTANTS?.ERROR_MESSAGES?.ADDING_NEW_PAGE)
    }

    try {
      const page = pdfDocRef?.current?.addPage([PAGE_WIDTH, PAGE_HEIGHT])
      currentPageRef.current = page

      // to draw Header on every page we are calling drawHeader in this function
      await drawHeader(page)
      currentYRef.current = PAGE_HEIGHT - HEADER_HEIGHT
      // to draw Footer on every page we are calling drawHeader in this function
      drawFooter(page)

      return page
    } catch (error) {
      console.error(PDF_CONSTANTS.ERROR_MESSAGES.ADDING_NEW_PAGE, error)
      throw new Error(
        `${PDF_CONSTANTS.ERROR_MESSAGES.FAILED_TO_ADD_NEW_PAGE}: ${error}`
      )
    }
  }

  // to give multiple text in same line eg. table row
  /**
   * Draws a single line of text on the specified PDF page.
   * Supports optional background color and text alignment.
   *
   * @param page - The PDF page where the text will be drawn.
   * @param font - Font style to be applied.
   * @param text - The content to be drawn.
   * @param x, y - Coordinates for text positioning.
   * @param fontSize - Size of the text.
   * @param color - RGB color for the text.
   * @param backgroundColor - (Optional) RGB background color.
   * @param alignment - Text alignment: 'left', 'center', or 'right'.
   * @param maxWidth - (Optional) Max width for the text container.
   * @param isRangeText -  boolean - this is specifically for parameter given range, sent true if using for sending range
   */

  const drawSingleLine = (
    page: PDFPage,
    font: PDFFont,
    text: string,
    x: number,
    y: number,
    fontSize: number,
    color: [number, number, number],
    backgroundColor?: [number, number, number],
    alignment: 'left' | 'center' | 'right' = 'left',
    maxWidth?: number,
    isRangeText?: boolean // this is specifically for parameter given range, sent true if using for sending range
  ) => {
    try {
      if (text === undefined || text === null) {
        console.warn(PDF_CONSTANTS?.WARNING_MESSAGES?.NULL_TEXT_ATTEMPT)
        return
      }
      const textStr = String(text)

      if (backgroundColor) {
        const textHeight = font?.heightAtSize(fontSize)
        page?.drawRectangle({
          x,
          y: y - textHeight,
          width: maxWidth,
          height: textHeight,
          color: rgb(...backgroundColor),
        })
      }
      // this condition is for range text in parameter table
      // range text can be string min - max value or can also be string which needs to be splitted with commas ","
      // the range text with commas will be going in if cindition and will change line after each comma
      if (isRangeText && textStr?.includes(',')) {
        const parts = textStr?.split(',')

        parts?.forEach((part) => {
          const trimmedPart = part?.trim()
          let alignedX = x

          if (maxWidth) {
            const textWidth = font?.widthOfTextAtSize(trimmedPart, fontSize)
            if (alignment === 'center') {
              alignedX = x + (maxWidth - textWidth) / 2
            } else if (alignment === 'right') {
              alignedX = x + maxWidth - textWidth
            }
          }

          page?.drawText(trimmedPart, {
            x: alignedX,
            y: currentYRef?.current,
            size: fontSize,
            font,
            color: rgb(...color),
          })
          currentYRef.current -= LINE_HEIGHT // changing line after each comma
        })
        currentYRef.current += LINE_HEIGHT // removing last added line
      } else {
        // else condition for regular tests
        let alignedX = x
        if (maxWidth) {
          const textWidth = font?.widthOfTextAtSize(textStr, fontSize)
          if (alignment === 'center') {
            alignedX = x + (maxWidth - textWidth) / 2
          } else if (alignment === 'right') {
            alignedX = x + maxWidth - textWidth
          }
        }

        page?.drawText(textStr, {
          x: alignedX,
          y,
          size: fontSize,
          font,
          color: rgb(...color),
        })
      }
    } catch (error) {
      console.error(PDF_CONSTANTS?.ERROR_MESSAGES?.DRAWING_SINGLE_LINE, error)
    }
  }

  // give text to pdf with params
  /**
   * Draws multiline text on the PDF page, handling line wrapping and styles.
   *
   * @param text - The content to draw (supports strings and numbers).
   * @param x - Horizontal position.
   * @param fontSize - Font size, default is 9.
   * @param isBold - If true, bold font is applied.
   * @param maxWidth - Optional max width for text wrapping.
   * @param styles - Optional styles for color, background, underline, and alignment.
   *
   * Throws:
   *  - Error if text drawing fails.
   */

  const drawText = async (
    text: string | number | null | undefined, // text to be printed
    x: number, // horizontally starting position for text to print
    fontSize = 9,
    isBold = false,
    maxWidth?: number,
    styles: {
      color?: [number, number, number]
      backgroundColor?: [number, number, number]
      underline?: boolean
      alignment?: 'left' | 'center' | 'right'
    } = {}
  ) => {
    try {
      if (text === null || text === undefined) text = ''
      const textString = text?.toString()

      const {
        color = styles?.color ?? [0, 0, 0],
        backgroundColor,
        underline = false,
        alignment = 'left',
      } = styles

      const font = isBold ? boldFontRef?.current : regularFontRef?.current
      const currentPage = currentPageRef?.current

      if (!font || !currentPage) return

      const lines = textString?.split('\n')
      let totalOffset = 0

      for (const line of lines) {
        if (maxWidth) {
          const words = line?.split(' ')
          let currentLine = ''
          let lineOffset = 0

          for (const word of words) {
            const testLine = `${currentLine}${currentLine ? ' ' : ''}${word}`
            const width = font?.widthOfTextAtSize(testLine, fontSize)

            if (width > maxWidth && currentLine !== '') {
              const lineHeight = font?.heightAtSize(fontSize) + 2
              await checkAndAddNewPage(lineHeight)

              drawSingleLine(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                currentPageRef.current!,
                font,
                currentLine,
                x,
                currentYRef?.current - totalOffset - lineOffset,
                fontSize,
                color,
                backgroundColor,
                alignment,
                maxWidth,
                false
              )
              currentLine = word
              lineOffset += LINE_HEIGHT
            } else {
              currentLine = testLine
            }
          }

          if (currentLine) {
            const lineHeight = font?.heightAtSize(fontSize) + 2
            await checkAndAddNewPage(lineHeight)

            drawSingleLine(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              currentPageRef.current!,
              font,
              currentLine,
              x,
              currentYRef?.current - totalOffset - lineOffset,
              fontSize,
              color,
              backgroundColor,
              alignment,
              maxWidth,
              false
            )
            totalOffset += lineOffset + LINE_HEIGHT
          }
        } else {
          const lineHeight = font?.heightAtSize(fontSize) + 2
          await checkAndAddNewPage(lineHeight)

          drawSingleLine(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            currentPageRef.current!,
            font,
            line,
            x,
            currentYRef?.current - totalOffset,
            fontSize,
            color,
            backgroundColor,
            alignment,
            undefined,
            false
          )
          totalOffset += LINE_HEIGHT
        }

        if (underline) {
          const textWidth = font?.widthOfTextAtSize(line, fontSize)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          currentPageRef?.current!.drawLine({
            start: { x, y: currentYRef?.current - totalOffset + 1 },
            end: {
              x: x + textWidth,
              y: currentYRef?.current - totalOffset + 1,
            },
            thickness: 0.5,
            color: rgb(...color),
          })
        }
      }

      currentYRef.current -= totalOffset
    } catch (error) {
      console.error(PDF_CONSTANTS?.ERROR_MESSAGES?.DRAWING_TEXT, error)
    }
  }

  // to add image to the pdf
  /**
   * Loads an image from a given URL and embeds it into the PDF document.
   * Supports PNG, JPEG, and base64-encoded SVG images.
   *
   * @param url - URL or base64 string of the image.
   * @param pdfDoc - The current PDF document for embedding the image.
   *
   * Returns:
   *  - Embedded image object.
   *
   * Throws:
   *  - Error if image format is unsupported or fetch fails.
   */

  const loadImage = async (url: string, pdfDoc: PDFDocument) => {
    try {
      if (url?.startsWith('data:image/svg+xml;base64,')) {
        const canvas = document?.createElement('canvas')
        const ctx = canvas?.getContext('2d')
        const img = new Image()

        await new Promise((resolve, reject) => {
          img.onload = resolve
          img.onerror = reject
          img.src = url
        })

        canvas.width = img?.width
        canvas.height = img?.height
        ctx?.drawImage(img, 0, 0)

        const pngDataUrl = canvas?.toDataURL('image/png')
        const pngData = atob(pngDataUrl?.split(',')[1])
        const pngArray = new Uint8Array(pngData?.length)

        for (let i = 0; i < pngData?.length; i++) {
          pngArray[i] = pngData?.charCodeAt(i)
        }

        return await pdfDoc?.embedPng(pngArray)
      }

      const response = await fetch(url)
      if (!response?.ok) {
        throw new Error(
          `${PDF_CONSTANTS?.ERROR_MESSAGES?.IMAGE_LOADING} ${response?.status}`
        )
      }

      const imageBuffer = await response?.arrayBuffer()
      const uint8Array = new Uint8Array(imageBuffer)

      const isJPEG = uint8Array[0] === 0xff && uint8Array[1] === 0xd8
      const isPNG =
        uint8Array[0] === 0x89 &&
        uint8Array[1] === 0x50 &&
        uint8Array[2] === 0x4e &&
        uint8Array[3] === 0x47

      if (isJPEG) {
        return await pdfDoc?.embedJpg(imageBuffer)
      } else if (isPNG) {
        return await pdfDoc?.embedPng(imageBuffer)
      } else {
        try {
          return await pdfDoc?.embedJpg(imageBuffer)
        } catch (jpegError) {
          try {
            return await pdfDoc?.embedPng(imageBuffer)
          } catch (pngError) {
            throw new Error(
              PDF_CONSTANTS?.ERROR_MESSAGES?.IMAGE_FORMAT_NOT_SUPPORTED
            )
          }
        }
      }
    } catch (error) {
      console.error(PDF_CONSTANTS?.ERROR_MESSAGES?.IMAGE_LOADING, error)
      throw new Error(PDF_CONSTANTS?.ERROR_MESSAGES?.IMAGE_FORMAT_NOT_SUPPORTED)
    }
  }

  /**
   * Draws observation data (nature of specimen, tests, and summary) for the panel.
   * Automatically handles page breaks when needed.
   *
   * @param panelData - The data object containing test and observation information.
   */

  const drawObservationView = async (panelData: any) => {
    try {
      const test = panelData?.tests?.[0]
      const sample = test?.values?.summary?.replace(/\\+/g, '\\')

      if (currentYRef?.current - LINE_HEIGHT * 5 <= FOOTER_HEIGHT + 5) {
        await addNewPage()
      }

      currentYRef.current -= LINE_HEIGHT

      const sanitizeText = (text: string): string => {
        if (!text) return ''
        return text?.replace(/\s+/g, ' ')?.trim()
      }

      const drawHeadingWithContent = async (
        heading: string,
        content: string
      ) => {
        if (
          !regularFontRef?.current ||
          !boldFontRef?.current ||
          !currentPageRef?.current
        )
          return

        const headingWidth = boldFontRef?.current?.widthOfTextAtSize(heading, 9)
        currentPageRef?.current?.drawText(heading, {
          x: MARGIN_LEFT,
          y: currentYRef?.current,
          size: 9,
          font: boldFontRef?.current,
          color: rgb(0, 0, 0),
        })

        const processedContent = parseHTML(content)
        const maxWidth = PAGE_WIDTH - MARGIN_LEFT * 2 - headingWidth - 5 // 5px gap between heading and content

        const drawJustifiedContent = async (text: string) => {
          const regularFont = regularFontRef?.current
          const boldFont = boldFontRef?.current
          const italicFont = italicFontRef?.current
          const fontSize = 9

          if (
            !regularFont ||
            !currentPageRef?.current ||
            !boldFont ||
            !italicFont
          )
            return

          const currentFont = regularFont
          const currentFontSize = fontSize
          const formattedWords: Array<{
            text: string
            font: PDFFont
            fontSize: number
          }> = []

          // split text into words and apply formatting
          const words = text?.split(/\s+/)
          for (const word of words) {
            formattedWords?.push({
              text: word,
              font: currentFont,
              fontSize: currentFontSize,
            })
          }

          // calculate positions for justified text
          let lineWords: typeof formattedWords = []
          let currentLineWidth = 0
          // let xPos = MARGIN_LEFT + headingWidth + 5 // Start after heading
          let isFirstLine = true // Track if it's the first line of a paragraph

          for (const wordObj of formattedWords) {
            const wordWidth = wordObj?.font?.widthOfTextAtSize(
              wordObj?.text,
              wordObj?.fontSize
            )
            const spaceWidth = wordObj?.font?.widthOfTextAtSize(
              ' ',
              wordObj?.fontSize
            )
            const availableWidth = isFirstLine
              ? maxWidth - 5
              : maxWidth + headingWidth
            if (
              currentLineWidth +
                wordWidth +
                (lineWords?.length > 0 ? spaceWidth : 0) <=
              availableWidth
            ) {
              lineWords?.push(wordObj)
              currentLineWidth +=
                wordWidth + (lineWords?.length > 1 ? spaceWidth : 0)
            } else {
              if (lineWords?.length > 1) {
                const totalWordWidth = lineWords?.reduce(
                  (sum, word) =>
                    sum +
                    word?.font?.widthOfTextAtSize(word?.text, word?.fontSize),
                  0
                )
                const totalSpacing = availableWidth - totalWordWidth
                const spaceBetweenWords = totalSpacing / (lineWords?.length - 1)

                let currentX = isFirstLine
                  ? MARGIN_LEFT + headingWidth + 5
                  : MARGIN_LEFT
                lineWords.forEach((word, index) => {
                  currentPageRef?.current?.drawText(word.text, {
                    x: currentX,
                    y: currentYRef?.current,
                    size: word?.fontSize,
                    font: word?.font,
                    color: rgb(0.1, 0.1, 0.1),
                  })
                  if (index < lineWords.length - 1) {
                    currentX +=
                      word?.font?.widthOfTextAtSize(
                        word?.text,
                        word?.fontSize
                      ) + spaceBetweenWords
                  }
                })
              }

              // Move to next line
              currentYRef.current -= LINE_HEIGHT * 0.9
              await checkAndAddNewPage(LINE_HEIGHT)
              // xPos = MARGIN_LEFT // Start from left margin for subsequent lines
              lineWords = [wordObj]
              currentLineWidth = wordWidth
              isFirstLine = false
            }
          }

          // draw remaining words
          if (lineWords.length > 0) {
            //  let currentX = xPos
            let currentX = isFirstLine
              ? MARGIN_LEFT + headingWidth + 5
              : MARGIN_LEFT
            lineWords?.forEach((word, index) => {
              currentPageRef?.current?.drawText(word.text, {
                x: currentX,
                y: currentYRef.current,
                size: word.fontSize,
                font: word.font,
                color: rgb(0.1, 0.1, 0.1),
              })
              if (index < lineWords.length - 1) {
                currentX +=
                  word?.font?.widthOfTextAtSize(word.text, word.fontSize) +
                  word?.font?.widthOfTextAtSize(' ', word.fontSize)
              }
            })
          }
        }

        await drawJustifiedContent(processedContent)
        currentYRef.current -= LINE_HEIGHT
      }

      // draw nature of specimen
      if (panelData?.name) {
        await drawHeadingWithContent(
          `${PDF_CONSTANTS?.OBSERVATION?.NATURE_OF_SPECIMEN}:`,
          sanitizeText(panelData?.name)
        )
        currentYRef.current -= LINE_HEIGHT
      }

      // draw observations
      if (test?.values?.observations?.length > 0) {
        for (const observation of test.values.observations) {
          await drawHeadingWithContent(
            `${sanitizeText(observation?.title)}:`,
            sanitizeText(observation?.content)
          )
          currentYRef.current -= LINE_HEIGHT
        }
        currentYRef.current += LINE_HEIGHT * 0.5
      }

      // draw summary if available
      if (sample) {
        await drawHeadingWithContent(
          `${PDF_CONSTANTS?.OBSERVATION?.SUMMARY}:`,
          sanitizeText(summaryReason(sample))
        )
      }
    } catch (error) {
      console.error(PDF_CONSTANTS?.ERROR_MESSAGES?.DRAW_OBSERVATION_VIEW, error)
      throw error
    }
  }

  const summaryReason = (text: string) => {
    return `${text?.replace(/\\n/g, '\n')?.trim()}` || '-'
  }

  /**
   * Draws detailed panel information including department name, tests,
   * sample types, and interpretations.
   *
   * @param panelDataObject - Object containing test panels and related information.
   * @param requestedBy - Name of the person who requested the tests.
   *
   * Throws:
   *  - Error if panel data is malformed.
   */

  const drawPanel = async (
    panelDataObject: Record<
      string,
      Array<{
        id?: string
        name?: string
        sequence?: number[]
        tests: any
        department: any
        resultType: string
        interpretation: any
        interpretationImage?: string
        sample: any
        pathologyName: any
        requestedBy: any
        pathologySignature: any
        labDepartments?: any
        technician_name?: string
      }>
    >
  ) => {
    try {
      const panelDataArray = Object.values(panelDataObject)?.flat() // Flatten panel data object into a single array for iteration
      if (currentYRef?.current - LINE_HEIGHT * 5 <= FOOTER_HEIGHT + 5) {
        await addNewPage() // Add new page if not enough space for the panel
      }
      if (!Array.isArray(panelDataArray)) {
        throw new TypeError(PDF_CONSTANTS?.ERROR_MESSAGES?.PANELDATA_ARRAY)
      }
      const sortPanelData = (
        panelDataArray:
          | {
              id?: string
              name?: string
              resultType: string
              sequence?: number[]
              tests: any
              department: any
              interpretation: any
              interpretationImage?: string
              sample: any
              pathologyName: any
              requestedBy: any
              pathologySignature: any
              labDepartments?: any
              technician_name?: string
            }[]
          | {
              interpretation: {
                trim: () => { (): any; new (): any; length: number }
              }
              tests: string | any[]
              resultType: boolean
            }[]
      ) => {
        const singleTestNoInterpretation: any[] = []
        const multipleTests: any[] = []
        const withInterpretation: any[] = []
        const observation: any[] = []

        // categorizing each panel
        panelDataArray?.forEach((panel) => {
          // check  if panel has interpretation
          const hasInterpretation =
            panel?.interpretation && panel?.interpretation?.trim()?.length > 0

          // check number of tests
          const testCount = panel?.tests?.length || 0
          const isObservation =
            panel?.resultType === PDF_CONSTANTS?.OBSERVATION?.OBSERVATION

          if (isObservation) {
            observation?.push(panel)
          } else {
            if (testCount > 1) {
              multipleTests?.push(panel)
            } else {
              if (hasInterpretation) {
                withInterpretation?.push(panel)
              } else {
                singleTestNoInterpretation?.push(panel)
              }
            }
          }
        })
        return [
          ...singleTestNoInterpretation,
          ...withInterpretation,
          ...multipleTests,
          ...observation,
        ]
      }

      const sortedPanelDataArray = sortPanelData(panelDataArray)

      for (const index in sortedPanelDataArray) {
        const panelData = sortedPanelDataArray[index]
        const departmentName =
          panelData?.id === PDF_CONSTANTS?.RADIOLOGY
            ? PDF_CONSTANTS?.RADIOLOGY_TEXT
            : panelData?.department?.department_name || ''

        const departmentHeaderHeight = LINE_HEIGHT * 2
        if (
          currentYRef?.current - departmentHeaderHeight <=
          FOOTER_HEIGHT + 5
        ) {
          await addNewPage() // Add new page if space is insufficient for the department header
        }

        if (departmentName && Number(index) == 0) {
          const font = boldFontRef?.current
          const fontSize = 10
          const department = `${PDF_CONSTANTS?.HEADERS?.DEPARTMENT_PREFIX} ${departmentName}`
          const textWidth = font?.widthOfTextAtSize(department, fontSize) || 0
          const centerX = (PAGE_WIDTH - textWidth) / 2

          await drawText(department, centerX, 10, true, undefined, {
            color: [0, 0, 0],
          }) // Center-align department name on the page
        } else {
          // if department name is there then draw department name else leave te space empty
          await drawText(
            '',
            MARGIN_LEFT + 180 + departmentName?.length,
            10,
            true,
            undefined,
            {
              color: [0, 0.3, 0.57],
            }
          )
        }
        currentYRef.current -= 5
        if (panelData?.sample) {
          const sampleHeight = LINE_HEIGHT * 2
          if (currentYRef?.current - sampleHeight <= FOOTER_HEIGHT + 5) {
            await addNewPage()
          }
          // draw test name heading
          await drawText(
            `${PDF_CONSTANTS?.HEADERS?.TEST_NAME}`,
            MARGIN_LEFT,
            9,
            true,
            undefined,
            {
              color: [0, 0, 0],
            }
          )
          currentYRef.current += LINE_HEIGHT // come again to same line and draw value for test name

          await drawText(
            `: ${panelData?.name}`,
            MARGIN_LEFT + 65,
            9,
            false,
            undefined,
            {
              color: [0.25, 0.25, 0.25],
            }
          )
        }
        currentYRef.current += 2
        if (panelData?.sample) {
          const sampleHeight = LINE_HEIGHT * 2
          if (currentYRef?.current - sampleHeight <= FOOTER_HEIGHT + 5) {
            await addNewPage()
          }
          // draw sample type heading
          await drawText(
            PDF_CONSTANTS?.HEADERS?.SAMPLE_TYPE,
            MARGIN_LEFT,
            9,
            true,
            undefined,
            {
              color: [0, 0, 0],
            }
          )
          currentYRef.current += LINE_HEIGHT // come back to same line and draw value for sample type
          await drawText(
            `: ${panelData?.sample}`,
            MARGIN_LEFT + 65,
            9,
            false,
            undefined,
            {
              color: [0.25, 0.25, 0.25],
            }
          )
          currentYRef.current -= 2
        }

        if (panelData?.resultType === PDF_CONSTANTS?.OBSERVATION?.OBSERVATION) {
          await drawObservationView(panelData)
        } else {
          if (panelData?.tests?.length > 1 && Number(index) > 0) {
            await addNewPage()
          }
          currentYRef.current -= 5

          const columnWidths = [212, 85, 83.5, 155] // widths of the columns of test
          const startX = MARGIN_LEFT
          let isHeaderDrawn = false

          if (
            panelData?.tests &&
            Array.isArray(panelData?.tests) &&
            panelData?.tests?.length > 0
          ) {
            for (const test of panelData.tests) {
              const testHeight = test?.method
                ? LINE_HEIGHT * 3
                : LINE_HEIGHT * 2
              const headerHeight = !isHeaderDrawn ? LINE_HEIGHT + 10 : 0
              const totalNeededHeight = testHeight + headerHeight

              if (
                currentYRef?.current - totalNeededHeight <=
                FOOTER_HEIGHT + 5
              ) {
                await addNewPage()
                isHeaderDrawn = false
              }

              if (!isHeaderDrawn) {
                drawTableHeader(startX, columnWidths)
                isHeaderDrawn = true
              }

              await drawTest(
                {
                  name: test?.name,
                  method: test?.method,
                  value: test?.value,
                  range: test?.range,
                  abnormal: test?.abnormal,
                  unit: test?.unit,
                  resultType: test?.resultType,
                },
                startX,
                columnWidths
              )
              currentYRef.current -= 1
            }
          }
        }
        const interpretationHeight = LINE_HEIGHT * 8

        if (currentYRef?.current - interpretationHeight <= FOOTER_HEIGHT + 5) {
          await addNewPage()
        }

        const sanitizedInterpretation = panelData?.interpretation
          ? panelData?.interpretation?.replace(/\n/g, '')
          : ''
        if (sanitizedInterpretation) {
          currentYRef.current -= LINE_HEIGHT
          await drawText(
            `${PDF_CONSTANTS?.HEADERS?.INTERPRETATION}`,
            MARGIN_LEFT,
            9,
            true
          )
        }

        const interpretationImage =
          panelData?.tests[0]?.panel?.interpretation_image
        await drawInterpretation(sanitizedInterpretation, interpretationImage)
        const pathologyHeight = LINE_HEIGHT * 2.5
        const signatureHeight = panelData?.pathologySignature ? 40 : 0
        const totalPathologyHeight = pathologyHeight + signatureHeight
        if (currentYRef?.current - totalPathologyHeight <= FOOTER_HEIGHT) {
          await addNewPage()
        }

        if (panelData?.pathologySignature) {
          currentYRef.current -= 28
        }

        if (panelData?.pathologySignature) {
          try {
            const pdfDoc = pdfDocRef?.current
            if (pdfDoc) {
              const signatureImage = await loadImage(
                panelData?.pathologySignature,
                pdfDoc
              )

              if (signatureImage) {
                currentPageRef?.current?.drawImage(signatureImage, {
                  x: PAGE_WIDTH - 130,
                  y: currentYRef?.current,
                  width: 100,
                  height: 40,
                })
                currentYRef.current -= 20
              }
            }
          } catch (error) {
            console.error(
              PDF_CONSTANTS?.ERROR_MESSAGES?.PROCESSING_SIGNATURE,
              error
            )
            await drawText(
              PDF_CONSTANTS?.HEADERS?.DIGITAL_SIGNATURE,
              MARGIN_LEFT,
              9,
              true
            )
            currentYRef.current -= 20
          }
        }

        if (panelData?.tests?.[0]?.organization?.name) {
          currentPageRef?.current?.drawText(
            `${PDF_CONSTANTS?.PATHOLOGY?.NOTE} ${panelData?.tests?.[0]?.organization?.name}`,
            {
              x: MARGIN_LEFT,
              y: currentYRef?.current,
              size: 8,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              font: boldFontRef.current!,
            }
          )
          currentYRef.current -= LINE_HEIGHT * 1.5
        }
        if (currentYRef?.current - totalPathologyHeight <= FOOTER_HEIGHT) {
          await addNewPage()
        }

        currentPageRef?.current?.drawText(
          PDF_CONSTANTS?.PATHOLOGY?.VERIFIED_BY,
          {
            x: MARGIN_LEFT,
            y: currentYRef?.current,
            size: 8,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            font: boldFontRef.current!,
          }
        )

        currentYRef.current -= LINE_HEIGHT
        if (panelData?.technician_name) {
          currentPageRef?.current?.drawText(
            `${panelData?.technician_name} (${PDF_CONSTANTS?.PATHOLOGY?.TECHNOLOGIST})`,
            {
              x: MARGIN_LEFT,
              y: currentYRef?.current,
              size: 8.5,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              font: regularFontRef.current! ?? boldFontRef.current!,
            }
          )
        }

        currentYRef.current += LINE_HEIGHT
        const approvedByTextWidth = boldFontRef?.current?.widthOfTextAtSize(
          PDF_CONSTANTS?.PATHOLOGY?.APPROVED_BY,
          9
        )

        if (panelData?.pathologyName) {
          currentPageRef?.current?.drawText(
            PDF_CONSTANTS?.PATHOLOGY?.APPROVED_BY,
            {
              x: approvedByTextWidth
                ? PAGE_WIDTH - approvedByTextWidth - MARGIN_LEFT + 9
                : PAGE_WIDTH - MARGIN_LEFT,
              y: currentYRef?.current,
              size: 8,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              font: boldFontRef.current!,
            }
          )
          currentYRef.current -= 12
          const pathologyHeight = LINE_HEIGHT * 2
          if (currentYRef?.current - pathologyHeight <= FOOTER_HEIGHT) {
            await addNewPage()
          }
          drawPathologyName(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            currentPageRef.current!,
            panelData?.pathologyName,
            panelData?.pathologistQualification,
            PAGE_WIDTH - 120,
            currentYRef?.current
          )
        }
        currentYRef.current -= 10
        const textWidth = boldFontRef?.current?.widthOfTextAtSize(
          `${panelData?.pathologyDepartment}`,
          7.5
        )
        currentPageRef?.current?.drawText(`${panelData?.pathologyDepartment}`, {
          x: textWidth
            ? PAGE_WIDTH - textWidth - MARGIN_LEFT + 3.5
            : PAGE_WIDTH - MARGIN_LEFT,
          y: currentYRef?.current,
          size: 8,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          font: regularFontRef.current!,
        })
        currentYRef.current -= LINE_HEIGHT * 3

        const isRemainingHeight =
          (PAGE_HEIGHT - HEADER_HEIGHT - FOOTER_HEIGHT) / 2 +
            64 -
            currentYRef?.current >
          0
            ? true
            : false
        if (Number(index) < panelDataArray.length - 1 && isRemainingHeight) {
          await addNewPage()
        } else if (
          !isRemainingHeight &&
          Number(index) < panelDataArray?.length - 1
        ) {
          currentYRef.current =
            (PAGE_HEIGHT - HEADER_HEIGHT - FOOTER_HEIGHT) / 2 +
            (invoiceWithHeaderValue ? 64 : 90)
          currentPageRef?.current?.drawLine({
            start: { x: MARGIN_LEFT, y: currentYRef?.current },
            end: { x: PAGE_WIDTH - MARGIN_LEFT, y: currentYRef?.current },
            thickness: 0.2,
            color: rgb(0.5, 0.5, 0.5),
          })
          currentYRef.current -= LINE_HEIGHT
        }
      }
      currentYRef.current -= LINE_HEIGHT * 3
    } catch (error) {
      console.error(PDF_CONSTANTS?.ERROR_MESSAGES?.DRAW_PANEL, error)
      throw error
    }
  }

  /**
   * Draws the name of the pathology lab or pathologist on the PDF.
   *
   * @param page - The current PDF page.
   * @param pathologyName - The name to display.
   * @param xPosition - X-coordinate for the text placement.
   * @param yPosition - Y-coordinate for the text placement.
   */

  const drawPathologyName = (
    page: PDFPage,
    pathologyName: string,
    qualification: string,
    xPosition: number,
    yPosition: number
  ) => {
    if (!regularFontRef?.current || !pathologyName) return

    const textWidth = regularFontRef?.current?.widthOfTextAtSize(
      `Dr. ${pathologyName} ${qualification ? `(${qualification})` : ''}`,
      8.4
    )
    const rightMargin = MARGIN_LEFT
    const xPos = PAGE_WIDTH - textWidth - rightMargin

    page?.drawText(
      `${PDF_CONSTANTS?.PATHOLOGY?.DOCTOR} ${pathologyName} ${
        qualification ? `(${qualification})` : ''
      }`,
      {
        x: xPos,
        y: yPosition,
        size: 8.5,
        font: regularFontRef?.current,
        color: rgb(0, 0, 0),
      }
    )
  }

  const parseHTML = (html: string) => {
    const listCounters: number[] = []

    const processReactElement = (
      element: any,
      depth = 0,
      color?: string
    ): string => {
      if (typeof element === 'string' || typeof element === 'number') {
        return color
          ? `<span style="color: ${color}">${element}</span>`
          : element?.toString()
      }

      if (Array.isArray(element)) {
        return element
          .map((item) => processReactElement(item, depth, color))
          .join('')
      }

      if (!element || !element?.props) {
        return ''
      }

      const { type, props } = element

      switch (type) {
        case 'ul':
        case 'ol':
          while (listCounters?.length <= depth) {
            listCounters?.push(0)
          }

          if (type === 'ol') {
            listCounters[depth] = 0
          }

          // eslint-disable-next-line no-case-declarations
          const listItems = Array.isArray(props?.children)
            ? props?.children
            : [props?.children]

          return (
            '\n\n' +
            listItems
              ?.map((item: any) => {
                const indentation = '   '.repeat(depth + 1)
                if (type === 'ol') {
                  listCounters[depth]++
                  const numberPrefix =
                    listCounters?.slice(0, depth + 1)?.join('.') + '. '
                  return `${indentation}${numberPrefix}${processReactElement(
                    item,
                    depth + 1,
                    color
                  )}`
                } else {
                  return `      • ${processReactElement(
                    item,
                    depth + 1,
                    color
                  )}`
                }
              })
              ?.join('\n') +
            '\n'
          )

        case 'li':
          return processReactElement(props?.children, depth, color) + '\n'

        case 'p':
          return processReactElement(props?.children, depth, color)

        case 'br':
          return '\n\n'

        case 'b':
        case 'strong':
          return `<b>${processReactElement(props?.children, depth, color)}</b>`

        case 'h1':
          return `<h1>${processReactElement(
            props?.children,
            depth,
            color
          )}</h1>`
        case 'h2':
          return `<h2>${processReactElement(
            props?.children,
            depth,
            color
          )}</h2>`

        case 'i':
        case 'em':
          return `<i>${processReactElement(props?.children, depth, color)}</i>`

        case 'span':
          return processReactElement(
            props?.children,
            depth,
            props?.style?.color || color
          )

        default:
          if (props?.children) {
            return processReactElement(props?.children, depth, color)
          }
          return ''
      }
    }

    const parsedElement = parse(html)
    return processReactElement(parsedElement)
  }

  // the drawInterpretation function to give interpretation
  /**
   * Draws interpretation text and images related to test results.
   * Automatically parses HTML-like structures for formatted text rendering.
   *
   * @param interpretationText - The text content for the interpretation.
   * @param interpretationImage - Optional image (chart/graph) related to the interpretation.
   */

  const drawInterpretation = async (
    interpretationText: string,
    interpretationImage: string | undefined
  ) => {
    const maxWidth = PAGE_WIDTH - MARGIN_LEFT * 2
    const regularFont = regularFontRef?.current
    const boldFont = boldFontRef?.current
    const italicFont = italicFontRef?.current
    const fontSize = 9

    if (!regularFont || !currentPageRef?.current || !boldFont || !italicFont)
      return

    const drawJustifiedParagraph = async (
      text: string,
      xOffset: number,
      isListItem: boolean
    ) => {
      let currentTextPart = ''
      let currentFont = regularFont
      let currentFontSize = fontSize
      const formattedWords: Array<{
        text: string
        font: PDFFont
        fontSize: number
      }> = []
      for (let i = 0; i < text?.length; i++) {
        if (text?.substring(i)?.startsWith('<h1>')) {
          if (currentTextPart) {
            formattedWords?.push(
              ...currentTextPart.split(/\s+/).map((word) => ({
                text: word?.trim(),
                font: currentFont,
                fontSize: currentFontSize,
              }))
            )
            currentTextPart = ''
          }
          currentFont = boldFont
          currentFontSize = fontSize + 4
          i += 3
          continue
        } else if (text?.substring(i)?.startsWith('</h1>')) {
          if (currentTextPart) {
            formattedWords?.push(
              ...currentTextPart.split(/\s+/).map((word) => ({
                text: word?.trim(),
                font: currentFont,
                fontSize: currentFontSize,
              }))
            )
            currentTextPart = ''
          }
          currentFont = regularFont
          currentFontSize = fontSize
          i += 4
          continue
        } else if (text?.substring(i)?.startsWith('<h2>')) {
          if (currentTextPart) {
            formattedWords?.push(
              ...currentTextPart
                .trim()
                .split(/\s+/)
                .map((word) => ({
                  text: word,
                  font: currentFont,
                  fontSize: currentFontSize,
                }))
            )
            currentTextPart = ''
          }
          currentFont = boldFont
          currentFontSize = fontSize + 2
          i += 3
          continue
        } else if (text?.substring(i)?.startsWith('</h2>')) {
          if (currentTextPart) {
            formattedWords?.push(
              ...currentTextPart
                .trim()
                .split(/\s+/)
                .map((word) => ({
                  text: word,
                  font: currentFont,
                  fontSize: currentFontSize,
                }))
            )
            currentTextPart = ''
          }
          currentFont = regularFont
          currentFontSize = fontSize
          i += 4
          continue
        } else if (text?.substring(i)?.startsWith('<b>')) {
          if (currentTextPart) {
            formattedWords?.push(
              ...currentTextPart
                .trim()
                .split(/\s+/)
                .map((word) => ({
                  text: word,
                  font: currentFont,
                  fontSize: currentFontSize,
                }))
            )
            currentTextPart = ''
          }
          currentFont = boldFont
          i += 2
          continue
        } else if (text?.substring(i)?.startsWith('</b>')) {
          if (currentTextPart) {
            formattedWords?.push(
              ...currentTextPart
                .trim()
                .split(/\s+/)
                .map((word) => ({
                  text: word,
                  font: currentFont,
                  fontSize: currentFontSize,
                }))
            )
            currentTextPart = ''
          }
          currentFont = regularFont
          i += 3
          continue
        } else if (text?.substring(i)?.startsWith('<i>')) {
          if (currentTextPart) {
            formattedWords?.push(
              ...currentTextPart
                .trim()
                .split(/\s+/)
                .map((word) => ({
                  text: word,
                  font: currentFont,
                  fontSize: currentFontSize,
                }))
            )
            currentTextPart = ''
          }
          currentFont = italicFont
          i += 2
          continue
        } else if (text?.substring(i)?.startsWith('</i>')) {
          if (currentTextPart) {
            formattedWords?.push(
              ...currentTextPart
                .trim()
                .split(/\s+/)
                .map((word) => ({
                  text: word,
                  font: currentFont,
                  fontSize: currentFontSize,
                }))
            )
            currentTextPart = ''
          }
          currentFont = regularFont
          i += 3
          continue
        }
        currentTextPart += text[i]
      }

      if (currentTextPart) {
        formattedWords?.push(
          ...currentTextPart
            .trim()
            .split(/\s+/)
            .map((word) => ({
              text: word,
              font: currentFont,
              fontSize: currentFontSize,
            }))
        )
      }

      // to process words into lines
      let currentLineWidth = 0
      let lineWords: typeof formattedWords = []

      const effectiveMaxWidth = isListItem ? maxWidth - 50 : maxWidth

      for (const wordObj of formattedWords) {
        const wordWidth = wordObj?.font?.widthOfTextAtSize(
          wordObj?.text,
          wordObj?.fontSize
        )
        const spaceWidth = wordObj?.font?.widthOfTextAtSize(
          ' ',
          wordObj?.fontSize
        )

        if (
          currentLineWidth +
            wordWidth +
            (lineWords?.length > 0 ? spaceWidth : 0) <=
          effectiveMaxWidth
        ) {
          lineWords?.push(wordObj)
          currentLineWidth +=
            wordWidth + (lineWords?.length > 1 ? spaceWidth : 0)
        } else {
          if (lineWords?.length > 1) {
            const totalWordWidth = lineWords?.reduce(
              (sum, word) =>
                sum + word?.font?.widthOfTextAtSize(word?.text, word?.fontSize),
              0
            )
            const totalSpacing = effectiveMaxWidth - totalWordWidth
            const spaceBetweenWords = totalSpacing / (lineWords?.length - 1)

            let xPos = xOffset
            lineWords?.forEach((word, index) => {
              currentPageRef?.current?.drawText(word?.text?.trim(), {
                x: xPos,
                y: currentYRef?.current,
                size: word?.fontSize,
                font: word?.font,
                color: rgb(0.1, 0.1, 0.1),
              })
              if (index < lineWords?.length - 1) {
                xPos +=
                  word?.font?.widthOfTextAtSize(word?.text, word?.fontSize) +
                  spaceBetweenWords
              }
            })
          }

          currentYRef.current -= LINE_HEIGHT * 0.9
          await checkAndAddNewPage(LINE_HEIGHT)

          lineWords = [wordObj]
          currentLineWidth = wordWidth
        }
      }

      if (lineWords?.length > 0) {
        let xPos = xOffset
        lineWords?.forEach((word) => {
          currentPageRef?.current?.drawText(word?.text?.trim(), {
            x: xPos,
            y: currentYRef?.current,
            size: word?.fontSize,
            font: word?.font,
            color: rgb(0.1, 0.1, 0.1),
          })
          xPos +=
            word?.font?.widthOfTextAtSize(word?.text, word?.fontSize) +
            word?.font?.widthOfTextAtSize(' ', word?.fontSize)
        })
        currentYRef.current -= LINE_HEIGHT
      }
    }

    if (interpretationText) {
      const processedText = parseHTML(interpretationText)
      const paragraphs = processedText?.split('\n\n')?.filter((p) => p)

      for (const paragraph of paragraphs) {
        const lineHeight = regularFont?.heightAtSize(fontSize) + 2
        await checkAndAddNewPage(lineHeight)

        const isListItem =
          paragraph?.trim()?.startsWith('•') ||
          paragraph?.trim()?.match(/^\s*\d+\./)

        if (isListItem) {
          const bulletMatch = paragraph?.match(/^(\s*[•\d.]+\s*)/)

          currentPageRef?.current?.drawText(
            bulletMatch ? bulletMatch[1] : '•',
            {
              x: MARGIN_LEFT,
              y: currentYRef?.current,
              size: fontSize,
              font: regularFont,
              color: rgb(0.1, 0.1, 0.1),
            }
          )
          const content = paragraph?.replace(/^\s*[•\d.]+\s*/, '')
          await drawJustifiedParagraph(content, MARGIN_LEFT + 22, true)
        } else {
          await drawJustifiedParagraph(paragraph, MARGIN_LEFT, false)
        }

        currentYRef.current -= LINE_HEIGHT * 0.1
      }
    }

    // handle interpretation image
    if (interpretationImage) {
      try {
        currentYRef.current -= LINE_HEIGHT // Add spacing before image

        if (pdfDocRef?.current) {
          const image = await loadImage(interpretationImage, pdfDocRef?.current)

          if (image) {
            // Calculate image dimensions to fit within available space
            const availableHeight = currentYRef?.current - FOOTER_HEIGHT
            const imgDims = image?.size()

            let finalWidth = Math.min(
              maxWidth - MARGIN_LEFT * 2,
              imgDims?.width
            )
            // Adjust image height if it exceeds available space
            let finalHeight = (maxWidth * imgDims?.height) / imgDims?.width

            const maxAllowedHeight = Math.min(
              availableHeight,
              availableHeight >=
                (PAGE_HEIGHT - HEADER_HEIGHT - FOOTER_HEIGHT) / 2
                ? (PAGE_HEIGHT - HEADER_HEIGHT - FOOTER_HEIGHT) / 2
                : finalHeight
            )

            if (finalHeight > maxAllowedHeight) {
              finalHeight = maxAllowedHeight
              finalWidth = (maxAllowedHeight * imgDims?.width) / imgDims?.height
            }

            if (currentYRef?.current - finalHeight <= FOOTER_HEIGHT) {
              await addNewPage()
            }

            currentYRef.current -= LINE_HEIGHT
            await drawText(
              PDF_CONSTANTS?.HEADERS?.INTERPRETATION_IMAGE,
              MARGIN_LEFT,
              9,
              true
            )

            const xPos = MARGIN_LEFT + (maxWidth - finalWidth) / 2 // Center the image horizontally

            currentPageRef?.current?.drawImage(image, {
              x: xPos,
              y: currentYRef?.current - finalHeight,
              width: finalWidth,
              height: finalHeight,
            })

            currentYRef.current -= finalHeight + LINE_HEIGHT // Adjust Y position after image
          }
        }
      } catch (error) {
        console.error(PDF_CONSTANTS?.ERROR_MESSAGES?.IMAGE_LOADING, error)
        currentYRef.current -= LINE_HEIGHT
      }
    }
    currentYRef.current -= LINE_HEIGHT
  }

  /**
   * Draws the header row for a table displaying test parameters and results.
   * The header includes column names like 'Parameter Name', 'Value', 'Unit', and 'Biological-Ref-Range'.
   *
   * @param startX - The starting x-coordinate for the header.
   * @param columnWidths - An array defining the width of each column.
   */

  const drawTableHeader = (startX: number, columnWidths: number[]) => {
    if (!currentPageRef?.current || !boldFontRef?.current) return

    const headers = PDF_CONSTANTS?.TABLE_HEADERS
    const currentY = currentYRef?.current

    currentPageRef?.current?.drawRectangle({
      x: startX - 5,
      y: currentY - LINE_HEIGHT + 7,
      width: columnWidths?.reduce((a, b) => a + b, 0) + 10,
      height: LINE_HEIGHT + 7,
      color: rgb(0.95, 0.95, 0.95),
    })

    let x = startX
    if (currentPageRef?.current && boldFontRef?.current)
      drawSingleLine(
        currentPageRef?.current,
        boldFontRef?.current,
        '',
        x,
        currentY,
        8.75,
        [0, 0, 0],
        undefined,
        'left',
        100,
        false
      )
    headers?.forEach((header, i) => {
      if (currentPageRef?.current && boldFontRef?.current)
        drawSingleLine(
          currentPageRef?.current,
          boldFontRef?.current,
          header,
          x,
          currentY,
          8.75,
          [0, 0, 0],
          undefined,
          i === 0 || i === 3 ? 'left' : 'center',
          columnWidths[i],
          false
        )
      x += columnWidths[i]
    })

    currentYRef.current -= LINE_HEIGHT + 5
  }

  /**
   * Draws the details of an individual test including name, method, value, unit, and reference range.
   * Highlights abnormal values in bold.
   *
   * @param test - An object containing test details (name, value, method, range, etc.).
   * @param startX - The starting x-coordinate for the test row.
   * @param columnWidths - An array defining the width of each column in the test row.
   */

  const drawTest = async (
    test: {
      name: string
      method: string
      value: string
      range: [number | undefined, number | undefined]
      abnormal: string
      unit: string
      resultType: string
    },
    startX: number,
    columnWidths: number[]
  ) => {
    if (
      !currentPageRef?.current ||
      !regularFontRef?.current ||
      !boldFontRef?.current
    )
      return

    const currentY = currentYRef?.current
    let x = startX

    const availableWidth = columnWidths[0] - 20

    const testNameLines = splitTextIntoLines(
      test?.name,
      availableWidth,
      regularFontRef?.current,
      9
    )

    testNameLines?.forEach((line, index) => {
      drawSingleLine(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        currentPageRef.current!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        regularFontRef.current!,
        line,
        x,
        currentY - index * LINE_HEIGHT,
        9,
        [0, 0, 0],
        undefined,
        'left',
        availableWidth,
        false
      )
    })

    let verticalOffset = testNameLines?.length * LINE_HEIGHT

    if (test?.method) {
      verticalOffset -= 3

      drawSingleLine(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        currentPageRef.current!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        italicFontRef.current!,
        `${PDF_CONSTANTS?.TABLE_METHOD?.METHOD}: ${test?.method}`,
        x,
        currentY - verticalOffset,
        7.5,
        [0.2, 0.2, 0.2],
        undefined,
        'left',
        availableWidth,
        false
      )

      verticalOffset += LINE_HEIGHT
    }

    x += columnWidths[0]

    const value = getFormattedNumber(test?.value)
    const isAbnormalValue =
      isOutsideRange(test?.value, test?.range) ||
      isAbnormal(test?.abnormal, test?.value ?? '')

    drawSingleLine(
      currentPageRef?.current,
      isAbnormalValue ? boldFontRef?.current : regularFontRef?.current,
      value,
      x,
      currentY,
      9,
      isAbnormalValue ? [0, 0, 0] : [0, 0, 0],
      undefined,
      'center',
      columnWidths[1],
      false
    )

    x += columnWidths[1]

    drawSingleLine(
      currentPageRef?.current,
      regularFontRef?.current,
      test?.unit || PDF_CONSTANTS?.PLACEHOLDERS?.DASH,
      x,
      currentY,
      9,
      [0, 0, 0],
      undefined,
      'center',
      columnWidths[2],
      false
    )

    x += columnWidths[2]

    const rangeText = test?.range?.join(' - ') || '-'
    drawSingleLine(
      currentPageRef?.current,
      regularFontRef?.current,
      rangeText,
      x,
      currentY,
      9,
      [0, 0, 0],
      undefined,
      'left',
      columnWidths[3],
      true
    )

    // verticalOffset +=

    currentYRef.current -= verticalOffset
  }

  /**
   * Splits a block of text into multiple lines based on the maximum width allowed.
   * Ensures that each line fits within the specified width using the provided font and font size.
   *
   * @param text - The text string to be split.
   * @param maxWidth - The maximum width for each line.
   * @param font - The font used to measure text width.
   * @param fontSize - The size of the font used.
   *
   * @returns {string[]} - An array of text lines.
   */

  const splitTextIntoLines = (
    text: string,
    maxWidth: number,
    font: PDFFont,
    fontSize: number
  ): string[] => {
    const words = text?.split(' ')
    const lines: string[] = []
    let currentLine = ''

    for (const word of words) {
      const testLine = currentLine ? `${currentLine} ${word}` : word
      const lineWidth = font?.widthOfTextAtSize(testLine, fontSize)

      if (lineWidth <= maxWidth) {
        currentLine = testLine
      } else {
        if (currentLine) {
          lines?.push(currentLine)
          currentLine = word
        } else {
          lines?.push(word)
          currentLine = ''
        }
      }
    }

    if (currentLine) {
      lines?.push(currentLine)
    }

    return lines
  }

  // to draw footer in pdf
  /**
   * Draws the footer at the bottom of each PDF page.
   * Includes disclaimers and advisory notes related to the report.
   *
   * @param page - The PDF page where the footer will be drawn.
   */

  const drawFooter = (page: PDFPage) => {
    if (invoiceWithHeaderValue) {
      page?.drawText(PDF_CONSTANTS?.FOOTER_TEXTS?.DISCLAIMER, {
        x: MARGIN_LEFT,
        y: MARGIN_TOP / 2 + LINE_HEIGHT / 2 - 2,
        size: 7.5,
      })

      page?.drawText(PDF_CONSTANTS?.FOOTER_TEXTS?.ADVISORY1, {
        x: MARGIN_LEFT,
        y: MARGIN_TOP / 2 - 3.5,
        size: 7.3,
      })
      page?.drawText(PDF_CONSTANTS?.FOOTER_TEXTS?.ADVISORY2, {
        x: MARGIN_LEFT,
        y: MARGIN_TOP / 2 - 12,
        size: 7.5,
      })

      page?.drawLine({
        start: { x: MARGIN_LEFT, y: FOOTER_HEIGHT - 5 },
        end: { x: PAGE_WIDTH - MARGIN_LEFT, y: FOOTER_HEIGHT - 5 },
        thickness: 0.5,
        color: rgb(0.3, 0.3, 0.3),
      })
    }
  }

  /**
   * Adds page numbers to all pages in the PDF document.
   * The position of the page numbers depends on whether the invoice header is included.
   *
   * @param pdfDoc - The PDF document where page numbers will be added.
   * @param invoiceWithHeaderValue - Boolean indicating if the header is included, affecting number placement.
   */

  const addPageNumbers = (
    pdfDoc: PDFDocument,
    invoiceWithHeaderValue: boolean | undefined
  ) => {
    const pageCount = pdfDoc?.getPageCount()

    for (let i = 0; i < pageCount; i++) {
      const page = pdfDoc?.getPage(i)
      page?.drawText(`Page ${i + 1} of ${pageCount}`, {
        x: invoiceWithHeaderValue ? PAGE_WIDTH - 67 : MARGIN_LEFT,
        y: MARGIN_TOP / 2 - 10,
        size: 8,
      })
    }
  }

  // to draw header on page
  /**
   * Draws the header on each PDF page.
   * The header includes the company logo, contact information, and patient-specific data.
   *
   * @param page - The PDF page where the header will be drawn.
   *
   * Throws:
   *  - Error if the header drawing fails.
   */

  const drawHeader = async (page: PDFPage) => {
    try {
      const pdfDoc = pdfDocRef?.current
      const logoWidth = 110
      const logoHeight = 23
      const headerY = PAGE_HEIGHT - 60
      const iconSize = 9

      if (invoiceWithHeaderValue) drawTopLine(page, PAGE_WIDTH)

      if (pdfDoc && dataRef?.current) {
        const logoImage = await fetch(medunitedLogo).then((res) =>
          res?.arrayBuffer()
        )
        const locationIconImage = await fetch(locationIcon).then((res) =>
          res?.arrayBuffer()
        )
        const emailIconImage = await fetch(emailIcon).then((res) =>
          res?.arrayBuffer()
        )
        const mobileIconImage = await fetch(mobileIcon).then((res) =>
          res?.arrayBuffer()
        )

        const logo = await pdfDoc?.embedPng(logoImage)
        const locationImg = await pdfDoc?.embedPng(locationIconImage)
        const emailImg = await pdfDoc?.embedPng(emailIconImage)
        const mobileImg = await pdfDoc?.embedPng(mobileIconImage)

        if (invoiceWithHeaderValue) {
          page?.drawImage(logo, {
            x: MARGIN_LEFT - 10,
            y: headerY + 23,
            width: logoWidth,
            height: logoHeight,
          })
        }

        const address =
          dataRef?.current?.patientAddress?.address?.line?.[0] || ''
        const phone =
          dataRef?.current?.patientAddress?.telecom?.[0]?.value || ''
        const email =
          dataRef?.current?.patientAddress?.telecom?.[1]?.value || ''

        const headerText = `${PDF_CONSTANTS?.HEADERS?.REG_ADDRESS}: ${address}`
        const phoneText = `${phone}`
        const emailText = `${email}`

        const addressFont = regularFontRef?.current
        const fontSize = 8
        const addressY = headerY
        const headerTextWidth = addressFont?.widthOfTextAtSize(
          headerText,
          fontSize
        )
        const phoneTextWidth = addressFont?.widthOfTextAtSize(
          phoneText,
          fontSize
        )
        const rightMargin = 25
        const combinedWidth = addressFont?.widthOfTextAtSize(
          `|  ${emailText}`,
          fontSize
        )
        const phoneX =
          phoneTextWidth && combinedWidth
            ? PAGE_WIDTH - (phoneTextWidth + combinedWidth) - rightMargin
            : PAGE_WIDTH - rightMargin

        const addressX = headerTextWidth
          ? PAGE_WIDTH - headerTextWidth - rightMargin
          : PAGE_WIDTH - rightMargin

        if (addressFont && invoiceWithHeaderValue) {
          page?.drawImage(locationImg, {
            x: addressX - iconSize + 1,
            y: addressY + 38.5,
            width: iconSize - 2.8,
            height: iconSize,
          })

          page?.drawText(headerText, {
            x: addressX,
            y: addressY + 40,
            size: fontSize,
            font: addressFont,
            color: rgb(0, 0, 0),
            maxWidth: 500,
          })

          page?.drawImage(mobileImg, {
            x: phoneX - iconSize - 4,
            y: addressY + 26,
            width: iconSize - 2,
            height: iconSize - 1,
          })

          page?.drawText(phoneText, {
            x: phoneX - 4,
            y: addressY + 27.5,
            size: fontSize,
            font: addressFont,
            color: rgb(0, 0, 0),
          })

          page?.drawImage(emailImg, {
            x: phoneTextWidth ? phoneX + phoneTextWidth - 2.5 : phoneX,
            y: addressY + 26,
            width: iconSize,
            height: iconSize - 1,
          })

          page?.drawText(`   ${emailText}`, {
            x: phoneTextWidth ? phoneX + phoneTextWidth + 2 : phoneX + 7,
            y: addressY + 27.5,
            size: fontSize,
            font: addressFont,
            color: rgb(0, 0, 0),
          })
        }

        const infoGrid = getInfoData(dataRef?.current)
        const colWidth = (PAGE_WIDTH - MARGIN_LEFT * 2) / 2 - 20
        const labelWidth = 70
        const valueWidth = colWidth - labelWidth - 8

        let currentY = invoiceWithHeaderValue ? addressY : addressY - 28

        // generate QR code for the invoice ID or any other URL you want
        if (dataRef?.current?.fileId) {
          try {
            const qrCodeSize = 55
            const qrCodeX = PAGE_WIDTH - MARGIN_LEFT - qrCodeSize
            const qrCodeY = headerY - 50

            // get shortened URL
            let qrCodeUrl
            try {
              qrCodeUrl = await shortenUrl(dataRef?.current?.fileId)
            } catch (urlError) {
              console.error(
                PDF_CONSTANTS?.ERROR_MESSAGES?.URL_SHORTNING_ERROR,
                urlError
              )
              qrCodeUrl = downloadUrl + dataRef?.current?.fileId
            }

            // to generate QR code which redirects to the url to open the particular patient pdf whenever scanned
            const qrCodeDataUrl = await QRCode?.toDataURL(qrCodeUrl, {
              width: qrCodeSize,
              margin: 1,
              errorCorrectionLevel: 'M',
            })

            const qrCodeImageBytes = await fetch(qrCodeDataUrl).then((res) =>
              res?.arrayBuffer()
            )

            const qrCodeImage = await pdfDoc?.embedPng(qrCodeImageBytes)
            page?.drawImage(qrCodeImage, {
              x: qrCodeX,
              y: invoiceWithHeaderValue ? qrCodeY : qrCodeY - 28,
              width: qrCodeSize,
              height: qrCodeSize,
            })

            if (regularFontRef?.current) {
              page?.drawText(PDF_CONSTANTS?.SCANNER_LABEL, {
                x: qrCodeX + qrCodeSize / 2 - 30,
                y: invoiceWithHeaderValue ? qrCodeY - 12 : qrCodeY - 41,
                size: 7,
                font: regularFontRef?.current,
                color: rgb(0, 0, 0),
              })
            }
          } catch (qrError) {
            console.error('Error generating QR code:', qrError)
          }
        }

        infoGrid?.forEach((row) => {
          if (regularFontRef?.current && boldFontRef?.current) {
            const additionalHeight =
              drawInfoRow(
                page,
                row as [string, string, string, string],
                MARGIN_LEFT,
                currentY,
                labelWidth,
                valueWidth,
                colWidth
              ) || 0
            currentY -= LINE_HEIGHT * 0.9 + additionalHeight
          }
        })
      }
      page?.drawLine({
        start: { x: MARGIN_LEFT, y: PAGE_HEIGHT - HEADER_HEIGHT + 20 },
        end: {
          x: PAGE_WIDTH - MARGIN_LEFT,
          y: PAGE_HEIGHT - HEADER_HEIGHT + 20,
        },
        thickness: 0.7,
        color: rgb(0.5, 0.5, 0.5),
      })
    } catch (error) {
      console.error(PDF_CONSTANTS?.ERROR_MESSAGES?.DRAWING_HEADER, error)
      throw error
    }
  }

  /**
   * Draws a single row of patient or report-related information in the header.
   * Splits long text into multiple lines if necessary.
   *
   * @param page - The PDF page where the information will be drawn.
   * @param rowData - An array containing the label and value pairs for two columns.
   * @param startX - The starting x-coordinate for the first column.
   * @param y - The y-coordinate for row placement.
   * @param labelWidth - Width of the label column.
   * @param valueWidth - Width of the value column.
   * @param colWidth - Total width of the column block.
   *
   * @returns {number} - The additional height used if the text wraps.
   */

  const drawInfoRow = (
    page: PDFPage,
    rowData: [string, string, string, string],
    startX: number,
    y: number,
    labelWidth: number,
    valueWidth: number,
    colWidth: number
  ) => {
    if (!boldFontRef?.current || !regularFontRef?.current) return

    const yHeight = y
    const fontSize = 9

    const getTextLines = (
      text: string,
      maxWidth: number,
      font: PDFFont
    ): string[] => {
      if (!text) return ['']
      const words = text?.toString().split(' ')
      const lines: string[] = []
      let currentLine = ''

      for (const word of words) {
        const testLine = currentLine ? `${currentLine} ${word}` : word
        const lineWidth = font?.widthOfTextAtSize(testLine, fontSize)

        if (lineWidth <= maxWidth) {
          currentLine = testLine
        } else {
          if (currentLine) {
            lines?.push(currentLine)
            currentLine = word
            HEADER_HEIGHT += LINE_HEIGHT
          } else {
            lines?.push(word)
            currentLine = ''
          }
        }
      }
      if (currentLine) {
        lines?.push(currentLine)
      }
      return lines
    }

    const value1Lines = getTextLines(
      rowData[1],
      valueWidth,
      regularFontRef?.current
    )

    drawSingleLine(
      page,
      boldFontRef?.current,
      rowData[0],
      startX,
      yHeight,
      fontSize,
      [0, 0, 0]
    )

    value1Lines?.forEach((line, index) => {
      drawSingleLine(
        page,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        regularFontRef.current!,
        `: ${line}`,
        startX + labelWidth,
        yHeight - index * LINE_HEIGHT,
        fontSize - 0.5,
        [0.1, 0.1, 0.1],
        undefined,
        'left',
        valueWidth
      )
    })

    startX += 30
    const value2Lines = getTextLines(
      rowData[3],
      valueWidth,
      regularFontRef?.current
    )

    drawSingleLine(
      page,
      boldFontRef?.current,
      rowData[2],
      startX + colWidth,
      yHeight,
      fontSize,
      [0, 0, 0]
    )

    value2Lines?.forEach((line, index) => {
      drawSingleLine(
        page,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        regularFontRef.current!,
        line ? `: ${line}` : '',
        startX + colWidth + labelWidth,
        yHeight - index * LINE_HEIGHT,
        fontSize - 0.5,
        [0.1, 0.1, 0.1],
        undefined,
        'left',
        valueWidth
      )
    })

    // update the curren Y position based on the maximum number of lines
    const maxLines = Math.max(value1Lines?.length, value2Lines?.length)
    return (maxLines - 1) * LINE_HEIGHT // Return the additional height used
  }

  /**
   * Draws colored lines at the top of each page as part of the header design.
   * The lines are used for branding or aesthetic purposes.
   * The lines are used for branding or aesthetic purposes.
   *
   * @param page - The PDF page where the lines will be drawn.
   * @param pageWidth - The total width of the page, used to calculate line placement.
   */
  const drawTopLine = (page: PDFPage, pageWidth: number) => {
    const headerTopLineHeight = 6
    page?.drawRectangle({
      x: 0,
      y: page?.getHeight() - headerTopLineHeight,
      width: pageWidth * 0.5,
      height: headerTopLineHeight,
      color: rgb(0.96, 0.45, 0.13),
    })

    page?.drawRectangle({
      x: pageWidth * 0.3,
      y: page?.getHeight() - headerTopLineHeight,
      width: pageWidth * 0.7,
      height: headerTopLineHeight,
      color: rgb(0, 0.3, 0.57),
    })
  }

  /**
   * Main function to generate the complete PDF document.
   * Iterates over panels and draws them, adding headers, footers, and page numbers.
   *
   * @param data - Invoice data containing patient details, panels, and configuration.
   * @returns {Blob} - The generated PDF document as a Blob.
   *
   * Throws:
   *  - Error if PDF generation fails at any step.
   */

  const generatePDF = async (data: InvoiceData): Promise<Blob> => {
    invoiceWithHeaderValue = data?.invoiceWithHeaderValue ?? false // variable to confirm that header image and Address and Footer should be shown or not
    HEADER_HEIGHT = !invoiceWithHeaderValue ? 183 : 155
    FOOTER_HEIGHT = !invoiceWithHeaderValue ? 70 : 45

    try {
      dataRef.current = data
      await initializePDF()
      const panels = data?.panels
      const radiologyResults = data?.radiologyResults
      if (data?.panels) {
        for (const [index, panel] of panels.entries()) {
          if (index > 0) {
            await addNewPage()
          }

          await drawPanel(panel)
        }
      }
      if (data?.radiologyResults) {
        for (const [index, panel] of radiologyResults.entries()) {
          if (index > 0) {
            await addNewPage()
          }

          await drawPanel(panel)
        }
      }

      const pdfDoc = pdfDocRef?.current
      if (!pdfDoc)
        throw new Error(PDF_CONSTANTS?.ERROR_MESSAGES?.FAILED_TO_CREATE_PDF)

      // check for last page to draw "~~~ End of Report ~~~" on that Page
      const lastPage = pdfDoc?.getPage(pdfDoc?.getPageCount() - 1)

      currentYRef.current = FOOTER_HEIGHT

      lastPage?.drawText(PDF_CONSTANTS?.FOOTER_TEXTS?.END_OF_REPORT, {
        x: PAGE_WIDTH - 335,
        y: currentYRef?.current,
        size: 9,
      })
      // to add pagenumbers in last of every page
      addPageNumbers(pdfDoc, data?.invoiceWithHeaderValue)

      const pdfBytes = await pdfDoc?.save()
      return new Blob([pdfBytes], { type: 'application/pdf' })
    } catch (error) {
      console.error(PDF_CONSTANTS?.ERROR_MESSAGES?.GENERATING_PDF, error)
      throw new Error(PDF_CONSTANTS?.ERROR_MESSAGES?.FAILED_TO_GENERATE_PDF)
    }
  }
  return { generatePDF }
}
