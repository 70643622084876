import { useEffect, useState } from 'react'
import { startSxpProxy } from '../../../../utils/api'
import { Practitioner } from 'fhir/r4'
import { ADMIN_PROJECT_ID } from '../../../../utils/constants'
import { emptyString } from '../../../Radiology/Constants/constants'
import { intent, forms } from '../../../administration/constants'

type labDepartments = {
  name: string
  id: string
  signature: string
  qualification: string
}

export const GetLabDepartments = () => {
  const [labDepartments, setLabDepartments] = useState<labDepartments[]>([])
  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, intent.getLabInchargeList)
      .then((res) => {
        const practitioners = res?.data?.entry?.filter(
          (e: { resource: { resourceType: string } }) =>
            e.resource?.resourceType === forms.practitioner
        )
        const data = practitioners?.map((e: { resource: Practitioner }) => {
          const firstName =
            e.resource?.name?.[0]?.given?.join(emptyString) ?? emptyString
          const lastName = e.resource?.name?.[0]?.family ?? emptyString
          const signature = e.resource?.photo?.[1]?.url ?? ''
          return {
            id: e.resource?.id,
            name: firstName.concat(' ', lastName),
            signature: signature,
            qualification: e?.resource?.qualification?.[0]?.code?.text,
          }
        })
        setLabDepartments(data)
      })
      .catch((err) => console.error(err))
  }, [])

  return labDepartments
}
