import { useEffect, useState } from 'react'
import '../bms.scss'
import { startSxpProxy } from '../../../utils/api'
import { CHAT_PROJECT_ID, IPD_PROJECT_ID } from '../../../utils/constants'
import { makeName } from '../../lms/utils'
import { getTotalAge } from '../../../utils/dateUtils'
import KeycloakService from '../../../utils/keycloakService'
import { useNavigate } from 'react-router-dom'
import Dialog from '../../../components/Dialog'
import AddTransferDialog from './AddTransferDialog'
import DischargeConformationDialog from './DischargeConformationDialog'
import {
  bedErrorMessages,
  bedFilter,
  bedNavigations,
  reasonOptionsArray,
  selectReason,
  stateValues,
  toastSuccessMessages,
  updateBedType,
} from '../constants'
import { allocateApi, allocation, tableHeadText } from '../modals'
import { emptyString, noData } from '../../Radiology/Constants/constants'
import Close from '../../../assets/images/close.png'
import Edit from '../../../assets/icons/edit_icon.svg'
import EditAdmissionDate from './EditAdmissionDate'
import { IPD_ADMIN, IPD_DISCHARGE_ADMIN } from '../../../utils/roles'
import { toast } from 'react-toastify'
import { useTheme } from '@mui/material'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'
import { sendSocketData } from '../../Notification/Socket'
import { DISCHARGED_IPD_PATIENT } from '../../Notification/Constants'

type Props = {
  onConfirm: (image: boolean) => void
  wardId: bedFilter
  setRefresh?: any
  refresh: boolean
  tableRefresh?: any
  mode: string
  onClose: () => void
}

const styles = () => ({
  reasonContainer: {
    display: 'flex',
    gap: 10,
  },
  reasonContent: {
    fontSize: '13px',
  },
})

const AddDischargeDialog = ({
  onConfirm,
  wardId,
  setRefresh,
  tableRefresh,
  mode,
  onClose,
}: Props) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const navigate = useNavigate()
  const [dischargeOpen, setDischargeOpen] = useState<boolean>(false)
  const [bed, setBed] = useState<bedFilter>(wardId)
  const [isTransferOpen, setIsTransferOpen] = useState<boolean>(false)
  const [isEditDate, setIsEditDate] = useState(false)
  const [dischargeError, setDischargeError] = useState(false)
  const [reason, setReason] = useState('')
  const [dischargeBedData, setDischargedBedData] = useState<updateBedType>({
    admissionDate: '',
    patientId: '',
    patientName: '',
    id: 0,
    bedMapId: 0,
    patientMapId: '',
    patientFhirId: '',
    location: '',
  })
  const transferBed = () => {
    setIsTransferOpen(true)
  }
  const dateObject = (dateString: any) => {
    const dateObject = new Date(dateString)
    const formattedDate = dateObject?.toISOString()?.split('T')?.[0]
    return formattedDate
  }
  const getPatientDetailsByIdentifier = () => {
    startSxpProxy(CHAT_PROJECT_ID, allocateApi?.getPatientDetailsByIdentifier, {
      patientIdentifier: bed?.patientId,
    }).then((data) => {
      if (data?.data?.entry?.length > 0) {
        const name = makeName(data?.data?.entry?.[0]?.resource.name)
        const gender = data?.data?.entry?.[0]?.resource.gender
        const age = getTotalAge(
          data?.data?.entry?.[0]?.resource.birthDate ?? ''
        )
        setBed((prevBed: any) => ({
          ...prevBed,
          patientName: name,
          gender: gender,
          age: age,
        }))
      }
    })
  }
  const getBedDetailsById = () => {
    startSxpProxy(IPD_PROJECT_ID, allocateApi?.getBedDetailsById, {
      bedId: parseInt(bed?.id),
    }).then((data: any) => {
      const bed = data?.data?.bed_by_pk
      const status = bed?.bed_patient_assignment_maps?.find(
        (status: any) =>
          status?.bed_status === allocation?.assigned &&
          status?.patient_id === bed?.patient_id
      )
      if (status && status !== bedErrorMessages?.bedUndefined) {
        setDischargeError(false)
        setDischargedBedData({
          admissionDate: bed?.admission_date,
          patientId: bed?.patient_id,
          patientName: bed?.patient_name,
          patientFhirId: status?.patient_registered_id,
          id: bed?.id,
          bedMapId: status?.id,
          patientMapId: status?.patient_id,
          location: bed?.location_id,
        })
      } else {
        setDischargeError(true)
        toast?.error(bedErrorMessages?.dischargePatientError)
        onClose()
      }
    })
  }

  const handleTransferClose = (e: boolean) => {
    onConfirm(e)
  }
  const handleDischargeClose = () => {
    setDischargeOpen(false)
  }
  const dischargeConfirm = (e: boolean) => {
    // setDischarge(e)
    setDischargeOpen(false)
    if (e) {
      sendDischargeData()
    }
    onConfirm(false)
  }

  const sendDischargeData = () => {
    const state = {
      id: parseInt(bed?.id),
      patientId: emptyString,
      bedMapId: dischargeBedData.bedMapId,
      patientMapId: dischargeBedData?.patientMapId,
      bedStatus: allocation?.dischargedText,
      dateOfDischarge: new Date().toISOString(),
      updatedBy: KeycloakService?.getUsername(),
      patientName: emptyString,
      admissionDate: emptyString,
      dischargedDate: emptyString,
      status: allocation?.available,
      patientFhirId: dischargeBedData?.patientFhirId,
      dateCreated: new Date().toISOString(),
      reason: reason,
    }
    startSxpProxy(IPD_PROJECT_ID, allocateApi?.updateAndDischargeBed, state)
      .then((data) => {
        sendSocketData({
          messageType: DISCHARGED_IPD_PATIENT,
          patientId: dischargeBedData?.patientId,
          patientLocation: dischargeBedData?.location,
          message: '',
        })

        toast?.success(`Discharged from ${wardId?.bedNumber} successfully`)
        toast?.error(bedErrorMessages?.refreshCheck)
        const encounterId = data?.data
        const state = {
          status: stateValues?.finished,
          encounterId: encounterId,
          reason: stateValues?.discharged,
        }
        startSxpProxy(IPD_PROJECT_ID, allocateApi?.closeEncounter, state)
          .then(() => {
            toast?.success(toastSuccessMessages?.encounterClosedSuccessfully)
            if (mode === allocation?.list) {
              onConfirm(false)
              setRefresh((prevState: boolean) => !prevState)
            }
            if (mode === allocation?.layout) {
              onConfirm(false)
              if (window.location.pathname === bedNavigations?.bedIpd) {
                navigate(bedNavigations?.bedIpdOverview)
              } else {
                navigate(bedNavigations?.bedIpd)
              }
            }
          })
          .catch(() => {
            toast.error(toastSuccessMessages?.encounterFailure)
          })
      })
      .catch((err) => {
        err?.response?.data?.error?.errors?.forEach((message: string) => {
          toast?.error(message)
        })
        toast?.error(err?.response?.data?.message)
      })
  }

  const allocateDateOpen = () => {
    setIsEditDate(true)
  }
  const handleEditDateClose = () => {
    setIsEditDate(false)
    onConfirm(false)
    setRefresh(true)
    tableRefresh()
  }
  const handleEditChange = () => {
    setIsEditDate(false)
    onConfirm(false)
  }
  useEffect(() => {
    getBedDetailsById()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bed?.id])
  useEffect(() => {
    getPatientDetailsByIdentifier()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bed?.patientId])

  return (
    <>
      {isEditDate && (
        <Dialog
          open={isEditDate}
          onClose={handleEditDateClose}
          style={{ width: '50%', padding: '1%' }}
        >
          <EditAdmissionDate
            onClose={handleEditChange}
            onConfirm={handleEditDateClose}
            wardId={wardId}
          />
        </Dialog>
      )}
      {dischargeOpen && (
        <Dialog
          open={dischargeOpen}
          onClose={handleDischargeClose}
          style={{ width: '28%', padding: '2%' }}
        >
          <DischargeConformationDialog
            onConfirm={dischargeConfirm}
            onClose={() => setDischargeOpen(false)}
          />
        </Dialog>
      )}

      <div className='assign-container'>
        <div>
          <span className='event-close' onClick={() => onClose()}>
            <img className='close-img' src={Close} alt='Close Event Dialog' />
          </span>
          <div>
            <span className=''>{allocation?.patientDetails}</span>
          </div>
          <table className='bed-table relative'>
            <thead>
              <tr>
                <th>{tableHeadText?.uhid}</th>
                <th className='text-center'>{tableHeadText?.name}</th>
                <th className='text-center'>{tableHeadText?.age}</th>
                <th className='text-center'>{tableHeadText?.gender}</th>
                <th className='text-left'>{tableHeadText?.admit}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='text-center'>{bed?.patientId}</td>
                <td className='text-center'>{bed?.patientName}</td>
                <td className='text-center'>{bed?.age}</td>
                <td className='text-center'>{bed?.gender}</td>
                <td className='flex-center'>
                  {bed?.admission ? dateObject(bed?.admission) : noData}
                  {KeycloakService?.hasRole([IPD_ADMIN]) && (
                    <div>
                      <button
                        className='bg-none'
                        onClick={() => allocateDateOpen()}
                      >
                        <img
                          alt='Edit Patient'
                          src={Edit}
                          className='height18-width20'
                        />
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <div>
            <span>{allocation?.bedDetails}</span>
          </div>
          <table className='bed-table relative'>
            <thead>
              <tr>
                <th>{tableHeadText.bedNo}</th>
                <th className='text-left'>{tableHeadText?.floor}</th>
                <th className='text-center'>{tableHeadText?.bedCategory}</th>
                <th className='text-center'>{tableHeadText?.roomNo}</th>
                <th className='text-center'>{tableHeadText?.status}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='text-center'>{bed?.bedNumber}</td>
                <td className='text-center'>{bed?.floorNumber}</td>
                <td className='text-center'>{bed?.bedCategory}</td>
                <td className='text-center'>{bed?.roomDetail}</td>
                <td className='text-center'>
                  {bed?.status === allocation?.occupied
                    ? allocation?.occupiedCase
                    : allocation?.availableCase}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {isTransferOpen && (
          <AddTransferDialog
            bed={bed}
            dischargedBedData={dischargeBedData}
            onClose={handleTransferClose}
            setRefresh={setRefresh}
            tableRefresh={tableRefresh}
            mode={mode}
          />
        )}
        <div className='assign-transfer'>
          <input
            type='button'
            value='Transfer'
            disabled={isTransferOpen === true}
            className={`assign-button ${
              isTransferOpen === true ? 'disableBtn' : ''
            }`}
            onClick={transferBed}
          />
          {KeycloakService?.hasRole([IPD_DISCHARGE_ADMIN]) && (
            <>
              <div className={classes?.reasonContainer}>
                <div>
                  <span className={classes?.reasonContent}>{selectReason}</span>
                  <select
                    value={reason}
                    onChange={(e) => setReason(e?.target?.value)}
                  >
                    {reasonOptionsArray?.map((option, index) => (
                      <option key={index} value={option?.value}>
                        {option?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <input
                  type='button'
                  value='Discharge'
                  className={`${reason ? 'assign-button' : ''}`}
                  disabled={dischargeError || !reason}
                  onClick={() => {
                    setDischargeOpen(true)
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
export default AddDischargeDialog
