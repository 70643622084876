import React, { useRef, useEffect, useState } from 'react'
import { AdminTableProps } from '../../models'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsAdminSearch, selectedLmsStatus } from '../../lmsSlice'
import { getSearchResults } from '../../utils'
import { create, menuValues, update } from '../../constants'
import { IconButton, useTheme } from '@mui/material'
import KeycloakService from '../../../../utils/keycloakService'
import { LMS_ADMIN_WRITE, SUPER_ADMIN_DELETE } from '../../../../utils/roles'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import '../../../administration/admin.scss'
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog'
import { Archive } from './Archive'
import { activeText } from '../../../Radiology/Constants/constants'
import MedAgDataGrid from '../../../../components/MedAgDataGrid'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import CustomHeader from '../../../Radiology/components/RadiologyModel/CustomHeader'
import { ColDef } from 'ag-grid-community'
import { radiologyColor } from '../../../Radiology/Utils/RadiologyColors'
import LmsProvenanceView from './LmsProvenanceView'
const styles = () => ({
  centerHeader: {
    color: radiologyColor?.white,
    textAlign: 'center',
    '& .ag-header-cell-text': {
      flex: 1,
    },
  },
  nameButton: {
    backgroundColor: '#0000',
    border: 'none',
    color: 'blue',
    cursor: 'pointer',
    padding: '0px',
    textAlign: 'left',
    textDecoration: 'underline',
  },
})

const LabTestTable = ({ data, onEdit }: AdminTableProps) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const status = useAppSelector(selectedLmsStatus)
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [tableData, setTableData] = useState<any>(data)
  const [parameterData, setParameterData] = useState<any>({})
  let filtered = getSearchResults(tableData, searchParam)
  filtered = filtered?.filter((d) => d?.status === status)

  filtered?.sort?.((a: any, b: any) => {
    if (a.department?.department_name < b.department?.department_name) {
      return -1
    } else if (a.department?.department_name > b.department?.department_name) {
      return 1
    } else {
      if (a.panel?.name < b.panel?.name) {
        return -1
      } else if (a.panel?.name > b.panel?.name) {
        return 1
      } else {
        return a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1
      }
    }
  })

  const paramTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = paramTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  useEffect(() => {
    setTableData(data)
  }, [data])

  function handleDeleteDialogOpen(d: any) {
    setDeleteDialog(true)
    setParameterData(d)
  }

  function handleCloseDeleteDialog() {
    setDeleteDialog(false)
    setParameterData(null)
  }
  const createProvenanceView = (params: any) => {
    return (
      <LmsProvenanceView
        mode={create}
        date={params?.data?.created_date ?? ''}
        name={params?.data?.created_by ?? '-'}
      />
    )
  }
  const updatedProvenanceView = (params: any) => {
    return (
      <LmsProvenanceView
        mode={update}
        date={params?.data?.updated_date ?? ''}
        name={params?.data?.updated_by ?? '-'}
      />
    )
  }
  const RenderActions = (params: any) => {
    return (
      <div className='set-action-icons'>
        {params?.data?.status === activeText ? (
          <IconButton
            title='Archive'
            size='small'
            onClick={() =>
              Archive(params?.data, setTableData, menuValues?.Parameter)
            }
            disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
          >
            <ArchiveOutlinedIcon fontSize='small' />
          </IconButton>
        ) : (
          <IconButton
            title='un-Archive'
            size='small'
            onClick={() =>
              Archive(params?.data, setTableData, menuValues?.Parameter)
            }
            disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
          >
            <UnarchiveOutlinedIcon fontSize='small' />
          </IconButton>
        )}
        <IconButton
          title='Delete'
          size='small'
          onClick={() => handleDeleteDialogOpen(params?.data)}
          disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
        >
          <DeleteOutlined fontSize='small' />
        </IconButton>
      </div>
    )
  }
  const renderName = (params: any) => {
    return KeycloakService.hasRole([LMS_ADMIN_WRITE]) ? (
      <button
        onClick={() => onEdit(params?.data?.id)}
        className={classes?.nameButton}
      >
        {params?.data?.name}
      </button>
    ) : (
      <span>{params?.data?.name}</span>
    )
  }
  const columnDefs: ColDef[] = [
    {
      headerName: 'Id',
      field: 'id',
      width: 70,
      sortable: true,
      filter: true,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Parameter Name',
      field: 'name',
      sortable: true,
      filter: true,
      headerClass: classes?.centerHeader,
      cellRenderer: renderName,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'left', whiteSpace: 'normal' },
      minWidth: 300,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Parameter Short Form',
      field: 'parameter',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => params?.data?.parameter_short_form ?? '-',
      cellClass: 'custom-header-cell',
      headerClass: classes?.centerHeader,
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
      },
      minWidth: 220,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Department',
      field: 'department',
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      cellRenderer: (params: any) =>
        params?.data?.department?.department_name ?? '-',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      headerComponent: CustomHeader,
      minWidth: 155,
    },
    {
      headerName: 'Sample',
      field: 'method',
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellRenderer: (params: any) => params?.data?.panel?.sample?.name ?? '-',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'left',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        lineHeight: 1.5,
      },
      flex: 1, // 🔹 Prevents column from shrinking
      wrapText: true,
      minWidth: 136,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Test',
      field: 'test',
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellRenderer: (params: any) => params?.data?.panel?.name ?? '-',
      cellStyle: {
        fontSize: '12px',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        lineHeight: 1.5,
      },
      minWidth: 166,
      flex: 1, // 🔹 Prevents column from shrinking
      wrapText: true,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Result Type',
      field: 'result type',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) =>
        params?.data?.test_result_type?.result_type ?? '-',
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      minWidth: 146,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Created By',
      field: 'created by',
      sortable: true,
      filter: true,
      cellRenderer: createProvenanceView,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 166,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Updated By',
      field: 'patientMobile',
      sortable: true,
      filter: true,
      cellRenderer: updatedProvenanceView,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 166,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      sortable: true,
      filter: true,
      cellRenderer: RenderActions,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 146,
      headerComponent: CustomHeader,
    },
  ]
  return (
    <>
      {deleteDialog && (
        <DeleteConfirmationDialog
          openDialog={deleteDialog}
          closeDialog={handleCloseDeleteDialog}
          data={parameterData}
          name={parameterData?.name}
          setData={setTableData}
          item={menuValues?.Parameter}
        />
      )}
      <MedAgDataGrid
        tableHeight={'100%'}
        rowData={filtered}
        columnDefs={columnDefs}
        pagination={false}
        headerHeight={30}
        rowHeight={40}
        searchFieldWidth={200}
        searchField={false}
        loading={false}
        exportIcon={false}
      />
    </>
  )
}

export default LabTestTable
