import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { makeName } from '../lms/utils'
import { getTotalAge } from '../../utils/dateUtils'
import {
  selectPatientsData,
  fetchPatientsAsync,
  resetPatientStatus,
  PatientData,
} from '../patients/patientsSlice'
import OPDSearch from './opdSearch'
import OPDPatientTable from './OpdScreen'
import { Checkbox, Dialog } from '@mui/material'
import { upComingAppointmentType } from './constant'
import { startSxpProxy } from '../../utils/api'
import { CHAT_PROJECT_ID, OPD_PROJECT_ID } from '../../utils/constants'
import { useLocation } from 'react-router-dom'
import OPDVisitCreateDialog from './OPDVisitCreateDialog'
import './opdVisit.scss'
import OPDPatientList from './OPDPatientList'

export type FormattedPatient = {
  id: any
  name: any
  gender: any
  age: any
  fhirId: any
  thopId: any
  invoiceId: any
  registeredOn: any
  registrationType: any
  mobile: any
}
interface Appointment {
  appointmentId: string
}

const OPDPatients = () => {
  const patients = useAppSelector(selectPatientsData)
  const dispatch = useAppDispatch()
  const [upcomingAppointments, setUpcomingAppointments] = useState<
    upComingAppointmentType[]
  >([])

  useEffect(() => {
    dispatch(fetchPatientsAsync())
    return () => {
      dispatch(resetPatientStatus())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const capitalizeFirstLetter = (word?: string) => {
    if (!word) return '-'
    return word.charAt(0).toUpperCase() + word.slice(1)
  }

  const formatted: FormattedPatient[] = patients?.map((p: PatientData) => {
    const thop = p?.resource?.identifier?.[0]
    const bahmni = p?.resource?.identifier?.[2]
    const created = p?.resource?.identifier?.[3]
    const regType = p?.resource?.identifier?.[3]
    const invoice = p?.resource?.identifier?.[5]
    const name = makeName(p?.resource?.name)
    const age = getTotalAge(p?.resource?.birthDate)
    const gender = capitalizeFirstLetter(p.resource?.gender)
    const tel1 = p?.resource?.telecom?.find((prt) => prt?.use === 'mobile')
    const tel2 = p?.resource?.telecom?.find((prt) => prt?.use === 'home')
    const photo = p?.resource?.photo?.[0]
    return {
      id: bahmni?.value ?? thop?.value ?? p.resource?.id ?? '',
      name: name.trim() ?? '',
      gender: gender,
      age: age,
      fhirId: p.resource?.id ?? '',
      thopId: thop?.value ?? '',
      invoiceId: invoice?.value ?? '',
      registeredOn: created?.period?.start ?? '',
      registrationType: regType?.value ?? '',
      mobile: tel1?.value ?? tel2?.value ?? '',
      photo: photo?.url ?? '',
    }
  })

  // const [show, setShow] = useState<boolean>(false)
  // const [query, setQuery] = useState<string>('')
  // const [param, setParam] = useState<string>('')
  // const [patientId, setPatientId] = useState<string>('')
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false)
  const [patientData, setPatientData] = useState<any>({})
  const [appointmentIds, setAppointmentIds] = useState<string[]>([])
  const [encounterDetails, setEncounterDetails] = useState<any>(null)
  const location = useLocation()
  const patientThopId = location?.state?.MUHId

  const handleOpenDialog = () => {
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }
  const { fhirId } = formatted?.[0] ?? {}
  // const handleSearch = (q: string, p: string) => {
  //   setQuery(q)
  //   setParam(fhirId)
  //   setPatientId(fhirId)
  //   if (!q || !p) {
  //     setShow(false)
  //   } else {
  //     setShow(true)
  //   }
  // }

  // const [refresh, setRefresh] = useState(false)
  // const handleRefresh = () => {
  //   setRefresh(true)
  // }

  const fetchAllAppointments = async (patientId: string) => {
    try {
      const intent = 'allAppointments'
      const state = {
        id: patientId,
      }
      const resp = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
      setUpcomingAppointments(resp?.data)
      const ids = resp?.data?.map(
        (appointment: Appointment) => appointment?.appointmentId
      )
      setAppointmentIds(ids)
    } catch (e) {
      console.error('Error fetching upcoming appointments:', e)
    }
  }

  const fetchEncounterForAppointments = async (appointmentIds: string) => {
    try {
      const intent = 'getEncounterForAppointments'
      const state = { appointmentIds }
      const resp = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
      setEncounterDetails(resp?.data)
    } catch (e) {
      console.error('Error fetching encounter details:', e)
    }
  }

  useEffect(() => {
    if (appointmentIds?.length > 0) {
      const appointmentIdsString = appointmentIds?.join(',')
      fetchEncounterForAppointments(appointmentIdsString)
        .then((resp) => console.log('Encounter details response:', resp))
        .catch((error) =>
          console.error('Error fetching encounter details:', error)
        )
    }
  }, [appointmentIds])

  const fetchData = useCallback(async () => {
    try {
      const patientIntent = 'getPatientApi'
      const state = { id: fhirId }
      const res1 = await startSxpProxy(OPD_PROJECT_ID, patientIntent, state)
      setPatientData(res1?.data)
      await fetchAllAppointments(fhirId)
      const visitsIntent = 'getPatientVisits'
      await startSxpProxy(OPD_PROJECT_ID, visitsIntent, state)
    } catch (e) {
      console.error('Error fetching data:', e)
    }
  }, [fhirId])
  useEffect(() => {
    fetchData()
  }, [fhirId, fetchData])

  const [selectedAppointments, setSelectedAppointment] = useState<
    string | null
  >(null)

  const handleCheckboxChange = (appointmentId: string) => {
    setSelectedAppointment(appointmentId)
  }

  return (
    <div className='opdLayoutContent'>
      <div className='opdpatientdata op-visit-dashboard-height'>
        <span>OP&nbsp;Visit&nbsp;Dashboard</span>
        <OPDSearch onCreateVisit={handleOpenDialog} thopId={patientThopId} />
      </div>
      <div className='opd-patient-list-container'>
        <OPDPatientList patients={formatted} isOpenDocsPage={false} />
      </div>
      <div className='op-visit-patient-content'>
        <OPDPatientTable patients={formatted} isOpenDocsPage={false} />
      </div>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth='md'>
        <div onClick={() => handleCloseDialog()} className='close-button'>
          X
        </div>
        <div className='modal-content'>
          <div
            style={{
              fontSize: '15px',
              marginBottom: '-0.4rem',
              paddingLeft: '0.5rem',
              paddingRight: '0.5rem',
            }}
          >
            Available Appointments
          </div>
          <p id='create-visit-modal-description'>
            <div
              style={{
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
              }}
            >
              <div className='appointments-table-container'>
                <table
                  className='data-table admin-table'
                  style={{ position: 'relative' }}
                >
                  <thead style={{ position: 'sticky', top: '0px', zIndex: 9 }}>
                    <tr>
                      <th style={{ width: '3rem' }}>Sl&nbsp;No</th>
                      <th className='text-left table-w-20'> ID</th>
                      <th className='text-left table-w-20'> Name</th>
                      <th className='text-left table-w-20'>Service</th>
                      <th className='text-left table-w-20'>Doctor Name</th>
                      <th className='text-left table-w-20'>Date</th>
                      <th className='text-left table-w-20'>Time Slot</th>
                      <th style={{ width: '6rem' }}>Create Visit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {upcomingAppointments
                      ?.filter((appointment) => {
                        const hasEncounter =
                          encounterDetails &&
                          Array.isArray(encounterDetails) &&
                          encounterDetails?.some(
                            (encounter) =>
                              encounter?.appointmentId ===
                              appointment?.appointmentId
                          )
                        return !hasEncounter
                      })
                      ?.map((appointment, index) => (
                        <tr key={appointment?.appointmentId}>
                          <td className='text-center'>{index + 1}</td>
                          <td className='text-center'>
                            {patientData?.identifier?.[2]?.value}
                          </td>
                          <td>
                            {patientData?.name[0]?.given?.[0]}&nbsp;
                            {patientData?.name[0]?.family}
                          </td>
                          <td>{appointment?.service}</td>
                          <td>{appointment?.docName}</td>
                          <td>{appointment?.date}</td>
                          <td className='text-center'>{appointment?.time}</td>
                          <td className='text-center'>
                            <Checkbox
                              onChange={() =>
                                handleCheckboxChange(appointment?.appointmentId)
                              }
                              checked={
                                selectedAppointments ===
                                appointment?.appointmentId
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    {upcomingAppointments.length === 0 && (
                      <tr>
                        <td colSpan={6} style={{ textAlign: 'center' }}>
                          No Visits
                        </td>
                      </tr>
                    )}
                    {upcomingAppointments.length > 0 &&
                      upcomingAppointments?.every((appointment) => {
                        const hasEncounter =
                          encounterDetails &&
                          Array?.isArray(encounterDetails) &&
                          encounterDetails?.some(
                            (encounter) =>
                              encounter?.appointmentId ===
                              appointment?.appointmentId
                          )
                        return hasEncounter
                      }) && (
                        <tr>
                          <td colSpan={6} style={{ textAlign: 'center' }}>
                            No Visits
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
              {selectedAppointments && (
                <OPDVisitCreateDialog
                  key={selectedAppointments}
                  patientId={fhirId}
                  appointmentId={selectedAppointments}
                  // onRefresh={handleRefresh}
                  onClose={handleCloseDialog}
                />
              )}
            </div>
          </p>
        </div>
      </Dialog>
    </div>
  )
}

export default OPDPatients
