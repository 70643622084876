import * as React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { startSxpProxy } from '../../../../utils/api'
import { ipVisitApi } from '../../models'
import { IPD_PROJECT_ID, LABS_PROJECT_ID } from '../../../../utils/constants'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import KeycloakService from '../../../../utils/keycloakService'
import { VALIDATION_WRITE } from '../../../../utils/roles'
import { formatInvoiceKeyData } from '../../../lms/utils'
import { InvoiceData } from '../../../lms/models'
import { dateDifference, formatReadableDate } from './Constants'
import { LabOrderProps, Order, Patient } from './Interfaces'
import { GetLabDepartments } from '../../../lms/components/search/GetLabDepartments'
import { useInvoicePDFGenerator } from '../../../lms/components/dashboard/InvoicePdfLib'

function Row({ row, patient, index, labDepartments }: LabOrderProps) {
  const [open, setOpen] = React.useState(false)
  const { generatePDF } = useInvoicePDFGenerator()
  const handleInvoicePrint = (item: Order, patient: Patient) => {
    if (!KeycloakService.hasRole([VALIDATION_WRITE])) {
      return
    }
    const intent = 'getLabTestsByIds'
    const panelIds =
      item?.panels?.flatMap((ip: any) =>
        ip?.lab_tests?.map((ipl: any) => ipl?.id)
      ) ?? []
    const testIds = item?.lab_tests?.map((il: any) => il.id) ?? []
    const state = {
      orderId: item?.id,
      ids: [...panelIds, ...testIds],
    }

    startSxpProxy(LABS_PROJECT_ID, intent, state)
      .then(async (data) => {
        const panel = formatInvoiceKeyData(
          data?.data?.lab_test ?? [],
          item?.status ?? '',
          labDepartments
        )

        const obj: InvoiceData = {
          id: patient?.muhid ?? '',
          name: patient?.patientName || '',
          age: patient?.dob || '',
          gender: patient?.gender || '',
          mobile: patient?.mobileNumber ?? '',
          lrNumber: item.lr_number || '',
          orderedOn: item.ordered_on ?? '',
          completedOn: item.collection_completed_on ?? '',
          requestedBy: item.requested_by ?? item.ordered_by_name ?? '',
          validatedOn: item.validation_completed_on ?? '',
          panels: panel,
          summary: item?.summary ?? '',
          orderType: item?.origin,
          fileId: item?.document_id,
        }
        const pdfBlob = await generatePDF(obj)
        const fileUrl = URL.createObjectURL(pdfBlob)

        const iframe = document.createElement('iframe')
        iframe.src = fileUrl
        iframe.width = '100%'
        iframe.height = '100%'
        iframe.style.border = 'none'

        const newWindow = window.open('', '_blank') as Window
        newWindow.document.body.style.margin = '0'
        newWindow.document.body.appendChild(iframe)
        newWindow.document.title = 'Test Results'
      })
      .catch((err) => {
        console.log('Error:', err)
      })
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align='center'>{index + 1}</TableCell>
        <TableCell component='th' scope='row'>
          {row?.lr_number}
        </TableCell>
        <TableCell align='left'>{row?.requested_by}</TableCell>
        <TableCell align='left'>{row?.status}</TableCell>
        <TableCell align='center'>
          {dateDifference(row?.ordered_on, row?.validation_completed_on)}
        </TableCell>
        <TableCell align='left'>
          <IconButton
            onClick={() => handleInvoicePrint(row, patient)}
            disabled={row?.status != 'COMPLETED' ? true : false}
          >
            <ArticleOutlinedIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell className='table-lab-visit-ipd-order' colSpan={7}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography
                  variant='h6'
                  gutterBottom
                  component='div'
                  fontSize={'14px'}
                  fontWeight={600}
                >
                  Tests
                </Typography>
                <Table
                  size='small'
                  aria-label='purchases'
                  sx={{ border: '1px solid #ddd' }}
                >
                  <TableHead className='table-head-lab-visit'>
                    <TableRow>
                      <TableCell className='table-head-th-lab-visit'>
                        Test
                      </TableCell>
                      <TableCell
                        className='table-head-th-lab-visit'
                        align='left'
                      >
                        Doctor
                      </TableCell>
                      <TableCell
                        className='table-head-th-lab-visit'
                        align='left'
                      >
                        Date & Time
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.panels.map((panel: any) => (
                      <TableRow key={panel?.name}>
                        <TableCell component='th' scope='row'>
                          {panel?.name}
                        </TableCell>
                        <TableCell align='left'>{row?.requested_by}</TableCell>
                        <TableCell align='left'>
                          {formatReadableDate(row?.ordered_on)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

interface LabProps {
  patientRegisteredId: string
}

export default function IpdLabOrderSummary(props: LabProps) {
  const { patientRegisteredId } = props

  const [labOrders, setLabOrders] = React.useState<Order[]>([])
  const [patient, setPatient] = React.useState<Patient>({})
  const labDepartments = GetLabDepartments()
  const fetchAllLabOrders = async (patientRegisteredId: string) => {
    const intent = ipVisitApi.getAllPatientLabOrdersIpd
    const state = { patId: patientRegisteredId }
    await startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        if (data.data.orders?.length > 0) {
          setLabOrders(data?.data?.orders)
          setPatient(data?.data?.patientDetils)
        }
      })
      .catch((err) => {
        console.error(err, 'err')
      })
  }

  React.useEffect(() => {
    fetchAllLabOrders(patientRegisteredId)
  }, [patientRegisteredId])

  return (
    <Box mt={8}>
      <TableContainer component={Paper} className='table-lab-visit-ipd-mui'>
        <Table aria-label='collapsible table' size='small'>
          <TableHead className='table-head-lab-visit'>
            <TableRow>
              <TableCell />
              <TableCell className='table-head-th-lab-visit' align='center'>
                Sl No
              </TableCell>
              <TableCell className='table-head-th-lab-visit'>Lab Id</TableCell>
              <TableCell className='table-head-th-lab-visit' align='left'>
                Requested By
              </TableCell>
              <TableCell className='table-head-th-lab-visit' align='left'>
                Status
              </TableCell>
              <TableCell className='table-head-th-lab-visit' align='center'>
                TAT
              </TableCell>
              <TableCell className='table-head-th-lab-visit' align='left'>
                View
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {labOrders?.map((row: any, index: number) => (
              <Row
                key={row?.lr_number}
                row={row}
                patient={patient}
                index={index}
                labDepartments={labDepartments}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
