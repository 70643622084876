import { useRef, useEffect } from 'react'
import { FormattedPatient } from '../patients/Patients'
import { useLocation } from 'react-router-dom'
import OPDVisits from './OPDVisit'
import { opdRoute } from './constant'
type Props = {
  patients: FormattedPatient[]
}

const OPDPatientTable = ({ patients }: Props & { isOpenDocsPage: boolean }) => {
  const patientTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  const location = useLocation()
  const isOpdPath = location.pathname === opdRoute
  const patientId = patients?.[0]?.fhirId

  return (
    <>
      <div className='dialog-container'>
        {isOpdPath && patients?.length === 1 && <OPDVisits id={patientId} />}
      </div>
    </>
  )
}

export default OPDPatientTable
