import { toast } from 'react-toastify'
import { ChangeEvent, useEffect, useState } from 'react'
import { uploadImage } from '../utils/api'
import {
  FILE_SERVER_URL,
  linkCopyText,
  uploadImageText,
  upload,
  chooseImage,
} from '../utils/constants'
import './form.scss'
import '../features/lms/lms.scss'

const InterpretationImage = ({ imageURL, setImageURL }: any) => {
  const [files, setFiles] = useState<FileList | null>(null)
  const [image, setImage] = useState<string>('')
  const [uploading, setUploading] = useState<boolean>(false)
  const [copySuccess, setCopySuccess] = useState<boolean>(false)

  useEffect(() => {
    if (!files?.length) {
      setImage('')
      return
    }
    const url = URL.createObjectURL(files[0])
    setImage(url)

    return () => URL.revokeObjectURL(url)
  }, [files])

  const confirm = async (e: any) => {
    e.preventDefault()
    if (files?.length) {
      const formData = new FormData()
      formData.append('file', files[0], 'profile.jpeg')
      setUploading(true)
      try {
        const data = await uploadImage(formData)
        const uploaded = data?.data?.[0]
        setImageURL(FILE_SERVER_URL + 'file/' + uploaded)
      } catch (err) {
        console.error('Failed to store captured image, Reason:', err)
        toast.error('Failed to upload image. Please try again')
      } finally {
        setUploading(false)
      }
    }
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files) {
      for (const file of files) {
        if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file?.type)) {
          setFiles(null)
          toast.error('Invalid file type. Please upload PNG or JPEG images.')
          return
        }
      }
    }
    setFiles(files)
  }

  const copyURL = () => {
    navigator.clipboard.writeText(imageURL)
    setCopySuccess(true)
    setTimeout(() => setCopySuccess(false), 2000)
  }

  return (
    <div className='interpretation-container'>
      <div className='collection-content'>
        {!image ? (
          <label className='custom-file-upload'>
            <input
              type='file'
              accept='.png, .jpg, .jpeg'
              onChange={handleFileChange}
            />
            {chooseImage}
          </label>
        ) : null}
        {files && <p className='file-name'>{files?.[0]?.name}</p>}
        {image ? (
          <div>
            <button
              disabled={!image || uploading}
              onClick={(e) => confirm(e)}
              className={`upload-button ${uploading ? 'disabled' : ''}`}
            >
              {upload}
            </button>
          </div>
        ) : null}
      </div>
      <div className='trim-container'>
        {imageURL ? (
          <>
            <span onClick={copyURL} className='copy-link'>
              {imageURL}
            </span>
            {!copySuccess && (
              <span className='copy-success'>{linkCopyText}</span>
            )}
          </>
        ) : (
          <>{uploadImageText}</>
        )}
      </div>
    </div>
  )
}

export default InterpretationImage
