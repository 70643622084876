import React from 'react'
import { useLocation } from 'react-router-dom'
import './App.scss'
import Layout from './components/Layout'
import AppRoutes from './components/AppRoutes'
import SxpChat from './components/SXPChat'
import KeycloakService from './utils/keycloakService'
import { SXP_CHAT } from './utils/roles'
import { allowedSxpRoutes } from './utils/constants'

function App() {
  const location = useLocation()

  return (
    <>
      <Layout>
        <AppRoutes />
      </Layout>
      {KeycloakService.hasRole([SXP_CHAT]) &&
      allowedSxpRoutes?.includes(location.pathname) ? (
        <SxpChat key={location.pathname} />
      ) : null}
    </>
  )
}

export default App
