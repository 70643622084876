export type PartnerModel = {
  phone: string
  id: number
  name: string
  ref: string | boolean
}
export type Name = {
  given: string[]
}

export type MembershipModel = {
  credits: any
  account_invoice_id: any
  account_invoice_line: any
  state: string
  id: string
  name: string
  membership_id: string[]
  patientId: string
  date_to: string
  date_from: string
  create_uid: string[]
  partner: PartnerModel
  partner_ids: PartnerModel
  invoice_date: string
  write_date: string
  thop_user_name: string
  thop_last_updated_user_name: string
  membership_partner_ids: any
  cognitivecare_invoice_id?: any
}
export type ABMembershipModel = {
  credits: any
  account_invoice_id: any
  account_invoice_line: any
  state: string
  id: string
  name: string
  membership_id: string[]
  patientId: string
  date_to: string
  date_from: string
  create_uid: string[]
  partner: PartnerModel
  partner_ids: PartnerModel
  invoice_date: string
  write_date: string
  thop_user_name: string
  thop_last_updated_user_name: string
  membership_partner_ids: any
  relation: any
}
export type PackageModel = {
  invoice: any
  id: string
  name: string
  state: string
  payment_state: string
  partner_id: PartnerModel
  partner_ids: PartnerModel
  invoice_date_time: string
  invoice_line_ids: PackageModellist[]
  thop_user_name: string
  operating_unit_id: any[]
}

export type PackageModellist = {
  name: string
  id: number
  price: number
}

export type Member = {
  uhid: any
  mobile: any
  memberDate: any
  id: any
  names: any
  age: any
  date: any
  primary: boolean
}
export type ABMember = {
  uhid: any
  mobile: any
  memberDate: any
  id: any
  names: any
  age: any
  date: any
  primary: boolean
  relation_with_primary_partner: string
}
export type ABMemberPatient = {
  relation_with_primary_partner?: any
  relation?: any
  isSelected?: boolean
  isNominee?: boolean
  id: string
  name: string
  dob: string
  gender: 'Male' | 'Female' | 'Others'
  uhid: string
  thopId: string
  registration: string
}
export type MemberPatient = {
  isSelected?: boolean
  isNominee?: boolean
  id: string
  name: string
  dob: string
  gender: 'Male' | 'Female' | 'Others'
  uhid: string
  thopId: string
  registration: string
}
export type MemberABPatient = {
  isSelected?: boolean
  isNominee?: boolean
  id: string
  name: string
  dob: string
  gender: 'Male' | 'Female' | 'Others'
  uhid: string
  thopId: string
  registration: string
  adultChildCount: any
}

export type PackagePatient = {
  isSelected?: boolean
  isNominee?: boolean
  id: string
  name: string
  dob: string
  gender: 'Male' | 'Female' | 'Others'
  uhid: string
  thopId: string
  registration: string
}

export type SearchPatient = {
  isSelected: boolean | undefined
  isNominee: boolean | undefined
  id: string
  name: string
  dob: string
  gender: 'Male' | 'Female' | 'Others'
  uhid: string
  thopId: string
  registration: string
}

export type MembershipType = {
  min_age_limit?: any
  id: number
  list_price: number
  name: string
  description_sale: any
  cognitivecare_odoo_product_id?: any
}
export type PaginateModel = {
  totalCount: any
  totalPages: any
  data: any
}
export const membershipTableHeadings = {
  slno: 'Sl No',
  MembershipId: 'Membership ID',
  uhid: 'UHID',
  primary: 'Primary',
  mobileNo: 'Mobile No.',
  operatingUnit: 'Operating Unit',
  membership: 'Membership',
  memType: 'Mem.Type',
  validity: 'Validity',
  memStatus: 'Mem.Status',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  payStatus: 'Pay.Status',
  edit: 'Edit',
  ABId: 'Ayuvu Bharosa ID',
  invoice: 'Invoice',
}
export const itemsArray: {
  innerKey: string
  quantity: any
  allowedCount: any
  discountPrice: any
  category: any
  categoryId: any
}[] = []
export const filterMembershipData = 'regular'
export const invoiceState = ['invoiced', 'waiting', 'paid', 'old']
export const unPaid = 'Unpaid'
export const paid = 'Paid'
export const statusPending = 'status-pending'
export const statusActive = 'status-active'
