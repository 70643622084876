import { Patient } from 'fhir/r4'
import { RadiologyOrderEvent, Organization } from './Constants/constants'

export type RadiologyOrders = {
  id?: string
  visit_id?: number
  panels?: any[]
  lab_tests?: any[]
  lab_results?: any[]
  lr_number?: string
  patient_id?: string
  patient_uhid?: string
  patientName?: string
  patientUhid?: string
  patientId?: string
  ordered_on?: string
  ordered_by_id?: string
  ordered_by_name?: string
  payment_status?: string
  payment_completed_on?: string
  collection_completed_on?: string
  validation_completed_on?: string
  validated_by_id?: string
  validated_by_name?: string
  status?: string
  source?: string
  document_id?: string
  dms_retry_upload?: boolean
  price?: number
  package_id?: number | null
  summary?: string
  order_events?: RadiologyOrderEvent[]
  requested_by?: string
  origin?: string
  radiology_events?: any
  radiology_lab_tests?: any
  authorised_by_id?: string
  authorised_by_name?: string
  samples?: any
  radiology_order_tests?: any[]
  radiology_test_status?: any[]
  radiology_test_results?: any[]
  radiology_report_handovers?: []
  patientCommunication?: string
}
export type RadiologyResult = {
  id: number
  patient_id: string
  radiology_order_id: number
  radiology_order: RadiologyOrders
  values?: any
  test_id: number
  sample_id: number
  sample_name: string
  value?: any
  test_name: string
  patient_uhid?: string
  extra_value: string
  referred_out: boolean | null
  referred_to: number | null
  organization: Organization
  ordered_by: string
  ordered_by_id: string
  ordered_on: string
  validated_by_id: string
  results_entered_by: string
  results_entered_by_id: string
  results_entered_on: string
  test_status: string
  observation: string | null
  document_url: string
  rejected_on?: string
  rejected_by_id?: string
  rejected_by?: string
  rejection_reason?: string
  validated_by?: string
  validated_on?: string
  summary_reason?: string
  authorised_by_id: string
  authorised_by_name?: string
}
export type RefRadiologyResult = RadiologyResult & {
  patient: Patient
}
export type RadiologyRow = {
  mode: { label: string; value: string } | null
  requestedBy: any
  sampleName: { label: string; value: number } | null
  testName: { label: string; value: number } | null
  origin: { label: string; value: string } | null
  packageName: { label: string; value: number } | null
  packageTests: {
    packageId: number
    packageName: string
    price: number
    testCount: number
    radiology_lab_tests: RadiologyLabTest[]
  } | null
}
export type PackageRow = {
  mode: { label: string; value: string } | null
  requestedBy: { label: string; value: number } | null
  sampleName: { label: string; value: number } | null
  testName: { label: string; value: number } | null
  origin: { label: string; value: string } | null
}
export type RadiologyMode = '' | 'package' | 'test'
export const radiologyIntent = {
  getAllRadiologyPackages: 'getAllRadiologyPackages',
  getAllRadiologyTestTemplates: 'getAllRadiologyTestTemplates',
  getRadiologyTestTemplatesById: 'getRadiologyTestTemplatesById',
  deleteRadiologyTestTemplateById: 'deleteRadiologyTestTemplateById',
  updateRadiologyTestTemplate: 'updateRadiologyTestTemplate',
  createRadiologyTestTemplate: 'createRadiologyTestTemplate',
  getRadiologyOrderById: 'getRadiologyOrderById',
  getPatientApi: 'getPatientApi',
  getRadiologyOrdersByDispatch: 'getRadiologyOrdersByDispatch',
  getRadiologyOrdersByValidation: 'getRadiologyOrdersByValidation',
  updateRadiologyResults: 'updateRadiologyResults',
}
export const apiMessages = {
  radiologyResultsSaved: 'Radiology Results Saved',
  registrationType: 'registrationType',
}
export const dialogMessages = {
  replaceDocument: 'Replace Document',
  testsToBeRemoved: 'Tests To Be Removed',
  backToDashboard: 'Back To Dashboard',
  goToDashboard: 'Do You Want To Go Back To Dashboard',
  documentUploaded:
    'A document is already uploaded. Do you want to replace it with a new one?',
  testToBeRemoved:
    'The Following Selected Tests To Be Removed If You Click On Yes.How Do You Want To Proceed?',
  referredTestSaved:
    'Test is Referred out and values are saved.Ready for Submit',
  noReferredValues: 'Test is Referred out .But values are not entered',
}
export const testStatus = {
  entered: 'ENTERED',
}
export type selectedTest = {
  label: string
  value: number
}
export type selectedPackage = {
  label: string
  value: number
}
export type RadiologyLabTest = {
  testId: string | number
  sampleId: number
  testName: string
  sampleName: string
  test_status?: string
}

// Interface for a radiology package
export type RadiologyPackage = {
  packageId: number
  packageName: string
  price: number
  radiology_lab_tests: RadiologyLabTest[]
  created_by: string
  created_at: string
  active_from: string
  status: string
  updated_at: string
  updated_by: string
  active_till: string
}

export type RadiologyPackageTest = {
  testId: string
  sampleId: number
  testName: string
  sampleName: string
}

export type RadiologyPackageOrder = {
  id: number
  name: string
  price: number
  radiology_lab_tests: RadiologyPackageTest[]
  created_by: string
  created_at: string
  active_from: string
  status: string
  updated_at: string
  updated_by: string
  active_till: string
}
export type radiologyOrganization = {
  id: number
  isCustomerRelate: boolean
  isLab: boolean
  isRadiology: boolean
  isVendorRelate: boolean
  name: string
  status: string
  updated_by: string
  odoo_partner_id: number
}
export type RadiologyObservation = {
  id: string
  title: string
  content: string
}
export type radiologyValues = {
  summary?: string
  observations: RadiologyObservation[]
}
export type radiologyDepartments = {
  id: string
  name: string
  siganture: string
  specialty: string
  qualification: string
  code: string
}
export type radiologists = {
  testId: number
  radiologist: string
}
export type Reason = {
  reason: string
  testId: number
}
