import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import {
  selectedLmsItem,
  selectedLmsSelectedValue,
  selectedLmsStatus,
  setLmsFormMode,
  setLmsItemId,
} from '../../lmsSlice'
import { startSxpProxy } from '../../../../utils/api'
import SampleTable from './SampleTable'
import PanelTable from './PanelTable'
import LabTestTable from './LabTestTable'
import PackagesTable from './PackagesTable'
import SequencesConfig from './SequencesConfig'
import DepartmentTable from './DepartmentTable'
import UnitTypeTable from './UnitTypeTable'
import MethodTable from './MethodTable'
import RenderOnRole from '../../../../components/RenderOnRole'
import { LMS_ADMIN_WRITE } from '../../../../utils/roles'
import ResultTypeTable from './ResultTypeTable'
import RadiologySampleTable from '../../../Radiology/RadiologyCategory'
import RadiologyTest from '../../../Radiology/RadiologyTest'
import Wards from './Wards'
import Rooms from './Rooms'
import Beds from './Beds'
import RadiologyPackages from '../../../Radiology/RadiologyPackages'
import TestRangeTable from './TestRangeTable'
import { API_INTENTS, ITEM_TYPES, LOADER_TEXT, NO_DATA } from './constants'
import ReportTemplatesTable from './ReportTemplatesTable'
import RadiologyReportTemplatesTable from '../../../Radiology/RadiologyReportTemplateTable'

type Props = {
  projectId: string | undefined
}

const AdminTable = ({ projectId }: Props) => {
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [updateCount, setUpdateCount] = useState(0)
  const item = useAppSelector(selectedLmsItem)
  const status = useAppSelector(selectedLmsStatus)
  const selectedValue = useAppSelector(selectedLmsSelectedValue)
  const dispatch = useAppDispatch()

  const handleEdit = (id: string) => {
    dispatch(setLmsItemId(id))
    dispatch(setLmsFormMode('edit'))
  }

  const fetchData = async () => {
    if (item && item !== ITEM_TYPES?.SEQUENCE) {
      setLoading(true)
      // let intent: string;
      const state = {}

      // Map item types to their corresponding API intents
      const intentMap: Record<string, string> = {
        [ITEM_TYPES?.SAMPLE]: API_INTENTS?.SAMPLE,
        [ITEM_TYPES?.PANEL]: API_INTENTS?.PANEL,
        [ITEM_TYPES?.LAB_TEST]: API_INTENTS?.LAB_TEST,
        [ITEM_TYPES?.DEPARTMENT]: API_INTENTS?.DEPARTMENT,
        [ITEM_TYPES?.UNIT_TYPE]: API_INTENTS?.UNIT_TYPE,
        [ITEM_TYPES?.RESULT_TYPE]: API_INTENTS?.RESULT_TYPE,
        [ITEM_TYPES?.METHOD]: API_INTENTS?.METHOD,
        [ITEM_TYPES?.TEST_RANGE]: API_INTENTS?.TEST_RANGE,
        [ITEM_TYPES?.PACKAGES]: API_INTENTS?.PACKAGES,
        [ITEM_TYPES?.RADIOLOGY_CATEGORY]: API_INTENTS?.RADIOLOGY_CATEGORY,
        [ITEM_TYPES?.RADIOLOGY_TEST]: API_INTENTS?.RADIOLOGY_TEST,
        [ITEM_TYPES?.RADIOLOGY_PACKAGES]: API_INTENTS?.RADIOLOGY_PACKAGES,
        [ITEM_TYPES?.WARD]: API_INTENTS?.WARD,
        [ITEM_TYPES?.RADIOLOGYREPORTTEAMPLATES]:
          API_INTENTS?.RADIOLOGYTEMPLATES,
        [ITEM_TYPES?.ROOMS]: API_INTENTS?.ROOMS,
        [ITEM_TYPES?.BEDS]: API_INTENTS?.BEDS,
        [ITEM_TYPES?.REPORTTEMPLATES]: API_INTENTS?.TEST_TEMPLATES,
      }

      const intent = intentMap[item] || ''

      try {
        const response = await startSxpProxy(projectId, intent, state)
        setData(response?.data || {})
        setUpdateCount((prev) => prev + 1)
      } catch (error) {
        setData(null)
      } finally {
        setLoading(false) // Stop loading
      }
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, selectedValue, status])

  const renderContent = () => {
    if (loading) return <p>{LOADER_TEXT}</p>

    if (!data && item !== ITEM_TYPES?.SEQUENCE) return <p>{NO_DATA}</p>

    const contentMap = {
      [ITEM_TYPES?.SAMPLE]: (
        <SampleTable data={data?.sample} onEdit={handleEdit} />
      ),
      [ITEM_TYPES?.PANEL]: (
        <PanelTable data={data?.panel} onEdit={handleEdit} />
      ),
      [ITEM_TYPES?.LAB_TEST]: (
        <LabTestTable data={data?.lab_test} onEdit={handleEdit} />
      ),
      [ITEM_TYPES?.DEPARTMENT]: (
        <DepartmentTable data={data?.department} onEdit={handleEdit} />
      ),
      [ITEM_TYPES?.UNIT_TYPE]: (
        <UnitTypeTable data={data?.test_unit_type} onEdit={handleEdit} />
      ),
      [ITEM_TYPES?.RESULT_TYPE]: (
        <ResultTypeTable data={data?.test_result_type} onEdit={handleEdit} />
      ),
      [ITEM_TYPES?.METHOD]: (
        <MethodTable data={data?.test_method} onEdit={handleEdit} />
      ),
      [ITEM_TYPES?.TEST_RANGE]: (
        <TestRangeTable data={data?.test_result_limits} onEdit={handleEdit} />
      ),
      [ITEM_TYPES?.PACKAGES]: (
        <PackagesTable data={data?.packages} onEdit={handleEdit} />
      ),
      [ITEM_TYPES?.RADIOLOGY_CATEGORY]: (
        <RadiologySampleTable
          data={data?.radiology_sample}
          onEdit={handleEdit}
        />
      ),
      [ITEM_TYPES?.RADIOLOGY_TEST]: (
        <RadiologyTest data={data?.radiology_lab_test} onEdit={handleEdit} />
      ),
      [ITEM_TYPES?.RADIOLOGY_PACKAGES]: (
        <RadiologyPackages
          data={data?.radiology_packages}
          onEdit={handleEdit}
        />
      ),
      [ITEM_TYPES?.WARD]: (
        <Wards data={data?.ward_details} onEdit={handleEdit} />
      ),
      [ITEM_TYPES?.ROOMS]: (
        <Rooms data={data?.room_details} onEdit={handleEdit} />
      ),
      [ITEM_TYPES?.BEDS]: <Beds data={data?.bed} onEdit={handleEdit} />,
      [ITEM_TYPES?.REPORTTEMPLATES]: (
        <ReportTemplatesTable data={data?.test_templates} onEdit={handleEdit} />
      ),
      [ITEM_TYPES?.RADIOLOGYREPORTTEAMPLATES]: (
        <RadiologyReportTemplatesTable
          data={data?.radiology_test_template}
          onEdit={handleEdit}
        />
      ),
      [ITEM_TYPES?.SEQUENCE]: (
        <RenderOnRole roles={[LMS_ADMIN_WRITE]} element={<SequencesConfig />} />
      ),
    }

    return contentMap[item] || <p>{NO_DATA}</p>
  }

  return <React.Fragment key={updateCount}>{renderContent()}</React.Fragment>
}

export default AdminTable
