import React, { Fragment } from 'react'
import { ElementProps } from '../models'
import FieldLabel from './FieldLabel'
import ErrorMessage from './ErrorMessage'

const RadioField = ({
  component,
  state,
  handleChange,
  errorMessage,
}: ElementProps) => {
  return (
    <div className='fieldSet'>
      <FieldLabel label={component?.label} required={component?.required} />

      <div className='radio-container'>
        <div className='radio-grid'>
          {component?.options?.map((d: any) => (
            <Fragment key={d?.value}>
              <input
                type='radio'
                value={state[component?.reference]}
                checked={state[component?.reference] === d?.value}
                onChange={() => handleChange(d?.value, component)}
                name={component?.reference}
                id={`${component?.reference}${d?.label}`}
              />
              <label htmlFor={`${component?.reference}${d?.label}`}>
                {d?.label}
              </label>
            </Fragment>
          ))}
        </div>
        <ErrorMessage errorMessage={errorMessage} />
      </div>
    </div>
  )
}

export default RadioField
