/* eslint-disable camelcase */
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSingleLabOrder } from '../useSingleLabOrder'
import { CLabel, LabResult, LmsPanel, toastOptions } from '../../models'
import KeycloakService from '../../../../utils/keycloakService'
import { getAgeInYears, readableDateFormat } from '../../../../utils/dateUtils'
import { useAppDispatch } from '../../../../app/hooks'
import { setLmsActiveTab, setLmsDateFilter } from '../../lmsSlice'
import LabelLogo from '../../../../assets/images/sampleLabelPrint.png'
import { toast } from 'react-toastify'
import { COLLECTION_WRITE, TESTING_WRITE } from '../../../../utils/roles'
import {
  LabOrder,
  LabTest,
  OrderEvent,
  OrderEventTest,
  Panel,
} from '../../../labTests/models'
import { startSxpProxy } from '../../../../utils/api'
import { CHAT_PROJECT_ID, LABS_PROJECT_ID } from '../../../../utils/constants'
import ToastMessage from '../ToastMessage'
import { getSelectedTestSet, makeName } from '../../utils'
import LoadingButton from '../../../../components/LoadingButton'
import PrintLabel from '../dashboard/PrintLabel'
import { useReactToPrint } from 'react-to-print'
import LocationByPrefix from '../../../patients/dashboard/LocationByPrefix'
import { sendSocketData } from '../../../Notification/Socket'
import { NEW_IPD_DATA_UPDATE } from '../../../Notification/Constants'

const CollectOrder = () => {
  const { id } = useParams()
  const [reload, setReload] = useState<number>(1)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { order, patient } = useSingleLabOrder(id ?? '', reload)
  const lastEvent = order?.order_events?.[0]
  const [checked, setChecked] = useState<number[]>([])
  const [checkAll, setCheckAll] = useState(false)
  const [checkRejected, setCheckRejected] = useState<number[]>([])
  const [checkRejectAll, setCheckRejectAll] = useState(false)
  const selectAllRef = useRef<HTMLInputElement | null>(null)
  const rejectAllRef = useRef<HTMLInputElement | null>(null)
  const [comments, setComments] = useState('')
  const [apiLoading, setApiLoading] = useState(false)
  const labelRef = useRef(null)
  const uhid = patient?.identifier?.find(
    (e: any) => e.system === 'patient-identifier'
  )?.value
  const labelPageStyle = `
    @page {
      size: 50mm 25mm;
    }
    @media print {
      @page {  
        size: 50mm 25mm landscape;
        margin: 0mm !important;
      }
    }
  `
  const handleLabelPrint = useReactToPrint({
    content: () => labelRef.current,
    pageStyle: labelPageStyle,
  })

  useEffect(() => {
    const unsubscribe = toast.onChange(({ data, status }) => {
      if (status === 'removed' && (data as any).completed) {
        dispatch(setLmsActiveTab('testing'))
        navigate('/lms/dashboard')
      }
    })
    return () => unsubscribe()
  }, [dispatch, navigate])

  useEffect(() => {
    if (selectAllRef.current) {
      selectAllRef.current.indeterminate = checkAll && checked.length > 0
    }
    if (rejectAllRef.current) {
      rejectAllRef.current.indeterminate =
        checkRejectAll && checkRejected.length > 0
    }
  }, [checkAll, checked, checkRejectAll, checkRejected])

  const handleCheck = (id?: number) => {
    if (id) {
      const available = checked.find((ch) => ch === id)
      if (available) {
        setChecked((ch) => ch.filter((ch) => ch !== id))
      } else {
        setChecked((ch) => [...ch, id])
      }
    }
  }

  const handleRejectCheck = (id?: number) => {
    if (id) {
      const available = checkRejected.find((ch) => ch === id)
      if (available) {
        setCheckRejected((ch) => ch.filter((ch) => ch !== id))
      } else {
        setCheckRejected((ch) => [...ch, id])
      }
    }
  }

  const toggleCheckAll = () => {
    setCheckAll((c) => !c)
    setChecked([])
  }

  const toggleCheckRejectAll = () => {
    setCheckRejectAll((c) => !c)
    setCheckRejected([])
  }

  const handleCollect = () => {
    setApiLoading(true)
    const testSet = getSelectedTestSet(panels, checkAll, checked, true)
    const pastTests = lastEvent?.tests ?? []
    const current = new Date().toISOString()
    const user = KeycloakService.getUsername()
    const currentEventTests: OrderEventTest[] = pastTests.map((pt) => {
      if (testSet.has(pt.test_id)) {
        return {
          test_id: pt.test_id,
          status: 'COLLECTED',
          updatedBy: user,
          updatedOn: current,
        }
      }
      return pt
    })
    const stateObj: Partial<OrderEvent> = {
      updated_by: user,
      order_id: order?.id,
      type: isComplete ? 'COLLECTION_COMPLETED' : 'COLLECTION_IN_PROGRESS',
      tests: currentEventTests,
    }
    startSxpProxy(LABS_PROJECT_ID, 'createOrderEvent', {
      event: stateObj,
    }).then(() => {
      sendSocketData({
        messageType: NEW_IPD_DATA_UPDATE,
        patientID: uhid,
        patientLocation: '',
        message: '',
      })
      setChecked([])
      setCheckAll(false)
      setCheckRejected([])
      setCheckRejectAll(false)
      setReload((r) => r + 1)
      setApiLoading(false)
      toast(
        <ToastMessage
          message={
            isComplete ? 'Sample Collection Complete' : 'Samples Collected'
          }
        />,
        {
          ...toastOptions,
          type: 'success',
        }
      )
      if (isComplete) {
        setTimeout(() => {
          dispatch(setLmsDateFilter(new Date().toISOString().slice(0, 10)))
          dispatch(setLmsActiveTab('collection'))
          navigate('/lms/dashboard')
        }, 1000)
      }
    })
  }

  const handleApprove = () => {
    if (
      (checkRejectAll && checkRejected.length === collectedTests.length) ||
      (!checkRejectAll && checkRejected.length === 0)
    ) {
      alert('Please select samples to approve')
      return
    }
    setApiLoading(true)
    const testSet = getSelectedTestSet(
      collectedTests,
      checkRejectAll,
      checkRejected
    )
    const pastTests = lastEvent?.tests ?? []
    const current = new Date().toISOString()
    const user = KeycloakService.getUsername()
    const currentEventTests: OrderEventTest[] = pastTests.map((pt) => {
      if (testSet.has(pt.test_id)) {
        return {
          test_id: pt.test_id,
          status: 'COLLECTION_APPROVED',
          updatedBy: user,
          updatedOn: current,
        }
      }
      return pt
    })
    const stateObj: Partial<OrderEvent> = {
      updated_by: user,
      order_id: order?.id,
      type: 'COLLECTION_APPROVED',
      tests: currentEventTests,
      comments: comments ?? undefined,
    }
    startSxpProxy(LABS_PROJECT_ID, 'createOrderEvent', {
      event: stateObj,
    }).then(() => {
      const resultObj: Partial<LabResult> = {
        lab_order_id: id ? parseInt(id) : 0,
        patient_id: patient?.id,
      }
      const results = currentEventTests
        .filter(
          (pt) =>
            pt.status === 'COLLECTION_APPROVED' && pt.updatedOn === current
        )
        .map((pt) => ({
          ...resultObj,
          collected_on: pt.updatedOn,
          collected_by: pt.updatedBy,
          lab_test_id: pt.test_id,
        }))
      startSxpProxy(LABS_PROJECT_ID, 'createLabResult', {
        labResults: results,
      }).then(() => {
        startSxpProxy(CHAT_PROJECT_ID, 'generateGlobalId', {}).then((data) => {
          const intent = 'updateLabOrdersShort'
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { order_events, ...rest } = order as LabOrder
          const updated = {
            ...rest,
            collection_completed_on: new Date().toISOString(),
            status: 'COLLECTED',
            lr_number: rest.lr_number ?? data.data.id,
          }
          const state = {
            order: updated,
            labOrderId: id,
          }
          startSxpProxy(LABS_PROJECT_ID, intent, state).then(() => {
            sendSocketData({
              messageType: NEW_IPD_DATA_UPDATE,
              patientID: uhid,
              patientLocation: '',
              message: '',
            })
            setApiLoading(false)
            setReload((r) => r + 1)
            toast(
              <ToastMessage message='Lab Sample Collection Approved. Redirecting to Dashboard...' />,
              {
                position: 'bottom-center',
                hideProgressBar: true,
                closeButton: false,
                theme: 'dark',
                autoClose: 1500,
                data: {
                  completed: true,
                },
              }
            )
          })
        })
      })
    })
  }
  const formattedPatient = () => {
    const uhid = patient?.identifier?.find(
      (e: any) => e.system === 'patient-identifier'
    )?.value
    const name = makeName(patient?.name)
    const phoneNumber = patient?.telecom?.find(
      (e: any) => e.use === 'mobile'
    )?.value
    const operatingUnit = LocationByPrefix({
      id:
        patient?.identifier?.[2]?.value ??
        patient?.identifier?.[0]?.value ??
        '-',
    })

    const age = patient?.birthDate ? getAgeInYears(patient?.birthDate) : '-'

    return { uhid, name, phoneNumber, operatingUnit, age }
  }
  const handleReject = () => {
    if (
      (checkRejectAll && checkRejected.length === collectedTests.length) ||
      (!checkRejectAll && checkRejected.length === 0)
    ) {
      alert('Please select samples to reject')
      return
    }
    if (!comments.trim()) {
      alert('Comments cannot be empty while rejecting')
      return
    }
    setApiLoading(true)
    const testSet = getSelectedTestSet(
      collectedTests,
      checkRejectAll,
      checkRejected
    )
    const pastTests = lastEvent?.tests ?? []
    const current = new Date().toISOString()
    const user = KeycloakService.getUsername()
    const currentEventTests: OrderEventTest[] = pastTests.map((pt) => {
      if (testSet.has(pt.test_id)) {
        return {
          test_id: pt.test_id,
          status: 'COLLECTION_REJECTED',
          updatedBy: user,
          updatedOn: current,
        }
      }
      return pt
    })
    const stateObj: Partial<OrderEvent> = {
      updated_by: user,
      order_id: order?.id,
      type: 'COLLECTION_REJECTED',
      tests: currentEventTests,
      comments: comments,
    }
    startSxpProxy(LABS_PROJECT_ID, 'createOrderEvent', {
      event: stateObj,
    }).then(() => {
      sendSocketData({
        messageType: NEW_IPD_DATA_UPDATE,
        patientID: uhid,
        patientLocation: '',
        message: '',
      })
      setChecked([])
      setCheckAll(false)
      setCheckRejected([])
      setCheckRejectAll(false)
      setReload((r) => r + 1)
      setApiLoading(false)
      toast(<ToastMessage message='Samples Rejected' />, {
        ...toastOptions,
        type: 'warning',
      })
      setTimeout(() => {
        navigate('/lms/dashboard')
      }, 1000)
    })
  }

  const panels: LmsPanel[] = useMemo(() => {
    if (!order) {
      return []
    }
    const tests = order?.lab_tests ?? []
    const grouped: Record<number, LabTest[]> = tests.reduce(
      (acc: Record<number, LabTest[]>, cur) => {
        const pnlId = cur.panelId
        if (acc[pnlId]) {
          acc[pnlId].push(cur)
        } else {
          acc[pnlId] = [cur]
        }
        return acc
      },
      {}
    )
    const pnls = []
    for (const key in grouped) {
      const first = grouped[key][0]
      const pnl: Panel = {
        id: first.panelId,
        name: first.panelName,
        active: true,
        sampleId: first.sampleId,
        sampleName: first.sampleName,
        lab_tests: grouped[key],
      }
      pnls.push(pnl)
    }
    const eventTests: Record<number, OrderEventTest> =
      lastEvent?.tests?.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.test_id]: cur,
        }),
        {}
      ) ?? {}
    const merged = [...(order.panels ?? []), ...pnls].map((m) => {
      const first = m.lab_tests[0]
      const obj: Partial<LmsPanel> = { ...m }
      const curTest = eventTests[first?.id]
      const validStatuses = [
        'ORDERED',
        'ORDER_MODIFIED',
        'COLLECTION_REJECTED',
        'REJECTED',
      ]
      if (curTest) {
        obj.disabled = !validStatuses.includes(curTest.status)
        obj.checked = !validStatuses.includes(curTest.status)
        obj.collectedBy = curTest.updatedBy
        obj.collectedOn = curTest.updatedOn
      }
      return obj as LmsPanel
    })
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    merged.sort((a, b) => (a.sampleId! < b.sampleId! ? -1 : 1))
    return merged
  }, [order, lastEvent])

  const collectedTests = useMemo(() => {
    const eventTests: Record<number, OrderEventTest> =
      lastEvent?.tests?.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.test_id]: cur,
        }),
        {}
      ) ?? {}
    return panels.filter((p) => {
      const testId = p.lab_tests[0]?.id
      return testId && eventTests[testId].status === 'COLLECTED'
    })
  }, [panels, lastEvent])
  const remaining = panels.filter((p) => !p.checked).length
  const isComplete =
    (checkAll && !checked.length) || (!checkAll && checked.length === remaining)
  const labelOptions = (): CLabel => ({
    name: formattedPatient()?.name,
    gender: formattedPatient()?.name,
    age: formattedPatient()?.age,
    patientId: formattedPatient()?.uhid,
    code: formattedPatient()?.uhid,
  })

  const checkValidation = (panel: LmsPanel) => {
    const check = order?.order_events?.[0]?.tests?.find(
      (e: any) => e?.test_id === panel?.lab_tests?.[0]?.id
    )
    return check?.status === 'COLLECTION_APPROVED' ? check?.updatedBy : '-'
  }
  return (
    <>
      <div className='collect-order'>
        <table className='data-table table-fixed admin-table relative'>
          <thead style={{ position: 'sticky', top: '0px' }}>
            <tr>
              <th className='table-w-5'>Sl No</th>
              <th>LR.Number</th>
              <th>UHID</th>
              <th>Name</th>
              <th>Mobile No.</th>
              <th>Operating Unit</th>
              <th className='table-w-5'>Service</th>
              <th>Ordered On</th>
              <th className='table-w-8'>Tests</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>-</td>
              <td>{formattedPatient()?.uhid}</td>
              <td>{formattedPatient()?.name}</td>
              <td>{formattedPatient()?.phoneNumber}</td>
              <td>{formattedPatient()?.operatingUnit}</td>
              <td>{order?.origin}</td>
              <td>
                {order?.ordered_on
                  ? readableDateFormat(new Date(order?.ordered_on ?? ''))
                  : '-'}
              </td>
              <td>{order?.panels?.length}</td>
              <td>
                <div className='result-actions'>
                  <div style={{ display: 'none' }}>
                    <PrintLabel data={labelOptions()} ref={labelRef} />
                  </div>
                  <button title='Print Label' onClick={handleLabelPrint}>
                    <img src={LabelLogo} alt='Print Label' />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {(!order?.id || panels.length === 0) && (
          <span className='all-collected'>
            Unable to Find Order with the given Id
          </span>
        )}
        <div style={{ marginTop: '30px' }}>
          <table className='data-table table-fixed admin-table relative'>
            <thead>
              <tr>
                <th
                  rowSpan={1}
                  className='text-center'
                  style={{ width: '5%' }}
                ></th>
                <th rowSpan={1} className='text-center'></th>
                <th rowSpan={1} className='text-center'></th>
                <th colSpan={2} className='text-center'>
                  <span style={{ marginRight: '6px' }}> Collection</span>
                  {((checkAll && checked.length < remaining) ||
                    (!checkAll && checked.length > 0)) &&
                    KeycloakService.hasRole([COLLECTION_WRITE]) && (
                      <button
                        disabled={apiLoading ?? false}
                        className='base-button compact-button'
                        onClick={handleCollect}
                      >
                        {isComplete ? 'Complete' : 'Collect'}
                      </button>
                    )}
                </th>
                <th colSpan={2} className='text-center'>
                  <div className='validation-content'>
                    <span className='mt9'>Validation</span>
                    {((checkRejectAll &&
                      checkRejected.length < collectedTests.length) ||
                      (!checkRejectAll && checkRejected.length > 0)) &&
                      KeycloakService.hasRole([TESTING_WRITE]) && (
                        <div className='collect-approval package-container'>
                          <div className='approval-buttons'>
                            <LoadingButton
                              title='Approve'
                              loading={apiLoading}
                              compact
                              onClick={handleApprove}
                            />
                            <LoadingButton
                              title='Reject'
                              loading={apiLoading}
                              compact
                              onClick={handleReject}
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </th>
              </tr>
              <tr>
                <th className='w4'>Sl No.</th>
                <th>Sample Name</th>
                <th>Test Name</th>
                <th className='text-center'>Collected By</th>
                <th className='text-center'>
                  Select All
                  {panels.some((p) => !p.checked) && (
                    <span>
                      <input
                        ref={selectAllRef}
                        type='checkbox'
                        disabled={!KeycloakService.hasRole([COLLECTION_WRITE])}
                        id='select-all-samples'
                        onChange={toggleCheckAll}
                        checked={checkAll}
                      />
                    </span>
                  )}
                </th>
                <th>
                  <>
                    <div className='collection-content-select'>
                      Select All
                      {collectedTests.length > 0 && (
                        <div className='package-container'>
                          <div className='collect-tests-header'>
                            <input
                              ref={rejectAllRef}
                              type='checkbox'
                              disabled={
                                !KeycloakService.hasRole([COLLECTION_WRITE])
                              }
                              id='reject-all-samples'
                              onChange={toggleCheckRejectAll}
                              checked={checkRejectAll}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                </th>
                <th className='text-center'>Validated By</th>
              </tr>
            </thead>
            <tbody>
              {panels?.map((e, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{e?.sampleName}</td>
                  <td>{e?.name}</td>
                  <td>{e?.checked && e?.disabled ? e?.collectedBy : '-'}</td>
                  <td className='text-center'>
                    <div key={e?.id} className='single-test'>
                      <div className='select-box'>
                        <input
                          type='checkbox'
                          checked={
                            e?.checked ||
                            checkAll !== checked.includes(e?.id as number)
                          }
                          disabled={
                            e?.disabled ||
                            !KeycloakService.hasRole([COLLECTION_WRITE]) ||
                            checkAll
                          }
                          onChange={() => handleCheck(e?.id)}
                        />
                      </div>
                    </div>
                  </td>
                  <td className='text-center'>
                    {collectedTests
                      ?.filter((ct) => ct?.id === e?.id)
                      ?.map((ct) => (
                        <div key={ct?.id} className='single-test'>
                          <div className='select-box'>
                            <input
                              type='checkbox'
                              checked={
                                checkRejectAll !==
                                checkRejected.includes(ct?.id as number)
                              }
                              disabled={
                                !KeycloakService.hasRole([COLLECTION_WRITE])
                              }
                              onChange={() => handleRejectCheck(ct?.id)}
                            />
                          </div>
                        </div>
                      ))}
                  </td>
                  <td>{checkValidation(e)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {collectedTests.length > 0 && (
          <div className='package-container'>
            {((checkRejectAll &&
              checkRejected.length < collectedTests.length) ||
              (!checkRejectAll && checkRejected.length > 0)) &&
              KeycloakService.hasRole([TESTING_WRITE]) && (
                <div className='collect-approval collect-content'>
                  <textarea
                    name='comments'
                    id='collect-comments'
                    cols={40}
                    rows={4}
                    value={comments}
                    placeholder='Comments'
                    onChange={({ target }) => setComments(target.value)}
                  ></textarea>
                </div>
              )}
          </div>
        )}
      </div>
    </>
  )
}

export default CollectOrder
