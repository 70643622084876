import { ScaleLoader } from 'react-spinners'
import { useAppDispatch } from '../app/hooks'
import { CHAT_PROJECT_ID } from '../utils/constants'
import { toast, ToastContainer } from 'react-toastify'
import { ReactNode, useEffect, useState } from 'react'
import { startSxpProxy, updateSxpState } from '../utils/api'
import { startDMSTokenRefresh } from '../utils/DMSApiService'
import {
  LocationData,
  setLocations,
  getLocationData,
  setTeleServiceId,
  setSelectedLocation,
} from '../features/location/locationSlice'
import Error from './Error'
import Header from './Header'
import KeycloakService from '../utils/keycloakService'
import { Box } from '@mui/material'

type Props = {
  children: ReactNode
}

const Layout = ({ children }: Props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    startSxpProxy(CHAT_PROJECT_ID, 'getLocationsAPI')
      .then((data) => {
        const locations: LocationData[] = data?.data?.entry
        locations?.sort((a, b) => {
          if (!a?.resource?.id) {
            return -1
          }
          if (!b?.resource?.id) {
            return 1
          }
          return parseInt(a?.resource?.id) < parseInt(b?.resource?.id) ? -1 : 1
        })
        const teleServiceId = data?.data?.teleServiceId
        const tokenLocation = KeycloakService.getLocation()
        let selected = locations?.find((l) => l?.resource?.id === tokenLocation)
        if (!selected) {
          selected = locations?.[0]
        }
        const loc = getLocationData(selected)
        dispatch(setLocations(locations))
        dispatch(setTeleServiceId(teleServiceId))
        dispatch(setSelectedLocation(loc?.id))
        const state = {
          selectedLocationId: loc?.id,
          locationValue: loc?.value,
          locationPrefix: loc?.prefix,
          selectedLocationName: loc?.name,
        }
        updateSxpState(CHAT_PROJECT_ID, state).then(() => {
          setTimeout(() => {
            setLoading(false)
            toast(`Logged in to location ${selected?.resource?.name}`, {
              position: 'bottom-center',
              hideProgressBar: true,
              closeButton: false,
              theme: 'dark',
              autoClose: 3000,
            })
          }, 1000)
        })
      })
      .catch(() => {
        setError(true)
        setLoading(false)
      })
  }, [dispatch])

  useEffect(() => {
    startDMSTokenRefresh()
  }, [])

  return (
    <div className='content-height'>
      <Header />
      {loading ? (
        <div className='app-loader'>
          <ScaleLoader color='#004C91' />
        </div>
      ) : error ? (
        <Error />
      ) : (
        <Box className='children-container'>{children}</Box>
      )}
      <ToastContainer />
    </div>
  )
}

export default Layout
