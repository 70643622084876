import { PropsWithChildren, useEffect, useReducer, useState } from 'react'
import './form.scss'
import { SxpComponent, SxpFormData } from './models'
import { startSxpForm, startSxpProxy } from '../utils/api'
import {
  normalizeComponents,
  SxpFormContext,
  SxpFormDispatchContext,
  sxpFormReducer,
} from './formContext'
import { toast } from 'react-toastify'
import SxpElement from './SxpElement'
import Loading from './fields/Loading'
import InterpretationImage from '../form/InterpretationImage'
import { useAppSelector } from '../app/hooks'
import { selectedLmsItem } from '../features/lms/lmsSlice'
import { CHAT_PROJECT_ID } from '../utils/constants'
import { sendSocketData } from '../features/Notification/Socket'
import { NEW_IPD_DATA_UPDATE } from '../features/Notification/Constants'
import { getBedPatientDetails } from '../features/ipRegistration/IpdPatientRegisterSlice'
import {
  selectLocations,
  selectSelectedLocationId,
} from '../features/location/locationSlice'

type Props = {
  project: string | undefined
  intent: string
  journeyState: any
  devMode?: boolean
  isDynamic?: boolean
  canSubmit?: boolean
  onJourneyEnd?: () => void
}

const initialData: SxpFormData = {
  componentArray: [],
  components: {},
  state: {},
  project: '',
  intent: '',
  error: null,
  apiError: false,
  messages: [],
  closeWindow: false,
}

const SxpForm = ({
  project,
  intent,
  journeyState,
  children,
  devMode = false,
  isDynamic = false,
  canSubmit = true,
  onJourneyEnd = () => null,
}: PropsWithChildren<Props>) => {
  const [loading, setLoading] = useState(true)
  const [formData, dispatch] = useReducer(sxpFormReducer, initialData)
  const [imageURL, setImageURL] = useState('')
  const imageUploadItem: string = useAppSelector(selectedLmsItem)
  const [isDisabled, setIsDisabled] = useState(false)
  const locationPath = window?.location?.pathname

  const locationNames = useAppSelector(selectLocations)
  const locationId = useAppSelector(selectSelectedLocationId)

  const locationName =
    locationNames?.find(
      (location: any) => location?.resource?.id === locationId
    )?.resource?.name || ''

  const bedPatientDetails = useAppSelector(getBedPatientDetails)
  const encounterId = formData?.state?.encounterId?.toString() || ''
  useEffect(() => {
    const fetchEncounterForAppointments = async (encounterId: string) => {
      if (encounterId) {
        try {
          const intent = 'visitStatus'
          const state = { encounterId }
          const resp = await startSxpProxy(CHAT_PROJECT_ID, intent, state)
          setIsDisabled(resp?.data?.status === 'finished')
        } catch (e) {
          console.error('Error fetching encounter details:', e)
        }
      }
    }
    fetchEncounterForAppointments(encounterId)
  }, [encounterId])

  useEffect(() => {
    dispatch({ type: 'set_project', value: project })
    dispatch({ type: 'set_intent', value: intent })
    dispatch({ type: 'set_dev_mode', value: devMode })
    startSxpForm(project, intent, journeyState, false)
      .then((data) => {
        dispatch({ type: 'set_submitted', value: false })
        dispatch({
          type: 'store_data',
          data: normalizeComponents(data, journeyState),
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({ type: 'store_data', data: initialData })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [project, intent, journeyState, devMode])

  const submitForm = () => {
    setLoading(true)
    startSxpForm(formData.project, formData.intent, formData.state, true)
      .then((data) => {
        dispatch({ type: 'set_submitted', value: true })
        let normalized = normalizeComponents(data, journeyState)
        if (data.closeWindow || data.apiError) {
          const obj: Record<string, SxpComponent> = {}
          for (const comp in formData.components) {
            const temp = formData.components[comp]
            if (data.closeWindow) {
              delete temp.errorMessage
            }
            obj[comp] = temp
          }
          const compArray = formData.componentArray.map((ca) => {
            if (data.closeWindow) {
              delete ca.errorMessage
            }
            return ca
          })
          normalized = {
            ...normalized,
            componentArray: compArray,
            components: obj,
          }
          if (data.closeWindow) {
            if (data.messages.length === 0) {
              toast.success('Form Submitted', {
                autoClose: 2000,
              })
            } else {
              toast.error(data.messages?.[0], {
                autoClose: 2000,
              })
            }
            normalized = { ...normalized, state: journeyState }
            onJourneyEnd()
          }
        }
        if (data.apiError) {
          for (const message of data.messages) {
            toast.error(message)
          }
        }
        dispatch({
          type: 'store_data',
          data: normalized,
        })
        sendSocketData({
          messageType: NEW_IPD_DATA_UPDATE,
          patientID: bedPatientDetails?.[0]?.patientId,
          patientLocation: locationName,
          message: formData.intent,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      {loading && <Loading />}
      <SxpFormContext.Provider value={formData}>
        <SxpFormDispatchContext.Provider value={dispatch}>
          {isDynamic
            ? formData.componentArray.map((comp: SxpComponent) => (
                <SxpElement
                  key={comp.id}
                  // disabled={!canSubmit || isDisabled}  //need for future purpose
                  disabled={isDisabled}
                  reference={comp.reference}
                  imageURL={imageURL}
                />
              ))
            : children}
          {imageUploadItem === 'panel' &&
            locationPath === '/administration/laboratory' && (
              <InterpretationImage
                imageURL={imageURL}
                setImageURL={setImageURL}
              />
            )}
          {!loading && canSubmit && (
            <div style={{ width: '40%', textAlign: 'right', marginTop: 24 }}>
              <button
                className={`btn btn-primaryBtn ${devMode ? 'disableBtn' : ''}`}
                disabled={devMode}
                onClick={submitForm}
              >
                Submit
              </button>
            </div>
          )}
        </SxpFormDispatchContext.Provider>
      </SxpFormContext.Provider>
    </>
  )
}

export default SxpForm
