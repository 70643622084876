import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { ABMemberPatient, MembershipType } from '../membership/models'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks'
import Loading from '../../form/fields/Loading'
import { startSxpProxy } from '../../utils/api'
import {
  CHAT_PROJECT_ID,
  AB_MEMBERHSIP_PROJECT_ID,
} from '../../utils/constants'
import { getAgeInYears } from '../../utils/dateUtils'
import ToastMessage from '../lms/components/ToastMessage'
import { toastOptions } from '../lms/models'
import { selectSelectedLocationId } from '../location/locationSlice'
import { LoadingMsg, CreateMembership } from '../patientRegstration/constants'
import {
  ABMembershipIntent,
  adultType,
  agesband,
  changeRegType,
  childType,
  COGNITIVE_OPTIONS,
  DetailesSaved,
  failtoCreate,
  MembershipList,
  noMemberFound,
  paymentMode,
  planType,
  Remove,
  selectabult,
  selectChild,
  selectOption,
  CognitiveCareConst,
  primaryAgevalidation,
} from './constants'
import { tableText } from '../administration/constants'
import { ABMemebrship } from '../bedManagement/endpoints/EndPoints'
import KeycloakService from '../../utils/keycloakService'

const ListOfABMembers = ({
  members,
  onMemberUnSelect,
}: {
  members: ABMemberPatient[]
  onMemberUnSelect: (id: string) => void
}) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState<any>()
  const [memberShipTypes, setMembershipTypes] = useState<MembershipType[]>([])
  const [primaryIndex, setPrimaryIndex] = useState(-1)
  const location = useAppSelector(selectSelectedLocationId)
  const [selectedAdultPlan, setSelectedAdultPlan] = useState<string>('')
  const [selectedChildPlan, setSelectedChildPlan] = useState<string>('')
  const [selctedPlanType, setSelectedPlanType] = useState('')
  const [planTypeOptions, setPlanTypeOptions] = useState<any[]>([])
  const [journalOptions, setjournalOptions] = useState<any[]>([])
  const [selectedJournal, setSelectedJournal] = useState('')
  const [CognitiveCare, setCognitiveCare] = useState<boolean | null>(null)
  const [ageBand, setAgeBand] = useState<any>('')
  const [ageValue, setAgeValue] = useState<any>()
  const [primaryAgeValue, setPrimaryAgeValue] = useState<any>()
  const handleAdultPlanChange = (value: string) => {
    setSelectedAdultPlan(value)
  }

  const handleChildPlanChange = (value: string) => {
    setSelectedChildPlan(value)
  }

  const fetchMembershipTypes = async (location: any) => {
    try {
      const data = await startSxpProxy(
        AB_MEMBERHSIP_PROJECT_ID,
        ABMembershipIntent?.ABMembershipProducts,
        { location }
      )
      setMembershipTypes(data?.data)
    } catch (err) {
      console.error(err)
    }
  }

  const GetPlanAgeBand = async () => {
    try {
      await startSxpProxy(
        AB_MEMBERHSIP_PROJECT_ID,
        ABMembershipIntent?.GetPlanAgeBand
      )?.then((data: any) => {
        if (Array?.isArray(data?.data?.type)) {
          setPlanTypeOptions(data?.data?.type)
          setjournalOptions(data?.data?.journal)
        }
      })
    } catch (err) {
      console?.error(err)
    }
  }
  useEffect(() => {
    fetchMembershipTypes(location)
    GetPlanAgeBand()
    if (ageValue !== undefined) {
      setAgeBand(calAgeBand(ageValue))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, primaryIndex])

  const handleCheckboxChange = (id: number) => {
    if (id !== primaryIndex) {
      const primaryAge = getAgeInYears(members?.[id]?.dob) || 0
      if (primaryAge < primaryAgeValue) {
        toast(
          <ToastMessage
            message={`${primaryAgevalidation} ${primaryAgeValue}`}
          />,
          {
            ...toastOptions,
            type: 'error',
          }
        )
        return
      }
      startSxpProxy(CHAT_PROJECT_ID, ABMembershipIntent?.checkRegAndRestrict, {
        patientIdentifier: members[id].uhid,
      })
        ?.then(() => {
          setPrimaryIndex(id)
          members[primaryIndex]
          const primaryAge = members?.[id]?.dob
          setAgeValue(getAgeInYears(primaryAge))
        })
        ?.catch((err: any) => {
          toast(
            <ToastMessage
              message={err?.response?.data?.error ?? `${changeRegType}`}
            />,
            {
              ...toastOptions,
              type: 'error',
            }
          )
        })
    } else {
      setPrimaryIndex(-1)
    }
  }

  const handleSelect = (e: any) => {
    const selectedId = e.target.value
    setSelectedOption(selectedId)
    const selectedData = memberShipTypes?.find(
      (option: any) => option?.id == selectedId
    )
    if (selectedData) {
      setPrimaryAgeValue(selectedData?.min_age_limit)
    }
  }

  const calAgeBand = (age: number): string | undefined => {
    if (!agesband || !Array?.isArray(agesband) || agesband.length === 0) {
      return undefined
    }
    for (let i = 0; i < agesband?.length; i++) {
      if (age <= agesband?.[i]?.name) {
        return agesband?.[i]?.value
      }
    }
    setAgeBand(agesband?.[agesband?.length - 1]?.value)
    return agesband?.[agesband?.length - 1]?.value
  }

  const handleUnselect = (id: string, idx: number) => {
    if (idx == primaryIndex) {
      setPrimaryIndex(-1)
    }
    onMemberUnSelect(id)
  }

  const createMember = async () => {
    setLoading(true)
    try {
      if (members?.length > 0) {
        const primary = members[primaryIndex]
        const associated = members?.filter((d, idx) => idx !== primaryIndex)
        const associatedPartnerRefs = associated.map((d) => ({
          ref: d.uhid,
          relation: d.relation || '',
        }))

        const selectedType = memberShipTypes?.find(
          (mt) => mt?.id === parseInt(selectedOption)
        )
        const body = {
          location: location,
          PlanType: selctedPlanType,
          age_band: ageBand,
          selectedAdultPlan: selectedAdultPlan,
          selectedChildPlan: selectedChildPlan,
          thop_user_id: KeycloakService?.getSub(),
          thop_user_name: KeycloakService?.getFullname(),
          product_id: selectedType?.cognitivecare_odoo_product_id,
          body: {
            membership_id: selectedType?.id,
            partner: primary?.uhid,
            price: selectedType?.list_price,
            associated_partner_refs: associatedPartnerRefs,
            adult: selectedAdultPlan,
            child: selectedChildPlan,
            cognitivecare_odoo_product_id:
              selectedType?.cognitivecare_odoo_product_id,
            cognitivecare_odoo_journal_id: selectedJournal,
            invoice_cognitivecare: CognitiveCare,
          },
        }
        await startSxpProxy(
          AB_MEMBERHSIP_PROJECT_ID,
          ABMembershipIntent?.createMembership,
          body
        )
        toast(<ToastMessage message={DetailesSaved} />, {
          ...toastOptions,
          type: 'success',
        })
        navigate(ABMemebrship)
      }
    } catch (error: any) {
      toast(
        <ToastMessage message={error?.response?.data?.error ?? failtoCreate} />,
        {
          ...toastOptions,
          type: 'error',
        }
      )
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <div className='patient' style={{ marginBottom: 20 }}>
        {loading ?? <Loading />}
        <p className='admin-header'>{MembershipList}</p>
        <table className='data-table table-fixed admin-table'>
          <thead className='admin-table-head sticky'>
            <tr>
              <th className='table-w-4'>{tableText?.slNo}</th>
              <th className='text-left table-w-15'>{tableText?.MUHID}</th>
              <th className='text-left table-w-30'>{tableText?.name}</th>
              <th className='text-center table-w-6'>{tableText?.gender}</th>
              <th className='text-center table-w-8'>{tableText?.age}</th>
              <th className='text-center table-w-6'>{tableText?.Relation}</th>
              <th className='text-center table-w-6'>{tableText?.action}</th>
            </tr>
          </thead>
          <tbody>
            {members?.length > 0 ? (
              members?.map((d: ABMemberPatient, idx) => (
                <tr className='memberId' key={d?.id}>
                  <td>
                    <input
                      type='checkbox'
                      disabled={!selectedOption}
                      checked={idx === primaryIndex}
                      onChange={() => handleCheckboxChange(idx)}
                    />
                  </td>
                  <td>{d?.uhid || d?.thopId}</td>
                  <td>
                    <button className='lms-btn'>{d?.name}</button>
                  </td>
                  <td className='text-center'>{d?.gender}</td>
                  <td className='text-center'>
                    {getAgeInYears(d?.dob) ?? '-'}
                  </td>
                  <td>{d?.relation}</td>
                  <td className='text-center'>
                    <button
                      className='typeButton'
                      onClick={() => handleUnselect(d.id, idx)}
                    >
                      {Remove}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className='text-center'>
                  {noMemberFound}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='flexDivs'>
        <select
          className='doctorSelected'
          value={CognitiveCare?.toString() || ''}
          onChange={(e) => setCognitiveCare(e.target.value === 'true')}
        >
          <option value=''>{CognitiveCareConst}</option>
          {COGNITIVE_OPTIONS?.map((option) => (
            <option key={option?.value} value={option?.value}>
              {option?.label}
            </option>
          ))}
        </select>
        <select
          className='doctorSelected'
          value={selctedPlanType || ''}
          onChange={(e) => setSelectedPlanType(e.target.value)}
        >
          <option value=''>{planType}</option>
          {planTypeOptions?.map((option: any) => (
            <option key={option?.value} value={option?.value}>
              {option?.name}
            </option>
          ))}
        </select>
        <select
          className='doctorSelected'
          value={selectedJournal || ''}
          onChange={(e) => setSelectedJournal(e.target.value)}
        >
          <option value=''>{paymentMode}</option>
          {journalOptions?.map((option: any) => (
            <option key={option?.value} value={option?.value}>
              {option?.name}
            </option>
          ))}
        </select>
        <select
          className='doctorSelected'
          value={selectedAdultPlan || ''}
          onChange={(e) => handleAdultPlanChange(e.target.value)}
        >
          <option value=''>{selectabult}</option>
          {adultType?.map((option) => (
            <option key={option?.value} value={option?.value}>
              {option?.label}
            </option>
          ))}
        </select>
        <select
          className='doctorSelected'
          value={selectedChildPlan || ''}
          onChange={(e) => handleChildPlanChange(e?.target?.value)}
        >
          <option value=''>{selectChild}</option>
          {childType?.map((option) => (
            <option key={option?.value} value={option?.value}>
              {option?.label}
            </option>
          ))}
        </select>
        <select
          className='doctorSelected'
          value={selectedOption}
          onChange={handleSelect}
        >
          <option value=''>{selectOption}</option>
          {memberShipTypes?.map((option) => (
            <option key={option?.id} value={option?.id}>
              {option?.name}
            </option>
          ))}
        </select>
        <button
          className={
            !selectedAdultPlan ||
            !selectedChildPlan ||
            !selectedOption ||
            members?.length === 0 ||
            primaryIndex < 0 ||
            loading
              ? 'disabled-button'
              : 'createbutton'
          }
          disabled={
            !selectedAdultPlan ||
            !selectedChildPlan ||
            !selectedOption ||
            members?.length === 0 ||
            primaryIndex < 0 ||
            loading
          }
          onClick={createMember}
        >
          {loading ? LoadingMsg : CreateMembership}
        </button>
      </div>
    </>
  )
}

export default ListOfABMembers
