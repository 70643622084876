import { useTheme } from '@emotion/react'
import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material'
import { ColDef } from 'ag-grid-community'
import {
  ADMIN_PROJECT_ID,
  MEDUNITED_KEYCLOCK_ID,
} from '../../../utils/constants'
import { startSxpProxy } from '../../../utils/api'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'
import { styles } from '../../ProRithm/ProRithmDasboard'
import {
  AddRoles,
  allRoles,
  AssignedClientRolesTitle,
  AssignedRolesOfGroupRoles,
  AvailableRoles,
  NoRoles,
  UserManagementIntent,
} from '../constants'
import { PractitionerOption } from './UserForm'
import MedAgDataGrid from '../../../components/MedAgDataGrid'
import { useNavigate } from 'react-router-dom'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import {
  radiologyButtons,
  toastOptions,
} from '../../Radiology/Constants/constants'
import { RoleOption } from './KeyclockRoles'
import { Cancel } from '../../AB Membership/constants'
import { toast } from 'react-toastify'
import ToastMessage from '../../lms/components/ToastMessage'

const AllClientRoles = () => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [groupRoles, setGroupRoles] = useState<PractitionerOption[]>([])
  const [clientRoles, setClientRoles] = useState<PractitionerOption[]>([])
  const [AssignedClientRoles, setAssignedClientRoles] = useState<
    PractitionerOption[]
  >([])
  const [viewroleid, setselectedViewRoleid] = useState<RoleOption[]>([])
  const [open, setOpen] = useState(false)
  const columnDefs: ColDef[] = [
    {
      headerName: 'Id',
      field: 'sNo',
      width: 80,
      valueGetter: (params: any) => {
        return params.node ? params.node.rowIndex + 1 : null
      },
      sortable: false,
      filter: false,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
    },
    {
      headerName: 'Role Group',
      field: 'name',
      flex: 1,
      width: 400,
      sortable: false,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
    },
    {
      headerName: 'Role Names',
      field: 'id',
      sortable: false,
      filter: false,
      autoHeight: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellRenderer: (params: any) => {
        return (
          <a
            className='clickable-cell'
            onClick={() => AssignedRolesOfGroupRole(params.data?.id)}
          >
            View
          </a>
        )
      },
    },
  ]
  const [selectedAvailableRoles, setSelectedAvailableRoles] = useState<
    RoleOption[]
  >([])
  // needed
  // const [selectedAssignedRoles, setSelectedAssignedRoles] = useState<
  //   RoleOption[]
  // >([])

  const getallRoles = () => {
    const intent = allRoles
    startSxpProxy(ADMIN_PROJECT_ID, intent)
      ?.then((data: any) => {
        setGroupRoles(data?.data?.grouproles)
        setClientRoles(data?.data?.medunitedRoles)
      })
      .catch((err: any) => {
        console.error(err)
      })
  }
  // needed
  // const handleRemoveRoles = () => {
  //   const SelectedKeyclockRoles = selectedAssignedRoles?.map((role) => ({
  //     id: role?.id,
  //     name: role?.name,
  //     clientRole: true,
  //     composite: false,
  //     containerId: MEDUNITED_KEYCLOCK_ID,
  //   }))
  //   startSxpProxy(ADMIN_PROJECT_ID, 'remove', {
  //     roleId: viewroleid,
  //     SelectedKeyclockRoles,
  //   })
  //     ?.then(() => {
  //       setOpen(false)
  //       setSelectedAvailableRoles([])
  //       setSelectedAssignedRoles([])
  //     })
  //     .catch((err) => console.error(err))
  // }
  const handleCancel = () => {
    setSelectedAvailableRoles([])
    setOpen(false)
  }

  const AssignedRolesOfGroupRole = (id: any) => {
    const intent = AssignedRolesOfGroupRoles
    setselectedViewRoleid(id)
    startSxpProxy(ADMIN_PROJECT_ID, intent, {
      roleId: id,
    })
      ?.then((data: any) => {
        setAssignedClientRoles(data?.data?.map((d: any) => d))
        setOpen(true)
      })
      .catch((err: any) => {
        console.error(err)
      })
  }

  useEffect(() => {
    getallRoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigate = useNavigate()
  const navigateBack = () => {
    navigate(-1)
  }

  const handleCheckboxChange = (roleId: string, isAssigned: boolean) => {
    const role = clientRoles?.find((role) => role?.id === roleId)
    if (!role) return
    if (isAssigned) {
      setSelectedAvailableRoles((prev) =>
        prev?.some((selectedRole) => selectedRole?.id === roleId)
          ? prev?.filter((selectedRole) => selectedRole?.id !== roleId)
          : [...prev, { id: roleId, name: role?.name }]
      )
    }
    // else {
    //   setSelectedAssignedRoles((prev) =>
    //     prev.some((selectedRole) => selectedRole.id === roleId)
    //       ? prev.filter((selectedRole) => selectedRole.id !== roleId)
    //       : [...prev, { id: roleId, name: role?.name }]
    //   )
    // }
  }

  const handleAddRoles = () => {
    const SelectedKeyclockRoles = selectedAvailableRoles?.map((role) => ({
      id: role?.id,
      name: role?.name,
      clientRole: true,
      composite: false,
      containerId: MEDUNITED_KEYCLOCK_ID,
    }))
    startSxpProxy(ADMIN_PROJECT_ID, UserManagementIntent?.AddRoleInGroupRole, {
      roleId: viewroleid,
      SelectedKeyclockRoles,
    })
      ?.then((response) => {
        setOpen(false)
        toast(<ToastMessage message={response?.data?.message} />, {
          ...toastOptions,
          type: 'success',
        })
        setSelectedAvailableRoles([])
      })
      .catch((err) => {
        toast(<ToastMessage message={err?.data?.message} />, {
          ...toastOptions,
          type: 'error',
        })
      })
  }
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <h3 className='rolestitlefontsize'>{AssignedClientRolesTitle}</h3>
          <div className='role-list-container'>
            <ul>
              {AssignedClientRoles?.length > 0 ? (
                AssignedClientRoles?.map((role: any, index: any) => (
                  <li key={index}>
                    {/* needed */}
                    {/* <Checkbox
                      checked={selectedAssignedRoles?.some(
                        (selectedRole) => selectedRole?.id === role?.id
                      )}
                      onChange={() => handleCheckboxChange(role?.id, true)}
                    /> */}
                    {role?.name}
                  </li>
                ))
              ) : (
                <p>{NoRoles}</p>
              )}
            </ul>
            {clientRoles?.length > 0 && (
              <div>
                <h3 className='rolestitlefontsize'>{AvailableRoles}</h3>
                <ul>
                  {clientRoles?.map((role) => (
                    <li key={role?.id}>
                      <Checkbox
                        checked={selectedAvailableRoles?.some(
                          (selectedRole) => selectedRole?.id === role?.id
                        )}
                        onChange={() => handleCheckboxChange(role?.id, false)}
                      />
                      {role?.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className='dialogbutton fixed-buttons'>
            <Button
              className={classes?.proRithmDasboardCreateBtn}
              onClick={handleAddRoles}
              disabled={selectedAvailableRoles?.length === 0}
              variant='contained'
              size='small'
            >
              {AddRoles}
            </Button>
            {/* needed */}
            {/* <Button
              className={classes?.proRithmDasboardCreateBtn}
              onClick={handleRemoveRoles}
              disabled={selectedAssignedRoles?.length === 0}
              variant='contained'
              size='small'
            >
              Remove
            </Button> */}
            <Button
              className={classes?.proRithmDasboardCreateBtn}
              onClick={handleCancel}
              variant='contained'
              size='small'
            >
              {Cancel}
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color='primary'>
            {radiologyButtons?.close}
          </Button>
        </DialogActions>
      </Dialog>
      <Box height={'93%'} width={'100%'}>
        <Box
          className={classes?.proRithmDasboardContainer}
          height={'100%'}
          width={'100%'}
        >
          <Box
            className={classes?.proRithmDasboardTableContainer}
            height={'100%'}
            width={'100%'}
          >
            <KeyboardBackspaceIcon
              onClick={navigateBack}
              className='backspace-icon-search-view'
            />
            <MedAgDataGrid
              tableHeading='Client Roles'
              tableHeight={'100%'}
              rowData={groupRoles}
              columnDefs={columnDefs}
              pagination={false}
              headerHeight={30}
              searchFieldWidth={200}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default AllClientRoles
