export const PackageTableHeading = {
  ThopId: 'Thop Id',
  OdooId: 'Odoo Id',
  PackageName: 'Package Name',
  TestName: 'TestName',
  TestCount: 'Test Count',
  Price: 'Price',
  StartEnd: 'Start-End Date',
  Created: 'Created By',
  Updated: 'Updated By',
  Status: 'Status',
  Actions: 'Actions',
}

export const panelTableHeadings = {
  ThopId: 'Thop Id',
  OdooId: 'Odoo Id',
  ShortForm: 'Short Form',
  TestName: 'Test Name',
  Department: 'Department',
  Sample: 'Sample',
  ParameterName: 'Parameter Name',
  ParaCount: 'Para Count',
  HandledAt: 'Handled At',
  Price: 'Price',
  TAT: 'TAT',
  Interpretation: 'Interpretation',
  Status: 'Status',
  Actions: 'Actions',
  Template: 'Templates Count',
  AddTemplates: 'AddTemplates',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
}

export const PanelsIntent = {
  getAllDepartmentsApi: 'getAllDepartmentsApi',
  getAllSamplesApi: 'getAllSamplesApi',
  updatePanelById: 'updatePanelById',
  createUpdatePanel: 'createUpdatePanel',
}
export const API_INTENTS = {
  SAMPLE: 'getAllSamplesApi',
  PANEL: 'getAllActiveInactivePanels',
  LAB_TEST: 'getAllLabTestsApi',
  DEPARTMENT: 'getAllDepartmentsApi',
  UNIT_TYPE: 'getAllTestUnitTypesApi',
  RESULT_TYPE: 'getAllTestResultTypes',
  METHOD: 'getAllTestMethodsApi',
  TEST_RANGE: 'getAllTestResultLimitsApi',
  PACKAGES: 'getAllPackagesApi',
  RADIOLOGY_CATEGORY: 'getRadiologySamplesAPI',
  RADIOLOGY_TEST: 'getRadiologyTestsAPI',
  RADIOLOGY_PACKAGES: 'getAllRadiologyPackages',
  WARD: 'getWardDetails',
  ROOMS: 'getRoomDetails',
  BEDS: 'getAllBeds',
  RADIOLOGYTEMPLATES: 'getAllRadiologyTestTemplates',
  TEST_TEMPLATES: 'getAllTestTemplates',
}

export const ITEM_TYPES = {
  SAMPLE: 'sample',
  PANEL: 'panel',
  LAB_TEST: 'labTest',
  DEPARTMENT: 'department',
  UNIT_TYPE: 'unitType',
  RESULT_TYPE: 'resultType',
  METHOD: 'method',
  TEST_RANGE: 'testRange',
  PACKAGES: 'packages',
  RADIOLOGY_CATEGORY: 'radiologyCategory',
  RADIOLOGY_TEST: 'radiologyTest',
  RADIOLOGY_PACKAGES: 'radiologyPackages',
  WARD: 'ward',
  ROOMS: 'rooms',
  BEDS: 'beds',
  SEQUENCE: 'sequence',
  RADIOLOGYREPORTTEAMPLATES: 'radiologyReportTemplates',
  REPORTTEMPLATES: 'reportTemplates',
}

export const DATA_KEYS = {
  SAMPLE: 'sample',
  PANEL: 'panel',
  LAB_TEST: 'lab_test',
  DEPARTMENT: 'department',
  UNIT_TYPE: 'test_unit_type',
  RESULT_TYPE: 'test_result_type',
  METHOD: 'test_method',
  TEST_RANGE: 'test_result_limits',
  PACKAGES: 'packages',
  RADIOLOGY_CATEGORY: 'radiology_sample',
  RADIOLOGY_TEST: 'radiology_lab_test',
  RADIOLOGY_PACKAGES: 'radiology_packages',
  WARD: 'ward_details',
  ROOMS: 'room_details',
  BEDS: 'bed',
}
export const NO_DATA = 'No Data'
export const LOADER_TEXT = 'Loading...'
export const CREATE = 'CREATE'
export const created = 'Created'
export const lastUpdated = 'Last Updated'
export const NA = 'NA'
export const viewButtons = {
  viewLess: '...View Less',
  viewMore: '...View More',
}
