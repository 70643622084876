import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { REGISTRATION_PROJECT_ID } from '../../utils/constants'
import {
  normalizeComponents,
  SxpFormContext,
  SxpFormDispatchContext,
  sxpFormReducer,
} from '../../form/formContext'
import { SxpComponent, SxpFormData } from '../../form/models'
import { startSxpForm } from '../../utils/api'
import { toast } from 'react-toastify'
import FormStepper from './FormStepper'
import { StepDTO } from 'react-form-stepper/dist/components/Step/StepTypes'
import StepElements from './StepElements'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from '../../form/fields/Loading'
import './profile.scss'
import ProfilePicture from './ProfilePicture'
import { useAppSelector } from '../../app/hooks'
import {
  getLocationData,
  selectLocationById,
  selectSelectedLocationId,
} from '../location/locationSlice'
import { selectPatientsRedirectTo } from '../patients/patientsSlice'
import IconButton from '@mui/material/IconButton'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { RegistrationTypes } from './constants'
import { previous } from '../Radiology/Constants/constants'
import { forms } from '../administration/constants'

const initialData: SxpFormData = {
  componentArray: [],
  components: {},
  state: {},
  project: '',
  intent: '',
  error: null,
  apiError: false,
  messages: [],
  closeWindow: false,
}

const refs: Record<string, number> = {
  service: 0,
  location: 0,
  campName: 0,
  salutation: 0,
  firstName: 0,
  middleName: 0,
  lastName: 0,
  gender: 0,
  dob: 0,
  age: 0,

  notes: 1,
  patientSource: 1,
  phoneNumber: 1,
  whatsapp: 1,
  alternateNumber: 1,
  emailId: 1,
  maritalStatus: 1,
  socioEconomicStatus: 1,
  governmentId: 1,
  otherId: 1,
  idNumber: 1,
  aadharId: 1,
  religion: 1,
  otherReligion: 1,
  occupation: 1,
  otherOccupation: 1,

  houseNo: 2,
  area: 2,
  town: 2,
  state: 2,
  district: 2,
  postalCode: 2,
  permanentAddressConfirmation: 2,
  permanentHouseNo: 2,
  permanentArea: 2,
  permanentTown: 2,
  permanentState: 2,
  permanentDistrict: 2,
  permanentPostalCode: 2,

  emergencyContactName: 3,
  emergencyPhoneNumber: 3,
  emergencyRelationshipWithPatient: 3,
  otherRelation: 3,
  emergencyAddress: 3,
}

const PatientRegistration = () => {
  const { id } = useParams()
  const locationId = useAppSelector(selectSelectedLocationId)
  const location = useAppSelector((state) =>
    selectLocationById(locationId, state)
  )
  const navigate = useNavigate()
  const initialState: any = useMemo(() => {
    if (location) {
      const loc = getLocationData(location)
      return {
        locationPrefix: loc.prefix,
        selectedLocationId: loc.id,
        selectedLocationName: loc.name,
        locationValue: loc.value,
      }
    }
    return {}
  }, [location])
  let intent: string
  if (id) {
    intent = 'editPatient'
    initialState['method'] = 'PUT'
    initialState['id'] = id
  } else {
    intent = 'dynamicRegistration'
    initialState['state'] = '1'
  }
  const [steps, setSteps] = useState<StepDTO[]>([])
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [loading, setLoading] = useState(true)
  const [formData, dispatch] = useReducer(sxpFormReducer, initialData)
  const redirectTo = useAppSelector(selectPatientsRedirectTo)

  useEffect(() => {
    dispatch({ type: 'set_project', value: REGISTRATION_PROJECT_ID })
    dispatch({ type: 'set_intent', value: intent })
    dispatch({ type: 'set_dev_mode', value: false })
    startSxpForm(REGISTRATION_PROJECT_ID, intent, initialState, false)
      .then((data) => {
        dispatch({ type: 'set_submitted', value: false })
        dispatch({
          type: 'store_data',
          data: normalizeComponents(data, initialState),
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({ type: 'store_data', data: initialData })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [intent, initialState])

  useEffect(() => {
    const stateSteps = formData?.state?.steps
    if (stateSteps) {
      const stepsArr = (stateSteps as string)
        .split(',')
        .map((st: string) => ({ label: st }))
      setSteps(stepsArr)
    } else {
      setSteps([])
    }
  }, [formData])

  const submitForm = (submit: boolean, previous: boolean) => {
    setLoading(true)
    startSxpForm(formData.project, formData.intent, formData.state, submit)
      .then((data) => {
        dispatch({ type: 'set_submitted', value: true })
        let normalized = normalizeComponents(data, initialState)
        if (data.apiError) {
          for (const message of data.messages) {
            toast.error(message)
          }
        }

        if (submit && (data.closeWindow || data.apiError)) {
          const obj: Record<string, SxpComponent> = {}
          const compArray = formData.componentArray.map((ca) => {
            if (data.closeWindow) {
              delete ca.errorMessage
            }
            return ca
          })
          for (const comp in formData.components) {
            const temp = formData.components[comp]
            if (data.closeWindow) {
              delete temp.errorMessage
            }
            obj[comp] = temp
          }
          normalized = {
            ...normalized,
            componentArray: compArray,
            components: obj,
          }
          if (data.closeWindow) {
            toast.success('Patient Registered')
            normalized = {
              ...normalized,
              state: { ...initialState, steps: normalized.state.steps },
            }
            setTimeout(() => {
              setLoading(true)
              if (redirectTo === 'patients') {
                navigate('/patients')
              } else {
                navigate('/membership')
              }
              setLoading(true)
            }, 2000)
          }
          setActiveIndex(0)
        } else if (!data.error || previous) {
          setActiveIndex((ps: number) => (previous ? ps - 1 : ps + 1))
        } else {
          const index = refs[normalized?.error?.reference ?? '']
          if (index && steps.length > 1) {
            setActiveIndex(index)
          }
        }
        dispatch({
          type: 'store_data',
          data: normalized,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handlePrevious = () => {
    submitForm(false, true)
  }

  const handleNext = () => {
    const isSubmit = activeIndex === steps.length - 1
    submitForm(isSubmit, false)
  }

  const handlePhotoChange = (photo: string) => {
    dispatch({
      type: 'update_state',
      reference: 'photo',
      value: photo,
    })
  }

  const service = formData.state['service']
  const photo = formData.state['photo']
  const navigateBack = () => {
    navigate('/patients')
  }

  return (
    <>
      <div className='patient-registration-container'>
        <IconButton onClick={navigateBack} className='registration-back-icon'>
          <KeyboardBackspaceIcon className='keyboard-space-icon' />
        </IconButton>
        {loading && <Loading />}
        <SxpFormContext.Provider value={formData}>
          <SxpFormDispatchContext.Provider value={dispatch}>
            <FormStepper steps={steps} activeIndex={activeIndex} />
            <div className='form-wrapper'>
              <StepElements index={activeIndex} kind={service as string} />
              {(service === RegistrationTypes?.full ||
                service === RegistrationTypes?.quick ||
                service === RegistrationTypes?.camp) && (
                <ProfilePicture
                  photo={photo as string}
                  onPhotoChange={handlePhotoChange}
                />
              )}
              <div className='steps-content '>
                {steps.length > 1 && (
                  <button
                    className={`btn btn-primaryBtn mr10 ${
                      activeIndex === 0 ? 'disableBtn' : ''
                    }`}
                    disabled={activeIndex === 0}
                    onClick={handlePrevious}
                  >
                    {previous}
                  </button>
                )}
                <button
                  className='btn btn-primaryBtn'
                  onClick={handleNext}
                  disabled={loading}
                >
                  {activeIndex === steps.length - 1
                    ? forms?.submit
                    : forms?.next}
                </button>
              </div>
            </div>
          </SxpFormDispatchContext.Provider>
        </SxpFormContext.Provider>
      </div>
    </>
  )
}

export default PatientRegistration
