export const selectOptions = {
  planType: 'Select Plan Type',
}
export const ABmembership = 'Ayuvu Bharosa Membership'
export const ABMembershipIntent = {
  abMembershipLines: 'abMembershipLines',
  relations: 'relations',
  createMembership: 'createMembership',
  updatePatientRegType: 'updatePatientRegType',
  getPatientById: 'getPatientById',
  checkRegAndRestrict: 'checkRegAndRestrict',
  editMembership: 'editMembership',
  revertMembership: 'revertMembership',
  getPatientsAPI: 'getPatientsAPI',
  abMembershipLine: 'abMembershipLine',
  ABMembershipProducts: 'ABMembershipProducts',
  membershipLines: 'membershipLines',
  benefits: 'benefits',
  checkABMember: 'checkABMember',
  GetcognitivecarePrices: 'GetcognitivecarePrices',
  getjournal: 'getjournal',
  GetPlanAgeBand: 'GetPlanAgeBand',
}
export const MemeberStatus = {
  paid: 'PaidMember',
  none: 'Non Memmber',
  waiting: 'Waiting Member',
  invoiced: 'Invoiced Member',
  canceled: 'Cancelled Member',
  old: 'Old Member',
  Others: 'Others',
}
export const Status = {
  paid: 'paid',
  none: 'none',
  waiting: 'waiting',
  invoiced: 'invoiced',
  canceled: 'canceled',
  old: 'old',
}
export const Expired = 'Expired'
export const Showing = 'Showing'
export const to = 'to'
export const of = 'of'
export const loadingmemebrship = 'Loading Memberships'
export const noMember = 'No Memberships found with uhid'
export const pagesize = [10, 20, 30, 40, 50]
export const membershipCreate = 'create'
export const resize = 'resize'
export const NoMemberinFHIR = 'No Members registered in fhir'
export const Categories = 'Categories'
export const Products = 'Products'
export const noBenefits = 'no benefits found for this membership'
export const InvoiceList = 'InvoicesList:'
export const InvoiceLink = 'Invoice Link'
export const Remove = 'Remove'
export const noMemberFound = 'No Members found'
export const selectChild = 'Select child'
export const selectOption = 'Select an option'
export const selectRelation = 'Select relation'
export const SearchResult = 'Search Results:'
export const changeRegType = 'change the registration Type'
export const editmembermsg = 'Edited Members Successfully'
export const membershipmember = 'already added in another membership'
export const MembershipList = 'Members List:'
export const Save = 'Save'
export const ABMembershipQuick = 'ABMembershipQuick'
export const ABMembershipFull = 'ABMembershipFull'
export const alreadyAvailable =
  'already available in membership.But is in pending state'
export const DetailesSaved = 'Membership Details Saved'
export const existingmember = 'Already available in membership.'
export const failtoCreate = 'Failed To Create Membership'
export const Confirm = 'Confirm'
export const Cancel = 'Cancel'
export const primaryAgevalidation = 'Primary member must be Above'
export const addrelation = 'Add Relation'
export const errorMsg = 'Error occurred'
export const IDENTIFIER = 'identifier'
export const PHONE = 'phone'
export const NAME = 'name'
export const existingMember = 'Member already added'
export const AyuvuBharosa = 'ayuvu_bharosa'
export const SEARCH_ITEMS = ['ID', 'Phone', 'Name']
export const adultType = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
]
export const childType = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
]
export const agesband = [
  {
    name: 35,
    value: '0_35',
  },
  {
    name: 45,
    value: '36_45',
  },
  {
    name: 55,
    value: '46_55',
  },
  {
    name: 65,
    value: '56_65',
  },
  {
    name: 70,
    value: '66_70',
  },
  {
    name: 75,
    value: '70_75',
  },
]
export const COGNITIVE_OPTIONS = [
  { label: 'Yes', value: 'false' },
  { label: 'No', value: 'true' },
]
export const CognitiveCareConst = 'Payment Done - Sales App '
export const planType = 'Plan Type'
export const paymentMode = 'Payment Mode'
export const selectabult = 'Select adult'
export const CognitiveCareInvoice = 'CC Invoice'
export const noinvoice = 'No invoice available'
export const self = 'self'
export const MBR = 'MBR'
export const AB = 'AB'
export const primaryAgeValue = 21
