import { useRef, useEffect, useState } from 'react'
import { AdminTableProps } from '../../models'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsAdminSearch, selectedLmsStatus } from '../../lmsSlice'
import '../../lms.scss'
import ProvenanceView from '../../../../components/ProvenanceView'
import { create, menuValues } from '../../constants'
import { IconButton } from '@mui/material'
import KeycloakService from '../../../../utils/keycloakService'
import { LMS_ADMIN_WRITE, SUPER_ADMIN_DELETE } from '../../../../utils/roles'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import '../../../administration/admin.scss'

import { DeleteConfirmationDialog } from './DeleteConfirmationDialog'
import { Archive } from './Archive'
import { activeText, noData } from '../../../Radiology/Constants/constants'
import MedAgDataGrid from '../../../../components/MedAgDataGrid'
import { radiologyColor } from '../../../Radiology/Utils/RadiologyColors'
import { useTheme } from '@emotion/react'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import CustomHeader from '../../../Radiology/components/RadiologyModel/CustomHeader'
import { ColDef } from 'ag-grid-community'

const styles = () => ({
  centerHeader: {
    color: radiologyColor?.white,
    textAlign: 'center',
    '& .ag-header-cell-text': {
      flex: 1,
    },
  },
  nameButton: {
    backgroundColor: '#0000',
    border: 'none',
    color: 'blue',
    cursor: 'pointer',
    padding: '0px',
    textAlign: 'left',
    textDecoration: 'underline',
  },
  ageType: {
    fontSize: 16,
  },
})

const TestRangeTable = ({ data, onEdit }: AdminTableProps) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const status = useAppSelector(selectedLmsStatus)
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)
  const [tableData, setTableData] = useState<any>(data)
  const [testRangeData, setTestRangeData] = useState<any>({})
  const filtered = tableData?.filter((d: any) => {
    const name = d?.lab_test?.name
      ?.toLowerCase()
      ?.includes(searchParam.toLowerCase())
    const rangeStatus = d?.status === status
    return name && rangeStatus
  })
  filtered?.sort?.((a: any, b: any) => {
    const p1 = a.lab_test?.panel
    const p2 = b.lab_test?.panel
    if (p1?.name < p2?.name) {
      return -1
    } else if (p1?.name > p2?.name) {
      return 1
    } else {
      if (a.lab_test?.name?.toLowerCase() < b.lab_test?.name?.toLowerCase()) {
        return -1
      } else if (
        a.lab_test?.name?.toLowerCase() > b.lab_test?.name?.toLowerCase()
      ) {
        return 1
      } else {
        return a.id < b.id ? -1 : 1
      }
    }
  })
  const paramRangeTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = paramRangeTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  useEffect(() => {
    setTableData(data)
  }, [data])

  function handleDeleteDialogOpen(d: any) {
    setDeleteDialog(true)
    setTestRangeData(d)
  }

  function handleCloseDeleteDialog() {
    setDeleteDialog(false)
    setTestRangeData(null)
  }
  const renderName = (params: any) => {
    return KeycloakService.hasRole([LMS_ADMIN_WRITE]) ? (
      <button
        onClick={() => onEdit(params?.data?.id)}
        className={classes?.nameButton}
      >
        {params?.data?.lab_test.name}
      </button>
    ) : (
      <span>{params?.data?.lab_test.name}</span>
    )
  }
  const ageRange = (params: any) => {
    return (
      <>
        {params?.data?.min_age || params?.data?.max_age ? (
          <span>
            {params?.data?.min_age ?? 0} to{' '}
            {params?.data.max_age ?? (
              <span className={classes?.ageType}>&infin;</span>
            )}{' '}
            {params?.data?.age_type ?? 'y'}
          </span>
        ) : (
          noData
        )}
      </>
    )
  }
  const ValueRange = (params: any) => {
    return (
      <>
        {params?.data?.min_value || params?.data?.max_value ? (
          <span>
            {params?.data?.min_value ?? 0} to {params?.data?.max_value}
          </span>
        ) : params?.data?.abnormal ? (
          <span>{params?.data.abnormal}</span>
        ) : params?.data?.ref_range ? (
          <div className='range-column'>
            {params?.data?.ref_range
              ?.split(',')
              ?.map((rangeValue: any, i: number) => (
                <span key={i}>{rangeValue?.trim()}</span>
              ))}
          </div>
        ) : (
          noData
        )}
      </>
    )
  }
  const createProvenanceView = (params: any) => {
    return (
      <ProvenanceView
        mode={create}
        date={params?.data?.created_date ?? ''}
        name={params?.data?.created_by ?? '-'}
      />
    )
  }
  const updatedProvenanceView = (params: any) => {
    return (
      <ProvenanceView
        mode={create}
        date={params?.data?.updated_date ?? ''}
        name={params?.data?.updated_by ?? '-'}
      />
    )
  }
  const RenderActions = (params: any) => {
    return (
      <div className='set-action-icons'>
        {params?.data?.status === activeText ? (
          <IconButton
            title='Archive'
            size='small'
            onClick={() =>
              Archive(params?.data, setTableData, menuValues?.ParameterRange)
            }
            disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
          >
            <ArchiveOutlinedIcon fontSize='small' />
          </IconButton>
        ) : (
          <IconButton
            title='un-Archive'
            size='small'
            onClick={() =>
              Archive(params?.data, setTableData, menuValues?.ParameterRange)
            }
            disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
          >
            <UnarchiveOutlinedIcon fontSize='small' />
          </IconButton>
        )}
        <IconButton
          title='Delete'
          size='small'
          onClick={() => handleDeleteDialogOpen(params?.data)}
          disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
        >
          <DeleteOutlined fontSize='small' />
        </IconButton>
      </div>
    )
  }
  const columnDefs: ColDef[] = [
    {
      headerName: 'Id',
      field: 'id',
      width: 70,
      sortable: true,
      filter: true,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Parameter Name',
      field: 'name',
      sortable: true,
      filter: true,
      headerClass: classes?.centerHeader,
      cellRenderer: renderName,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'left', whiteSpace: 'normal' },
      minWidth: 300,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Test Name',
      field: 'patientUhid',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => params?.data?.lab_test.panel?.name ?? '-',
      cellClass: 'custom-header-cell',
      headerClass: classes?.centerHeader,
      cellStyle: {
        fontSize: '12px',
        textAlign: 'left',
        whiteSpace: 'normal !important',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        lineHeight: 1.5,
      },
      flex: 1,
      wrapText: true,
      minWidth: 270,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Gender',
      field: 'gender',
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      cellRenderer: (params: any) => params?.data?.gender ?? '-',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      headerComponent: CustomHeader,
      minWidth: 120,
    },
    {
      headerName: 'Method',
      field: 'method',
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellRenderer: (params: any) =>
        params?.data?.test_method?.method_name ?? '-',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'left',
        whiteSpace: 'normal !important',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        lineHeight: 1.5,
      },
      flex: 1,
      wrapText: true,
      minWidth: 166,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Unit Type',
      field: 'unit type',
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellRenderer: (params: any) =>
        params?.data?.test_unit_type?.unit_type ?? '-',
      cellStyle: { fontSize: '12px' },
      minWidth: 166,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Age Range',
      field: 'age',
      sortable: true,
      filter: true,
      cellRenderer: ageRange,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 166,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Value Range',
      field: 'patientMobile',
      sortable: true,
      filter: true,
      cellRenderer: ValueRange,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 166,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Created By',
      field: 'created by',
      sortable: true,
      filter: true,
      cellRenderer: createProvenanceView,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 166,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Updated By',
      field: 'patientMobile',
      sortable: true,
      filter: true,
      cellRenderer: updatedProvenanceView,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 166,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      sortable: true,
      filter: true,
      cellRenderer: RenderActions,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 166,
      headerComponent: CustomHeader,
    },
  ]
  return (
    <>
      {deleteDialog && (
        <DeleteConfirmationDialog
          openDialog={deleteDialog}
          closeDialog={handleCloseDeleteDialog}
          data={testRangeData}
          name={testRangeData?.lab_test?.name}
          setData={setTableData}
          item={menuValues?.ParameterRange}
        />
      )}
      <MedAgDataGrid
        tableHeight={'100%'}
        rowData={filtered}
        columnDefs={columnDefs}
        pagination={false}
        headerHeight={30}
        rowHeight={40}
        searchFieldWidth={200}
        searchField={false}
        loading={false}
        exportIcon={false}
      />
    </>
  )
}

export default TestRangeTable
