import { Patient } from 'fhir/r4'
import { useState, useEffect, useRef } from 'react'
import { startSxpProxy } from '../../../utils/api'
import {
  AB_MEMBERHSIP_PROJECT_ID,
  CHAT_PROJECT_ID,
  MEMBERSHIP_PROJECT_ID,
} from '../../../utils/constants'
import { makeName } from '../../lms/utils'
import '../../membership/membership.scss'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { useAppSelector } from '../../../app/hooks'
import { selectSelectedLocationId } from '../../location/locationSlice'
import SelectableSearch from '../../../components/SelectableSearch'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ToastMessage from '../../lms/components/ToastMessage'
import { toastOptions } from '../../lms/models'
import {
  ABMemberPatient,
  MembershipModel,
  SearchPatient,
} from '../../membership/models'
import { IconButton } from '@mui/material'
import EditABMemberTable from './EditMemberTable'
import SearchABPatients from './SearchPatients'
import {
  ABMembershipIntent,
  alreadyAvailable,
  IDENTIFIER,
  MBR,
  membershipmember,
  NAME,
  PHONE,
  resize,
  SEARCH_ITEMS,
  Status,
} from '../constants'
import { ABMemebrship } from '../../bedManagement/endpoints/EndPoints'
import { GENDER_OPTIONS } from '../../ipRegistration/Constants'

const EditABPatientSearch = () => {
  const location = useAppSelector(selectSelectedLocationId)

  const patientsTableContainerRef = useRef<HTMLDivElement | null>(null)
  const [patients, setPatients] = useState<SearchPatient[]>([])
  const [previousMembers, setPreviousMembers] = useState<ABMemberPatient[]>([])
  const [members, setMembers] = useState<ABMemberPatient[]>([])
  const [plantype, setPlanType] = useState('')
  const { id } = useParams()

  const navigate = useNavigate()
  const [show, setShow] = useState<boolean>(false)

  const handleSearch = (query: string, param: string) => {
    if (!query) {
      setPatients([])
      setShow(false)
      return
    }
    setShow(true)
    const intent = ABMembershipIntent?.getPatientsAPI
    let q = ''
    switch (query) {
      case 'ID':
        q = IDENTIFIER
        break
      case 'Phone':
        q = PHONE
        break
      case 'Name':
        q = NAME
        break
    }
    const state = {
      pageSize: 20,
      query: q,
      value: param ? param : undefined,
    }
    startSxpProxy(CHAT_PROJECT_ID, intent, state)
      ?.then((data) => {
        if (data?.data?.entry?.length) {
          setPatients(formatPatients(data?.data?.entry))
        } else {
          setPatients([])
        }
      })
      ?.catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    startSxpProxy(
      AB_MEMBERHSIP_PROJECT_ID,
      ABMembershipIntent?.abMembershipLine,
      {
        location,
        lineId: id,
      }
    )
      ?.then((data: any) => {
        const fetchedMembershipDetail = data?.data
        const uhid = data?.data?.[0]?.partner?.ref
        const associatedUhid = data?.data?.[0]?.partner_ids?.map(
          (e: { ref: any }) => e?.ref
        )
        setPlanType(
          (data?.data?.[0]?.adult || 0) + (data?.data?.[0]?.child || 0)
        )
        const memberUhids = []
        memberUhids?.push(uhid)
        memberUhids?.push(associatedUhid)
        const memberUhidsArray = [memberUhids?.[0], ...memberUhids[1]]
        const membersOne = memberUhidsArray?.join(',')
        const state = { patientIdentifier: membersOne }
        startSxpProxy(
          MEMBERSHIP_PROJECT_ID,
          ABMembershipIntent?.getPatientById,
          state
        )
          ?.then((data) => {
            const memberDetails = data?.data?.data?.entry?.map(
              (e: { resource: any }) => e?.resource
            )
            const uhidObj = memberDetails?.reduce(
              (acc: any, cur: { identifier: { value: any }[] }) => {
                const uhid = cur.identifier?.[2]?.value
                return { ...acc, [uhid]: cur }
              },
              {}
            )
            const memberDetailArray: ABMemberPatient[] = memberUhidsArray?.map(
              (d, i) => {
                const role = uhidObj?.[d]
                const relation =
                  fetchedMembershipDetail?.[0]?.membership_partner_ids?.find(
                    (partner: any) =>
                      partner?.partner_id?.ref === role?.identifier?.[2]?.value
                  )?.relation_with_primary_partner
                return {
                  id: role?.id ?? '',
                  uhid: role?.identifier?.[2]?.value,
                  name: makeName(role?.name),
                  registration: role?.identifier?.[3]?.value,
                  gender:
                    role?.gender === GENDER_OPTIONS?.[0]?.value
                      ? 'Male'
                      : role?.gender === GENDER_OPTIONS?.[1]?.value
                      ? 'Female'
                      : 'Others',
                  dob: role?.birthDate ?? '',
                  thopId: role?.identifier?.[0]?.value ?? '',
                  isSelected: true,
                  isNominee: i === 0 ? true : false,
                  adultChildCount: (d?.adult || 0) + (d?.child || 0),
                  relation_with_primary_partner: relation || '',
                }
              }
            )
            setMembers(memberDetailArray)
            setPreviousMembers(memberDetailArray)
          })
          ?.catch((err) => {
            console?.error(err)
          })
      })
      ?.catch((err) => {
        console.error(err)
      })
  }, [id, location])

  const formatPatients = (entry: { resource: Patient }[]): SearchPatient[] => {
    return (
      entry?.map((en) => {
        const gender = en?.resource?.gender
        const pat: SearchPatient = {
          id: en?.resource?.id ?? '',
          name: makeName(en?.resource?.name),
          gender:
            gender === GENDER_OPTIONS?.[0]?.value
              ? 'Male'
              : gender === GENDER_OPTIONS?.[1]?.value
              ? 'Female'
              : 'Others',
          dob: en?.resource?.birthDate ?? '',
          uhid: en?.resource?.identifier?.[2]?.value ?? '',
          thopId: en?.resource?.identifier?.[0]?.value ?? '',
          registration: en?.resource?.identifier?.[3]?.value ?? 'ad',
          isSelected: false,
          isNominee: false,
        }
        return pat
      }) ?? []
    )
  }
  const handleMemberSelect = (id: string, relation: string) => {
    const selectedMember = patients?.find((member) => member?.id === id)
    if (selectedMember) {
      const newMember: any = {
        ...selectedMember,
        relation,
      }
      startSxpProxy(
        MEMBERSHIP_PROJECT_ID,
        ABMembershipIntent?.membershipLines,
        {
          location,
          partner: selectedMember?.uhid,
        }
      )
        ?.then(async (data) => {
          const membershipLines: MembershipModel[] = data?.data?.data ?? []
          const hasConflictingMembership = membershipLines?.some(
            (item) =>
              item?.name?.split('/')?.[0] !== MBR &&
              [Status?.waiting, Status?.invoiced, Status?.paid]?.includes(
                item?.state
              )
          )
          if (membershipLines?.length === 0 || !hasConflictingMembership) {
            setMembers((prev) => [...prev, newMember])
          } else {
            if (hasConflictingMembership) {
              toast(<ToastMessage message={alreadyAvailable} />, {
                ...toastOptions,
                type: 'error',
              })
            } else {
              toast(<ToastMessage message={membershipmember} />, {
                ...toastOptions,
                type: 'error',
              })
            }
          }
        })
        ?.catch((err) => {
          toast(<ToastMessage message={err?.response?.data?.error} />, {
            ...toastOptions,
            type: 'error',
          })
        })
    }
  }
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientsTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 25}px`
      }
    }

    window.addEventListener(resize, resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener(resize, resizeHandler)
    }
  }, [])
  const handleMemberUnSelect = (id: string) => {
    const member = members?.filter((d) => d?.id !== id)
    setMembers(member)
  }
  const handleMembership = () => {
    navigate(ABMemebrship)
  }

  return (
    <>
      <div className='layoutcontent'>
        <div className='overallContainer'>
          <div>
            <IconButton
              onClick={handleMembership}
              className='membership-icon-btn'
            >
              <KeyboardBackspaceIcon className='backspace-icon-membership-view' />
            </IconButton>
          </div>
          <div className='flexDiv'>
            <SelectableSearch items={SEARCH_ITEMS} onSearch={handleSearch} />
          </div>
        </div>
        <EditABMemberTable
          members={members}
          onMemberUnSelect={handleMemberUnSelect}
          previousMembers={previousMembers}
        />
        {show && (
          <SearchABPatients
            patients={patients}
            onMemberSelect={handleMemberSelect}
            members={members}
            plantype={plantype}
          />
        )}
      </div>
    </>
  )
}
export default EditABPatientSearch
