import { useAppDispatch } from '../../../app/hooks'
import { useNavigate } from 'react-router-dom'
import KeycloakService from '../../../utils/keycloakService'
import { PATIENTS_WRITE } from '../../../utils/roles'
import SelectableSearch from '../../../components/SelectableSearch'
import {
  fetchPatientsAsync,
  setPatientSearchQueryValue,
  setRedirectTo,
} from '../patientsSlice'
import { create } from '../../Radiology/Constants/constants'
import FilterButton from '../filter/Filter'
import { getallRegType, OPRegistration, RegistrationType } from '../constants'
import { useEffect, useState } from 'react'
import { startSxpProxy } from '../../../utils/api'
import { REGISTRATION_PROJECT_ID } from '../../../utils/constants'
import { registration } from '../../bedManagement/endpoints/EndPoints'

const PatientSearch = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [selectedType, setSelectedType] = useState<any[]>([])
  const [regType, setSelectedRegType] = useState<string>('')
  const handleSearch = (query: any, param: string) => {
    let q = ''
    switch (query) {
      case 'ID':
        q = 'identifier'
        break
      case 'Phone':
        q = 'phone'
        break
      case 'Name':
        q = 'name'
        break
    }
    dispatch(setPatientSearchQueryValue({ query: q, value: param }))
    dispatch(fetchPatientsAsync())
  }

  const registerPatient = () => {
    if (KeycloakService.hasRole([PATIENTS_WRITE])) {
      dispatch(setRedirectTo('patients'))
      navigate(registration)
    }
  }
  const getRegistrationType = async () => {
    try {
      const intent = getallRegType
      const resp = await startSxpProxy(REGISTRATION_PROJECT_ID, intent)

      if (resp?.data?.fhir_registration_types) {
        setSelectedType(resp?.data?.fhir_registration_types)
      }
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    getRegistrationType()
  }, [])
  const handleRegTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value
    setSelectedRegType(selectedValue)
    handleSearch('ID', selectedValue)
  }

  return (
    <div className='patient-filter'>
      <div className='admin-header-text'>{OPRegistration}</div>
      <div className='patient-search'>
        <div>
          <select value={regType} onChange={handleRegTypeChange}>
            <option value=''>{RegistrationType}</option>
            {selectedType?.map((type) => (
              <option key={type?.id} value={type?.value}>
                {type?.name}
              </option>
            ))}
          </select>
        </div>
        <FilterButton />
        <SelectableSearch
          items={['ID', 'Phone', 'Name']}
          onSearch={handleSearch}
        />
        <button
          className={`btn btn-primaryBtn ml10 ${
            KeycloakService.hasRole([PATIENTS_WRITE]) ? '' : 'auth-disabled'
          }`}
          onClick={registerPatient}
        >
          {create}
        </button>
      </div>
    </div>
  )
}

export default PatientSearch
