import {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react'
import { startSxpProxy, uploadImage } from '../../../utils/api'
import { FILE_SERVER_URL, OPD_PROJECT_ID } from '../../../utils/constants'
import { toast } from 'react-toastify'
import Lightbox from '../../../components/LightBox/LightBox'
import Loading from '../../../form/fields/Loading'
import uploadIcon from '../../../assets/icons/Upload.svg'
import ToastMessage from '../../lms/components/ToastMessage'
import { imageValidationRegex } from '../constants'
import { sendSocketData } from '../../Notification/Socket'
import { NEW_IPD_DATA_UPDATE } from '../../Notification/Constants'

interface Document {
  docId: string
  title: string
  url: string
}

interface UploadDocsProps {
  patientId: any
  muhid?: string | number
}

const UploadDocs: React.FC<UploadDocsProps> = ({ patientId, muhid }) => {
  const docsRef = useRef<HTMLInputElement | null>(null)
  const [uploadedDoc, setUploadedDoc] = useState<Document[]>([])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedDocUrl, setSelectedDocUrl] = useState('')
  const [urlIndex, setUrlIndex] = useState<number>(-1)
  const [urls, setUrls] = useState<string[]>([])
  const [isOnlyOneImage, setIsOnlyOneImage] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [closeLightbox, setCloseLightbox] = useState<boolean>(true)

  const openLightbox = (index: number) => {
    setCloseLightbox(true)
    setUrlIndex(index)
  }

  const urlsArray = useMemo(
    () => uploadedDoc?.map((item: any) => item?.url),
    [uploadedDoc]
  )

  const fetchPatientDocs = useCallback(async () => {
    setLoading(true)
    try {
      const intent = 'getPatientReports'
      const state = {
        patientId: patientId,
      }
      const resp = await startSxpProxy(OPD_PROJECT_ID, intent, state)
      if (resp?.data?.entry) {
        const docData: Document[] = resp?.data?.entry?.map((e: any) => ({
          docId: e?.resource?.id,
          url: e?.resource?.content?.[0]?.attachment?.url,
          title: e?.resource?.content?.[0]?.attachment?.title,
        }))
        setUploadedDoc(docData)
      } else {
        setUploadedDoc([])
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }, [patientId])

  useEffect(() => {
    fetchPatientDocs()
  }, [patientId, fetchPatientDocs])

  useEffect(() => {
    const imageUrls = urlsArray?.filter(
      (selectedDocUrl: string) => !selectedDocUrl?.endsWith('.pdf')
    )

    if (imageUrls?.length === 1) {
      setIsOnlyOneImage(true)
    } else {
      setIsOnlyOneImage(false)
    }
  }, [urlsArray])

  const uploadPatientDocs = async (urls: string, title = '') => {
    if (urls) {
      setLoading(true)

      try {
        const intent = 'uploadPatientReport'
        const state = {
          patientId: patientId,
          reportUrl: urls,
          title: title,
        }
        const resp: any = await startSxpProxy(OPD_PROJECT_ID, intent, state)
        if (resp?.data) {
          sendSocketData({
            messageType: NEW_IPD_DATA_UPDATE,
            patientID: muhid ?? '',
            patientLocation: '',
            message: '',
          })
          const docData: Document[] = [
            {
              docId: resp?.data?.id,
              url: resp?.data?.content?.[0]?.attachment?.url,
              title: resp?.data?.content?.[0]?.attachment?.title,
            },
          ]
          setUploadedDoc((prev) => [...prev, ...docData])
        }
        setLoading(false)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const handleUploadDoc = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true)

    const files = e.target.files
    if (files?.length) {
      const formData = new FormData()
      formData.append('file', files[0])
      const fileName = files?.[0]?.name ?? ''
      try {
        const response = await uploadImage(formData)
        const urls = `${FILE_SERVER_URL}file/${response.data[0]}`
        await uploadPatientDocs(urls, fileName)
      } catch (err) {
        console.error('error uploading the file', err)
      }
    } else {
      console.error('----- No --------')
    }
    setLoading(false)
  }

  const handlePreview = (url: string, index: number) => {
    setSelectedDocUrl(url)
    setIsModalOpen(true)
    setCloseLightbox(true)
    setUrlIndex(index)
    openLightbox(index)
    setUrls(urlsArray)
  }

  const handleDownload = (url: string) => {
    const link = document.createElement('a')
    link.href = url
    link.download = ''
    link.target = '_blank'
    link.rel = 'noopener noreferrer'
    link?.click()
  }

  const handleDelete = async (DocId: number) => {
    try {
      const intent = 'deletePatientReport'
      const state = {
        id: DocId,
      }
      await startSxpProxy(OPD_PROJECT_ID, intent, state)
      sendSocketData({
        messageType: NEW_IPD_DATA_UPDATE,
        patientID: muhid ?? '',
        patientLocation: '',
        message: '',
      })
      toast(<ToastMessage message={'Report Deleted Successfully'} />, {
        position: 'bottom-center',
        hideProgressBar: true,
        closeButton: false,
        theme: 'dark',
        autoClose: 1500,
        data: {
          completed: true,
        },
      })
      await fetchPatientDocs()
    } catch (e) {
      console.error(e)
    }
  }

  const DocumentModal = () => {
    const handleCloseModal = () => {
      setIsModalOpen(false)
      setSelectedDocUrl('')
    }
    return (
      <div className={`document-modal ${isModalOpen ? 'open' : ''}`}>
        {selectedDocUrl?.endsWith('.pdf') && (
          <div className='document-modal-content'>
            <button className='close-modal-button' onClick={handleCloseModal}>
              Close
            </button>
            <iframe
              src={selectedDocUrl}
              width='100%'
              height='100%'
              title='Document Preview'
            />
          </div>
        )}
        {urlIndex !== -1 &&
          imageValidationRegex?.test(urls?.[urlIndex]) &&
          closeLightbox && (
            <Lightbox
              images={urlsArray}
              isOnlyOneImage={isOnlyOneImage}
              imageIndex={urlIndex}
              image={urls?.[urlIndex]}
              title='Image Viewer'
              onClose={() => {
                setCloseLightbox(false)
                setIsModalOpen(false)
                setSelectedDocUrl('')
              }}
            />
          )}
      </div>
    )
  }

  const handleIconClick = () => {
    if (docsRef?.current) {
      docsRef?.current?.click()
    }
  }

  return (
    <div className='DocContainer'>
      <div className='mainCard'>
        <h3>
          <div className='docViewBody'>
            <div className='docUploadBtn'>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between !important',
                }}
              >
                <label htmlFor='docUrl'>
                  <span className='docUrl'>Patient Documents </span>
                </label>
                <img
                  title='Upload Documents'
                  src={uploadIcon}
                  alt='upload button'
                  onClick={handleIconClick}
                  style={{ cursor: 'pointer' }}
                />
              </div>
              <input
                id='docUrl'
                ref={docsRef}
                type='file'
                accept='image/*, application/pdf'
                style={{ display: 'none' }}
                onChange={handleUploadDoc}
              />
            </div>
          </div>
        </h3>
        <div className='docView'>
          <ul className='docListView'>
            {uploadedDoc?.length > 0 &&
              uploadedDoc?.map((data: any, index: number) => {
                const { docId, url } = data
                const serialNumber = index + 1

                return (
                  <li className='docListItem' key={index}>
                    <div className='docItemInfo'>
                      <div className='docItemSerial'>{`${serialNumber} - `}</div>
                      <div className='docItemTitle'>{data?.title}</div>
                      <div className='docItemButtons'>
                        <button
                          className='docItemButton'
                          onClick={() => handlePreview(url, index)}
                        >
                          Preview
                        </button>
                        <button
                          className='docItemButton'
                          onClick={() => handleDownload(url)}
                        >
                          Download
                        </button>
                        <button
                          className='docItemButton'
                          onClick={() => handleDelete(docId)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
      {isModalOpen && <DocumentModal />}
      {loading && <Loading />}
    </div>
  )
}

export default UploadDocs
