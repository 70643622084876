import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  fetchPatientsAsync,
  PatientData,
  resetPatientStatus,
  selectPatientsData,
} from './patientsSlice'
import PatientSearch from './components/PatientSearch'
import PatientTable from './components/PatientTable'
import PatientNavigation from './components/PatientNavigation'
import { makeName } from '../lms/utils'
import { getTotalAge } from '../../utils/dateUtils'

export type FormattedPatient = {
  photo?: any
  id: string
  name: string
  gender: string
  age: string
  fhirId: string
  thopId: string
  invoiceId: string
  registeredOn: string
  registrationType: string
  mobile: string
}

const Patients = () => {
  const patients = useAppSelector(selectPatientsData)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchPatientsAsync())
    return () => {
      dispatch(resetPatientStatus())
    }
  }, [dispatch])

  const capitalizeFirstLetter = (word?: string) => {
    if (!word) return '-'

    return word.charAt(0).toUpperCase() + word.slice(1)
  }

  const formatted: FormattedPatient[] = patients?.map((p: PatientData) => {
    const thop = p.resource?.identifier?.[0]
    const bahmni = p.resource?.identifier?.[2]
    const created = p.resource?.identifier?.[3]
    const regType = p.resource?.identifier?.[3]
    const invoice = p.resource?.identifier?.[5]
    const name = makeName(p.resource.name)
    const age = getTotalAge(p.resource?.birthDate)
    const gender = capitalizeFirstLetter(p.resource?.gender)
    const tel1 = p.resource?.telecom?.find((prt) => prt.use === 'mobile')
    const tel2 = p.resource?.telecom?.find((prt) => prt.use === 'home')
    return {
      id: bahmni?.value ?? thop?.value ?? p.resource?.id ?? '',
      name: name.trim() ?? '',
      gender: gender,
      age: age,
      fhirId: p.resource?.id ?? '',
      thopId: thop?.value ?? '',
      invoiceId: invoice?.value ?? '',
      registeredOn: created?.period?.start ?? '',
      registrationType: regType?.value ?? '',
      mobile: tel1?.value ?? tel2?.value ?? '',
    }
  })

  return (
    <div className='layoutcontent'>
      <PatientSearch />
      <PatientTable patients={formatted} />
      <PatientNavigation />
    </div>
  )
}

export default Patients
