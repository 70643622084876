import React, { forwardRef } from 'react'
import logo from '../../../assets/icons/logo.svg'
import locationIcon from '../../../assets/icons/location.svg'
import mobileIcon from '../../../assets/icons/mobile.svg'
import emailIcon from '../../../assets/icons/Email.svg'
import vector from '../../../assets/icons/Vector.svg'
import { getRegistrationNo } from './PatientRegCardPrint'
import { Patient } from 'fhir/r4'
import { getTotalAge, readableDateTime } from '../../../utils/dateUtils'
import { getFilterdData } from './OpCaseSheetPrint'
import { getLocationAddress } from '../utils'
import { useAppSelector } from '../../../app/hooks'
import { selectLocations } from '../../location/locationSlice'
import { emptyString } from '../../Radiology/Constants/constants'
import { profilePicture } from '../constants'
import { allocation } from '../../bedManagement/modals'

type PatientProps = {
  patient: Patient
  diagnosisList: any
  labOrderList: any
  medications: any
  visitsList: any
}

const EPrescriptionPrint = ({
  patient,
  visitsList,
  medications,
  labOrderList,
  diagnosisList,
}: PatientProps) => {
  const { name, gender, birthDate } = patient
  const { drName, department, date, drRegistrationNo, designation, signature } =
    visitsList

  const registrationNoData: any = getRegistrationNo(patient)

  const registrationNo = registrationNoData?.regNo?.value
    ? registrationNoData?.regNo?.value
    : registrationNoData?.thopId?.value

  const locations = useAppSelector(selectLocations)
  const locationAddress = getLocationAddress(registrationNo, locations)

  const patientName = `${name?.[0]?.given?.[0]} ${
    name?.[0]?.given?.[1] ? name?.[0]?.given?.[1] : ''
  } ${name?.[0]?.family}`

  const age = birthDate ? getTotalAge(new Date(birthDate)) : ''

  const DrName = `${drName?.[0]?.given?.[0]} ${
    drName?.[0]?.given?.[1] ? drName?.[0]?.given?.[1] : ''
  } ${drName?.[0]?.family}`

  const appointmentDate = readableDateTime(date)

  const diagnosisData = getFilterdData(diagnosisList)
  const finalDiagnosis = diagnosisData?.ProvisionalDiagnosis
  const ListLabOrderList =
    labOrderList?.length > 0 ? labOrderList?.[0]?.panels : labOrderList

  return (
    <div>
      <link
        href='https://fonts.googleapis.com/css?family=Roboto'
        rel='stylesheet'
      />
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n            body{margin: 0; padding: 0;}\n          *{box-sizing: border-box;}\n            @page :left {\n                margin-left: 0cm;\n                margin-right: 0cm;\n                margin-top: 0cm;\n                margin-bottom: 0cm;\n            }\n            @page :right {\n                margin-left: 0cm;\n                margin-right: 0cm;\n                margin-top: 0cm;\n                margin-bottom: 0cm;\n            }\n      \n        ',
        }}
      />
      <div
        className='opCaseSheetMain'
        style={{ marginBottom: '50px', marginTop: '10px' }}
      >
        <table>
          <tbody>
            <tr>
              <td>
                <div>
                  <div className='RegCardMain'>
                    <div className='regCardHeader'>
                      <div className='medUniLogo'>
                        <img src={logo ?? ''} alt='Medunited Logo' />
                      </div>
                      <div className='medUniAdd'>
                        <span style={{ display: 'block' }}>
                          <img src={vector} alt='stethoscope icon' />
                          <p
                            style={{
                              fontWeight: '600',
                              fontSize: '14px',
                              marginLeft: '5px',
                            }}
                          >
                            {`Dr. ${DrName}`}
                            <span
                              style={{
                                fontWeight: '400',
                                fontSize: '12px',
                                marginLeft: '5px',
                              }}
                            >
                              {`(${designation})`}
                            </span>
                            <span
                              style={{
                                fontWeight: '400',
                                fontSize: '10px',
                                marginLeft: '5px',
                              }}
                            >
                              Registration No:{drRegistrationNo ?? ''}
                            </span>
                          </p>
                        </span>
                        {/* <p
                          style={{
                            display: 'block',
                            marginTop: '0px',
                            fontSize: '10px',
                          }}
                        >
                          Registration No:{drRegistrationNo}
                        </p> */}
                        <span>
                          <img src={locationIcon ?? ''} alt='location icon' />
                          <p> {locationAddress?.address?.line?.[0]}</p>
                          <br />
                          <span>
                            <img src={mobileIcon ?? ''} alt='mobile icon' />
                            <p> {locationAddress?.telecom?.[0]?.value}</p>
                          </span>
                          &nbsp;&nbsp;&nbsp;
                          <span>
                            <img src={emailIcon ?? ''} alt='email icon' />
                            <p
                              style={{
                                marginLeft: '5px',
                                lineHeight: '11px',
                              }}
                            >
                              {locationAddress?.telecom?.[1]?.value}
                            </p>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className='epresBody'>
                      <div className='epresTitle'>
                        <h4> e-Prescription</h4>
                      </div>
                      <hr
                        style={{ marginTop: '-25px' }}
                        className='horizontalLine'
                      />

                      <div className='headerRibbon'>
                        <div className='flexOne'>
                          <div className='headerRibbon'>
                            <div className='flexOne patientUHID'>
                              <p>Date of Consultation :</p>
                            </div>
                            <div style={{ flex: 1, marginTop: '15px' }}>
                              <span>{appointmentDate ?? ''}</span>
                            </div>
                          </div>
                          <div className='headerRibbon'>
                            <div className='flexOne patientUHID'>
                              <p>Name :</p>
                            </div>
                            <div style={{ flex: 1, marginTop: '15px' }}>
                              <span>{patientName ?? ''}</span>
                            </div>
                          </div>
                        </div>
                        <div className='flexOne'>
                          <div className='headerRibbon'>
                            <div className='flexOne patientUHID'>
                              <p>Department :</p>
                            </div>
                            <div
                              style={{
                                flex: 1,
                                marginTop: '15px',
                                marginLeft: '-70px',
                              }}
                            >
                              <span>{department ?? ''}</span>
                            </div>
                          </div>
                          <div className='headerRibbon'>
                            <div className='flexOne patientUHID'>
                              <p>Gender :</p>
                            </div>
                            <div style={{ flex: 1, marginTop: '15px' }}>
                              <span>{gender ?? ''}</span>
                            </div>
                            <div className='flexOne patientUHID'>
                              <p>Age :</p>
                            </div>
                            <div
                              style={{
                                flex: 1,
                                marginTop: '15px',
                                marginLeft: '-50px',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <span>{age ?? ''}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className='wongPainScoreLine'>
                          <span>Diagnosis</span>
                        </p>
                        <hr
                          style={{ marginTop: '-15px' }}
                          className='horizontalLine'
                        />
                        <div>
                          <span
                            className='caseSheetSummery'
                            style={{ maxHeight: '90px' }}
                          >
                            {finalDiagnosis ?? ''}
                          </span>
                        </div>
                        <p className='wongPainScoreLine '>
                          <span>Treatment</span>
                        </p>
                        <hr
                          // style={{ marginTop: '-15px' }}
                          className='horizontalLine'
                        />
                        <div
                          style={{
                            display: 'inline-block',
                            minHeight: '150px',
                            width: '100%',
                          }}
                        >
                          <table
                            className='medication-table'
                            style={{
                              borderCollapse: 'collapse',
                              border: '1px solid #DCDCDC',
                              tableLayout: 'fixed',
                            }}
                          >
                            <thead>
                              <tr>
                                <th
                                  className='medication-cell-head'
                                  style={{
                                    border: '1px solid #DCDCDC',
                                    padding: '4px',
                                  }}
                                >
                                  No
                                </th>
                                <th
                                  colSpan={5}
                                  className='medication-cell-head'
                                  style={{
                                    border: '1px solid #DCDCDC',
                                    padding: '4px',
                                  }}
                                >
                                  Medicine
                                </th>
                                <th
                                  colSpan={5}
                                  className='medication-cell-head'
                                  style={{
                                    border: '1px solid #DCDCDC',
                                    padding: '4px',
                                  }}
                                >
                                  Dose Detail&apos;s
                                </th>
                                <th
                                  colSpan={2}
                                  className='medication-cell-head'
                                  style={{
                                    border: '1px solid #DCDCDC',
                                    padding: '4px',
                                  }}
                                >
                                  Add Instruction
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {medications?.map((e: any, i: number) => {
                                return (
                                  <tr
                                    key={i + e.drugName}
                                    style={{
                                      borderBottom: '1px solid #DCDCDC',
                                      height: '20px',
                                    }}
                                  >
                                    <td
                                      className='medication-cell'
                                      style={{
                                        borderRight: '1px solid #DCDCDC',
                                        padding: '8px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {i + 1}
                                    </td>
                                    <td
                                      colSpan={5}
                                      className='medication-cell'
                                      style={{
                                        borderRight: '1px solid #DCDCDC',
                                        padding: '8px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      {e?.drug_name ?? ''}
                                    </td>
                                    <td
                                      colSpan={5}
                                      style={{
                                        borderRight: '1px solid #DCDCDC',
                                        padding: '8px',
                                        textAlign: 'left',
                                      }}
                                      className='medication-cell'
                                    >
                                      {`${e?.dose} ${e?.dose_units} ${e?.frequency}`}
                                      {e?.next_visit_date && (
                                        <span>
                                          {', Until:'}
                                          {e?.next_visit_date}
                                        </span>
                                      )}
                                    </td>

                                    <td
                                      style={{
                                        wordSpacing: '-2px',
                                        textTransform: 'capitalize',
                                      }}
                                      colSpan={2}
                                      className='medication-cell'
                                    >
                                      {e?.instructions
                                        ? e?.instructions
                                        : allocation?.hyphen}
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div>
                          <p style={{ color: '#004D91', fontSize: '18px' }}>
                            <span>Special instructions</span>
                          </p>
                          <hr className='horizontalLine' />
                          <div
                            style={{
                              display: 'inline-block',
                            }}
                          >
                            <table className='medication-table '>
                              <thead>
                                <tr>
                                  <th
                                    style={{ width: '63px' }}
                                    className='medication-cell-head'
                                  >
                                    No
                                  </th>
                                  <th
                                    style={{ minWidth: '200px' }}
                                    colSpan={2}
                                    className='medication-cell-head'
                                  >
                                    Lab Test
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {ListLabOrderList?.map(
                                  (panel: any, panelIndex: number) => (
                                    <tr
                                      key={panelIndex + panel}
                                      style={{ height: '20px' }}
                                    >
                                      <td
                                        style={{ width: '63px' }}
                                        className='medication-cell'
                                      >
                                        {panelIndex + 1}
                                      </td>
                                      <td
                                        style={{ minWidth: '200px' }}
                                        className='medication-cell'
                                      >
                                        {panel.name}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              // marginTop: '10px',
                            }}
                          >
                            <div style={{ flex: 4 }}>
                              <p className='pText'>{''}</p>
                            </div>
                            <div className='flexTwo'>
                              <div className='signature-details'>
                                {signature !== emptyString && (
                                  <img
                                    className='admin-signature'
                                    src={signature}
                                    alt={profilePicture}
                                  />
                                )}
                              </div>
                              <em> Signature</em>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className='ribbon'>
          <div className='headerRibbon'>
            <div className='orangeline' />
            <div className='blueline' />
          </div>
        </div>
        <div className='footer-style'>
          <footer style={{ position: 'fixed', bottom: 0, width: '100%' }}>
            <div className='headerRibbon' style={{ height: '40px' }}>
              <div
                style={{
                  backgroundColor: '#F47421',
                  flexGrow: 1,
                }}
              >
                <p
                  style={{
                    fontSize: '12px',
                    wordSpacing: '-1px',
                    marginLeft: '-9px',
                    paddingLeft: '5px',
                    marginTop: '-2px',
                  }}
                >
                  Note: This prescription is generated on a teleconsultation
                </p>
              </div>
              <div style={{ backgroundColor: '#004D91', flexGrow: 1 }}>
                <div style={{ textAlign: 'center' }}>
                  <p
                    style={{
                      marginLeft: '-2px',
                      marginTop: '-2px',
                      color: 'white',
                    }}
                  >
                    A unit of CR Healthcare Services Pvt Ltd (CIN -
                    U85110TG2021PTC153300)
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

const EPrescription = forwardRef<HTMLDivElement, PatientProps>(function Label(
  props,
  ref
) {
  const { patient, diagnosisList, labOrderList, visitsList, medications } =
    props
  return (
    <div ref={ref}>
      <EPrescriptionPrint
        patient={patient}
        visitsList={visitsList}
        diagnosisList={diagnosisList}
        labOrderList={labOrderList}
        medications={medications}
      />
    </div>
  )
})

export default EPrescription
