import SelectableSearch from '../../../components/SelectableSearch'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setRedirectTo } from '../../patients/patientsSlice'
import ListOfMembers from './ListOfMembers'
import { MemberPatient, MembershipModel, SearchPatient } from '../models'
import SearchPatientList from './SearchPatientList'
import { startSxpProxy } from '../../../utils/api'
import {
  CHAT_PROJECT_ID,
  MEMBERSHIP_PROJECT_ID,
} from '../../../utils/constants'
import { Patient } from 'fhir/r4'
import { makeName } from '../../lms/utils'
import { toast } from 'react-toastify'
import ToastMessage from '../../lms/components/ToastMessage'
import { toastOptions } from '../../lms/models'
import { selectSelectedLocationId } from '../../location/locationSlice'
import IconButton from '@mui/material/IconButton'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import '../membership.scss'
const CreateMembership = () => {
  const location = useAppSelector(selectSelectedLocationId)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [show, setShow] = useState<boolean>(false)
  const [patients, setPatients] = useState<SearchPatient[]>([])
  const [members, setMembers] = useState<MemberPatient[]>([])

  const formatPatients = (entry: { resource: Patient }[]): SearchPatient[] => {
    return (
      entry.map((en) => {
        const gender = en.resource.gender
        const pat: SearchPatient = {
          id: en.resource.id ?? '',
          name: makeName(en.resource.name),
          gender:
            gender === 'male'
              ? 'Male'
              : gender === 'female'
              ? 'Female'
              : 'Others',
          dob: en.resource.birthDate ?? '',
          uhid: en.resource?.identifier?.[2]?.value ?? '',
          thopId: en.resource?.identifier?.[0]?.value ?? '',
          isSelected: false,
          isNominee: false,
          registration: en.resource?.identifier?.[3]?.value ?? '',
        }
        return pat
      }) ?? []
    )
  }

  const handleSearch = (query: string, param: string) => {
    if (!query) {
      setPatients([])
      setShow(false)
      return
    }
    setShow(true)
    const intent = 'getPatientsAPI'
    let q = ''
    switch (query) {
      case 'ID':
        q = 'identifier'
        break
      case 'Phone':
        q = 'phone'
        break
      case 'Name':
        q = 'name'
        break
    }
    const state = {
      pageSize: 20,
      query: q,
      value: param ? param : undefined,
    }
    startSxpProxy(CHAT_PROJECT_ID, intent, state)
      .then((data) => {
        if (data.data.entry?.length) {
          setPatients(formatPatients(data.data.entry))
        } else {
          setPatients([])
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleMemberSelect = (id: string) => {
    const selectedMember = patients.find((member) => member.id === id)
    if (selectedMember) {
      startSxpProxy(MEMBERSHIP_PROJECT_ID, 'membershipLines', {
        location,
        partner: selectedMember?.uhid,
      })
        .then(async (data) => {
          const members: MembershipModel[] = data?.data?.data ?? []
          const member = !members?.every(
            (item) =>
              item?.state !== 'waiting' &&
              item?.state !== 'invoiced' &&
              item?.state !== 'paid'
          )
          if (members?.length === 0 || !member) {
            setMembers((prev) => [...prev, selectedMember])
          } else {
            if (member) {
              toast(
                <ToastMessage message={'already available in membership.'} />,
                {
                  ...toastOptions,
                  type: 'error',
                }
              )
            } else {
              toast(
                <ToastMessage message={'already available in membership'} />,
                {
                  ...toastOptions,
                  type: 'error',
                }
              )
            }
          }
        })
        .catch((err) => {
          console.log(err)
          toast(
            <ToastMessage
              message={err?.response?.data?.error ?? 'eror occured'}
            />,
            {
              ...toastOptions,
              type: 'error',
            }
          )
        })
    }
  }

  const handleMemberUnSelect = (id: string) => {
    const member = members.filter((d) => d.id !== id)
    setMembers(member)
  }

  const handlePatient = () => {
    dispatch(setRedirectTo('membership'))
    navigate('/patient-registration')
  }

  const handleMembership = () => {
    navigate('/membership')
  }

  return (
    <div className='membership-layout-content'>
      <div className='overallContainer'>
        <div>
          <IconButton onClick={handleMembership}>
            <KeyboardBackspaceIcon className='backspace-icon-membership-view' />
          </IconButton>
        </div>
        <div className='flexDiv'>
          <SelectableSearch
            items={['ID', 'Phone', 'Name']}
            onSearch={handleSearch}
          />
          <button
            className={
              show && patients.length === 0
                ? 'btn btn-primaryBtn'
                : 'disabled-button'
            }
            onClick={handlePatient}
            disabled={!(show && patients.length === 0)}
          >
            Register Patient
          </button>
        </div>
      </div>
      <div className='mb10'></div>
      {(members?.length > 0 || show) && (
        <ListOfMembers
          members={members}
          onMemberUnSelect={handleMemberUnSelect}
        />
      )}
      {show && (
        <SearchPatientList
          patients={patients}
          onMemberSelect={handleMemberSelect}
          members={members}
        />
      )}
    </div>
  )
}

export default CreateMembership
