import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { OPD_PROJECT_ID } from '../../../utils/constants'
import SxpForm from '../../../form/SxpForm'
import { useAppSelector } from '../../../app/hooks'
import { selectSelectedPatient } from '../appointmentsSlice'

const CreateVisit = () => {
  const { appId } = useParams()
  const patientId = useAppSelector(selectSelectedPatient)
  const navigate = useNavigate()

  const handleJourneyEnd = () => {
    setTimeout(() => {
      navigate('/appointments')
    }, 600)
  }

  return (
    <div className='layoutcontent total-width-content'>
      <SxpForm
        project={OPD_PROJECT_ID}
        intent='createVisit'
        journeyState={{
          appointmentId: appId,
          id: patientId,
          conformPatientAddress: 'no',
          isMedicoLegalCase: 'no',
        }}
        onJourneyEnd={handleJourneyEnd}
        isDynamic
      />
    </div>
  )
}

export default CreateVisit
