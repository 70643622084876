export const modeType = {
  admin: 'admin',
  signature: 'signature',
}

export const forms = {
  filter: 'Filter:',
  active: 'Active',
  inactive: 'Inactive',
  practitioner: 'Practitioner',
  practitionerRole: 'PractitionerRole',
  loading: '...Loading',
  salutation: ' Salutation',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  gender: 'Gender',
  dob: 'DOB',
  speciality: ' Speciality',
  department: 'Department',
  service: 'Service',
  qualification: 'Qualification',
  registrationNumber: ' Registration Number',
  phoneNumber: 'Phone Number',
  submit: 'Submit',
  Submitting: 'Submitting...',
  next: 'Next',
}

export const formValues = {
  active: 'active',
  inActive: 'inactive',
  all: 'all',
  email: 'email',
  na: 'NA',
  phone: 'phone',
  mr: 'Mr',
  mrs: 'Mrs',
  miss: 'Miss',
  dr: 'DR.',
  master: 'Master',
  male: 'male',
  female: 'female',
  other: 'other',
  healthService: 'healthService',
  OperatingUnit: 'Operating Unit',
}

export const placeHolder = {
  doctorName: 'Search by Doctor Name',
  doctors: 'Enter Doctors Name',
  searchName: 'Search by Name',
  email: 'Enter Email',
  phone: 'Enter Phone Number',
  pathologyName: 'Search by Pathologist Name',
  pathologists: 'Enter Pathologists Name',
  firstName: 'Enter First Name',
  lastName: 'Enter Last Name',
}

export const tableText = {
  name: 'Name',
  speciality: 'Speciality',
  superSpecialty: 'Super Specialty',
  qualification: 'Qualification',
  regNo: 'Reg No',
  slNo: 'Sl No',
  email: 'Email-ID',
  phone: 'Mobile No.',
  status: 'Status',
  gender: 'Gender',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  action: 'Action',
  operatingUnit: 'Operating Unit',
  noDoctorsFound: 'No Doctors Found',
  noPathologistFound: 'No Pathologists Found',
  service: 'Service',
  VisitType: 'Visit Type',
  DocName: 'Doctor Name',
  Date: 'Date',
  TimeSlot: 'Time Slot',
  ViewVisit: 'View Visit',
  treatmentPlan: 'Treatment Plan',
  View: 'View',
  errormsg: 'No Appointments found',
  Allowecount: 'Allowed Count',
  Quantity: 'Quantity',
  DiscountPrice: 'Discount Price',
  uhid: 'Patient UHID',
  age: 'age',
  Relation: 'Relation',
  MUHID: 'UHID',
}

export const intent = {
  fetchProvenance: 'fetchProvenance',
  fetchProvByDoctors: 'fetchProvByDoctors',
  getDoctorById: 'getDoctorById',
  getDoctors: 'getDoctors',
  doctorActive: 'doctorActive',
  createDoc: 'createDoc',
  editDoctor: 'editDoctor',
  getSpecilities: 'getSpecilities',
  getHealthCareService: 'getHealthCareService',
  getLabInchargeList: 'getLabInchargeList',
  getLabDepartments: 'getLabDepartments',
  editLabIncharge: 'editLabIncharge',
  createLabIncharge: 'createLabIncharge',
  labInchargeActive: 'labInchargeActive',
  editLabDepartments: 'editLabDepartments',
  createLabDepartments: 'createLabDepartments',
  getSpecilitiesDoctor: 'getSpecialtiesAW',
  getDoctorsAshaWorkers: 'getDoctorsAndAshaWorkers',
  getLabTestsByIds: 'getLabTestsByIds',
  getDocIdForLabOrder: 'getDocIdForLabOrder',
  updateLabOrderWithDocId: 'updateLabOrderWithDocId',
  updateLabOrderRetryById: 'updateLabOrderRetryById',
  getLabOrdersByDate: 'getLabOrdersByDate',
  editLocation: 'editLocation',
  deleteLocation: 'deleteLocation',
  getPatientsByIdsApi: 'getPatientsByIdsApi',
  b2bPartner: 'b2bPartner',
  partnerArchive: 'archive-unarchivePartner',
  deletePartner: 'deleteB2BPartner',
  deleteTestTemplate: 'deleteTestTemplate',
  deleteRadiologyTemplate: 'deleteRadiologyTestTemplateById',
}

export const messages = {
  errorDoctorMessage: 'Failed to Update doctor status...',
  successDoctorMessage: 'Doctor edited successfully!',
  panelDeletedSuccess: 'Panel Deleted successfully!',
  updatedsuccess: 'Updated successfully!',
  ErrorMessage: 'Error updating status:',
  createdSuccessfully: 'Test Created successfully',
  Deletedsuccessfully: 'Deleted successfully',
  createdDoctorSuccess: 'Doctor created successfully!',
  errorPathologistMessage: 'Failed to Update pathologist status...',
  successPathologistMessage: 'Pathologist edited successfully!',
  createdPathologistSuccess: 'Pathologist created successfully!',
  validPhoneMessage: 'Please enter valid phone number',
  ValidEmailMessage: 'Please enter a valid email address.',
  validNameMessage: 'Please enter your name in characters',
  backToPathology: 'Back to Pathology',
  notValidPathologist: 'Not a Valid Pathologist',
  editedlocmsg: 'Location is edited',
  whatsappError: 'Failed to send whatsapp message.',
  reportFailed: 'Report Notification failed.',
  notificationSent: 'Report notification will be sent shortly.',
  labReportFixed: 'Lab report fixed successfully.',
  archiveSuccess: 'Archived Successfully',
  unarchiveSuccess: 'Un-Archived Successfully',
  processingReportNotification: 'Processing... Please Do Not Close The Page.',
}

export const headers = {
  backToLab: 'Back to LabDepartments',
  submit: ' Submit',
  notValidLabId: 'Lab Department Id is not valid',
}

export const ErrorMessage = {
  CharactersCheck: 'Please enter your name in characters',
  PhoneNumberCheck: 'Please enter a valid phone number (10 digits).',
  AddressCheck: 'Please enter a valid email address.',
  GSTCheck:
    'GST number must be 15 characters long and alphanumeric (uppercase).',
  edittedMessage: 'Location is edited',
  editErrorMessage: 'Failed to Edit Location...',
  createdMessage: 'Location is created',
  createdEroorMessage: 'Failed to create location.',
}

export const locationIntent = {
  editLocation: 'editLocation',
  createLocation: 'createLocation',
}

export const FormFeilds = {
  Prefix: 'Prefix',
  PrefixPlaceHolder: 'Enter Location Prefix',
  OperatingUnit: 'Operating Unit',
  OPPlaceholder: 'Enter Location Name',
  Cluster: 'Cluster',
  Type: 'Type',
}

export const UserManagementIntent = {
  KeyclockUsers: 'KeyclockUsers',
  getOdooUsers: 'getOdooUsers',
  getfhirUsers: 'getfhirUsers',
  fetchProvenance: 'fetchProvenance',
  keyclockUserSearch: 'keyclockUserSearch',
  keyclockCompositeRoles: 'keyclockCompositeRoles',
  UserAssignedLocation: 'UserAssignedLocation',
  passwordReset: 'passwordReset',
  inactiveUser: 'inactiveUser',
  updatedataKeyclock: 'updatedataKeyclock',
  GetUserGroupRoles: 'GetUserGroupRoles',
  addGroupRole: 'addGroupRole',
  addKeyclockRoles: 'addKeyclockRoles',
  keyclockAvaiableRoles: 'keyclockAvaiableRoles',
  AddRoleInGroupRole: 'AddRoleInGroupRole',
  RemoveRole: 'RemoveRole',
  RemoveClientRole: 'RemoveClientRole',
}

export const UserManagementMessages = {
  UserCreatedMsg: 'User data updated successfully!',
  UserErrorMsg: 'Error updating user data.',
  KeyclockErrorMsg: 'Error while creating a user in keycloak',
  PasswordIncorrect: 'Passwords do not match',
  Done: 'Done',
  ResetErrorMsg: 'Error resetting password:',
  EditErrorMsg: 'Error updating user status:',
  NoMatchLoc: 'No matching location name found',
  Amalapuram: 'Amalapuram',
  KeylockRoleErrorMsg: 'Error fetching Keycloak roles:',
  Fetchusers: 'Error fetching users:',
  FetchOdooUsers: 'Error fetching Odoo users:',
  FetchFhirUsers: 'Error fetching FHIR users:',
  ProvenanceDataErrorMsg: 'Error fetching provenance data:',
  DataError: 'Data not loaded:',
  ResetPswd: 'Reset Password',
  OperatingUnit: 'Operating Unit',
}

export const locationtableHeadings = {
  Createdon: 'Created on',
  UpdatedOn: 'Updated On',
  Address: 'Address',
}

export const odooServerError = 'odoo server Error'

export const OrganizationIntent = {
  createOrganization: 'createOrganization',
  editOrganization: 'editOrganization',
  getAllOrganizationForAdmin: 'getAllOrganizationForAdmin',
  getOrganizationById: 'getOrganizationById',
}

export const CreatePartnerForm = {
  Name: 'Name',
  PhoneNumber: 'Phone Number',
  Status: 'Status',
  Submit: 'Submit',
  SelectOption: 'Select Option',
  EmailId: 'Email Id',
  phone: 'phone',
  PhoneTelecom: 'Phone Telecoms:',
}

export const PartnerTable = {
  SerialNo: 'Sl.No',
  Partner_Type: 'Partner Type',
  Partner_Name: 'Partner Name',
  Status: 'Status',
  Action: 'Action',
  Partner: 'Partner',
  PhoneNumber: 'Phone Number',
  apierrorMsg: 'No Partners Found',
  invalidPartnerID: 'Not a Valid partner Id',
  CreatedBy: 'Created By',
  UpdatedBy: 'Updated By',
  EmailId: 'Email Id',
  OdooId: 'Odoo Id',
}
export const create = 'CREATE'
export const update = 'UPDATE'
export const rejected = 'REJECTED'
export const completed = 'COMPLETED'
export const approved = 'approved'
export const unapproved = 'unApproved'
export const entered = 'ENTERED'
export const resultsEntered = 'RESULTS_ENTERED'
export const validation = 'validation'
export const testing = 'testing'
export const Observation = 'Observation'
export const doctor = 'doctor'
export const prefix = 'prefix'
export const abText = 'AB'
export const GetClientRoles = 'GetClientRoles'
export const allRoles = 'allRoles'
export const AssignedRolesOfGroupRoles = 'AssignedRolesOfGroupRole'
export const AssignedClientRolesTitle = 'Assigned Client Roles'
export const NoRoles = 'No roles assigned.'
export const AvailableRoles = 'Available Roles'
export const AssignedRoles = 'Assigned Roles'
export const AddRoles = 'Add'
export const SelectedLocation = 'SelectedLocation'
export const KeycloakRoles = 'Keycloak Roles'
export const Edit = 'Edit'
export const ViewRoles = 'View Roles'
export const SelectedKeyclockRoles = 'SelectedKeyclockRoles'
export const selectedClientRoles = 'selectedClientRoles'
export const SelectPractitionerRole = 'SelectPractitionerRole'
export const SelectPractitionerRoleName = 'SelectPractitionerRoleName'
export const checkbox = 'checkbox'
export const phonenumber = 'phonenumber'
export const genderValues = ['miss', 'ms', 'mrs']

export const PackageErrorMsg = {
  InvalidName: 'Enter a valid Name',
  InvalidPrice: 'Enter a valid Price',
  InvalidDate: 'Enter a valid From Date',
  InvalidTest: 'Please add Tests',
  PckSuccessMsg: 'Package saved successfully',
  PckErrorMsg: 'Error saving package',
  SelectSample: 'Please select a Sample Name before adding.',
  SelectTest: 'Please select a test Name before adding.',
  b2bErrorMsg: 'Please fill all required fields',
}

export const LabMsg = {
  LabResultsSaved: 'Lab Results Saved',
  LabResultsSubmitted: 'Lab Results Submitted',
  SelectPathologists: 'Select a pathologists to continue',
  SelectPathogistsNotReferred:
    'Select a pathologists to continue for not referred',
  OrderValidated: 'Lab Order Validated',
  RejectMsg: 'Select a reject to continue---',
  ValidatedRejected: 'Lab Results Validated [Some Rejected]',
}

export const LabIntent = {
  createOrderEvent: 'createOrderEvent',
  updateLabResults: 'updateLabResults',
  updateLabOrdersShort: 'updateLabOrdersShort',
}
export const LocationTableHeadings = {
  slNo: 'Sl No',
  prefix: 'Prefix',
  operatingUnit: 'Operating Unit',
  type: 'Type',
  address: 'Address',
  gstinNo: 'GSTIN No.',
  dlNo: 'DL No.',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  action: 'Action',
}
