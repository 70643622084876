import React, { useRef, useEffect, useState } from 'react'
import { AdminTableProps, Package } from '../../models'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsAdminSearch, selectedLmsStatus } from '../../lmsSlice'
import { getSearchResults } from '../../utils'
import { LabTests } from '../../../labTests/models'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import { create, menuValues, update } from '../../constants'
import KeycloakService from '../../../../utils/keycloakService'
import { LMS_ADMIN_WRITE, SUPER_ADMIN_DELETE } from '../../../../utils/roles'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import '../../../administration/admin.scss'
import { DeleteConfirmationDialog } from './DeleteConfirmationDialog'
import { Archive } from './Archive'
import { activeText } from '../../../Radiology/Constants/constants'
import { ColDef } from 'ag-grid-community'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import CustomHeader from '../../../Radiology/components/RadiologyModel/CustomHeader'
import { radiologyColor } from '../../../Radiology/Utils/RadiologyColors'
import MedAgDataGrid from '../../../../components/MedAgDataGrid'
import InterpretationDialog from './InterpretationDialog'
import LmsProvenanceView from './LmsProvenanceView'
import { viewButtons } from './constants'
const styles = () => ({
  centerHeader: {
    color: radiologyColor?.white,
    textAlign: 'center',
    '& .ag-header-cell-text': {
      flex: 1,
    },
  },
  nameButton: {
    backgroundColor: '#0000',
    border: 'none',
    color: 'blue',
    cursor: 'pointer',
    padding: '0px',
    textAlign: 'left',
    textDecoration: 'underline',
  },
  interpretationView: {
    color: 'blue',
    cursor: 'pointer',
  },
  dialogTitleText: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  dialogContent: {
    padding: '8px 16px',
  },
  templateItem: {
    padding: '6px 8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  templateText: {
    fontSize: '0.9rem',
  },
  noTemplatesText: {
    padding: '8px 0',
  },
  iconButtonContainer: {
    display: 'flex',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listContainer: {
    padding: 0,
  },
})
import { PanelTemplatesEditDialog } from '../PanelTemplatesEditDialog'
import { TEMPLATE_CONSTANTS } from '../../../../utils/constants'

interface Template {
  id: string
  template_name: string
  interpretation_image: string
  description: string
  created_by: string
  created_on: string
  status: string
  test_id: string
  test_name: string
  updated_by: string
  updated_on: string
}

const PanelTable = ({ data, onEdit }: AdminTableProps) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const status = useAppSelector(selectedLmsStatus)
  const [tableData, setTableData] = useState(data)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [editTemplatesDialogOpen, setEditTemplatesDialogOpen] =
    useState<boolean>(false)
  const [availableTemplatesDialogOpen, setAvailableTemplatesDialogOpen] =
    useState<boolean>(false)
  const [selectedPanel, setSelectedPanel] = useState<Package | null>(null)
  const [interpretationDialogOpen, setInterpretationDialogOpen] =
    useState(false)
  const [selectedInterpretation, setSelectedInterpretation] =
    useState<string>('')

  const handleOpenInterpretationDialog = (interpretation: string) => {
    setSelectedInterpretation(interpretation)
    setInterpretationDialogOpen(true)
  }
  const [templates, setTemplates] = useState<Template[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null
  )
  const [newTemplate, setNewTemplate] = useState<boolean>(false)

  const handleTemplateCountClick = (d: any) => {
    setTemplates(d?.test_templates)
    setSelectedPanel(d)
    setAvailableTemplatesDialogOpen(true)
  }

  const handleTemplateClick = (template: Template) => {
    setNewTemplate(false)
    setSelectedTemplate(template)
    setAvailableTemplatesDialogOpen(false)
    setEditTemplatesDialogOpen(true)
  }

  const handleAddNewTemplate = () => {
    setNewTemplate(true)
    setSelectedTemplate(null)
    setAvailableTemplatesDialogOpen(false)
    setEditTemplatesDialogOpen(true)
  }
  const handleEditTemplatesDialog = () => {
    setEditTemplatesDialogOpen(false)
    setSelectedPanel(null)
  }
  const handleCloseInterpretationDialog = () => {
    setInterpretationDialogOpen(false)
    setSelectedInterpretation('')
  }
  useEffect(() => {
    if (Array?.isArray(data) && data?.length > 0) {
      setTableData(data)
    }
  }, [data])

  let filtered = getSearchResults(tableData, searchParam)
  filtered = filtered?.filter((d) => d?.status === status)

  filtered?.sort?.((a: any, b: any) => {
    if (a?.department?.department_name < b?.department?.department_name) {
      return -1
    } else if (
      a?.department?.department_name > b?.department?.department_name
    ) {
      return 1
    } else {
      return a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : 1
    }
  })
  const handleAvailableTemplatesDialog = () => {
    setAvailableTemplatesDialogOpen(false)
    setSelectedPanel(null)
  }
  const tableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = tableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set())
  const toggleRow = (rowIndex: number) => {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev)
      if (newExpandedRows?.has(rowIndex)) {
        newExpandedRows?.delete(rowIndex)
      } else {
        newExpandedRows?.add(rowIndex)
      }
      return newExpandedRows
    })
  }
  const renderLabTests = (labTests: LabTests[], rowIndex: number) => {
    const initialItemCount = 2
    const isExpanded = expandedRows?.has(rowIndex)

    return (
      <ul className='labTestParameter'>
        {labTests
          ?.slice(0, isExpanded ? labTests.length : initialItemCount)
          ?.map((item: LabTests) => (
            <li key={item?.id}>• {item?.name}</li>
          ))}
        {labTests?.length > initialItemCount && (
          <li>
            <span onClick={() => toggleRow(rowIndex)} className='linkbtns'>
              {isExpanded ? viewButtons?.viewLess : viewButtons?.viewMore}
            </span>
          </li>
        )}
      </ul>
    )
  }

  const handleOpenDeleteDialog = (pkg: Package) => {
    setSelectedPanel(pkg)
    setDeleteDialogOpen(true)
  }
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
    setSelectedPanel(null)
  }
  const renderName = (params: any) => {
    return KeycloakService.hasRole([LMS_ADMIN_WRITE]) ? (
      <button
        onClick={() => onEdit(params?.data?.id)}
        className={classes?.nameButton}
      >
        {params?.data?.name}
      </button>
    ) : (
      <span>{params?.data?.name}</span>
    )
  }
  const renderInterpretationContext = (params: any) => {
    return (
      <div>
        {params?.data?.interpretation ? (
          <span
            onClick={() =>
              handleOpenInterpretationDialog(params?.data?.interpretation)
            }
            className={classes?.interpretationView}
          >
            View...
          </span>
        ) : (
          '-'
        )}
      </div>
    )
  }
  const createProvenanceView = (params: any) => {
    return (
      <LmsProvenanceView
        mode={create}
        date={params?.data?.created_on ?? ''}
        name={params?.data?.created_by ?? '-'}
      />
    )
  }
  const updatedProvenanceView = (params: any) => {
    return (
      <LmsProvenanceView
        mode={update}
        date={params?.data?.updated_on ?? ''}
        name={params?.data?.updated_by ?? '-'}
      />
    )
  }
  const RenderActions = (params: any) => {
    return (
      <div className='set-action-icons'>
        {params?.data?.status === activeText ? (
          <IconButton
            title='Archive'
            size='small'
            onClick={() =>
              Archive(params?.data, setTableData, menuValues?.Panel)
            }
            disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
          >
            <ArchiveOutlinedIcon fontSize='small' />
          </IconButton>
        ) : (
          <IconButton
            title='Un-Archive'
            size='small'
            onClick={() =>
              Archive(params?.data, setTableData, menuValues?.Panel)
            }
            disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
          >
            <UnarchiveOutlinedIcon fontSize='small' />
          </IconButton>
        )}
        <IconButton
          title='Delete'
          size='small'
          onClick={() => handleOpenDeleteDialog(params?.data)}
          disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
        >
          <DeleteOutlined fontSize='small' />
        </IconButton>
      </div>
    )
  }
  const RenderTemplateCunts = (params: any) => {
    return (
      <div className='set-action-icons'>
        <Button
          onClick={() => {
            handleTemplateCountClick(params?.data)
          }}
          size='medium'
        >
          {params?.data?.test_templates?.length}
          <AddOutlinedIcon fontSize='small' />
        </Button>
      </div>
    )
  }
  const columnDefs: ColDef[] = [
    {
      headerName: 'Thop Id',
      field: 'id',
      width: 110,
      sortable: true,
      filter: true,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Odoo Id',
      field: 'name',
      sortable: true,
      filter: true,
      headerClass: classes?.centerHeader,
      cellRenderer: (params: any) => params?.data?.odoo_test_id ?? '-',
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'left' },
      minWidth: 125,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Short Form',
      field: 'short form',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => params?.data?.test_short_form ?? '-',
      cellClass: 'custom-header-cell',
      headerClass: classes?.centerHeader,
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
      },
      minWidth: 145,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Test Name',
      field: 'test',
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      cellRenderer: renderName,
      headerClass: classes?.centerHeader,
      cellStyle: {
        fontSize: '12px',
        textAlign: 'left',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        lineHeight: 0.5,
      },
      flex: 1,
      wrapText: true,
      headerComponent: CustomHeader,
      minWidth: 215,
    },
    {
      headerName: 'Department',
      field: 'department',
      sortable: true,
      filter: true,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellRenderer: (params: any) =>
        params?.data?.department?.department_name ?? '-',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      headerComponent: CustomHeader,
      minWidth: 155,
    },
    {
      headerName: 'Sample',
      field: 'method',
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: classes?.centerHeader,
      cellRenderer: (params: any) => params?.data?.sample?.name ?? '-',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        lineHeight: 1.5,
      },
      flex: 1,
      wrapText: true,
      minWidth: 136,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Parameter Name',
      field: 'parameter',
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: classes?.centerHeader,
      cellRenderer: (params: any) =>
        renderLabTests(params?.data?.lab_tests || [], params?.node?.rowIndex),
      cellStyle: {
        fontSize: '12px',
        textAlign: 'left',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        lineHeight: 1.5,
      },
      flex: 1,
      wrapText: true,
      minWidth: 206,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Para Count',
      field: 'count',
      sortable: true,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: classes?.centerHeader,
      cellRenderer: (params: any) => params?.data?.lab_tests?.length ?? 0,
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
      },
      minWidth: 121,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Handled At',
      field: 'handled_at',
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: classes?.centerHeader,
      cellRenderer: (params: any) => params?.data?.handled_at ?? '-',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
      },
      minWidth: 146,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Price',
      field: 'price',
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: classes?.centerHeader,
      cellRenderer: (params: any) => params?.data?.price ?? '-',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
      },
      minWidth: 106,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'TAT',
      field: 'tat',
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: classes?.centerHeader,
      cellRenderer: (params: any) => params?.data?.tat ?? '1',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
      },
      minWidth: 96,
      flex: 1,
      wrapText: true,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Interpretation',
      field: 'test',
      sortable: true,
      filter: false,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellRenderer: renderInterpretationContext,
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
      },
      minWidth: 126,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Created By',
      field: 'created by',
      sortable: true,
      filter: true,
      cellRenderer: createProvenanceView,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: {
        fontSize: '12px',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        lineHeight: 1.5,
      },
      minWidth: 166,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Updated By',
      field: 'patientMobile',
      sortable: true,
      filter: true,
      cellRenderer: updatedProvenanceView,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'left',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        lineHeight: 1.5,
      },
      minWidth: 166,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      sortable: true,
      filter: true,
      cellRenderer: RenderActions,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 146,
      headerComponent: CustomHeader,
    },
    {
      headerName: 'Template Counts',
      field: 'actions',
      sortable: true,
      filter: true,
      cellRenderer: RenderTemplateCunts,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 146,
      headerComponent: CustomHeader,
    },
  ]
  return (
    <>
      <MedAgDataGrid
        tableHeight={'100%'}
        rowData={filtered}
        columnDefs={columnDefs}
        pagination={false}
        headerHeight={30}
        rowHeight={40}
        searchFieldWidth={200}
        searchField={false}
        loading={false}
        exportIcon={false}
      />
      {deleteDialogOpen && (
        <DeleteConfirmationDialog
          openDialog={deleteDialogOpen}
          closeDialog={handleCloseDeleteDialog}
          data={selectedPanel}
          name={selectedPanel?.name}
          setData={setTableData}
          item={menuValues?.Panel}
        />
      )}
      <InterpretationDialog
        interpretationDialogOpen={interpretationDialogOpen}
        selectedInterpretation={selectedInterpretation}
        onClose={handleCloseInterpretationDialog}
      />
      <PanelTemplatesEditDialog
        openDialog={editTemplatesDialogOpen}
        closeDialog={handleEditTemplatesDialog}
        testId={selectedPanel?.id}
        testName={selectedPanel?.name}
        newTemplate={newTemplate}
        selectedTemplate={selectedTemplate}
        templates={templates}
      />
      [12:59] Vishwakarma Mayank
      <Dialog
        open={availableTemplatesDialogOpen}
        onClose={() => handleAvailableTemplatesDialog()}
        fullWidth
        maxWidth='sm'
        id='available-templates-dialog'
      >
        <DialogTitle>
          <Box className={classes?.titleContainer}>
            <Typography variant='h6' className={classes?.dialogTitleText}>
              {TEMPLATE_CONSTANTS?.availableTemplateText} {selectedPanel?.name}
            </Typography>
            <Box className={classes?.iconButtonContainer}>
              <IconButton
                aria-label='add template'
                color='primary'
                onClick={handleAddNewTemplate}
                size='small'
                id='add-template-button'
                title='Add New Template'
              >
                <AddIcon fontSize='small' />
              </IconButton>
              <IconButton
                aria-label='close'
                onClick={() => setAvailableTemplatesDialogOpen(false)}
                size='small'
                id='close-dialog-button'
                title='Close'
              >
                <CloseIcon fontSize='small' />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers className={classes?.dialogContent}>
          <List className={classes?.listContainer}>
            {templates?.length > 0 ? (
              templates?.map((template) => (
                <ListItem
                  key={template.id}
                  id={`template-item-${template.id}`}
                  onClick={() => handleTemplateClick(template)}
                  className={classes?.templateItem}
                >
                  <ListItemText
                    primary={template?.template_name}
                    classes={{
                      primary: classes?.templateText,
                    }}
                  />
                </ListItem>
              ))
            ) : (
              <Typography variant='body2' className={classes?.noTemplatesText}>
                {TEMPLATE_CONSTANTS?.noAvailableTemplates}
              </Typography>
            )}
          </List>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PanelTable
