import * as React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { startSxpProxy } from '../../../../utils/api'
import { IPD_PROJECT_ID } from '../../../../utils/constants'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import { formatReadableDate } from './Constants'
import { Patient, RadiologyOrder, RadiologyOrderProps } from './Interfaces'
import {
  radiologyIntents,
  radiologyTestStatus,
  radiologyText,
} from '../../../Radiology/Constants/constants'
import { useRadiologyDepartments } from '../../../Radiology/components/RadiologyModel/useRadiologyDepartments'
import { transformRadiologyResults } from '../../../Radiology/Utils/utils'
import { useInvoicePDFGenerator } from '../../../lms/components/dashboard/InvoicePdfLib'

function Row({
  row,
  patient,
  index,
  radiologyDepartments,
}: RadiologyOrderProps) {
  const [open, setOpen] = React.useState(false)
  const { generatePDF } = useInvoicePDFGenerator()
  const handleInvoicePrint = async (row: any, patient: Patient) => {
    const obj: any = {
      type: radiologyText,
      id: patient?.muhid ?? '',
      name: patient?.patientName || '',
      age: patient?.dob || '',
      gender: patient?.gender || '',
      mobile: patient?.mobileNumber ?? '',
      lrNumber: row?.lr_number ?? '',
      radiologyTests: row?.radiology_order_tests,
      radiologyResults: transformRadiologyResults(
        row?.radiology_test_results,
        radiologyDepartments
      ),
      orderType: row?.origin ?? '',
      orderedOn: row?.ordered_on ?? '',
      invoiceWithHeaderValue: false,
      requestedBy: row?.requested_by ?? row?.ordered_by_name ?? '',
      validatedOn: row?.validation_completed_on ?? '',
      fileId: row?.document_id ?? '',
    }
    const doc = await generatePDF(obj)
    const fileUrl = URL.createObjectURL(doc)
    const iframe = document.createElement('iframe')
    iframe.src = fileUrl
    iframe.width = '100%'
    iframe.height = '100%'
    iframe.style.border = 'none'
    const newWindow = window.open('', '_blank') as Window
    newWindow.document.body.style.margin = '0'
    newWindow.document.body.appendChild(iframe)
    newWindow.document.title = 'Test Results'
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align='center'>{index + 1}</TableCell>
        <TableCell component='th' scope='row'>
          {row?.lr_number}
        </TableCell>
        <TableCell align='left'>{row?.requested_by}</TableCell>
        <TableCell align='left'>{row?.status}</TableCell>
        <TableCell align='left'>
          <IconButton
            onClick={() => handleInvoicePrint(row, patient)}
            disabled={
              row?.status != radiologyTestStatus?.completed ? true : false
            }
          >
            <ArticleOutlinedIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell className='table-lab-visit-ipd-order' colSpan={7}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box m={1}>
                <Typography
                  variant='h6'
                  gutterBottom
                  component='div'
                  fontSize={'14px'}
                  fontWeight={600}
                >
                  Tests
                </Typography>
                <Table
                  size='small'
                  aria-label='purchases'
                  sx={{ border: '1px solid #ddd' }}
                >
                  <TableHead className='table-head-lab-visit'>
                    <TableRow>
                      <TableCell className='table-head-th-lab-visit'>
                        Test
                      </TableCell>
                      <TableCell
                        className='table-head-th-lab-visit'
                        align='left'
                      >
                        Doctor
                      </TableCell>
                      <TableCell
                        className='table-head-th-lab-visit'
                        align='left'
                      >
                        Date & Time
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.radiology_order_tests?.map((panel: any) => (
                      <TableRow key={panel?.name}>
                        <TableCell component='th' scope='row'>
                          {panel?.test_name}
                        </TableCell>
                        <TableCell align='left'>{row?.requested_by}</TableCell>
                        <TableCell align='left'>
                          {formatReadableDate(row?.ordered_on)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

interface LabProps {
  patientRegisteredId: string
}

export default function IpdRadiologyOrderSummary(props: LabProps) {
  const { patientRegisteredId } = props
  const radiologyDepartments = useRadiologyDepartments()

  const [radiologyOrders, setRadiologyOrders] = React.useState<
    RadiologyOrder[]
  >([])
  const [patient, setPatient] = React.useState<Patient>({})

  const fetchAllRadiologyOrders = async (patientRegisteredId: string) => {
    const intent = radiologyIntents?.getAllRadiologyOrdersByIpd
    const state = { patId: patientRegisteredId }
    await startSxpProxy(IPD_PROJECT_ID, intent, state)
      .then((data) => {
        if (data?.data?.orders?.length > 0) {
          setRadiologyOrders(data?.data?.orders)
          setPatient(data?.data?.patientDetils)
        }
      })
      .catch((err) => {
        console.error(err, 'err')
      })
  }

  React.useEffect(() => {
    fetchAllRadiologyOrders(patientRegisteredId)
  }, [patientRegisteredId])

  return (
    <TableContainer component={Paper} className='table-lab-visit-ipd-mui'>
      <Table aria-label='collapsible table' size='small'>
        <TableHead className='table-head-lab-visit'>
          <TableRow>
            <TableCell />
            <TableCell className='table-head-th-lab-visit' align='center'>
              Sl No
            </TableCell>
            <TableCell className='table-head-th-lab-visit'>
              Radiology Id
            </TableCell>
            <TableCell className='table-head-th-lab-visit' align='left'>
              Requested By
            </TableCell>
            <TableCell className='table-head-th-lab-visit' align='left'>
              Status
            </TableCell>
            <TableCell className='table-head-th-lab-visit' align='left'>
              View
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {radiologyOrders.map((row: any, index: number) => (
            <Row
              key={row?.lr_number}
              row={row}
              patient={patient}
              index={index}
              radiologyDepartments={radiologyDepartments}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
