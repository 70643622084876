import { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import PatientTable from '../../../lms/components/PatientTable'
import SelectableSearch from '../../../../components/SelectableSearch'
import AllRadiologyOrders from './AllRadiologyOrders'
import { IconButton, Button, useTheme } from '@mui/material'
import { capitalizeFirstLetter } from '../../../patients/utils'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import {
  selectedRadiologyActiveTab,
  selectedRadiologyShowBack,
  setRadiologyShowBack,
} from '../../RadiologySlice'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import {
  create,
  createRadiologyOrder,
  dialogMessages,
  radiologyButtons,
  radiologyRoute,
} from '../../Constants/constants'
import { radiologyEndPoint } from '../../RadiologyEndPoints'
import CustomDialog from '../../dialogComponent/CustomDialogComponent'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import { radiologyColor } from '../../Utils/RadiologyColors'

const styles = () => ({
  radiologyContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px',
  },
  searchViewContainer: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-between',
    marginTop: '5px',
  },
  searchViewContent: {
    fontWeight: 600,
    fontSize: '15px',
    color: radiologyColor?.deepBlue,
    display: 'flex',
  },
  iconContainer: {
    marginTop: '3px',
  },
  backspaceIcon: {
    fontSize: 20,
    color: radiologyColor?.blue,
    marginTop: '-8px',
  },
  activeTabContent: {
    display: 'flex',
  },
  createButton: {
    alignItems: 'center',
    marginBottom: '10px',
    gap: '2rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  radiologyCreateButton: {
    textTransform: 'none !important',
    backgroundColor: radiologyColor?.deepBlue,
  },
  showContainer: {
    marginBottom: '10px',
  },
})

const RadiologySearchView = () => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [show, setShow] = useState<boolean>(false)
  const [query, setQuery] = useState<string>('')
  const [param, setParam] = useState<string>('')
  const [patientId, setPatientId] = useState<string>('')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [removeDialog, setRemoveDialog] = useState(false)
  const [row, setRow] = useState<any>([])
  const locationRoute = window.location.pathname
  const selectedRadiologyActive = useAppSelector(selectedRadiologyActiveTab)
  const showBack = useAppSelector(selectedRadiologyShowBack)

  const handlePatientSelect = (id: string) => {
    if (id !== patientId) {
      setPatientId(id)
      navigate('')
    }
  }

  const handleSearch = (q: string, p: string) => {
    setQuery(q)
    setParam(p)
    setPatientId('')
    if (!q || !p) {
      setShow(false)
      dispatch(setRadiologyShowBack(''))
      setRow([])
    } else {
      setShow(true)
    }
    navigate('')
  }

  const handleView = (url: string) => {
    setPatientId('')
    setShow(false)
    navigate(url)
  }

  const handleCreate = (id: string) => {
    setPatientId('')
    setShow(false)
    dispatch(setRadiologyShowBack('create'))
    navigate(`${radiologyEndPoint?.create}/${id}`)
  }
  const navigateBack = () => {
    navigate(radiologyEndPoint?.radiologyDashboard)
  }
  const navigateDialog = () => {
    setRemoveDialog(true)
  }
  const handleDialogClose = () => {
    setRemoveDialog(false)
  }
  const handleDialogConfirm = () => {
    setRemoveDialog(false)
    dispatch(setRadiologyShowBack(''))
    navigate(radiologyEndPoint?.radiologyDashboard)
  }

  return (
    <>
      <CustomDialog
        open={removeDialog}
        title={
          showBack === create
            ? dialogMessages?.backToDashboard
            : dialogMessages?.testsToBeRemoved
        }
        content={
          <p>
            {showBack === create
              ? dialogMessages?.goToDashboard
              : dialogMessages?.testToBeRemoved}
          </p>
        }
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        primaryButtonText={radiologyButtons?.yes}
        secondaryButtonText={radiologyButtons?.no}
      />

      <div className={classes?.radiologyContainer}>
        <div className={classes?.searchViewContainer}>
          <div className={classes?.searchViewContent}>
            {locationRoute?.includes(radiologyEndPoint?.radiologyCreate) ||
            locationRoute === radiologyRoute?.location ? (
              <>
                {showBack && (
                  <IconButton
                    onClick={navigateDialog}
                    className={classes?.iconContainer}
                  >
                    <KeyboardBackspaceIcon className={classes?.backspaceIcon} />
                  </IconButton>
                )}
                <span>{createRadiologyOrder}</span>
              </>
            ) : (
              <div className={classes?.activeTabContent}>
                <IconButton onClick={navigateBack}>
                  <KeyboardBackspaceIcon className={classes?.backspaceIcon} />
                </IconButton>
                <span className={classes?.iconContainer}>
                  {capitalizeFirstLetter(selectedRadiologyActive)}
                </span>
              </div>
            )}
          </div>
          <div className={classes?.createButton}>
            <SelectableSearch items={['ID']} onSearch={handleSearch} />
            <Button
              size='small'
              className={classes?.radiologyCreateButton}
              variant='contained'
              disabled={row?.length === 0 || !row}
              onClick={() => handleCreate(row?.[0]?.id)}
            >
              Create
            </Button>
          </div>
        </div>
        <div className={classes?.showContainer}>
          {show ? (
            <PatientTable
              searchQuery={query}
              searchParam={param}
              onPatientSelect={handlePatientSelect}
              setRow={setRow}
              selectedId={patientId}
            />
          ) : null}

          {patientId ? (
            <AllRadiologyOrders
              id={patientId}
              onView={handleView}
              onCreate={handleCreate}
            />
          ) : null}
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default RadiologySearchView
