export const BED_IPD_DASHBOARD_URL = '/bedIpd/ipVisit/dashboard'
export const IP_VISIT_VITALS_URL = '/visits/ipdVisit/'
export const USER_CREATATION = '/administration/userCreation'
export const Edit = 'edit'
export const Create = 'create'
export const doctor = '/administration/doctors'
export const partners = '/administration/referredOut'
export const radilogist = '/administration/radiologist'
export const proRithmDashboard = '/proRithmDashboard'
export const pathologist = 'administration/pathologist'
export const labDevices = '/labDevices'
export const radiologyDevices = '/radiologyDevices'
export const editABMembership = '/ab/membership-edit'
export const ABMemberDashboard = '/ab/membership-dashboard/'
export const ABMemebrship = '/ab/membership'
export const labordercreate = '/lms/search/createorder'
export const laborercollect = '/lms/search/collect/'
export const KEYCLOCK_ROLES = 'keyclock/roles'
export const registration = '/patient-registration'
export const searchpatient = 'api/fhir/Patient?identifier='
