import axios from 'axios'
import KeycloakService from '../utils/keycloakService'
import {
  mobileText,
  radiologyIntents,
  service,
} from '../features/Radiology/Constants/constants'
import { Patient } from 'fhir/r4'
import { makeName } from '../features/lms/utils'
import { RadiologyOrders } from '../features/Radiology/models'
import { startSxpProxy } from '../utils/api'
import { RADIOLOGY_PROJECT_ID } from '../utils/constants'
import { radiologySearchEndpoint } from '../utils/Endpoints'

export const sendWhatsAppMessage = async (body: any): Promise<any> => {
  const token = KeycloakService.getToken()

  try {
    const response: any = await axios.post(
      `${process.env.REACT_APP_API_GATEWAY_URL}${radiologyIntents?.sendNotification}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    )
    const { data, success, message } = response?.data || {}
    if (success) {
      return {
        success: true,
        data: data,
        message: message,
      }
    } else {
      return {
        success: false,
        data: null,
        message: message,
      }
    }
  } catch (error: any) {
    console.error('Error fetching assigned patient for bed: ', error)
    return {
      success: false,
      error: error?.message,
    }
  }
}
export const notificationUpload = async (
  blobData: Blob,
  patient: Patient | null,
  order: RadiologyOrders | null
): Promise<any> => {
  const name = makeName(patient?.name)
  const mobile = patient?.telecom?.find(
    (prt: any) => prt?.use === mobileText
  )?.value
  const preferenceCommunication = patient?.telecom?.find(
    (e: any) => e?.system === service?.sms
  )
  const preference =
    preferenceCommunication?.value === service?.yes
      ? service?.SMS
      : service?.WHATSAPP
  try {
    const token = sessionStorage.getItem('react-token')

    const formData = new FormData()
    formData.append(service?.stateId, service?.stateIdValue)
    formData.append(service?.preference, preference)
    formData.append(service?.sendTo, `91${mobile}`)
    formData.append(service?.location, `${order?.source}`)
    formData.append(service?.name, `${name}`)
    formData.append(service?.sourceType, service?.radiology)
    formData.append(service?.file, blobData, `${name}_${order?.id}_pdf.pdf`)

    const response = await axios.post(
      `${process.env.REACT_APP_API_GATEWAY_URL}${radiologyIntents?.notificationUpload}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    if (response?.status === 200) {
      return response.data
    } else {
      return { fileId: '' }
    }
  } catch (error) {
    console.error(error)
  }
}

export const updateRadiologyOrderWithDocId = async (
  orderId: any,
  docId: any,
  dmsUploadRetry: boolean
) => {
  if (!orderId) {
    throw new Error('Order ID is required')
  }

  const state: any = {
    id: orderId,
    dmsUploadRetry: dmsUploadRetry,
  }
  if (docId && docId !== '') {
    state.docId = docId
  }

  try {
    const response = await startSxpProxy(
      RADIOLOGY_PROJECT_ID,
      radiologyIntents?.updateRadiologyOrderWithDocId,
      state
    )
    return response
  } catch (error) {
    console.error('Error updating radiology order:', error)
    throw error
  }
}
export const sendNotificationReport = async (blobData: Blob, item: any) => {
  try {
    const token = sessionStorage.getItem('react-token')

    const formData = new FormData()
    formData.append(service?.stateId, item?.id)
    formData.append(service?.preference, item?.patientCommunication)
    formData.append(service?.sendTo, `91${item?.patientMobile}`)
    formData.append(service?.location, `${item?.source}`)
    formData.append(service?.name, `${item?.patientName}`)
    formData.append(service?.sourceType, service?.radiology)
    formData.append(
      service?.file,
      blobData,
      `${item?.patientName}_${item?.id}_pdf.pdf`
    )

    const response = await axios.post(
      `${process.env.REACT_APP_API_GATEWAY_URL}${radiologyIntents?.notificationUpload}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    if (response?.status === 200) {
      return response.data
    } else {
      return { fileId: '' }
    }
  } catch (error) {
    console.error(error)
  }
}

export const getAllRadiologyBacklogOrders = async (
  param: string,
  value: string,
  page: number,
  size: number
) => {
  const token = KeycloakService.getToken()
  try {
    const response: any = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}${radiologySearchEndpoint}?param=${param}&value=${value}&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const { data, success, message } = response?.data || {}
    if (success) {
      return {
        success: true,
        data: data,
        message: message,
      }
    } else {
      return {
        success: false,
        data: null,
        message: message,
      }
    }
  } catch (error: any) {
    console.error('Error opening file: ', error)
    return {
      success: false,
      data: null,
      message: error?.message,
    }
  }
}
