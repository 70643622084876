export const INTENT_UPDATE_VISIT = 'updateVisitStatus'
export const DECISION_ACCEPT = 'accept'
export const DECISION_REJECT = 'reject'
export const NEW_IPD_REGISTERED = 'newIpdRegistrationUpdate'
export const NEW_IPD_DATA_UPDATE = 'newIpdDataUpdate'
export const DISCHARGED_IPD_PATIENT = 'deleteIpdRegistration'
export const MESSAGE_TYPES = {
  CARE_PLAN: 'careplan',
  ACCEPT_CARE_PLAN: 'accept-careplan',
  REJECT_CARE_PLAN: 'reject-careplan',
}
export const USER_ID = 'user-id'
export const DATA_STATUS = {
  PROCESSED: 'PROCESSED',
  REJECTED: 'REJECTED',
}

export const STATUS = {
  FINISHED: 'finished',
  CANCELLED: 'cancelled',
}

export const NOTIFICATION_STATUS = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
}

export const FROM_NOTIFICATION = 'yes'

export const TOAST_WARNING_REASON = 'Please write the reason for rejection'

export const NOTIFICATION_BUTTONS = {
  Live: 'live',
  Unread: 'unread',
}

export const INTERPRETATION_EMPTY = '<p><br></p>'
export const INTENT_NOTIFICATION_HISTORY = 'unreadNotifications'
export const NOTIFICATION_HISTORY_STATUS = 'ONHOLD'
export const ROUTE_IPD_NAVIGATE = '/bedIpd/ipVisit/dashboard'
