import { useState } from 'react'
import { AdminTableProps } from '../lms/models'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  selectedLmsAdminSearch,
  setLmsFormMode,
  setLmsItem,
  setLmsItemId,
} from '../lms/lmsSlice'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import { getSearchResults } from '../lms/utils'
import { emptyString, noData, viewMore } from './Constants/constants'
import './radiology.scss'
import RadiologyTestForm from '../lms/components/admin/RadiologyTestForm'
import CloseIcon from '@mui/icons-material/Close'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import AddIcon from '@mui/icons-material/Add'

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'
import { ColDef, ITextFilterParams } from 'ag-grid-community'
import MedAgDataGrid from '../../components/MedAgDataGrid'
import { radiologyColor } from './Utils/RadiologyColors'
import { RadiologyTemplatesEditDialog } from './RadiologyTemplatesEditDialog'
import { TEMPLATE_CONSTANTS } from '../../utils/constants'
interface Template {
  id: string
  template_name: string
  interpretation_image: string
  description: string
  created_by: string
  created_on: string
  status: string
  test_id: string
  test_name: string
  updated_by: string
  updated_on: string
}

const styles = () => ({
  radiologyTestContainer: {
    width: '100%',
    height: '100%',
  },
  centerHeader: {
    color: radiologyColor?.white,
    textAlign: 'center',
    '& .ag-header-cell-text': {
      flex: 1,
    },
  },
  interpretationDialogContent: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
  },
  radiologyTableGoggle: {
    color: radiologyColor?.blue,
    cursor: 'pointer',
  },
  dialogTitleText: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  dialogContent: {
    padding: '8px 16px',
  },
  templateItem: {
    padding: '6px 8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  templateText: {
    fontSize: '0.9rem',
  },
  noTemplatesText: {
    padding: '8px 0',
  },
  iconButtonContainer: {
    display: 'flex',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listContainer: {
    padding: 0,
  },
})

const RadiologyTest = ({ data }: AdminTableProps) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const filtered = getSearchResults(data, searchParam)
  const dispatch = useAppDispatch()
  const [selectedId, setSelectedId] = useState<string>('')
  const textLength = 10
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [dialogContent, setDialogContent] = useState('')

  const [availableTemplatesDialogOpen, setAvailableTemplatesDialogOpen] =
    useState<boolean>(false)
  const [editTemplatesDialogOpen, setEditTemplatesDialogOpen] =
    useState<boolean>(false)
  const [selectedTest, setSelectedTest] = useState<any>(null)
  const [templates, setTemplates] = useState<Template[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null
  )
  const [newTemplate, setNewTemplate] = useState<boolean>(false)

  filtered?.sort?.((a: any, b: any) => {
    if (a?.radiology_sample?.name < b?.radiology_sample?.name) {
      return -1
    } else if (a?.radiology_sample?.name > b?.radiology_sample?.name) {
      return 1
    } else {
      return a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : 1
    }
  })

  const handleEditTest = (id: string) => {
    setSelectedId(id)
    dispatch(setLmsFormMode('edit'))
    dispatch(setLmsItemId(id))
    dispatch(setLmsItem('radiologyTest'))
  }

  const renderCategory = (params: any) => {
    return (
      <div onClick={() => handleEditTest(params?.data?.id)}>
        {params?.data?.name}
      </div>
    )
  }
  const renderSample = (params: any) => {
    return params?.data?.radiology_sample?.name
  }
  const renderHandleAt = (params: any) => {
    return params?.data?.handled_at ?? noData
  }
  const handlePrice = (params: any) => {
    return params?.data?.price ?? noData
  }
  const toggleView = (interpretation: string) => {
    setDialogContent(interpretation)
    setIsDialogOpen(true)
  }

  const handleInterpretation = (params: any) => {
    const interpretation = params?.data?.Interpretation
    if (interpretation.length <= textLength) {
      return interpretation
    }
    const text = interpretation.slice(0, textLength)
    return (
      <>
        {params?.data?.Interpretation !== emptyString &&
          params?.data?.Interpretation && (
            <div>
              <span> {text}</span>
              <span
                className={classes?.radiologyTableGoggle}
                onClick={() => toggleView(interpretation)}
              >
                {viewMore}
              </span>
            </div>
          )}
      </>
    )
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  const handleTemplateCountClick = (data: any) => {
    setTemplates(data?.radiology_test_templates || [])
    setSelectedTest(data)
    setAvailableTemplatesDialogOpen(true)
  }

  const handleTemplateClick = (template: Template) => {
    setNewTemplate(false)
    setSelectedTemplate(template)
    setAvailableTemplatesDialogOpen(false)
    setEditTemplatesDialogOpen(true)
  }

  const handleAddNewTemplate = () => {
    setNewTemplate(true)
    setSelectedTemplate(null)
    setAvailableTemplatesDialogOpen(false)
    setEditTemplatesDialogOpen(true)
  }

  const handleEditTemplatesDialog = () => {
    setEditTemplatesDialogOpen(false)
    setSelectedTest(null)
  }

  const handleAvailableTemplatesDialog = () => {
    setAvailableTemplatesDialogOpen(false)
    setSelectedTest(null)
  }

  const renderTemplateCount = (params: any) => {
    return (
      <div className='set-action-icons'>
        <Button
          onClick={() => {
            handleTemplateCountClick(params?.data)
          }}
          size='small'
        >
          {params?.data?.radiology_test_templates?.length || 0}
          <AddOutlinedIcon fontSize='small' />
        </Button>
      </div>
    )
  }

  const columnDefs: ColDef[] = [
    {
      headerName: 'Sl No',
      field: 'id',
      width: 90,
      sortable: true,
      filter: false,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
    },
    {
      headerName: 'Test Name',
      field: 'category',
      flex: 1,
      sortable: true,
      filter: false,
      cellRenderer: renderCategory,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        cursor: 'pointer',
        color: 'blue',
      },
      minWidth: 165,
    },
    {
      headerName: 'Category',
      field: 'category',
      flex: 1,
      sortable: true,
      filter: true,
      cellRenderer: renderSample,
      filterParams: { buttons: ['clear'] } as ITextFilterParams,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 120,
    },
    {
      headerName: 'Handled At',
      field: 'handled_at',
      flex: 1,
      sortable: true,
      filter: true,
      cellRenderer: renderHandleAt,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 145,
    },
    {
      headerName: 'Price',
      field: 'price',
      flex: 1,
      sortable: true,
      filter: true,
      cellRenderer: handlePrice,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 110,
    },
    {
      headerName: 'Interpretation',
      field: 'Interpretation',
      flex: 1,
      sortable: true,
      filter: true,
      cellRenderer: handleInterpretation,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', display: 'flex' },
      minWidth: 140,
    },
    {
      headerName: 'Status',
      field: 'status',
      flex: 1,
      minWidth: 120,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
    },
    {
      headerName: 'Templates Count',
      field: 'radiology_test_templates',
      flex: 1,
      minWidth: 120,
      sortable: false,
      filter: false,
      cellRenderer: renderTemplateCount,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
    },
  ]
  return (
    <>
      {selectedId ? (
        <RadiologyTestForm testId={selectedId} />
      ) : (
        <Box className={classes?.radiologyTestContainer}>
          <MedAgDataGrid
            tableHeight={'100%'}
            rowData={filtered}
            columnDefs={columnDefs}
            pagination={false}
            headerHeight={30}
            searchFieldWidth={200}
            exportIcon={false}
            searchField={false}
          />
        </Box>
      )}
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>Interpretation</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography
            variant='body1'
            className={classes?.interpretationDialogContent}
          >
            {dialogContent}
          </Typography>
        </DialogContent>
      </Dialog>
      <Dialog
        open={availableTemplatesDialogOpen}
        onClose={() => handleAvailableTemplatesDialog()}
        fullWidth
        maxWidth='sm'
        id='available-templates-dialog'
      >
        <DialogTitle>
          <Box className={classes?.titleContainer}>
            <Typography variant='h6' className={classes?.dialogTitleText}>
              {TEMPLATE_CONSTANTS?.availableTemplateText} {selectedTest?.name}
            </Typography>
            <Box className={classes?.iconButtonContainer}>
              <IconButton
                aria-label='add template'
                color='primary'
                onClick={handleAddNewTemplate}
                size='small'
                id='add-template-button'
                title='Add New Template'
              >
                <AddIcon fontSize='small' />
              </IconButton>
              <IconButton
                aria-label='close'
                onClick={() => setAvailableTemplatesDialogOpen(false)}
                size='small'
                id='close-dialog-button'
                title='Close'
              >
                <CloseIcon fontSize='small' />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers className={classes?.dialogContent}>
          <List className={classes?.listContainer}>
            {templates?.length > 0 ? (
              templates?.map((template) => (
                <ListItem
                  key={template.id}
                  id={`template-item-${template?.id}`}
                  onClick={() => handleTemplateClick(template)}
                  className={classes?.templateItem}
                >
                  <ListItemText
                    primary={template?.template_name}
                    classes={{
                      primary: classes?.templateText,
                    }}
                  />
                </ListItem>
              ))
            ) : (
              <Typography variant='body2' className={classes?.noTemplatesText}>
                {TEMPLATE_CONSTANTS?.noAvailableTemplates}
              </Typography>
            )}
          </List>
        </DialogContent>
      </Dialog>
      {editTemplatesDialogOpen && (
        <RadiologyTemplatesEditDialog
          openDialog={editTemplatesDialogOpen}
          closeDialog={handleEditTemplatesDialog}
          testId={selectedTest?.id}
          testName={selectedTest?.name}
          newTemplate={newTemplate}
          selectedTemplate={selectedTemplate}
          templates={templates}
        />
      )}
    </>
  )
}

export default RadiologyTest
