import { MealProgress } from '../bedManagement/IpdVisit/IpdSummary/MedicationProgress'
import {
  MEALS,
  MEAL_INTERVALS,
  MEAL_TIMINGS,
  onAlternateDays,
  opdMedications,
  visitDashboard,
} from './constant'
import { capitalizeFirstLetter } from '../patients/utils'
import { useTheme } from '@emotion/react'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import { radiologyColor } from '../Radiology/Utils/RadiologyColors'
import { ColDef } from 'ag-grid-community'
import MedAgTable from '../../components/MedAgTable'
const styles = () => ({
  centerHeader: {
    textAlign: 'center',
    '& .ag-header-cell-text': {
      flex: 1,
      color: radiologyColor?.white,
      fontWeight: 500,
    },
  },
})
const OpdMedicationDashboard = ({ allMedications }: any) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const IntervalsRenderer = ({ value, data }: { value: any; data: any }) => {
    if (!value || value?.length === 0) return visitDashboard?.hyphen

    if (opdMedications?.includes(data?.frequency)) {
      return (
        <div className='drug-flexRow'>
          <MealProgress
            activeMeals={[
              {
                meal: MEALS?.BREAKFAST,
                time: data?.intervals?.some(
                  (option: any) =>
                    option?.value === MEAL_INTERVALS?.BREAKFAST_BEFORE
                )
                  ? MEAL_TIMINGS?.BEFORE
                  : '',
              },
              {
                meal: MEALS?.BREAKFAST,
                time: data?.intervals?.some(
                  (option: any) =>
                    option?.value === MEAL_INTERVALS?.BREAKFAST_AFTER
                )
                  ? MEAL_TIMINGS?.AFTER
                  : '',
              },
              {
                meal: MEALS?.LUNCH,
                time: data?.intervals?.some(
                  (option: any) =>
                    option?.value === MEAL_INTERVALS?.LUNCH_BEFORE
                )
                  ? MEAL_TIMINGS?.BEFORE
                  : '',
              },
              {
                meal: MEALS?.LUNCH,
                time: data?.intervals?.some(
                  (option: any) => option?.value === MEAL_INTERVALS?.LUNCH_AFTER
                )
                  ? MEAL_TIMINGS?.AFTER
                  : '',
              },
              {
                meal: MEALS?.DINNER,
                time: data?.intervals?.some(
                  (option: any) =>
                    option?.value === MEAL_INTERVALS?.DINNER_BEFORE
                )
                  ? MEAL_TIMINGS?.BEFORE
                  : '',
              },
              {
                meal: MEALS?.DINNER,
                time: data?.intervals.some(
                  (option: any) =>
                    option?.value === MEAL_INTERVALS?.DINNER_AFTER
                )
                  ? MEAL_TIMINGS?.AFTER
                  : '',
              },
            ]}
          />
        </div>
      )
    }

    if (data?.frequency === onAlternateDays) {
      return value?.map((e: any) => e?.value)?.join(', ')
    }

    return value?.[0]?.value
  }
  const columnDefs: ColDef[] = [
    {
      headerName: 'Drug Name',
      field: 'drug_name',
      valueFormatter: (params: any) => capitalizeFirstLetter(params.value),
      minWidth: 200,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      headerClass: classes?.centerHeader,
      flex: 1,
    },
    {
      headerName: 'Dose',
      field: 'dose',
      minWidth: 100,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      headerClass: classes?.centerHeader,
    },
    {
      headerName: 'Unit',
      field: 'dose_units',
      valueFormatter: (params: any) => capitalizeFirstLetter(params?.value),
      minWidth: 120,
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      cellClass: 'custom-header-cell',
      headerClass: classes?.centerHeader,
    },
    {
      headerName: 'Route',
      field: 'route',
      valueFormatter: (params: any) => capitalizeFirstLetter(params?.value),
      minWidth: 140,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      headerClass: classes?.centerHeader,
    },
    {
      headerName: 'Frequency',
      field: 'frequency',
      valueFormatter: (params: any) => capitalizeFirstLetter(params?.value),
      minWidth: 150,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      headerClass: classes?.centerHeader,
    },
    {
      headerName: 'Intervals',
      field: 'intervals',
      cellRenderer: IntervalsRenderer,
      minWidth: 250,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      headerClass: classes?.centerHeader,
    },
    {
      headerName: 'Duration/Units',
      field: 'duration_units',
      valueGetter: (params: any) =>
        `${params?.data?.duration} ${params?.data?.duration_units}`,
      minWidth: 180,
      cellClass: 'custom-header-cell',
      cellStyle: {
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      headerClass: classes?.centerHeader,
    },
    {
      headerName: 'Total Qty',
      field: 'total_quantity',
      minWidth: 120,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      headerClass: classes?.centerHeader,
    },
    {
      headerName: 'Start Date',
      field: 'start_date',
      minWidth: 140,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      headerClass: classes?.centerHeader,
    },
    {
      headerName: 'End Date',
      field: 'next_visit_date',
      minWidth: 140,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
      headerClass: classes?.centerHeader,
      cellRenderer: (params: any) => params?.value || '-',
    },
  ]
  return (
    <div className='opd-medication-dashboard opd-medication-container'>
      <MedAgTable
        rowData={allMedications}
        columnDefs={columnDefs}
        headerHeight={30}
        pagination={false}
        enableCellTextSelection={true}
        domLayout='autoHeight'
      />
    </div>
  )
}
export default OpdMedicationDashboard
