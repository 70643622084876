import React from 'react'
import { ElementProps } from '../models'
import FieldLabel from './FieldLabel'
import ErrorMessage from './ErrorMessage'

const TextAreaField = ({
  component,
  state,
  handleChange,
  disabled = false,
  errorMessage,
}: ElementProps) => {
  return (
    <div className='fieldSet'>
      <FieldLabel label={component.label} required={component.required} />
      <div>
        <textarea
          rows={4}
          value={state[component.reference] || ''}
          name={component.reference}
          placeholder={component.placeHolder}
          required={component.required}
          disabled={disabled}
          onChange={(e) => handleChange(e.target.value, component)}
          style={errorMessage ? { borderColor: 'red' } : {}}
        />
        <ErrorMessage errorMessage={errorMessage} />
      </div>
    </div>
  )
}

export default TextAreaField
